import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import TextInput from "../text-input";
import {
  SetStateAction,
  ICoZeConvertToDealForm,
  ICoZeConvertToDealFormErrors,
  ICoZeConvertToDealSuccess,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: ICoZeConvertToDealFormErrors;
  setFormErrors: SetStateAction<ICoZeConvertToDealFormErrors | undefined>;
  form: ICoZeConvertToDealForm;
  setForm: SetStateAction<ICoZeConvertToDealForm>;
  onClose: () => void;
  onConfirm: (
    form: ICoZeConvertToDealForm,
  ) => Promise<ICoZeConvertToDealSuccess | undefined>;
}

export default function ConvertToDealFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const updatedForm = { ...form };
    const depreciationStream = await onConfirm(updatedForm);
    depreciationStream && handleOnClose();
  };

  const handleResetForm = () => {
    setForm({
      project_name: "",
      deal_name: "",
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        <TextInput
          required
          type="string"
          label="Deal Name"
          name="deal_name"
          // endAdornment={<>%</>}
          value={form.deal_name}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.deal_name)}
          helperText={formErrors?.deal_name}
          disabled={loading}
        />
        <TextInput
          required
          type="string"
          label="Project Name"
          name="project_name"
          // endAdornment={<>%</>}
          value={form.project_name}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.project_name)}
          helperText={formErrors?.project_name}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
