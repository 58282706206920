import { lighten } from "@mui/material/styles";
import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    root: {
      position: "absolute",
      left: 0,
      right: 0,
      height: "4px",
      backgroundColor: `${selectedTheme.color.aboveSurface} !important`,
    },
    progressBar: {
      backgroundColor: selectedTheme.color.secondary,
    },
    progressBarBuffer: {
      backgroundColor: lighten(selectedTheme.color.secondary, 0.8),
    },
  };
});
