export const externalDarkTheme = {
  color: {
    primary: "#00C077",
    secondary: "#5F51F4",
    danger: "#F44336",
    gray: "#F6F6F6",
    background: "#000000",
    surface: "#121212",
    aboveSurface: "#212121",
    highlightColor: "#FFFFFF",
    text: "#FFFFFF",
    disabledBackground: "#333333",
    disabledText: "#444444",
    aboveAll: "#333333",
    secondaryHighlight: "#a49f68",
    secondaryBackground: "#000000",
  },
};
