import Layout from "../../components/layout";
import WorkflowWorkingGroupListView from "../../views/workflow/working-group-list";

export default function WorkflowWorkingGroupListPage(): JSX.Element {
  return (
    <Layout title="Workflow">
      <WorkflowWorkingGroupListView />
    </Layout>
  );
}
