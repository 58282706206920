import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputSponsorEquityView from "../../../../views/deal/output/sponsor-equity";

export default function DealOutputSponsorEquityPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputSponsorEquityView />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
