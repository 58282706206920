import React from "react";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";

import MultiAutocompleteField from "../multi-autocomplete-field";
import { cn } from "../../utils/helpers";
import { IProjectFilters, ISelectOption } from "../../interfaces";
import {
  US_STATES,
  PROJECT_STAGE_OPTIONS,
  PROJECT_TYPE_OPTIONS,
  US_STATES_OPTIONS,
  PROJECT_TYPES,
  PROJECT_STAGES,
  PROJECT_COD_OPTIONS,
  PROJECT_STAGE_CONSTRUCTION,
  PROJECT_STAGE_DEVELOPMENT,
  PROJECT_STAGE_OPERATION,
  PROJECT_TAX_CREDIT_TYPE_OPTIONS,
  MARKETS_OPTIONS,
  PROJECT_TAX_CREDIT_TYPE,
  MARKETS,
} from "../../constants";

export default function ProjectFilters(): JSX.Element {
  const [filterParams, setFilterParams] = useSearchParams();
  const [filters, setFilters] = React.useState<IProjectFilters>({});

  React.useEffect(() => {
    const type = filterParams.get("type");
    const stage = filterParams.get("project_stage");
    const state = filterParams.get("state");
    const sub_stage = filterParams.get("sub_stage");
    const cod = filterParams.get("cod");
    const tax_credit__credit_type = filterParams.get("tax_credit__credit_type");
    const power_market = filterParams.get("power_market");

    const getSubStageBasedOnStage = (stageValue: string | null) => {
      switch (stageValue) {
        case "DEV":
          return Object.keys(PROJECT_STAGE_DEVELOPMENT) as Array<
            keyof typeof PROJECT_STAGES
          >;
        case "CON":
          return Object.keys(PROJECT_STAGE_CONSTRUCTION) as Array<
            keyof typeof PROJECT_STAGES
          >;
        case "OP":
          return Object.keys(PROJECT_STAGE_OPERATION) as Array<
            keyof typeof PROJECT_STAGES
          >;
        default:
          return sub_stage?.split(",") as Array<keyof typeof PROJECT_STAGES>;
      }
    };
    if (
      state ||
      type ||
      cod ||
      sub_stage ||
      tax_credit__credit_type ||
      power_market
    ) {
      setFilters((prev) => {
        const newFilters = { ...prev };
        if (state) {
          newFilters.state = state?.split(",") as Array<keyof typeof US_STATES>;
        }
        if (type) {
          newFilters.type = type?.split(",") as Array<
            keyof typeof PROJECT_TYPES
          >;
        }
        if (cod) {
          newFilters.cod = cod?.split(",");
        }
        if (sub_stage) {
          newFilters.sub_stage = getSubStageBasedOnStage(stage);
        }
        if (tax_credit__credit_type) {
          newFilters.tax_credit__credit_type = tax_credit__credit_type?.split(
            ",",
          ) as Array<keyof typeof PROJECT_TAX_CREDIT_TYPE>;
        }
        if (power_market) {
          newFilters.power_market = power_market?.split(",") as Array<
            keyof typeof MARKETS
          >;
        }
        return newFilters;
      });
    } else {
      setFilters({
        sub_stage: [],
        state: [],
        type: [],
        cod: [],
        tax_credit__credit_type: [],
        power_market: [],
      });
    }
  }, [filterParams]);

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | ISelectOption[] | null,
    name: string,
  ) => {
    if (Array.isArray(value)) {
      const v = value?.map((o: ISelectOption) => o.value);
      setFilters((prev) => {
        return {
          ...prev,
          [name]: v as Array<string>,
        };
      });
      const params = new URLSearchParams(filterParams);
      params.set(name, v.toString());
      setFilterParams(params);
    }
  };

  return (
    <Box
      className={cn(
        "flex flex-col gap-4 md:grid md:grid-cols-6 gap-y-1 gap-x-4 md:items-start",
      )}
    >
      <MultiAutocompleteField
        label="Type"
        name="type"
        options={PROJECT_TYPE_OPTIONS}
        value={filters.type || []}
        onChange={handleAutoCompleteChange}
        limitTags={2}
        size="small"
      />
      <MultiAutocompleteField
        label="Stage"
        name="sub_stage"
        options={PROJECT_STAGE_OPTIONS}
        value={filters.sub_stage || []}
        onChange={handleAutoCompleteChange}
        limitTags={2}
        size="small"
      />
      <MultiAutocompleteField
        label="Project State"
        name="state"
        options={US_STATES_OPTIONS}
        value={filters.state || []}
        onChange={handleAutoCompleteChange}
        dataPw="project-state-filter"
        limitTags={2}
        size="small"
      />
      <MultiAutocompleteField
        label="COD"
        name="cod"
        options={PROJECT_COD_OPTIONS}
        value={filters.cod || []}
        onChange={handleAutoCompleteChange}
        limitTags={2}
        size="small"
      />
      <MultiAutocompleteField
        label="Tax Credit Type"
        name="tax_credit__credit_type"
        options={PROJECT_TAX_CREDIT_TYPE_OPTIONS}
        value={filters.tax_credit__credit_type || []}
        onChange={handleAutoCompleteChange}
        limitTags={2}
        size="small"
      />
      <MultiAutocompleteField
        label="Power Market"
        name="power_market"
        options={MARKETS_OPTIONS}
        value={filters.power_market || []}
        onChange={handleAutoCompleteChange}
        limitTags={2}
        size="small"
      />
    </Box>
  );
}
