import React, { SetStateAction } from "react";
import MuiButton from "@mui/material/Button";
import { format } from "date-fns";

import useStyles from "./styles";
import { cn, numberToUSD } from "../../utils/helpers";

interface IProps {
  curve: number[];
  dateSchedule: string[];
  inUSD?: boolean;
  toggle: boolean;
  handleToggle: React.Dispatch<SetStateAction<boolean>>;
  showTotal?: { total: number | null };
  showOnlyYear?: boolean;
  startAdornment?: string;
  endAdornment?: string;
  initialVisibleRows?: number;
}

export default function DateSchedule({
  curve,
  dateSchedule,
  inUSD,
  toggle,
  handleToggle,
  showTotal,
  showOnlyYear = false,
  startAdornment = "",
  endAdornment = "",
  initialVisibleRows = 5,
}: IProps) {
  const styles = useStyles();

  // convert YYYY-MM-DD to YYYY/MM/DD
  if (dateSchedule?.length > 0 && dateSchedule[0]?.includes("-")) {
    dateSchedule = dateSchedule.map((d) => d.replaceAll("-", "/"));
  }

  dateSchedule = dateSchedule.map((d) =>
    format(new Date(d), showOnlyYear ? "yyyy" : "M/d/yyyy"),
  );

  const renderable = curve.map((p, i) => {
    const value = inUSD
      ? `${startAdornment}${numberToUSD.format(p)}${endAdornment}`
      : `${startAdornment}${p}%${endAdornment}`;
    return (
      <span key={i} style={{ display: "block" }}>
        <span className={styles.classes.date}>{dateSchedule[i]}</span>
        {value}
      </span>
    );
  });

  const onToggleClick = () => {
    handleToggle((prev) => !prev);
  };

  return (
    <>
      {renderable.slice(0, toggle ? undefined : initialVisibleRows)}
      {dateSchedule.length > initialVisibleRows && (
        <span className={cn("block h-[36px] w-[100%]")}>
          <MuiButton
            classes={{ root: styles.classes.showMoreCurveBtn }}
            onClick={onToggleClick}
          >
            {toggle ? "Show Less" : "Show More"}
          </MuiButton>
        </span>
      )}

      {showTotal ? (
        <span className={cn("flex float-end font-bold")}>
          <span className={styles.classes.date}>Total</span>
          {showTotal.total !== null && inUSD
            ? numberToUSD.format(showTotal.total)
            : showTotal.total}
        </span>
      ) : null}
    </>
  );
}
