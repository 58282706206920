import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS } from "../../../constants";
import {
  IDealDebt,
  IDealCase,
  IDealDetails,
  DealOutputPageTypes,
} from "../../../interfaces";

export interface IDealSliceState {
  currentDeal: IDealDetails | undefined;
  currentDealStatus: { loading: boolean; errored: boolean };
  currentDealTermDebt: IDealDebt | undefined;
  currentDealTermDebtStatus: { loading: boolean; errored: boolean };
  selectedDealOutputPageTab: {
    [k in DealOutputPageTypes | "dealId"]: string;
  };
  dealOutputLoaded: boolean;
  casesOfDeal: IDealCase[];
}

export const dealSlice = createSlice({
  name: "deal",
  initialState: {
    currentDeal: undefined,
    currentDealStatus: { loading: false, errored: false },
    currentDealTermDebt: undefined,
    currentDealTermDebtStatus: { loading: false, errored: false },
    selectedDealOutputPageTab: DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
    dealOutputLoaded: false,
    casesOfDeal: [],
    status: "ACT",
  },
  reducers: {
    setCurrentDeal(
      state: IDealSliceState,
      action: PayloadAction<IDealSliceState["currentDeal"]>,
    ) {
      // This if condition is needed because of sidebar navigations in deal layout
      // Show deal > output > construction-debt when is_construction_debt_added is true
      // has_construction_loan and has_investor_bridge_loan are being toggled in frontend
      // when they are added/removed from deal > sizing > construction-debt
      if (action.payload) {
        action.payload.is_construction_debt_added =
          action.payload.has_construction_loan ||
          action.payload.has_investor_bridge_loan;
      }
      state.currentDeal = action.payload;
    },
    setCurrentDealStatus(
      state: IDealSliceState,
      action: PayloadAction<IDealSliceState["currentDealStatus"]>,
    ) {
      state.currentDealStatus = action.payload;
    },
    setCurrentDealTermDebt(
      state: IDealSliceState,
      action: PayloadAction<IDealSliceState["currentDealTermDebt"]>,
    ) {
      state.currentDealTermDebt = action.payload;
    },
    setCurrentDealTermDebtStatus(
      state: IDealSliceState,
      action: PayloadAction<IDealSliceState["currentDealTermDebtStatus"]>,
    ) {
      state.currentDealTermDebtStatus = action.payload;
    },
    updateSelectedDealOutputPageTab(
      state: IDealSliceState,
      action: PayloadAction<IDealSliceState["selectedDealOutputPageTab"]>,
    ) {
      state.selectedDealOutputPageTab = action.payload;
    },
    updateDealOutputLoaded(
      state: IDealSliceState,
      action: PayloadAction<IDealSliceState["dealOutputLoaded"]>,
    ) {
      state.dealOutputLoaded = action.payload;
    },
    setCasesOfDeal(
      state: IDealSliceState,
      action: PayloadAction<IDealSliceState["casesOfDeal"]>,
    ) {
      state.casesOfDeal = action.payload;
    },
    resetSelectedDealOutputPageTab(
      state: IDealSliceState,
      action: PayloadAction<keyof IDealSliceState["selectedDealOutputPageTab"]>,
    ) {
      state.selectedDealOutputPageTab[action.payload] =
        DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS[action.payload];
    },
  },
});
