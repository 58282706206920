import React from "react";
import TableCell, { TableCellProps } from "@mui/material/TableCell";

import useStyle from "./style";

interface IStickyTableCellProps extends TableCellProps {
  direction?: "left" | "right";
  fixedColumnWidth: number;
  highZIndex?: boolean;
}

export default function StickyTableCell({
  direction = "left",
  fixedColumnWidth,
  highZIndex,
  children,
  ...restProps
}: IStickyTableCellProps) {
  const style = useStyle({ direction, fixedColumnWidth, highZIndex });

  return (
    <TableCell classes={{ root: style.classes.stickyTableCell }} {...restProps}>
      {children}
    </TableCell>
  );
}
