import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{ headerHeight?: number }>()((
  theme,
  { headerHeight },
) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    boldRow: {
      ".MuiTableCell-root": {
        fontWeight: 600,
      },
    },
    stickyRow: {
      position: "sticky",
      top: `${headerHeight}px`,
      zIndex: 6,
      backgroundColor: selectedTheme.color.surface,
    },
  };
});
