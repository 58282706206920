import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    logsDrawer: {
      position: "absolute",
      top: 64,
      width: 370,
      height: "calc(100vh - 64px)",
    },
    title: {
      position: "sticky",
      top: 0,
      backgroundColor: selectedTheme.color.aboveAll,
      borderBottom: `1px solid ${selectedTheme.color.background}`,
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      padding: theme.spacing(1),
      zIndex: 10,
    },
  };
});
