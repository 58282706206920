import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  dealSection: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
    "& > *": {
      width: "49%",
    },
    "& > *:first-of-type": {
      marginBottom: theme.spacing(2),
      "& > *:first-of-type": {
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down(900)]: {
      flexDirection: "column",
      "& > *": {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        width: "100%",
      },
    },
  },
}));
