import DealPagesLayout from "../../../components/deal-pages-layout";
import DealDepreciationView from "../../../views/deal/components/depreciation";
import TabbedLayout from "../../../components/tabbed-layout";
import {
  getDealProjectDepreciation,
  updateDealProjectDepreciation,
} from "../../../apis/deal/depreciation";

export default function DealDepreciation(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Components">
        <DealDepreciationView
          getDealProjectDepreciation={getDealProjectDepreciation}
          editDealDepreciation={updateDealProjectDepreciation}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
