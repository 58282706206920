import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputTaxEquitySummaryView from "../../../../views/deal/output/tax-equity/summary";
import { getTaxEquityReportSummary } from "../../../../apis/report/deal";

export default function DealOutputTaxEquitySummary(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputTaxEquitySummaryView
          getTaxEquityReportSummary={getTaxEquityReportSummary}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
