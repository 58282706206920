import { darken, lighten } from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../utils/helpers";

export default makeStyles<{ reduceHeight: number }>()((
  theme,
  { reduceHeight },
) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    container: {
      padding: theme.spacing(2),
      maxWidth: "calc(100vw - 241px)",
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
      },
    },
    datagrid: {
      "& .MuiDataGrid-columnHeader": {
        // Forced to use important since overriding inline styles
        paddingBlock: "0.75rem !important",
        height: "unset !important",
        minWidth: "250px !important",
        lineHeight: "1.3 !important",
      },
      "& .MuiDataGrid-columnHeaders": {
        // Forced to use important since overriding inline styles
        maxHeight: "350px !important",
      },
      "& .bgGray": {
        backgroundColor: selectedTheme.color.aboveSurface,
      },
      "& .dealHeading:hover": {
        cursor: "pointer",
      },
      "& .fontBold": {
        fontWeight: "bolder",
      },
      "& .underlinedSection": {
        textDecoration: "underline",
        color: selectedTheme.color.text,
        backgroundColor: selectedTheme.color.aboveSurface,
      },
      "& .MuiDataGrid-columnHeaderTitleContainerContent": {
        width: "100%",
      },
      "& .datagridCell-highlight": {
        backgroundColor: `${selectedTheme.color.secondaryHighlight} !important`,
      },
      [theme.breakpoints.up("md")]: {
        width: "calc(100vw - 262px)",
      },
      width: "100%",
      height: `calc(100vh - ${reduceHeight}px)`,
    },
    backButtonContainer: {
      display: "flex",
      alignItems: "center",
      marginTop: theme.spacing(1),
      cursor: "pointer",
      color: selectedTheme.color.gray,
      marginBottom: theme.spacing(2),
      "&:hover": {
        color: selectedTheme.color.secondary,
      },
    },
    downloadBtn: {
      backgroundColor: selectedTheme.color.secondary,
      padding: theme.spacing(0.25),
      textTransform: "none",
      alignSelf: "start",
      color: "#fff",
      ":hover": {
        backgroundColor: darken(selectedTheme.color.secondary, 0.2),
      },
      margin: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      "& .MuiButtonBase-root": {
        paddingLeft: 0,
      },
    },
    disabledDownloadBtn: {
      color: `${theme.palette.grey[500]} !important`,
      backgroundColor: `${lighten(
        selectedTheme.color.secondary,
        0.2,
      )} !important`,
    },
  };
});
