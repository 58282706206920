import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CopyIcon from "@mui/icons-material/ContentCopy";
import Typography from "@mui/material/Typography";

import IconButton from "../icon-button";
import { cn } from "../../utils/helpers";
import { SetStateAction } from "../../interfaces";
import { useCopyToClipboard } from "../../utils/hooks";

interface IProps {
  label: string;
  primaryAction: SetStateAction<boolean>;
  toggled: boolean;
  value: (string | number | null)[] | null;
}

export default function CurveFieldLabel({
  label,
  primaryAction,
  toggled,
  value,
}: IProps) {
  const { copyToClipboard } = useCopyToClipboard();

  const handleCopy = (data: (string | number | null)[] | null) => {
    if (data) copyToClipboard(data);
  };

  return (
    <span className={cn("flex items-center gap-2")}>
      <Typography component="span">{label}</Typography>
      <span>
        <IconButton onClick={() => primaryAction((v) => !v)}>
          {toggled ? (
            <ExpandLessIcon fontSize="small" />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )}
        </IconButton>
        <IconButton onClick={() => handleCopy(value)}>
          <CopyIcon fontSize="small" />
        </IconButton>
      </span>
    </span>
  );
}
