import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputDebtSummaryView from "../../../../views/deal/output/debt/summary";
import { getDebtReportSummary } from "../../../../apis/report/deal";

export default function DealOutputDebtSummary(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputDebtSummaryView
          getDebtReportSummary={getDebtReportSummary}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
