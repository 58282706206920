import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import AddIcon from "@mui/icons-material/Add";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyles from "../../common-styles";
import Button from "../../../../../../components/button";
import PopoverMenu from "../../../../../../components/popover-menu";
import ViewWrapper from "../../../../../../components/view-wrapper";
import ProjectRevenueContractFormModal from "../../../../../../components/project-revenue-contract-form-modal";
import StickyTableCell from "../../../../../../components/sticky-table-cell";
import { useAPI, useAppSelector } from "../../../../../../utils/hooks";
import {
  setCurrentOrgCurvesAction,
  setDeleteModalPropsAction,
} from "../../../../../../utils/redux/slices";
import {
  USER_PERMISSIONS,
  PROJECT_REVENUE_CONTRACT_TYPE,
  PROJECT_REVENUE_FORM_DEFAULT_STATE,
} from "../../../../../../constants";
import {
  numberToUSD,
  sortArrayOfObjects,
} from "../../../../../../utils/helpers";
import {
  IProjectRevenueFormErrors,
  ServerPaginatedResponse,
  IProjectRevenueForm,
  IProjectRevenue,
  IProjectTiming,
  ITableColumn,
  ITableSort,
  ISelectOption,
  IGetCurvesParams,
  IOrganizationCurve,
} from "../../../../../../interfaces";

const REVENUE_TABLE_COLUMNS: ITableColumn[] = [
  { id: "type", label: "Type", minWidth: 80, align: "left" },
  { id: "name", label: "Name", minWidth: 80, align: "left" },
  { id: "start_date", label: "Start Date", minWidth: 80, align: "left" },
  { id: "term_years", label: "Term (Yrs)", minWidth: 80, align: "left" },
  { id: "price", label: "Price", minWidth: 80, align: "left" },
  { id: "escalator", label: "Escalator", minWidth: 80, align: "left" },
  {
    id: "base_year",
    label: "Escalator Base Year",
    minWidth: 80,
    align: "left",
  },
  { id: "volume_percentage", label: "Volume %", minWidth: 80, align: "left" },
  { id: "volume", label: "Volume (MW)", minWidth: 80, align: "left" },
  {
    id: "cash_basis_lag",
    label: "Cash Lag (Months)",
    minWidth: 80,
    align: "left",
  },
  { id: "action", label: "Action", minWidth: 80, align: "right" },
];

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  getProjectRevenueContracts: (
    projectId: number,
  ) => Promise<ServerPaginatedResponse<IProjectRevenue[]>>;
  addProjectRevenueContract: (
    projectId: number,
    form: IProjectRevenueForm,
  ) => Promise<IProjectRevenue>;
  editProjectRevenueContract: (
    projectId: number,
    revenueContractId: number,
    form: IProjectRevenueForm,
  ) => Promise<IProjectRevenue>;
  deleteProjectRevenueContract: (
    projectId: number,
    revenueContractId: number,
  ) => Promise<boolean>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
}

export default function ProjectRevenueContractsView({
  getProjectTiming,
  getProjectRevenueContracts,
  addProjectRevenueContract,
  editProjectRevenueContract,
  deleteProjectRevenueContract,
  getCurves,
}: IProps): JSX.Element {
  const styles = useStyles();

  const navigate = useNavigate();
  const { projectId } = useParams();

  const dispatch = useDispatch();
  const { currentProject } = useAppSelector((s) => s.project);
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { currentOrgCurves } = useAppSelector((s) => s.org);

  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();

  const [selectedRevenueToEdit, setSelectedRevenueToEdit] =
    React.useState<number>();
  const [addRevenueContractModalOpen, setAddRevenueContractModalOpen] =
    React.useState<boolean>(false);
  const [editRevenueContractModalOpen, setEditRevenueContractModalOpen] =
    React.useState<boolean>(false);
  const [revenueContracts, setRevenueContracts] = React.useState<
    IProjectRevenue[]
  >([]);

  const [sortRevenueTable, setSortRevenueTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [form, setForm] = React.useState<IProjectRevenueForm>(
    PROJECT_REVENUE_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getProjectRevenueCallAPI(Number(projectId)).then((response) => {
      response && setRevenueContracts(response.results);
    });
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setProjectTiming(response[0]);
    });
  }, [projectId]);

  const {
    callAPI: getProjectRevenueCallAPI,
    errored: getRevenueFailed,
    loading: loadingRevenue,
  } = useAPI((projectId: number) => getProjectRevenueContracts(projectId), {
    initialLoading: true,
  });

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: addProjectRevenueContractCallAPI,
    fieldErrors: addProjectRevenueContractFormErrors,
    setFieldErrors: setAddProjectRevenueContractFormErrors,
    loading: addingRevenueContract,
  } = useAPI<IProjectRevenue, IProjectRevenueFormErrors>(
    (projectId: number, form: IProjectRevenueForm) =>
      addProjectRevenueContract(projectId, form),
  );

  const {
    callAPI: editProjectRevenueContractCallAPI,
    fieldErrors: editProjectRevenueContractFormErrors,
    setFieldErrors: setEditProjectRevenueContractFormErrors,
    loading: editRevenueContractLoading,
  } = useAPI<IProjectRevenue, IProjectRevenueFormErrors>(
    (projectId: number, revenueId: number, form: IProjectRevenueForm) =>
      editProjectRevenueContract(projectId, revenueId, form),
  );

  const { callAPI: deleteProjectRevenueContractCallAPI } = useAPI(
    (projectId: number, revenueId: number) =>
      deleteProjectRevenueContract(projectId, revenueId),
    { setConfirmModalLoading: true },
  );

  const handleAddRevenueContract = async (form: IProjectRevenueForm) => {
    const revenue = await addProjectRevenueContractCallAPI(
      Number(projectId),
      form,
    );

    if (revenue) {
      getProjectRevenueCallAPI(Number(projectId)).then((response) => {
        response && setRevenueContracts(response.results);
      });
    }

    return revenue;
  };

  const handleEditRevenueContract = async (form: IProjectRevenueForm) => {
    const revenue = await editProjectRevenueContractCallAPI(
      Number(projectId),
      Number(selectedRevenueToEdit),
      form,
    );

    if (revenue) {
      const updatedRevenueContracts = revenueContracts.map((d) => {
        if (d.id === revenue.id) {
          return revenue;
        }
        return d;
      });
      setRevenueContracts(updatedRevenueContracts);
    }
    return revenue;
  };

  const sortedRevenueRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        revenueContracts,
        sortRevenueTable?.orderBy,
        sortRevenueTable?.order,
      ),
    [revenueContracts, sortRevenueTable],
  );

  const getDefaultBaseYear = (cod?: string) => {
    return !isNaN(Date.parse(cod || ""))
      ? new Date(cod || "").getFullYear()
      : "";
  };

  const handleOpenAddRevenueContractModal = async () => {
    await handleGetCurves();
    setForm((prevState) => ({
      ...prevState,
      start_date: projectTiming?.cod || "",
      base_year: getDefaultBaseYear(projectTiming?.cod),
      cash_basis_lag: 0,
      term_input_method: "YR",
      start_input_method: "SP",
      term_start_point: "COD",
      term_end_point: "PEOL",
      revenue_curve: new Array(projectTiming?.date_schedule.length).fill(0),
    }));
    setAddRevenueContractModalOpen(true);
  };

  const handleGetCurves = async () => {
    const curves = await getCurves({ curve_type: ["PC"] }).catch(() => null);
    curves && dispatch(setCurrentOrgCurvesAction(curves.results));
  };

  const handleOpenEditRevenueContractModal = async (id: number) => {
    const revenueContract = revenueContracts.find((d) => d.id === id);

    if (revenueContract) {
      await handleGetCurves();
      const { ...otherProps } = revenueContract;
      let { base_year } = revenueContract;
      if (!base_year) {
        base_year = getDefaultBaseYear(projectTiming?.cod) || null;
      }
      setSelectedRevenueToEdit(id);
      setForm({
        ...otherProps,
        base_year,
      });
      setEditRevenueContractModalOpen(true);
    }
  };

  const handleCloseEditRevenueContractModal = () => {
    setSelectedRevenueToEdit(undefined);
    setEditRevenueContractModalOpen(false);
  };

  const handleCloseAddRevenueContractModal = () => {
    setAddRevenueContractModalOpen(false);
  };

  const sortRevenueRows = (orderBy: string) => {
    if (orderBy === sortRevenueTable.orderBy) {
      setSortRevenueTable({
        orderBy,
        order: sortRevenueTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortRevenueTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const goToRevenue = (id: number) => {
    if (ctrlPressed) {
      window.open(`/project/${projectId}/pro-forma/revenue/${id}`, "_blank");
      return;
    }
    navigate(`/project/${projectId}/pro-forma/revenue/${id}`);
  };

  const handleOnDeleteRevenueClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Revenue",
        description: "Are you sure you want to delete this revenue?",
        onConfirm: () => handleDeleteRevenueContract(id),
      }),
    );
  };

  const handleDeleteRevenueContract = async (id: number) => {
    const response = await deleteProjectRevenueContractCallAPI(
      Number(projectId),
      id,
    );

    if (response) {
      setRevenueContracts(revenueContracts.filter((d) => d.id !== id));
    }
  };

  const organizationCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves.map((c) => {
      return {
        label: c.name,
        value: String(c.id),
      };
    });
  }, [currentOrgCurves]);

  return (
    <>
      <ViewWrapper loading={loadingRevenue} error={getRevenueFailed}>
        <Box className={styles.classes.topSection}>
          <Typography variant="h6">Revenue Contracts</Typography>
          <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
            <Button
              canOpenUpgrade
              startIcon={<AddIcon />}
              btnType="primary"
              label="Add Revenue Contract"
              onClick={handleOpenAddRevenueContractModal}
            />
          </Protect>
        </Box>

        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer classes={{ root: styles.classes.tableContainer }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead className={styles.classes.header}>
                <TableRow>
                  {REVENUE_TABLE_COLUMNS.map((column, idx) => {
                    if (column.id === "action") {
                      return (
                        <StickyTableCell
                          key={idx}
                          direction="right"
                          fixedColumnWidth={80}
                          align="center"
                          highZIndex
                        >
                          {column.label}
                        </StickyTableCell>
                      );
                    }
                    return (
                      <TableCell
                        key={idx}
                        align={column.align as "left"}
                        style={{ minWidth: column.minWidth }}
                      >
                        <TableSortLabel
                          active={sortRevenueTable.orderBy === column.id}
                          direction={
                            sortRevenueTable.orderBy === column.id
                              ? sortRevenueTable.order
                              : "asc"
                          }
                          onClick={() => sortRevenueRows(column.id)}
                        >
                          {column.label}
                        </TableSortLabel>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRevenueRows.length === 0 ? (
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={REVENUE_TABLE_COLUMNS.length}
                    >
                      There are no revenue contracts in place currently, please
                      add one.
                    </TableCell>
                  </TableRow>
                ) : null}
                {sortedRevenueRows.map((revenue, idx) => {
                  return (
                    <TableRow
                      hover
                      key={idx}
                      tabIndex={-1}
                      className={styles.classes.dataRow}
                      onClick={() => goToRevenue(revenue.id)}
                      data-pw={`project-revenue-${idx + 1}`}
                    >
                      <TableCell align="left">
                        {revenue.type === "CAPACITY" &&
                        currentProject?.type === "BAST"
                          ? "Tolling Agreement"
                          : PROJECT_REVENUE_CONTRACT_TYPE[revenue.type]}
                      </TableCell>
                      <TableCell align="left">
                        {revenue.name !== null ? revenue.name : ""}
                      </TableCell>

                      <TableCell align="left">{revenue.start_date}</TableCell>
                      <TableCell align="left">
                        {revenue.type === "OTHER" ? "N/A" : revenue.term_years}
                      </TableCell>
                      <TableCell align="left">
                        {revenue.type === "OTHER"
                          ? "Schedule"
                          : revenue.price_input_type === "OC"
                            ? "Org Curve"
                            : numberToUSD.format(revenue.price) +
                              (revenue.type === "CAPACITY"
                                ? " / kW-Month"
                                : " / MWh")}
                      </TableCell>
                      <TableCell align="left">
                        {revenue.type === "OTHER"
                          ? "N/A"
                          : revenue.escalator + "%"}
                      </TableCell>
                      <TableCell align="left">
                        {revenue.type === "OTHER" ? "N/A" : revenue.base_year}
                      </TableCell>
                      <TableCell align="left">
                        {revenue.type === "OTHER"
                          ? "N/A"
                          : revenue.volume_percentage}
                      </TableCell>
                      <TableCell align="left">
                        {revenue.type === "OTHER" ? "N/A" : revenue.volume}
                      </TableCell>
                      <TableCell align="left">
                        {revenue.cash_basis_lag === null
                          ? "N/A"
                          : revenue.cash_basis_lag}
                      </TableCell>
                      <StickyTableCell
                        direction="right"
                        fixedColumnWidth={80}
                        align="center"
                      >
                        <PopoverMenu
                          uniqueId={idx}
                          canOpenUpgrade
                          items={[
                            {
                              label: "Edit",
                              dataPw: `edit-revenue-${idx + 1}`,
                              onClick: () =>
                                handleOpenEditRevenueContractModal(revenue.id),
                            },
                            {
                              label: "Delete",
                              dataPw: `delete-revenue-${idx + 1}`,
                              onClick: () =>
                                handleOnDeleteRevenueClick(revenue.id),
                            },
                          ]}
                        />
                      </StickyTableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </ViewWrapper>

      <ProjectRevenueContractFormModal
        headerLabel="Add Revenue Contract"
        open={addRevenueContractModalOpen}
        loading={addingRevenueContract}
        dateSchedule={projectTiming?.date_schedule || []}
        formErrors={addProjectRevenueContractFormErrors}
        setFormErrors={setAddProjectRevenueContractFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseAddRevenueContractModal}
        onConfirm={handleAddRevenueContract}
        org_curves={organizationCurvesOptions}
      />

      <ProjectRevenueContractFormModal
        headerLabel="Edit Revenue"
        open={editRevenueContractModalOpen}
        loading={editRevenueContractLoading}
        dateSchedule={projectTiming?.date_schedule || []}
        formErrors={editProjectRevenueContractFormErrors}
        setFormErrors={setEditProjectRevenueContractFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseEditRevenueContractModal}
        onConfirm={handleEditRevenueContract}
        org_curves={organizationCurvesOptions}
      />
    </>
  );
}
