import { getDealReporting } from "../../../../apis/report/deal";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputConstructionDebtConstructionLoanView from "../../../../views/deal/output/construction-debt/construction-loan";

export default function DealOutputConstructionDebtConstructionLoanPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputConstructionDebtConstructionLoanView
          getDealReporting={getDealReporting}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
