import { IUpdateProjectProductionForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum PROJECT_GROSS_PRODUCTION_METHOD {
  NCF = "Gross NCF",
  AEP = "Gross AEP",
  YLD = "Gross Yield",
}

export const PROJECT_GROSS_PRODUCTION_METHOD_OPTIONS = enumToOptions(
  PROJECT_GROSS_PRODUCTION_METHOD,
);

export enum PROJECT_PRODUCTION_INPUT_TYPE {
  SI = "Single Input",
  TI = "Table Input",
}

export const PROJECT_PRODUCTION_INPUT_TYPE_OPTIONS = enumToOptions(
  PROJECT_PRODUCTION_INPUT_TYPE,
);

export enum PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE {
  P50_10YR = "P50 (10-Yr)",
  P75_10YR = "P75 (10-Yr)",
  P90_10YR = "P90 (10-Yr)",
  P95_10YR = "P95 (10-Yr)",
  P99_10YR = "P99 (10-Yr)",
  P50_1YR = "P50 (1-Yr)",
  P75_1YR = "P75 (1-Yr)",
  P90_1YR = "P90 (1-Yr)",
  P95_1YR = "P95 (1-Yr)",
  P99_1YR = "P99 (1-Yr)",
}

export const PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE_OPTIONS = enumToOptions(
  PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE,
);

export enum PROJECT_PRODUCTION_SEASONALITY_ADJUSTMENT_TYPE {
  MO = "Monthly",
  QU = "Quarterly",
  SA = "Semi-Annual",
  AN = "Annual",
}

export const PROJECT_PRODUCTION_SEASONALITY_ADJUSTMENT_TYPE_OPTIONS =
  enumToOptions(PROJECT_PRODUCTION_SEASONALITY_ADJUSTMENT_TYPE);

export enum PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES {
  DRP = "Degradation Rate",
  DRPC = "Degradation Percentage Curve",
}

export enum PROJECT_PRODUCTION_DEGRADATION_METHODS {
  LIN = "Linear",
  GEO = "Geometric",
}

export const PROJECT_PRODUCTION_DEGRADATION_METHODS_OPTIONS = enumToOptions(
  PROJECT_PRODUCTION_DEGRADATION_METHODS,
);

export const PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES_OPTIONS = enumToOptions(
  PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES,
);

export enum PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES {
  CP = "Curtailment Percentage",
  CPC = "Curtailment Percentage Curve",
}

export const PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES_OPTIONS = enumToOptions(
  PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES,
);

export enum PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES {
  OLP = "Other Losses Percentage",
  OLPC = "Other Losses Percentage Curve",
}

export const PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES_OPTIONS =
  enumToOptions(PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES);

export enum PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES {
  AFP = "Availability Factor Percentage",
  AFPC = "Availability Factor Percentage Curve",
}

export const PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES_OPTIONS =
  enumToOptions(PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES);

export const UPDATE_PROJECT_PRODUCTION_FORM_DEFAULT_STATE: IUpdateProjectProductionForm =
  {
    availability_factor_input_type: "",
    availability_factor_percentage: "",
    availability_factor_percentage_curve: [],
    capacity_ac: "",
    capacity_dc: "",
    duration_hrs: null,
    // conversion_rate: "",
    curtailment_input_type: "",
    curtailment_percentage: "",
    curtailment_percentage_curve: [],
    degradation_input_type: "",
    degradation_method: "",
    degradation_rate_percentage: "",
    degradation_rate_percentage_curve: [],
    production_input_type: "",
    production_input: "",
    gross_production: "",
    standard_deviation: null,
    gross_production_method: "",
    other_losses_input_type: "",
    other_losses_percentage: "",
    other_losses_percentage_curve: [],
    probability_factor_type: "",
    case_probability_factor_type: "",
    adjust_for_leap_years: false,
    seasonality_adjustment_factors: [],
    seasonality_adjustment_type: "",
    seasonality_adjustment_factors_total: 0,
  };

export const PROJECT_PRODUCTION_METHOD_INPUT_UNIT_MAP: Record<
  keyof typeof PROJECT_GROSS_PRODUCTION_METHOD,
  string
> = {
  NCF: " %",
  AEP: " MWh",
  YLD: " kWh/kWp",
};
