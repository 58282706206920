import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IDealTaxCreditTransferPortfolio,
  IDealTaxCreditTransferPortfolioForm,
} from "../../interfaces";

export const getDealTransferPortfolio = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.transferPortfolio.get, { dealId });
  return await AxiosHelper.get<IDealTaxCreditTransferPortfolio[]>(url);
};

export const updateDealTransferPortfolio = async (
  dealId: number,
  transferConfigId: number,
  form: IDealTaxCreditTransferPortfolioForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.transferPortfolio.update, {
    dealId,
    transferConfigId,
  });
  return await AxiosHelper.patch<
    IDealTaxCreditTransferPortfolioForm,
    IDealTaxCreditTransferPortfolio
  >(url, form);
};
