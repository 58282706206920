import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import ReportTable from "../../../../../components/report-table";
import ViewWrapper from "../../../../../components/view-wrapper";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import { useAxios } from "../../../../../components/axios-provider";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { DEVELOPER_CASH_REPORT_TABLE_COLUMNS } from "../../../../../constants";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  useAPI,
  useAppSelector,
  useSessionStorage,
} from "../../../../../utils/hooks";
import {
  getDealReportingUrl,
  generateDeveloperCashReportRows,
} from "../../../../../utils/helpers";
import {
  ITableRow,
  ReportTerm,
  ReportType,
  ReportPerspective,
  IDeveloperCashReport,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IDeveloperCashReport>;
}

export default function DealOutputDeveloperCashView({
  getDealReporting,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { dealId, caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const { currentDeal } = useAppSelector((s) => s.deal);

  const [report, setReport] = React.useState<IDeveloperCashReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealId}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealId}-output-periodicity`,
      "MO",
    );

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      periodicity: OutputReportPeriodicityType,
    ) => getDealReporting(dealId, "developer", "cashdetail", term, periodicity),
    { initialLoading: true },
  );

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealReportingCallAPI(Number(caseDealId), buyout, periodicityType).then(
        (response) => {
          if (response?.data) {
            setReport(response);
          }
        },
      );
    }
  }, [caseDealId, buyout, periodicityType]);

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [getReportFailed, loadingReport]);

  const handleDownloadReport = async () => {
    if (caseDealId) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(caseDealId),
        "developer",
        "cashdetail",
        buyout,
        periodicityType,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const cashTableHeader = React.useMemo(() => {
    if (currentDeal?.developer_horizon === "DO") {
      return DEVELOPER_CASH_REPORT_TABLE_COLUMNS.filter(
        (c) =>
          c.id !== "developer_fee_payments" &&
          c.id !== "development_cost_reimbursements",
      );
    }
    return DEVELOPER_CASH_REPORT_TABLE_COLUMNS;
  }, [currentDeal]);

  const cashTableRows: ITableRow[] = React.useMemo(() => {
    return report
      ? generateDeveloperCashReportRows(report, cashTableHeader)
      : [];
  }, [cashTableHeader, report]);

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealReportingUrl(
            Number(caseDealId),
            "developer",
            "cashdetail",
            buyout,
            periodicityType,
          )
        ]
      }
    >
      <DealOutputFilterFields
        buyout={buyout}
        periodicityType={periodicityType}
        setBuyout={setBuyout}
        setPeriodicityType={setPeriodicityType}
        downloadReport={handleDownloadReport}
        disableDownload={isDownloading}
        showPeriodicity
      />
      <ReportTable
        columns={cashTableHeader}
        rows={cashTableRows}
        boldTotal
        stickyTotal
      />
    </ViewWrapper>
  );
}
