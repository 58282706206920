import MuiTab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import MuiTabs, { TabsProps } from "@mui/material/Tabs";

import useStyles from "./styles";
import { ITab } from "../../interfaces";
import { useAppSelector } from "../../utils/hooks";

interface IProps {
  tabs: ITab[];
  selectedTab?: string;
  onClick: (tab: ITab) => void;
  tabProps?: TabsProps;
  children: JSX.Element;
  hasStickyTabs?: boolean;
}

export default function ContentWrappedTabs({
  tabs,
  selectedTab,
  onClick,
  tabProps,
  children,
  hasStickyTabs = false,
}: IProps): JSX.Element {
  const { contentLoaded } = useAppSelector((s) => s.common);
  const styles = useStyles({ contentLoaded, hasStickyTabs });

  return (
    <Box className={styles.classes.container}>
      <MuiTabs
        variant="standard"
        value={selectedTab}
        classes={{
          root: styles.classes.tabs,
          indicator: styles.classes.selectedTabIndicator,
        }}
        {...tabProps}
      >
        {tabs.map((tab, idx) => (
          <MuiTab
            key={idx}
            label={tab.label}
            value={tab.value}
            onClick={() => onClick(tab)}
            disabled={tab.disabled}
            classes={{
              selected: styles.classes.selectedTab,
              root: styles.classes.tab,
            }}
          />
        ))}
      </MuiTabs>
      <Box className={styles.classes.content}>{children}</Box>
    </Box>
  );
}
