import React from "react";
import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip";
import useStyles from "./styles";

interface IProps {
  title: string | number | JSX.Element | undefined;
  children: JSX.Element;
}

const Tooltip: React.FC<IProps & TooltipProps> = ({
  title,
  children,
  ...restProps
}) => {
  const styles = useStyles();

  const parseTitle = (title: string) => {
    return title.split("\\n").map((line, idx) => (
      <div key={idx}>
        {line.split(/(<b>.*?<\/b>)/).map((part, idx) => {
          if (part.startsWith("<b>") && part.endsWith("</b>")) {
            return <b key={idx}>{part.slice(3, -4)}</b>;
          }
          return part;
        })}
      </div>
    ));
  };

  const tooltipTitle = typeof title === "string" ? parseTitle(title) : title;

  return (
    <MuiTooltip
      title={tooltipTitle}
      classes={{ tooltip: styles.classes.tooltip }}
      {...restProps}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
