import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import {
  IProjectDepreciation,
  IProjectDepreciationStream,
  IAddProjectDepreciationStreamForm,
  IUpdateProjectDepreciationStreamForm,
} from "../../interfaces";

export const getProjectDepreciation = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.depreciation.get, { projectId });
  return await AxiosHelper.get<IProjectDepreciation[]>(url);
};

export const getProjectDepreciationStream = async (
  projectId: number,
  depreciationId: number,
  streamId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.depreciation.getStream, {
    projectId,
    depreciationId,
    streamId,
  });
  return await AxiosHelper.get<IProjectDepreciationStream>(url);
};

export const addProjectDepreciationStream = async (
  projectId: number,
  depreciationId: number,
  form: IAddProjectDepreciationStreamForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.depreciation.createStream, {
    projectId,
    depreciationId,
  });
  return await AxiosHelper.post<
    IAddProjectDepreciationStreamForm,
    IProjectDepreciationStream
  >(url, form);
};

export const updateProjectDepreciationStream = async (
  projectId: number,
  depreciationId: number,
  streamId: number,
  form: IUpdateProjectDepreciationStreamForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.depreciation.updateStream, {
    projectId,
    depreciationId,
    streamId,
  });
  return await AxiosHelper.patch<
    IUpdateProjectDepreciationStreamForm,
    IProjectDepreciationStream
  >(url, form);
};

export const deleteProjectDepreciationStream = async (
  projectId: number,
  depreciationId: number,
  streamId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.depreciation.deleteStream, {
    projectId,
    depreciationId,
    streamId,
  });
  return await AxiosHelper.delete(url);
};
