import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { IProjectProformaResponse, ProformaReportType } from "../../interfaces";

export const getDealProformaDetails = async (
  dealId: number,
  periodicity: ProformaReportType,
  basis: "cash" | "accrual",
) => {
  const url = replaceParams(DEAL_API_ROUTES.dealProforma.get, {
    dealId,
    periodicity,
    basis,
  });
  return await AxiosHelper.get<IProjectProformaResponse>(url);
};

export const downloadDealProforma = async (
  dealId: number,
  periodicity: ProformaReportType,
  basis: "cash" | "accrual",
) => {
  return await AxiosHelper.get<Blob>(
    `reporting/deal/${dealId}/proforma-download/`,
    {
      params: {
        periodicity,
        basis,
      },
      responseType: "arraybuffer",
    },
  ).then((response) => {
    const contentBlob = new Blob([response], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(contentBlob));
    link.setAttribute(
      "download",
      `deal-output-download-proforma-${dealId}-${periodicity}.xlsx`,
    );
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  });
};
