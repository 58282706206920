import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputDebtServiceCoverageView from "../../../../views/deal/output/debt/service-coverage";

export default function DealOutputDebtServiceCoverage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputDebtServiceCoverageView />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
