import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { isArray, isObject } from "lodash";

import useStyles from "./styles";
import { ILog } from "../../interfaces";
import { OBJECT_TYPE, objectTypeMap } from "../../constants";

interface IProps {
  log: ILog;
  type: OBJECT_TYPE;
}

export default function Log({ log, type }: IProps) {
  const styles = useStyles();

  // get icon
  const getIcon = (): JSX.Element => {
    switch (log.action) {
      case "create":
        return <AddIcon />;
      case "update":
        return <EditIcon />;
      case "delete":
        return <DeleteIcon />;
      case "access":
        return <CircleIcon />;
      case "add":
        return <AddIcon />;
      default:
        return <CircleIcon />;
    }
  };

  // get action
  const getAction = (action: string): string => {
    switch (action) {
      case "create":
        return "created ";
      case "update":
        return "updated ";
      case "delete":
        return "deleted ";
      case "access":
        return "accessed ";
      case "add":
        return "added ";
      default:
        return "";
    }
  };

  // get time in HH:MM format
  const formatTimestamp = (timestamp: string): string => {
    const date = new Date(timestamp);
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  // convert variable name
  const convertToTitleCase = (str: string) => {
    // Split the string by underscore
    const words = str.split("_");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1),
    );

    // Join the words with a space
    const result = capitalizedWords.join(" ");

    return result;
  };

  // get human readable field names
  const getFieldName = (field: string): string => {
    const defaultField = `${field} `;
    return convertToTitleCase(defaultField);
  };

  // get field values
  const getUpdate = () => {
    if (["create", "delete"].includes(log?.action)) {
      return (
        <span className={styles.classes.highlight}>
          {` ${objectTypeMap[log?.object_type as OBJECT_TYPE]?.name} `}
        </span>
      );
    }
    return Object.entries(log?.changes)?.map((change, idx) => {
      const [field, changeData] = change;
      return (
        <React.Fragment key={idx}>
          {isArray(changeData) && (
            <div>
              <span className={styles.classes.highlight}>
                {getFieldName(field)}
              </span>
              {changeData[0]} <ArrowForwardIcon /> {changeData[1]}
            </div>
          )}
          {/* if changeData is an object */}
          {!isArray(changeData) && isObject(changeData) && (
            <>
              {` ${getAction(
                changeData["operation"],
              )} ${changeData?.objects?.join(", ")}`}
            </>
          )}
        </React.Fragment>
      );
    });
  };

  return (
    <>
      <Box className={styles.classes.log}>
        <Box className={styles.classes.iconContainer}>{getIcon()}</Box>
        <Box className={styles.classes.logDetail}>
          <Box className={styles.classes.text}>
            {log?.user} {getAction(log?.action)}
            {getUpdate()}
          </Box>
          <Box>{`${formatTimestamp(log?.timestamp)}`}</Box>
        </Box>
      </Box>
    </>
  );
}
