import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputLesseeView from "../../../../views/deal/output/sale-leaseback/lessee";

export default function DealOutputLesseePage() {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputLesseeView />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
