import { makeStyles } from "tss-react/mui";

export default makeStyles<{ contentLoaded: boolean; withPadding?: boolean }>()(
  (theme, { contentLoaded, withPadding }) => ({
    container: {
      height: contentLoaded ? "auto" : "calc(100% - 100px)",
      padding: withPadding ? 16 : 0,
    },
  }),
);
