import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    borderBottomWidth: "2px",
    borderColor: theme.palette.primary.main,
    fontWeight: 600,
    "&::before, &::after": {
      borderTopWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
}));
