import React from "react";
import Box from "@mui/material/Box";
import LogsIcon from "@mui/icons-material/Restore";
import { Protect } from "@clerk/clerk-react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import DealCaseDuplicateFormModal from "../deal-case-duplicate-form-modal";
import UpdateCaseFormModal from "../update-case-form-modal";
import IconButton from "../icon-button";
import Logs from "../logs";
import LogsWrapper from "../logs-wrapper";
import ActionButton from "../action-button";
import { cn } from "../../utils/helpers";
import { useAPI, useAppSelector, useDrawer, useLogs } from "../../utils/hooks";
import {
  setCasesOfDealAction,
  setDeleteModalPropsAction,
} from "../../utils/redux/slices";
import {
  USER_PERMISSIONS,
  UPDATE_CASE_FORM_DEFAULT_STATE,
  DUPLICATE_CASE_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  IDealCase,
  IDuplicateDealCaseForm,
  IDuplicateDealCaseFormErrors,
  ILogsConfiguration,
  IUpdateDealCaseForm,
  IUpdateDealCaseFormErrors,
} from "../../interfaces";

interface IProps {
  deleteDealCase: (dealId: number, caseId: number) => Promise<boolean>;
  duplicateDealCase: (
    dealId: number,
    caseId: number,
    form: IDuplicateDealCaseForm,
  ) => Promise<IDealCase>;
  getDealCaseDetails: (dealId: number, caseId: number) => Promise<IDealCase>;
  updateDealCase: (
    dealId: number,
    caseId: number,
    form: IUpdateDealCaseForm,
  ) => Promise<IDealCase>;
  refetchCasesOfDeal: () => Promise<void>;
}

export default function CaseActionButtons({
  deleteDealCase,
  duplicateDealCase,
  getDealCaseDetails,
  updateDealCase,
  refetchCasesOfDeal,
}: IProps): JSX.Element {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { casesOfDeal } = useAppSelector((s) => s.deal);

  const { caseId, dealId } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleOpenDrawer, handleCloseDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  React.useEffect(() => {
    getDealCaseDetailsCallAPI(dealId, caseId);
  }, [dealId, caseId]);

  const [duplicateCaseModalOpen, setDuplicateCaseModalOpen] =
    React.useState<boolean>(false);
  const [duplicateCaseForm, setDuplicateCaseForm] =
    React.useState<IDuplicateDealCaseForm>(DUPLICATE_CASE_FORM_DEFAULT_STATE);
  const [editCaseModalOpen, setEditCaseModalOpen] =
    React.useState<boolean>(false);
  const [editDealCaseForm, setEditDealCaseForm] =
    React.useState<IUpdateDealCaseForm>(UPDATE_CASE_FORM_DEFAULT_STATE);

  const { callAPI: deleteDealCaseCallAPI } = useAPI(
    (dealId, caseId) => deleteDealCase(dealId, caseId),
    { setConfirmModalLoading: true },
  );

  const { callAPI: getDealCaseDetailsCallAPI, response: caseDetails } = useAPI(
    (dealId: number, caseId: number) => getDealCaseDetails(dealId, caseId),
    { initialLoading: true },
  );

  const {
    callAPI: duplicateCaseCallAPI,
    fieldErrors: duplicateCaseFormErrors,
    setFieldErrors: setDuplicateCaseFormErrors,
    loading: duplicateCaseLoading,
  } = useAPI<IDealCase, IDuplicateDealCaseFormErrors>(
    (caseId: number, form: IDuplicateDealCaseForm) =>
      duplicateDealCase(Number(dealId), caseId, form),
  );

  const {
    callAPI: editDealCaseCallAPI,
    fieldErrors: editDealCaseFormErrors,
    setFieldErrors: setEditDealCaseFormErrors,
    loading: editDealCaseLoading,
  } = useAPI<IDealCase, IUpdateDealCaseFormErrors>(
    (caseId: number, form: IUpdateDealCaseForm) =>
      updateDealCase(Number(dealId), caseId, form),
  );

  const gotoCasesList = () => {
    navigate(`/deal/${dealId}/cases`);
  };

  const onDeleteCase = async (caseId: number) => {
    const response = await deleteDealCaseCallAPI(dealId, caseId);

    if (response) {
      setCasesOfDealAction(casesOfDeal.filter((d) => d.id !== caseId));
      gotoCasesList();
    }
  };

  const handleOnDeleteClick = (caseId?: number) => {
    if (caseId) {
      dispatch(
        setDeleteModalPropsAction({
          open: true,
          title: "Delete Case",
          description: "Are you sure you want to delete?",
          onConfirm: () => onDeleteCase(caseId),
        }),
      );
    }
  };

  const handleOpenDuplicateModal = (caseId: number) => {
    setDuplicateCaseModalOpen(true);
    setDuplicateCaseForm((prev) => ({ ...prev, id: caseId }));
  };

  const handleOpenEditModal = (id: number) => {
    if (caseDetails) {
      setEditCaseModalOpen(true);

      setEditDealCaseForm({
        id: id,
        name: caseDetails.name,
        description: caseDetails.description,
      });
    }
  };

  const handleCloseDuplicateModal = () => {
    setDuplicateCaseModalOpen(false);
  };

  const handleDuplicateCase = async (form: IDuplicateDealCaseForm) => {
    const { id: caseId } = form;
    const dealCase = await duplicateCaseCallAPI(caseId, form);

    if (dealCase) {
      refetchCasesOfDeal();
      navigate(`/deal/${dealId}/case/${dealCase.id}`);
    }

    return dealCase;
  };

  const handleCloseEditModal = () => {
    setEditCaseModalOpen(false);
  };

  const handleEditCase = async (form: IUpdateDealCaseForm) => {
    const { id: caseId } = form;
    const dealCase = await editDealCaseCallAPI(caseId, form);
    if (dealCase) {
      refetchCasesOfDeal();
      getDealCaseDetailsCallAPI(dealId, caseId);
    }
    return dealCase;
  };

  const caseDetailsLogConfiguration: ILogsConfiguration = {
    id: Number(caseDetails?.child_deal?.id),
    type: "deal",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      caseDetailsLogConfiguration.type,
      caseDetailsLogConfiguration.id,
    );
  };

  return (
    <Box className={cn("flex flex-col items-end gap-2")}>
      <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
        <IconButton onClick={handleOnOpenLogs}>
          <LogsIcon />
        </IconButton>
        <Box className={cn("flex items-center gap-4")}>
          <ActionButton
            actionType="delete"
            canOpenUpgrade
            onClick={() => handleOnDeleteClick(Number(caseId))}
          >
            Delete
          </ActionButton>
          <ActionButton
            actionType="duplicate"
            canOpenUpgrade
            onClick={() => handleOpenDuplicateModal(Number(caseId))}
          >
            Duplicate
          </ActionButton>
          <ActionButton
            actionType="edit"
            canOpenUpgrade
            onClick={() => handleOpenEditModal(Number(caseId))}
          >
            Edit
          </ActionButton>
        </Box>
      </Protect>

      <DealCaseDuplicateFormModal
        headerLabel="Duplicate Case"
        open={duplicateCaseModalOpen}
        loading={duplicateCaseLoading}
        form={duplicateCaseForm}
        setForm={setDuplicateCaseForm}
        formErrors={duplicateCaseFormErrors}
        setFormErrors={setDuplicateCaseFormErrors}
        onClose={handleCloseDuplicateModal}
        onConfirm={handleDuplicateCase}
      />

      <UpdateCaseFormModal
        headerLabel="Edit Case"
        open={editCaseModalOpen}
        loading={editDealCaseLoading}
        form={editDealCaseForm}
        setForm={setEditDealCaseForm}
        formErrors={editDealCaseFormErrors}
        setFormErrors={setEditDealCaseFormErrors}
        onClose={handleCloseEditModal}
        onConfirm={handleEditCase}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={caseDetailsLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={caseDetailsLogConfiguration.id}
        />
      </LogsWrapper>
    </Box>
  );
}
