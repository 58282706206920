import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  tableHead: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  paginationRow: {
    backgroundColor: "#F6F6F6",
  },
}));
