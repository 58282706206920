import React from "react";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { format, isValid } from "date-fns";

import Modal from "../modal";
import TextInput from "../text-input";
import AutocompleteField from "../autocomplete-field";
import DatePicker from "../date-picker";
import useStyles from "./styles";
import {
  IDealSaleLeaseback,
  IDealSaleLeasebackSizingForm,
  IDealSaleLeasebackSizingFormErrors,
  ISelectOption,
  SetStateAction,
} from "../../interfaces";
import {
  DEAL_SLB_LEASE_TYPES,
  DEAL_SLB_PREPAY_INPUT_TYPES_OPTIONS,
  DEAL_SLB_RESERVE_MODE_TYPES_OPTIONS,
  DEAL_SLB_RESIDUAL_SIZING_MODE_TYPES_OPTIONS,
  DEAL_SLB_SOLVE_FORS_OPTIONS,
  DEAL_SLB_LEASE_TYPES_OPTIONS,
  DEAL_SALES_LEASEBACK_FORM_DEFAULT_STATE,
} from "../../constants";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IDealSaleLeasebackSizingFormErrors;
  setFormErrors: SetStateAction<IDealSaleLeasebackSizingFormErrors | undefined>;
  form: IDealSaleLeasebackSizingForm;
  setForm: SetStateAction<IDealSaleLeasebackSizingForm>;
  onClose: () => void;
  onConfirm: (
    form: IDealSaleLeasebackSizingForm,
  ) => Promise<IDealSaleLeaseback | undefined>;
}

export default function DealSaleLeasebackFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const saleLeaseback = await onConfirm(form);
    saleLeaseback && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(DEAL_SALES_LEASEBACK_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="md"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      classes={{ paper: styles.classes.modal }}
      resetForm={handleResetForm}
    >
      <Grid container spacing={2} rowSpacing="none">
        <Grid item xs={12} md={6}>
          <Stack gap={1}>
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Structure
            </Divider>
            <AutocompleteField
              label="Lease Type"
              name="lease_type"
              onChange={handleSingleAutoCompleteChange}
              options={DEAL_SLB_LEASE_TYPES_OPTIONS}
              value={String(form.lease_type)}
              helperText={formErrors?.lease_type}
              onFocus={() => {
                clearSelectErrorOnFocus("lease_type");
              }}
              disabled={loading}
            />
            <AutocompleteField
              label="Solve for"
              name="solve_for"
              onChange={handleSingleAutoCompleteChange}
              options={DEAL_SLB_SOLVE_FORS_OPTIONS}
              value={String(form.solve_for)}
              helperText={formErrors?.solve_for}
              onFocus={() => {
                clearSelectErrorOnFocus("solve_for");
              }}
              disabled={loading}
            />
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Key Parameters
            </Divider>
            <TextInput
              required
              isNumeric
              label="Purchase Price"
              name="purchase_amount"
              endAdornment={<>$</>}
              value={form.purchase_amount}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.purchase_amount)}
              helperText={formErrors?.purchase_amount}
              disabled
            />
            <TextInput
              required
              isNumeric
              label="Lessor Yield"
              name="target_yield"
              endAdornment={<>%</>}
              value={form.target_yield}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.target_yield)}
              helperText={formErrors?.target_yield}
              disabled={loading || form.solve_for === "MAXYLD"}
            />
            <DatePicker
              label="Lease Start Date"
              value={
                form.lease_start_date ? new Date(form.lease_start_date) : null
              }
              onChange={(v) => handleDateChange(v, "lease_start_date")}
              onOpen={() => clearNonTextFieldErrorOnFocus("lease_start_date")}
              error={Boolean(formErrors?.lease_start_date)}
              helperText={formErrors?.lease_start_date}
              disabled
            />
            <TextInput
              required
              isNumeric
              label="Lease Term (Years)"
              name="lease_term"
              endAdornment={<>Yrs</>}
              value={form.lease_term}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.lease_term)}
              helperText={formErrors?.lease_term}
              disabled={loading}
            />
            <AutocompleteField
              label="Prepayment Input Method"
              name="prepayment_input_mode"
              onChange={handleSingleAutoCompleteChange}
              options={DEAL_SLB_PREPAY_INPUT_TYPES_OPTIONS}
              value={String(form.prepayment_input_mode)}
              helperText={formErrors?.prepayment_input_mode}
              onFocus={() => {
                clearSelectErrorOnFocus("prepayment_input_mode");
              }}
              disabled={loading}
            />
            <TextInput
              required
              isNumeric
              label="Prepayment Limit ($,%)"
              name="prepayment_limit"
              endAdornment={
                <>{form.prepayment_input_mode === "DOLLAR" ? "$" : "%"}</>
              }
              value={form.prepayment_limit}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.prepayment_limit)}
              helperText={formErrors?.prepayment_limit}
              disabled={loading}
            />
            <TextInput
              required
              isNumeric
              label="467 Loan Rate"
              name="loan_rate"
              endAdornment={<>%</>}
              value={form.loan_rate}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.loan_rate)}
              helperText={formErrors?.loan_rate}
              disabled={loading}
            />
            <TextInput
              required
              isNumeric
              label="Coverage Ratio: Contracted"
              name="coverage_ratio_cnt"
              value={form.coverage_ratio_cnt}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.coverage_ratio_cnt)}
              helperText={formErrors?.coverage_ratio_cnt}
              disabled={loading || form.solve_for === "MAXRTCV"}
            />
            <TextInput
              required
              isNumeric
              label="Coverage Ratio: Uncontracted"
              name="coverage_ratio_unc"
              value={form.coverage_ratio_unc}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.coverage_ratio_unc)}
              helperText={formErrors?.coverage_ratio_unc}
              disabled={loading || form.solve_for === "MAXRTCV"}
            />
            <TextInput
              required
              isNumeric
              label="Coverage Ratio: Special"
              name="coverage_ratio_spc"
              value={form.coverage_ratio_spc}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.coverage_ratio_spc)}
              helperText={formErrors?.coverage_ratio_spc}
              disabled={loading || form.solve_for === "MAXRTCV"}
            />
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Buyout
            </Divider>
            <TextInput
              required
              isNumeric
              label="EBO Yield Premium"
              name="ebo_yield_premium"
              endAdornment={<>%</>}
              value={form.ebo_yield_premium}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.ebo_yield_premium)}
              helperText={formErrors?.ebo_yield_premium}
              disabled={loading}
            />
            <TextInput
              required
              isNumeric
              label="EBO Term (Years)"
              name="ebo_term"
              endAdornment={<>Yrs</>}
              value={form.ebo_term}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.ebo_term)}
              helperText={formErrors?.ebo_term}
              disabled={loading}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack gap={1}>
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Reserves
            </Divider>
            <AutocompleteField
              label="Reserve Sizing Method"
              name="reserve_mode"
              onChange={handleSingleAutoCompleteChange}
              options={DEAL_SLB_RESERVE_MODE_TYPES_OPTIONS}
              value={String(form.reserve_mode)}
              helperText={formErrors?.reserve_mode}
              onFocus={() => {
                clearSelectErrorOnFocus("reserve_mode");
              }}
              disabled={loading}
            />
            {/* reserve_mode in SPEC */}
            {form.reserve_mode &&
              ["SPEC", "PCTPUR"].includes(form.reserve_mode) && (
                <TextInput
                  required
                  isNumeric
                  label="Rent Reserve Amount"
                  name="reserve_amount"
                  endAdornment={
                    <>
                      {form.reserve_mode === "SPEC"
                        ? "$"
                        : form.reserve_mode === "PCTPUR"
                          ? "%"
                          : ""}
                    </>
                  }
                  value={form.reserve_amount}
                  onFocus={clearErrorOnFocus}
                  onChange={onTextChange}
                  error={Boolean(formErrors?.reserve_amount)}
                  helperText={formErrors?.reserve_amount}
                  disabled={loading || form.reserve_mode === null}
                />
              )}
            {form.reserve_mode === "AVGRT" && (
              <TextInput
                required
                isNumeric
                label="Rent Multiple" // AKA "Months of Reserve"
                name="months_of_reserve"
                value={form.months_of_reserve}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.months_of_reserve)}
                helperText={formErrors?.months_of_reserve}
                disabled={loading}
              />
            )}
            {form.reserve_mode === "HGHRT" && (
              <TextInput
                required
                isNumeric
                label="Consecutive Periods"
                name="consecutive_periods_of_highest_rent"
                value={form.consecutive_periods_of_highest_rent}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.consecutive_periods_of_highest_rent)}
                helperText={formErrors?.consecutive_periods_of_highest_rent}
                disabled={loading}
              />
            )}
            <Divider
              classes={{ root: styles.classes.divider }}
              textAlign="left"
            >
              Residual Value
            </Divider>
            <AutocompleteField
              label="Residual Sizing Method"
              name="residual_sizing_mode"
              onChange={handleSingleAutoCompleteChange}
              options={DEAL_SLB_RESIDUAL_SIZING_MODE_TYPES_OPTIONS}
              value={String(form.residual_sizing_mode)}
              helperText={formErrors?.residual_sizing_mode}
              onFocus={() => {
                clearSelectErrorOnFocus("residual_sizing_mode");
              }}
              disabled={loading || form.solve_for === "MINRESD"}
            />
            {["SPECD", "SPECP"].includes(form.residual_sizing_mode!) && (
              <TextInput
                required
                isNumeric
                label="Residual Value ($,%)"
                name="residual_value"
                endAdornment={
                  <>
                    {form.residual_sizing_mode === "SPECD"
                      ? "$"
                      : form.residual_sizing_mode === "SPECP"
                        ? "%"
                        : ""}
                  </>
                }
                value={form.residual_value}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.residual_value)}
                helperText={formErrors?.residual_value}
                disabled={
                  loading ||
                  !["SPECD", "SPECP"].includes(form.residual_sizing_mode!)
                }
              />
            )}
            {!["SPECD", "SPECP"].includes(form.residual_sizing_mode!) && (
              <TextInput
                required
                isNumeric
                label="Residual Value Discount Rate"
                name="residual_pv_rate"
                endAdornment={<>%</>}
                value={form.residual_pv_rate}
                onFocus={clearErrorOnFocus}
                onChange={onTextChange}
                error={Boolean(formErrors?.residual_pv_rate)}
                helperText={formErrors?.residual_pv_rate}
                disabled={loading}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
    </Modal>
  );
}
