import { makeStyles } from "tss-react/mui";

export default makeStyles<{ fullWidth: boolean }>()((theme, { fullWidth }) => ({
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1.5),
  },
  option: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  autoCompleteRoot: {
    width: fullWidth ? "100%" : "calc(100% - 40px)",
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
