import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputSponsorCashChartsView from "../../../../views/deal/output/sponsor-equity/charts";
import {
  getDealSponsorCashEquityChart,
  getDealSponsorEquityCashChart,
} from "../../../../apis/report/deal";

export default function DealOutputSponsorCharts(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputSponsorCashChartsView
          getDealSponsorCashEquityChart={getDealSponsorCashEquityChart}
          getDealSponsorEquityCashChart={getDealSponsorEquityCashChart}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
