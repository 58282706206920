import { makeStyles } from "tss-react/mui";

export default makeStyles<{
  direction: "left" | "right";
  fixedColumnWidth: number;
  highZIndex?: boolean;
}>()((theme, { direction, fixedColumnWidth, highZIndex }) => {
  const borderColor = "#e0e0e0";

  const getBorderStyle = (dir?: "left" | "right") =>
    dir ? `1px solid ${borderColor}` : "none";

  return {
    stickyTableCell: {
      position: "sticky",
      [direction]: 0,
      zIndex: highZIndex ? 18 : 12,
      minWidth: `${fixedColumnWidth}px`,
      fontWeight: "bold",
      borderRight: getBorderStyle(direction === "left" ? "right" : undefined),
      borderLeft: getBorderStyle(direction === "right" ? "left" : undefined),
      backgroundColor: theme.palette.background.paper,
      paddingBlock: "0px",
    },
  };
});
