import TabbedLayout from "../../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../../components/project-pages-layout";
import ProjectDepreciationStreams from "../../../../views/project/tax/depreciation/list";
import {
  addProjectDepreciationStream,
  deleteProjectDepreciationStream,
  getProjectDepreciation,
  updateProjectDepreciationStream,
} from "../../../../apis/project/depreciation";

export default function Depreciation(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Tax">
        <ProjectDepreciationStreams
          addProjectDepreciationStream={addProjectDepreciationStream}
          deleteProjectDepreciationStream={deleteProjectDepreciationStream}
          getProjectDepreciation={getProjectDepreciation}
          updateProjectDepreciationStream={updateProjectDepreciationStream}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
