import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  proformaTableContainer: {
    marginTop: theme.spacing(2),
    maxHeight: "calc(100vh - 110px)",
    overflow: "scroll",
    position: "relative",
  },
  periodicityType: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
  },
}));
