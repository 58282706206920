import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import useStyles from "./styles";
import ReportTable from "../../components/report-table";
import ChartWrapper from "../../components/chart-wrapper";
import CapitalSourcesChart from "../deal/output/partnership/summary/charts-capital";
import PartnershipSummaryCharts from "../deal/output/partnership/summary/charts";
import {
  ICapitalChart,
  ICoZe,
  IPartnershipReportSummaryChart,
  ITableRow,
} from "../../interfaces";
import {
  IRR_PARTNERSHIP_SUMMARY_COLUMN,
  PORTFOLIO_SUMMARY_TABLE_COLUMNS,
} from "../../constants";

interface IProps {
  coZe: ICoZe;
  partnershipTableIrrRows: ITableRow[];
  taxTableRows: ITableRow[];
  partnershipChartData?: IPartnershipReportSummaryChart;
  capitalChartData?: ICapitalChart;
  partnershipAllocationTable: JSX.Element | null;
}

const PartnershipTab: React.FC<IProps> = ({
  coZe,
  partnershipTableIrrRows,
  taxTableRows,
  partnershipChartData,
  capitalChartData,
  partnershipAllocationTable: PartnershipAllocationTable,
}) => {
  const styles = useStyles();
  return (
    <Box>
      <Box className={styles.classes.dealSection}>
        <Box>
          <Box className={styles.classes.innerChartContainer}>
            <ReportTable
              columns={IRR_PARTNERSHIP_SUMMARY_COLUMN}
              rows={partnershipTableIrrRows}
              fullWidth
            />
          </Box>
          {coZe?.deal_structure !== "EO" && (
            <ReportTable
              columns={PORTFOLIO_SUMMARY_TABLE_COLUMNS}
              rows={taxTableRows}
              fullWidth
            />
          )}
        </Box>
        <Box className={styles.classes.chartContainer}>
          <Stack spacing={2}>
            <Box className={styles.classes.innerChartContainer}>
              {/* Use "Portfolio" when its equity only */}
              <ChartWrapper
                title={`${
                  coZe?.deal_structure === "EO" ? "Portfolio" : "Partnership"
                } Value Components (NPV)`}
              >
                <PartnershipSummaryCharts data={partnershipChartData} />
              </ChartWrapper>
            </Box>

            <Box className={styles.classes.innerChartContainer}>
              {coZe?.deal_structure !== "EO" && (
                <ChartWrapper title="Capital Sources">
                  <CapitalSourcesChart
                    data={capitalChartData}
                    dealStructureType={
                      coZe?.deal_structure === "CEP"
                        ? "cash-equity"
                        : "tax-equity"
                    }
                  />
                </ChartWrapper>
              )}
            </Box>
          </Stack>
        </Box>
      </Box>
      {PartnershipAllocationTable && PartnershipAllocationTable}
    </Box>
  );
};

export default PartnershipTab;
