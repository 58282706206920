import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectProductionView from "../../../views/project/pro-forma/production";
import TabbedLayout from "../../../components/tabbed-layout";
import { getProjectTiming } from "../../../apis/project/timing";
import {
  getProjectProduction,
  getProjectProductionComponents,
  updateProjectProduction,
} from "../../../apis/project/production";

export default function Production(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectProductionView
          getProjectTiming={getProjectTiming}
          getProjectProduction={getProjectProduction}
          updateProjectProduction={updateProjectProduction}
          getProjectProductionComponents={getProjectProductionComponents}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
