import { makeStyles } from "tss-react/mui";
import { Property as CssProperty } from "csstype";

export default makeStyles<{
  fullWidth: boolean;
  tooltipOptions?: { alignment: CssProperty.AlignItems };
}>()((theme, { fullWidth, tooltipOptions }) => ({
  container: {
    display: "flex",
    alignItems: tooltipOptions?.alignment || "center",
    marginBottom: theme.spacing(1.5),
    width: "100%",
  },
  formControl: {
    width: fullWidth ? "100%" : "calc(100% - 40px)",
  },
  labelAnimated: {
    lineHeight: "2em",
  },
  labelShrink: {
    lineHeight: "normal",
  },
  input: {
    padding: theme.spacing(1.6, 1.8),
  },
  infoIcon: {
    marginLeft: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
