import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import ViewWrapper from "../../../../../components/view-wrapper";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import ReportTable from "../../../../../components/report-table";
import { useAxios } from "../../../../../components/axios-provider";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  generateSlbTaxReportTableRows,
  getDealReportingUrl,
  generateSlbTaxReportTableHeaders,
} from "../../../../../utils/helpers";
import {
  IReport,
  ITableColumn,
  ITableRow,
  OutputReportPeriodicityType,
  ReportPerspective,
  ReportTerm,
  ReportType,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IReport>;
}

export default function DealOutputLesseeTaxView({ getDealReporting }: IProps) {
  const dispatch = useDispatch();

  const { dealId, caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IReport>();

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealId}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealId}-output-periodicity`,
      "MO",
    );

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealReportingCallAPI(Number(caseDealId), buyout, periodicityType).then(
        (response) => {
          if (response?.["leasetax"]) {
            setReport(response);
          }
        },
      );
    }
  }, [caseDealId, buyout, periodicityType]);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
    ) =>
      getDealReporting(
        dealId,
        "lessee",
        "leasetax",
        term,
        reportPeriodicityType,
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [getReportFailed, loadingReport]);

  const taxTableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateSlbTaxReportTableRows(report) : [];
  }, [report]);

  const columnHeaders: ITableColumn[] = React.useMemo(() => {
    return report ? generateSlbTaxReportTableHeaders(report) : [];
  }, [report]);

  return (
    <>
      <ViewWrapper
        loading={loadingReport}
        error={getReportFailed}
        errorHeading={getReportErrorObj?.heading}
        errorDescription={getReportErrorObj?.description}
        showLimitHitView={showUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[
            getDealReportingUrl(
              Number(caseDealId),
              "lessee",
              "leasetax",
              buyout,
              periodicityType,
            )
          ]
        }
      >
        <DealOutputFilterFields
          buyout={buyout}
          periodicityType={periodicityType}
          setBuyout={setBuyout}
          setPeriodicityType={setPeriodicityType}
          showBuyout={report?.has_investor_buyout}
          showPeriodicity
          showEbo
        />
        <ReportTable columns={columnHeaders} rows={taxTableRows} boldTotal />
      </ViewWrapper>
    </>
  );
}
