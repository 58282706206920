import Layout from "../../components/layout";
import WorkflowDataRoomView from "../../views/workflow/data-room";

export default function WorkflowDataRoomPage(): JSX.Element {
  return (
    <Layout title="Workflow">
      <WorkflowDataRoomView />
    </Layout>
  );
}
