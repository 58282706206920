import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  root: {
    height: "100%",
  },
  container: {
    display: "flex",
    marginTop: 68,
  },
  content: {
    // height: "100%",
    width: "calc(100vw - 230px)",
    margin: "0 auto",
    [theme.breakpoints.down(900)]: {
      width: "100%",
    },
  },
}));
