export default function CapeZeroLogo(): JSX.Element {
  return (
    <svg
      width="120"
      height="23"
      viewBox="0 0 120 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3174_29925)">
        <path
          d="M35.187 4.96704L34.8311 4.85774C34.2113 4.60173 33.6732 4.43777 33.2183 4.36442C32.762 4.29106 32.1694 4.25511 31.4405 4.25511C30.383 4.25511 29.4632 4.48379 28.6798 4.93972C27.8963 5.39708 27.2894 6.02704 26.8618 6.83102C26.4342 7.635 26.2189 8.5296 26.2189 9.51624C26.2189 11.1602 26.7197 12.4618 27.7227 13.4211C28.7257 14.3804 30.0917 14.8594 31.8236 14.8594C32.6257 14.8594 33.7105 14.622 35.0765 14.1474L35.1855 14.0928V16.614C34.4753 16.87 33.8224 17.0527 33.2312 17.162C32.6386 17.2713 32.0144 17.326 31.3587 17.326C29.7545 17.326 28.3598 16.9793 27.176 16.2847C25.9908 15.59 25.0796 14.6537 24.4425 13.4758C23.804 12.2978 23.4855 10.9861 23.4855 9.54357C23.4855 8.101 23.8141 6.7663 24.4698 5.597C25.1442 4.4277 26.0912 3.51441 27.3123 2.85713C28.5334 2.19984 29.9281 1.87048 31.495 1.87048C32.8438 1.87048 34.0735 2.0891 35.1855 2.52777V4.96704H35.187Z"
          fill="#191F37"
        />
        <path
          d="M36.964 6.94003C37.4375 6.77607 38.0345 6.64375 38.7548 6.54308C39.4751 6.4424 40.072 6.39206 40.5455 6.39206C42.2401 6.39206 43.4569 6.70704 44.1959 7.33699C44.9334 7.96695 45.3036 8.9953 45.3036 10.4206V17.0797H42.9518L42.9246 16.1477C42.1038 16.805 41.0836 17.1344 39.8625 17.1344C38.7318 17.1344 37.8293 16.8467 37.1563 16.2714C36.4819 15.6961 36.1447 14.9425 36.1447 14.0105C36.1447 12.9879 36.5221 12.1882 37.2797 11.6129C38.0359 11.0376 39.1164 10.7126 40.5197 10.6407L42.5701 10.5314C42.5701 9.85537 42.5056 9.38938 42.3793 9.13337C42.2516 8.84141 42.0234 8.64005 41.6963 8.53074C41.3677 8.42144 40.8483 8.36678 40.138 8.36678C39.6645 8.36678 39.1121 8.41281 38.4836 8.50342C37.8551 8.59546 37.3486 8.7134 36.9655 8.8601V6.94147L36.964 6.94003ZM42.6778 12.3105L40.8181 12.4471C40.1079 12.5018 39.5884 12.6384 39.2599 12.8584C38.9313 13.0785 38.7677 13.3978 38.7677 13.8178C38.7677 14.2377 38.9227 14.5671 39.2326 14.8044C39.5425 15.0417 39.9701 15.1611 40.5183 15.1611C40.9559 15.1611 41.3146 15.1251 41.5987 15.0518C41.8814 14.9784 42.2416 14.8145 42.6792 14.5585V12.3119L42.6778 12.3105Z"
          fill="#191F37"
        />
        <path
          d="M48.5302 6.55643L48.5029 7.40644C49.5059 6.83976 50.562 6.55643 51.674 6.55643C52.4761 6.55643 53.2682 6.73908 54.0531 7.1044C54.891 7.50711 55.5382 8.12269 55.9945 8.954C56.4493 9.78531 56.6775 10.8036 56.6775 12.0088C56.6775 13.5622 56.1997 14.7904 55.2426 15.6951C54.2855 16.5998 52.9783 17.0514 51.3196 17.0514C50.9365 17.0514 50.5849 17.0097 50.2664 16.9277C49.9479 16.8457 49.5332 16.7047 49.0224 16.5034V22.7238H46.3979V6.55643H48.5302ZM50.9365 8.63902C50.3353 8.63902 49.7068 8.76702 49.0496 9.02303V14.4769C49.559 14.8235 50.1617 14.9975 50.8533 14.9975C51.8921 14.9975 52.6713 14.7473 53.1907 14.2439C53.7101 13.7419 53.9698 12.9509 53.9698 11.8737C53.9698 10.7417 53.7288 9.91906 53.2452 9.40705C52.7617 8.89647 51.9911 8.63902 50.9351 8.63902H50.9365Z"
          fill="#191F37"
        />
        <path
          d="M66.8196 16.9431C65.8352 17.2178 64.6687 17.3544 63.3199 17.3544C61.5334 17.3544 60.1158 16.8568 59.0683 15.8615C58.0194 14.8662 57.4957 13.5373 57.4957 11.8746C57.4957 10.7787 57.7238 9.81075 58.1787 8.96938C58.6335 8.14669 59.2261 7.51242 59.9551 7.06513C60.684 6.61783 61.4861 6.39346 62.3614 6.39346C63.9283 6.39346 65.1407 6.87815 65.9974 7.8461C66.854 8.81404 67.2816 10.1574 67.2816 11.8746V12.8886H60.1459C60.2923 14.4779 61.4674 15.2732 63.6729 15.2732C65.0575 15.2732 66.1064 15.1826 66.8167 14.9985V16.9445L66.8196 16.9431ZM62.4173 8.44729C61.8161 8.44729 61.3053 8.68892 60.8863 9.17361C60.4673 9.6583 60.2392 10.2839 60.2033 11.0505H64.6873C64.6873 10.2278 64.4864 9.58926 64.0861 9.1319C63.6843 8.67597 63.129 8.44729 62.4188 8.44729H62.4173Z"
          fill="#191F37"
        />
        <path
          d="M69.8524 2.0065H80.98V3.6231L72.9417 14.6401H81.0345V17.0794H69.333V15.4081L77.1517 4.41989H69.8524V2.0065Z"
          fill="#191F37"
        />
        <path
          d="M91.0413 16.9431C90.057 17.2178 88.8904 17.3544 87.5416 17.3544C85.7552 17.3544 84.3375 16.8568 83.29 15.8615C82.2411 14.8662 81.7174 13.5373 81.7174 11.8746C81.7174 10.7787 81.9456 9.81075 82.4004 8.96938C82.8553 8.14669 83.4479 7.51242 84.1768 7.06513C84.9057 6.61783 85.7078 6.39346 86.5831 6.39346C88.15 6.39346 89.3625 6.87815 90.2191 7.8461C91.0757 8.81404 91.5033 10.1574 91.5033 11.8746V12.8886H84.3676C84.514 14.4779 85.6892 15.2732 87.8946 15.2732C89.2793 15.2732 90.3282 15.1826 91.0384 14.9985V16.9445L91.0413 16.9431ZM86.6391 8.44729C86.0379 8.44729 85.527 8.68892 85.108 9.17361C84.6891 9.6583 84.4609 10.2839 84.425 11.0505H88.9091C88.9091 10.2278 88.7082 9.58926 88.3078 9.1319C87.9061 8.67597 87.3508 8.44729 86.6405 8.44729H86.6391Z"
          fill="#191F37"
        />
        <path
          d="M92.6002 17.0801H95.1974V9.07769C95.7986 8.87633 96.3726 8.77565 96.9192 8.77565C97.74 8.77565 98.4503 8.93961 99.0515 9.26897V6.69306C98.5952 6.60245 98.2035 6.55643 97.8763 6.55643H97.6309C96.3008 6.55643 95.1242 6.77073 94.104 7.20076C93.0838 7.6308 92.5816 8.31973 92.6002 9.26897V17.0787V17.0801Z"
          fill="#191F37"
        />
        <path
          d="M105.587 17.3257C104.511 17.3257 103.572 17.0884 102.77 16.6137C101.95 16.1391 101.317 15.4905 100.871 14.6678C100.424 13.8451 100.2 12.9145 100.2 11.8718C100.2 10.1732 100.693 8.83421 101.677 7.85764C102.661 6.88107 103.973 6.39206 105.614 6.39206C107.256 6.39206 108.563 6.88538 109.537 7.87202C110.512 8.85866 110.999 10.1833 110.999 11.8459C110.999 12.9246 110.781 13.8739 110.344 14.6965C109.887 15.5192 109.25 16.1621 108.43 16.6281C107.609 17.0941 106.662 17.3271 105.586 17.3271L105.587 17.3257ZM105.587 8.44732C104.804 8.44732 104.161 8.7623 103.66 9.39226C103.158 10.0222 102.908 10.8492 102.908 11.8718C102.908 12.8944 103.158 13.7128 103.66 14.324C104.161 14.9367 104.804 15.2416 105.587 15.2416C106.37 15.2416 107.064 14.9396 107.556 14.337C108.048 13.7343 108.293 12.9117 108.293 11.8704C108.293 10.8291 108.038 10.0208 107.528 9.39082C107.018 8.76086 106.37 8.44589 105.587 8.44589V8.44732Z"
          fill="#191F37"
        />
        <path
          d="M3.85124 0.000396729C1.72473 0.000396729 0 1.52063 0 3.39468V17.3644H15.8469C17.9734 17.3644 19.6981 15.8442 19.6981 13.9702V0.000396729H3.85124Z"
          fill="#191F37"
        />
        <path
          d="M8.87202 7.52083L10.4734 5.78198H4.28182C3.35488 5.78198 2.60156 6.53563 2.60156 7.46618V14.3151L8.87058 7.52083H8.87202Z"
          fill="url(#paint0_linear_3174_29925)"
        />
        <path
          d="M15.7417 4.52569L17.0991 3.05148H10.9076C9.98067 3.05148 9.22736 3.80513 9.22736 4.73568V11.5789L15.7417 4.52569Z"
          fill="#1DED85"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3174_29925"
          x1="7.78294"
          y1="10.7684"
          x2="1.61289"
          y2="7.21406"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.06" stopColor="#013556" />
          <stop offset="0.07" stopColor="#023D58" />
          <stop offset="0.16" stopColor="#0A7265" />
          <stop offset="0.25" stopColor="#119E70" />
          <stop offset="0.34" stopColor="#16C079" />
          <stop offset="0.42" stopColor="#19D97F" />
          <stop offset="0.5" stopColor="#1CE783" />
          <stop offset="0.56" stopColor="#1DED85" />
        </linearGradient>
        <clipPath id="clip0_3174_29925">
          <rect width="120" height="22.7244" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
