import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputPartnershipSummaryView from "../../../../views/deal/output/partnership/summary";
import {
  getCapitalChartData,
  getOutputPartnershipSplit,
  getOutputPartnershipSummary,
} from "../../../../apis/report/deal";

export default function DealOutputPartnershipSummary(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputPartnershipSummaryView
          getOutputPartnershipSplit={getOutputPartnershipSplit}
          getOutputPartnershipSummary={getOutputPartnershipSummary}
          getCapitalChartData={getCapitalChartData}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
