import { IIconProps } from "../../interfaces";

export default function CompareIcon({ fillColor }: IIconProps): JSX.Element {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0H12C13.0938 0 14 0.90625 14 2V12C14 13.125 13.0938 14 12 14H2C0.875 14 0 13.125 0 12V2C0 0.90625 0.875 0 2 0ZM4 6C3.4375 6 3 6.46875 3 7V10C3 10.5625 3.4375 11 4 11C4.53125 11 5 10.5625 5 10V7C5 6.46875 4.53125 6 4 6ZM6 4V10C6 10.5625 6.4375 11 7 11C7.53125 11 8 10.5625 8 10V4C8 3.46875 7.53125 3 7 3C6.4375 3 6 3.46875 6 4ZM10 8C9.4375 8 9 8.46875 9 9V10C9 10.5625 9.4375 11 10 11C10.5312 11 11 10.5625 11 10V9C11 8.46875 10.5312 8 10 8Z"
        fill={fillColor ?? "black"}
      />
    </svg>
  );
}
