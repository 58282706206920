import { DEAL_API_ROUTES, REPORT_API_ROUTES } from "../../constants";
import {
  AxiosHelper,
  addParamsToUrl,
  replaceParams,
} from "../../utils/helpers";
import {
  IReport,
  ReportPerspective,
  ReportType,
  IDealReportByStage,
  ICashReportChart,
  IPartnershipReportSplit,
  OutputReportPeriodicityType,
  ITaxEquityReportSummaryMaster,
  ISponsorEquityReportSummaryMaster,
  IDebtReportSummaryMaster,
  ReportTerm,
  IPartnershipReportSummaryMaster,
  ICapitalChart,
  IDealDebtBalanceChart,
  ITransferReportSummaryMaster,
  ITaxEquityChart,
  ISponsorCashEquityChart,
  ITaxEquityCashChart,
  ISponsorCashFlowChart,
  IValueComponentSummaryResponse,
  IDeveloperReportSummary,
  ISponsorEquityBOL_ReportSummary,
  IConstructionDebtSummary,
} from "../../interfaces";

export const getDealReporting = async <Report = IReport>(
  dealId: number,
  reportPerspective: ReportPerspective,
  reportType: ReportType,
  reportTerm: ReportTerm,
  periodicity?: OutputReportPeriodicityType,
  reportingType?: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.get, {
    dealId,
    reportPerspective,
    reportType,
    periodicity,
    reportTerm,
    reportingType,
  });
  return await AxiosHelper.get<Report>(url);
};

export const getSponsorEquityBOL_ReportSummary = async (
  dealId: number,
  params: { force_unlevered: boolean },
) => {
  const { force_unlevered } = params;
  const url = addParamsToUrl(
    REPORT_API_ROUTES.deal.getSponsorEquityBOL_ReportSummary,
    { dealId },
    force_unlevered ? { force_unlevered } : {},
  );

  return await AxiosHelper.get<ISponsorEquityBOL_ReportSummary>(url);
};

export const getDeveloperReportSummary = async (dealId: number) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDeveloperReportSummary, {
    dealId,
  });
  return await AxiosHelper.get<IDeveloperReportSummary>(url);
};

export const getTaxEquityReportSummary = async (dealId: number) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getTaxEquityReportSummary, {
    dealId,
  });
  return await AxiosHelper.get<ITaxEquityReportSummaryMaster>(url);
};

export const getSponsorEquityReportSummary = async (
  dealId: number,
  params: { force_unlevered: boolean },
) => {
  const { force_unlevered } = params;
  const url = addParamsToUrl(
    REPORT_API_ROUTES.deal.getSponsorEquityReportSummary,
    { dealId },
    force_unlevered ? { force_unlevered } : {},
  );
  return await AxiosHelper.get<ISponsorEquityReportSummaryMaster>(url);
};

export const getDebtReportSummary = async (dealId: number) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDebtReportSummary, {
    dealId,
  });
  return await AxiosHelper.get<IDebtReportSummaryMaster>(url);
};

export const getTransferReportSummary = async (dealId: number) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getTransferReportSummary, {
    dealId,
  });
  return await AxiosHelper.get<ITransferReportSummaryMaster>(url);
};

export const getOutputPartnershipSummary = async (dealId: number) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getOutputPartnershipSummary,
    {
      dealId,
    },
  );
  return await AxiosHelper.get<IPartnershipReportSummaryMaster>(url);
};

export const getConstructionDebtSummary = async (dealId: number) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getConstructionDebtSummary, {
    dealId,
  });
  return await AxiosHelper.get<IConstructionDebtSummary>(url);
};

export const getOutputPartnershipNetValueComponentChart = async (
  dealId: number,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getOutputPartnershipNetValueComponentChart,
    {
      dealId,
    },
  );
  return await AxiosHelper.get<IValueComponentSummaryResponse>(url);
};

export const getOutputPartnershipSplit = async (dealId: number) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getOutputPartnershipSplit, {
    dealId,
  });
  return await AxiosHelper.get<IPartnershipReportSplit>(url);
};

export const getCapitalChartData = async (dealId: number) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getCapitalChartData, {
    dealId,
  });
  return await AxiosHelper.get<ICapitalChart>(url);
};

export const getNonTransferCashChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getNonTransferCashChart, {
    dealId,
    is_percent,
  });
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getTransferCashChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getTransferCashChart, {
    dealId,
    is_percent,
  });
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getDealDebtServiceChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealDebtServiceChart, {
    dealId,
    is_percent,
  });
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getDealSponsorCashEquityChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealSponsorCashEquityChart,
    {
      dealId,
      is_percent,
    },
  );
  return await AxiosHelper.get<ISponsorCashEquityChart>(url);
};

export const getDealSponsorEquityCashChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealSponsorEquityCashChart,
    {
      dealId,
      is_percent,
    },
  );
  return await AxiosHelper.get<ISponsorCashFlowChart>(url);
};

export const getDealTaxEquityChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealTaxEquityChart, {
    dealId,
    is_percent,
  });
  return await AxiosHelper.get<ITaxEquityChart>(url);
};

export const getDealTaxEquityCashChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealTaxEquityCashChart, {
    dealId,
    is_percent,
  });
  return await AxiosHelper.get<ITaxEquityCashChart>(url);
};

export const getDealDebtBalanceChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealDebtBalanceChart, {
    dealId,
    is_percent,
  });
  return await AxiosHelper.get<IDealDebtBalanceChart>(url);
};

export const getDealPartnershipDepreciationChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealPartnershipDepreciationChart,
    {
      dealId,
      is_percent,
    },
  );
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getDealNetOperatingIncomeChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getDealNetOperatingIncomeChart,
    {
      dealId,
      is_percent,
    },
  );
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const getDealOtherIncomeChart = async (
  dealId: number,
  is_percent: string,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDealOtherIncomeChart, {
    dealId,
    is_percent,
  });
  return await AxiosHelper.get<ICashReportChart>(url);
};

export const downloadUserDealReport = async (
  dealId: number,
  reportPerspective?: ReportPerspective,
  reportType?: ReportType,
  reportTerm?: ReportTerm,
  periodicity?: OutputReportPeriodicityType,
) => {
  const url = replaceParams(REPORT_API_ROUTES.deal.getDownloadUserDealReport, {
    dealId,
    reportPerspective,
    reportType,
    periodicity,
    reportTerm,
  });

  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    const contentBlob = new Blob([response], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(contentBlob));
    link.setAttribute("download", `report-output-download-${dealId}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};

export const downloadDealReport = async (dealId: number) => {
  return await AxiosHelper.get<Blob>(`reporting/deal/${dealId}/raw-download/`, {
    responseType: "arraybuffer",
  }).then((response) => {
    const contentBlob = new Blob([response], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(contentBlob));
    link.setAttribute("download", `deal-output-${dealId}.xlsx`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  });
};

export const downloadDealReportSet = async (dealId: number) => {
  return await AxiosHelper.get<Blob>(
    `reporting/deal/${dealId}/download-reportset/`,
    {
      responseType: "arraybuffer",
    },
  ).then((response) => {
    const contentBlob = new Blob([response], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(contentBlob));
    link.setAttribute(
      "download",
      `deal-output-reports-formulas-${dealId}.xlsx`,
    );
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  });
};

export const downloadABCInputs = async (dealId: number) => {
  return await AxiosHelper.get<Blob>(`accufy/deal/${dealId}/inputs-download/`, {
    responseType: "arraybuffer",
  }).then((response) => {
    const contentBlob = new Blob([response], {
      type: "application/octet-stream",
    });

    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(contentBlob));
    link.setAttribute(
      "download",
      `deal-abc-inputs-${dealId}-${process.env.REACT_APP_NODE_ENV}.xlsx`,
    );
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  });
};

export const getDealReportingByStage = async () => {
  return await AxiosHelper.get<IDealReportByStage>(
    REPORT_API_ROUTES.deal.getByStage,
  );
};

export const downloadDealCostsReport = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.summary.download, {
    dealId,
  });
  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    const contentBlob = new Blob([response], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(contentBlob));
    link.setAttribute("download", `deal-cost-report-download-${dealId}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
