import React from "react";
import { delay } from "lodash";
import { useLocation, useNavigate } from "react-router-dom";

import LoaderScreen from "../../../../components/loader-screen";
import { useAppSelector } from "../../../../utils/hooks";

export default function DealOutputConstructionDebtView(): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { selectedDealOutputPageTab } = useAppSelector((s) => s.deal);

  React.useEffect(() => {
    delay(() => {
      navigate(
        `${pathname}/${selectedDealOutputPageTab["construction-debt"]}`,
        {
          replace: true,
        },
      );
    }, 200);
  }, []);

  return <LoaderScreen />;
}
