import React from "react";
import { Chart as ChartJS, BarElement, BarController } from "chart.js";

import { useScreenWidth } from "../../../../../utils/hooks";
import { IPartnershipReportSummaryChart } from "../../../../../interfaces";
import { chartTooltipArrayOfNumbersToNumber } from "../../../../../utils/helpers";

ChartJS.register(BarElement, BarController);

interface IProps {
  data?: IPartnershipReportSummaryChart;
}

export default function Chart({ data }: IProps): JSX.Element {
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);
  const width = useScreenWidth();

  React.useEffect(() => {
    const chart2DRef = chartRef?.current?.getContext("2d");

    const chartData = [
      {
        label: "Cash Distributions",
        backgroundColor: "#00B969",
        value: data?.cash_distributions.data,
        percent: data?.cash_distributions.percent,
      },
      (data?.transfer_proceeds?.data !== undefined &&
        data?.transfer_proceeds?.data !== null && {
          label: "Transfer Cash",
          backgroundColor: "#4F5EA1",
          value: data?.transfer_proceeds?.data,
          percent: data?.transfer_proceeds?.percent,
        }) ||
        {},
      {
        label: "Tax Credits",
        backgroundColor: "#5335FA",
        value: data?.tax_credit.data,
        percent: data?.tax_credit.percent,
      },
      {
        label: "Depreciation Benefits",
        backgroundColor: "#7E68FB",
        value: data?.depreciation.data,
        percent: data?.depreciation.percent,
      },
      {
        label: "Tax Detriments",
        backgroundColor: "#9481FC",
        value: data?.taxable_income.data,
        percent: data?.taxable_income.percent,
      },
      {
        label: "Total Value",
        backgroundColor: "#9F9F9F",
        value: data?.tax_efficient_value.data,
        percent: data?.tax_efficient_value.percent,
      },
    ].filter((cD) => cD.label !== undefined);

    const taxEquityChartObj =
      chart2DRef &&
      new ChartJS(chart2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: chartData.map((cD) => cD.label),
          datasets: [
            {
              data: chartData.map((cD) => cD.value),
              backgroundColor: chartData.map((cD) => cD.backgroundColor),
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  return (
                    chartTooltipArrayOfNumbersToNumber(ctx) +
                    " / " +
                    chartData[ctx.dataIndex].percent +
                    "%"
                  );
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y: {
              title: {
                display: true,
                text: "Amount ($)",
              },
            },
          },
        },
      });

    return () => {
      taxEquityChartObj?.destroy();
    };
  }, [data, width]);

  return <canvas id="partnershipChart" ref={chartRef} />;
}
