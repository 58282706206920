import React from "react";
import { useParams } from "react-router-dom";

import ViewWrapper from "../../../../components/view-wrapper";
import ReportTableV2 from "../../../../components/report-table-v2";
import { useAPI } from "../../../../utils/hooks";
import { IReportTable } from "../../../../interfaces";

interface IProps {
  getProjectValuationCalculations: (projectId: number) => Promise<IReportTable>;
}

export default function ProjectValuationCalculationView({
  getProjectValuationCalculations,
}: IProps): JSX.Element {
  const { projectId } = useParams();

  const [calculations, setCalculations] = React.useState<IReportTable>();

  const {
    callAPI: getCalculationsCallAPI,
    loading: getCalculationsLoading,
    errored: getCalculationsFailed,
  } = useAPI(
    (projectId) => getProjectValuationCalculations(Number(projectId)),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    getCalculationsCallAPI(projectId).then((res) => {
      res && setCalculations(res);
    });
  }, [projectId]);

  return (
    <ViewWrapper loading={getCalculationsLoading} error={getCalculationsFailed}>
      {calculations ? (
        <ReportTableV2 data={calculations} groupExpandedDepth={-1} />
      ) : null}
    </ViewWrapper>
  );
}
