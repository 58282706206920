import React from "react";

interface IThemeContext {
  darkMode: boolean;
  setDarkMode: (newValue: boolean) => void;
}

const ThemeContext = React.createContext<IThemeContext>({
  darkMode: false,
  setDarkMode: () => undefined,
});

export default ThemeContext;
