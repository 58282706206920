import DealPagesLayout from "../../components/deal-pages-layout";
import DealTimingView from "../../views/deal/timing";
import { getDealTiming, updateDealTiming } from "../../apis/deal/timing";

export default function DealTiming(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealTimingView
        getDealTiming={getDealTiming}
        updateDealTiming={updateDealTiming}
      />
    </DealPagesLayout>
  );
}
