import TabbedLayout from "../../../components/tabbed-layout";
import DealPagesLayout from "../../../components/deal-pages-layout";
import DealTaxCreditTransferView from "../../../views/deal/sizing/transfer";
import { updateDealTiming } from "../../../apis/deal/timing";
import {
  getDealTransferPortfolio,
  updateDealTransferPortfolio,
} from "../../../apis/deal/transfer";
import {
  getDealTaxCreditTransferDetails,
  updateDealTaxCreditTransferDetails,
} from "../../../apis/deal/tax-credit";

export default function Transfer(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealTaxCreditTransferView
          updateDealTransferPortfolio={updateDealTransferPortfolio}
          getDealTransferPortfolio={getDealTransferPortfolio}
          getDealTaxCreditTransferDetails={getDealTaxCreditTransferDetails}
          updateDealTiming={updateDealTiming}
          updateDealTaxCreditTransferDetails={
            updateDealTaxCreditTransferDetails
          }
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
