import { makeStyles } from "tss-react/mui";
import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);
  return {
    activeButton: {
      backgroundColor: selectedTheme.color.aboveSurface,
    },
    button: {
      textTransform: "capitalize",
    },
  };
});
