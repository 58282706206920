import React from "react";
import { SelectChangeEvent } from "@mui/material";

import Modal from "../modal";
import TextInput from "../text-input";
import CurrencyInput from "../currency-input";
import SelectInput from "../select-input";
import {
  IProjectCostForm,
  IProjectCostFormErrors,
  SetStateAction,
} from "../../interfaces";
import { PROJECT_COST_FMV_STEP_UP_INPUT_TYPE_OPTIONS } from "../../constants";

interface IProps {
  open: boolean;
  form: IProjectCostForm;
  headerLabel: string;
  loading: boolean;
  formErrors?: IProjectCostFormErrors;
  setFormErrors: SetStateAction<IProjectCostFormErrors | undefined>;
  setForm: SetStateAction<IProjectCostForm>;
  onClose: () => void;
  onConfirm: (form: IProjectCostForm) => Promise<unknown | undefined>;
}

export default function ProjectCostFormModal({
  open,
  form,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    setForm({
      fmv_step_up_input_type: "",
      profit_percent: "",
      fmv_step_up: "",
    });
    setFormErrors({});
    onClose();
  };

  const formatToTwoDecimalPlaces = (value: string) => {
    return parseFloat(value).toFixed(2);
  };

  const handleOnConfirm = async () => {
    form.fmv_step_up = formatToTwoDecimalPlaces(form.fmv_step_up);
    const deal = await onConfirm(form);
    deal && handleOnClose();
  };

  const handleResetForm = () => {
    setForm({
      fmv_step_up_input_type: "",
      profit_percent: "",
      fmv_step_up: "",
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <>
        <SelectInput
          required
          label="FMV Step Up Input Type"
          selected={form.fmv_step_up_input_type}
          name="fmv_step_up_input_type"
          items={PROJECT_COST_FMV_STEP_UP_INPUT_TYPE_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("fmv_step_up_input_type")}
          onChange={(e) => handleSelectInputChange(e, "fmv_step_up_input_type")}
          error={Boolean(formErrors?.fmv_step_up_input_type)}
          helperText={formErrors?.fmv_step_up_input_type}
          disabled={loading}
          fullWidth={false}
          tooltip="Increase in value from de-risking the project and bringing it to completion and sale (i.e. entrepreneurial profit, developer profit). Calculated as FMV Step Up Amount / Project Cost."
        />
        {form.fmv_step_up_input_type === "PP" && (
          <TextInput
            required
            label="FMV Step Up Percent"
            name="profit_percent"
            value={form.profit_percent}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.profit_percent)}
            helperText={formErrors?.profit_percent}
            disabled={loading}
            endAdornment={<>%</>}
            fullWidth={false}
          />
        )}
        {form.fmv_step_up_input_type === "FSA" && (
          <CurrencyInput
            required
            label="FMV Step Up Amount"
            name="fmv_step_up"
            value={form.fmv_step_up}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.fmv_step_up)}
            helperText={formErrors?.fmv_step_up}
            disabled={loading}
            startAdornment={<>$</>}
            fullWidth={false}
          />
        )}
      </>
    </Modal>
  );
}
