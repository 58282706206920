import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import FormControl from "@mui/material/FormControl";
import TextField, { TextFieldProps } from "@mui/material/TextField";

import useStyles from "./styles";
import Tooltip from "../tooltip";
import { IExtendedFormHelperTextProps } from "../../interfaces";
import {
  formatNumberForView,
  keepNumbersDotAndMinus,
} from "../../utils/helpers";

interface IProps {
  label?: string;
  value: string;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  maximumFractionDigits?: number;
  dataPw?: boolean;
  tooltip?: string;
  fullWidth?: boolean;
}

export default function CurrencyInput({
  label,
  value,
  startAdornment,
  endAdornment,
  onChange,
  maximumFractionDigits,
  dataPw,
  tooltip,
  fullWidth = true,
  ...restProps
}: IProps & TextFieldProps): JSX.Element {
  const styles = useStyles({ fullWidth: fullWidth });

  const handleFormattedValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = keepNumbersDotAndMinus(e?.target.value);
    onChange(e);
  };

  const getFormattedValue = () => {
    return formatNumberForView(value);
  };

  return (
    <Box className={styles.classes.container}>
      <FormControl className={styles.classes.formControl} fullWidth>
        <TextField
          fullWidth
          size="small"
          {...restProps}
          label={label}
          autoComplete="off"
          variant="outlined"
          inputProps={{
            className: styles.classes.input,
            "data-pw": dataPw || label,
          }}
          InputProps={{
            startAdornment: startAdornment || <>$</>,
            endAdornment: endAdornment || null,
          }}
          FormHelperTextProps={
            {
              "data-pw": `helper-text-${dataPw || label}`,
            } as IExtendedFormHelperTextProps
          }
          InputLabelProps={{
            classes: {
              shrink: styles.classes.labelShrink,
              animated: styles.classes.labelAnimated,
            },
          }}
          value={getFormattedValue()}
          onChange={handleFormattedValue}
        />
      </FormControl>

      {tooltip && (
        <Tooltip title={tooltip} placement="top-end">
          <InfoIcon classes={{ root: styles.classes.infoIcon }} />
        </Tooltip>
      )}
    </Box>
  );
}
