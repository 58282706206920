import { getDealReporting } from "../../../../apis/report/deal";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputConstructionDebtEquityView from "../../../../views/deal/output/construction-debt/equity";

export default function DealOutputConstructionDebtEquityPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputConstructionDebtEquityView
          getDealReporting={getDealReporting}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
