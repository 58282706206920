import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputLeaseView from "../../../../views/deal/output/sale-leaseback/lease";

export default function DealOutputLeasePage() {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputLeaseView />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
