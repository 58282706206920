import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputDeveloperSummaryView from "../../../../views/deal/output/developer/summary";
import { getDeveloperReportSummary } from "../../../../apis/report/deal";

export default function DealOutputDeveloperSummaryPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputDeveloperSummaryView
          getDeveloperReportSummary={getDeveloperReportSummary}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
