import ProjectMerchantRevenueDetails from "../../../../../views/project/pro-forma/revenue/merchant-revenue/details";
import ProjectRevenueLayout from "../../../../../components/project-revenue-layout";
import ProjectPagesLayout from "../../../../../components/project-pages-layout";
import TabbedLayout from "../../../../../components/tabbed-layout";
import { getCurves } from "../../../../../apis/configuration/base";
import { getProjectTiming } from "../../../../../apis/project/timing";
import {
  updateMerchantRevenue,
  getProjectMerchantRevenue,
  deleteProjectMerchantRevenueContract,
} from "../../../../../apis/project/revenue-contracts";

export default function MerchantRevenueDetails(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectRevenueLayout>
          <ProjectMerchantRevenueDetails
            updateProjectMerchantRevenue={updateMerchantRevenue}
            getProjectMerchantRevenueDetails={getProjectMerchantRevenue}
            getProjectTiming={getProjectTiming}
            deleteProjectMerchantRevenueContract={
              deleteProjectMerchantRevenueContract
            }
            getCurves={getCurves}
          />
        </ProjectRevenueLayout>
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
