import DealPagesLayout from "../../../components/deal-pages-layout";
import DealInputTaxEquityView from "../../../views/deal/sizing/tax-equity";
import TabbedLayout from "../../../components/tabbed-layout";
import { getDealTiming } from "../../../apis/deal/timing";
import {
  getDealTaxEquity,
  updateDealTaxEquity,
} from "../../../apis/deal/tax-equity";

export default function TaxEquity(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealInputTaxEquityView
          getDealTaxEquity={getDealTaxEquity}
          getDealTiming={getDealTiming}
          updateDealTaxEquity={updateDealTaxEquity}
          dealStructureType="tax-equity"
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
