import React from "react";
import Box from "@mui/material/Box";

import useStyles from "./styles";
import ReportTable from "../../components/report-table";
import ChartWrapper from "../../components/chart-wrapper";
import SponsorEquitySummaryCharts from "../deal/output/sponsor-equity/summary/charts";
import {
  ICoZe,
  ITableRow,
  ITaxEquityReportSummaryChart,
} from "../../interfaces";
import {
  CO_ZE_SPONSOR_EQUITY_IRR_IGNORE_FIELDS,
  IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_DP,
  IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_TRANSFER,
  ITEMS_AND_TOTAL_TABLE_COLUMN,
} from "../../constants";

interface IProps {
  coZe: ICoZe;
  sponsorEquityTableIrrRows: ITableRow[];
  sponsorEquityShortTableRows: ITableRow[];
  sponsorEquityReportSummaryChart: ITaxEquityReportSummaryChart | undefined;
}

const SponsorEquityTab: React.FC<IProps> = ({
  coZe,
  sponsorEquityTableIrrRows,
  sponsorEquityShortTableRows,
  sponsorEquityReportSummaryChart,
}) => {
  const styles = useStyles();
  return (
    <Box>
      <Box className={styles.classes.dealSection}>
        <Box>
          <Box className={styles.classes.innerChartContainer}>
            <ReportTable
              columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
              rows={sponsorEquityTableIrrRows}
              fullWidth
              ignore={CO_ZE_SPONSOR_EQUITY_IRR_IGNORE_FIELDS}
            />
          </Box>
          <ReportTable
            columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
            rows={sponsorEquityShortTableRows}
            ignore={
              coZe.deal_tax_credit_structure === "DP"
                ? IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_DP
                : IGNORE_SPONSOR_EQUITY_TABLE_FIELDS_FOR_TRANSFER
            }
            fullWidth
          />
        </Box>

        <Box className={styles.classes.chartContainer}>
          <Box className={styles.classes.innerChartContainer}>
            <ChartWrapper title="Sponsor Equity Value Components (NPV)">
              <SponsorEquitySummaryCharts
                sponsorEquityChart={sponsorEquityReportSummaryChart}
                isDirectPay={coZe.deal_tax_credit_structure === "DP"}
              />
            </ChartWrapper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SponsorEquityTab;
