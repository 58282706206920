import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    section: {
      width: "100%",
      padding: theme.spacing(2),
      [theme.breakpoints.down(600)]: {
        maxWidth: "100%",
      },
    },
    dealSection: {
      marginBottom: theme.spacing(4),
      "& > *": {
        width: "100%",
      },
      [theme.breakpoints.down(1500)]: {
        display: "contents",
        "& > *": {
          width: "100%",
        },
      },
      "& > *:first-of-type": {
        marginBottom: theme.spacing(4),
      },
    },
    tableTitleContainer: {
      display: "flex",
      justifyContent: "space-between",
    },
    tablePaper: {
      margin: theme.spacing(2, 0),
    },
    header: {
      ".MuiTableCell-root": {
        fontWeight: 600,
      },
    },
    dataRow: {
      cursor: "pointer",
    },
    link: {
      cursor: "pointer",
      fontWeight: 600,
      color: selectedTheme.color.secondary,
      padding: theme.spacing(0.2, 2, 0.2, 0),
    },
  };
});
