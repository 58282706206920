import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import SelectInput from "../select-input";
import {
  DEAL_DEPRECIATION_ELECTION_OPTIONS,
  UPDATE_DEAL_DEPRECIATION_ELECTION_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  SetStateAction,
  IUpdateDealDepreciationResponse,
  IUpdateDealDepreciationElectionForm,
  IUpdateDealDepreciationElectionFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IUpdateDealDepreciationElectionFormErrors;
  setFormErrors: SetStateAction<
    IUpdateDealDepreciationElectionFormErrors | undefined
  >;
  form: IUpdateDealDepreciationElectionForm;
  setForm: SetStateAction<IUpdateDealDepreciationElectionForm>;
  onClose: () => void;
  onConfirm: (
    form: IUpdateDealDepreciationElectionForm,
  ) => Promise<IUpdateDealDepreciationResponse | undefined>;
}

export default function DealDepreciationElectionEditModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleOnConfirm = async () => {
    const deal = await onConfirm(form);
    deal && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(UPDATE_DEAL_DEPRECIATION_ELECTION_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      open={open}
      maxWidth="xs"
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        <SelectInput
          required
          label="Depreciation Election"
          selected={form.macrs_election}
          items={DEAL_DEPRECIATION_ELECTION_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("macrs_election")}
          onChange={(e) => handleSelectInputChange(e, "macrs_election")}
          error={Boolean(formErrors?.macrs_election)}
          helperText={formErrors?.macrs_election}
          disabled={loading}
          tooltip="<b>Regular</b> = No changes to MACRS property depreciation methods \n<b>Bonus</b> =  An additional first-year depreciation allowance that maximizes upfront write-off. Applies to elligible MACRS property \n<b>12yr SL ADS</b> = A slower depreciation schedule of 12 years. Applies to elligible MACRS property."
          fullWidth={false}
        />
      </Box>
    </Modal>
  );
}
