import TabbedLayout from "../../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../../components/project-pages-layout";
import ProjectOtherPageLayout from "../../../../components/project-other-page-layout";
import ProjectOtherFlowView from "../../../../views/project/pro-forma/other-flows/flow";
import { getProjectTiming } from "../../../../apis/project/timing";
import {
  addProjectOtherFlow,
  deleteProjectOtherFlow,
  editProjectOtherFlow,
  getProjectOtherFlow,
} from "../../../../apis/project/other-flows";

export default function ProjectOtherFlowPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectOtherPageLayout>
          <ProjectOtherFlowView
            addProjectOtherFlow={addProjectOtherFlow}
            getProjectOtherFlow={getProjectOtherFlow}
            getProjectTiming={getProjectTiming}
            editProjectOtherFlow={editProjectOtherFlow}
            deleteProjectOtherFlow={deleteProjectOtherFlow}
          />
        </ProjectOtherPageLayout>
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
