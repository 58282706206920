import {
  ICoZe,
  ICoZeForm,
  ICoZeConvertToDealForm,
  ICoZeConvertToDealSuccess,
  EmptyObjectType,
} from "../../interfaces";
import { CO_ZE_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getCoZes = async () => {
  return await AxiosHelper.get<ICoZe | EmptyObjectType>(
    CO_ZE_API_ROUTES.base.get,
  );
};

export const createCoZe = async (form: ICoZeForm) => {
  return await AxiosHelper.post<ICoZeForm, ICoZe>(
    CO_ZE_API_ROUTES.base.create,
    form,
  );
};

export const updateCoZe = async (coZeId: number, form: ICoZeForm) => {
  const url = replaceParams(CO_ZE_API_ROUTES.base.update, {
    coZeId,
  });
  return await AxiosHelper.patch<ICoZeForm, ICoZe>(url, form);
};

export const deleteCoZe = async (coZeId: number) => {
  const url = replaceParams(CO_ZE_API_ROUTES.base.delete, {
    coZeId,
  });
  return await AxiosHelper.delete(url);
};

export const updateCozeConvertToDeal = async (
  coZeId: number,
  form: ICoZeConvertToDealForm,
) => {
  const url = replaceParams(CO_ZE_API_ROUTES.base.updateCoZeConvertToDeal, {
    coZeId,
  });
  return await AxiosHelper.post<
    ICoZeConvertToDealForm,
    ICoZeConvertToDealSuccess
  >(url, form);
};
