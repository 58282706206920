import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  logContainer: {
    padding: theme.spacing(1),
  },
  logDetails: {
    display: "flex",
    justifyContent: "space-between",
  },
  loadMoreContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
  },
}));
