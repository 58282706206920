import DealPagesLayout from "../../../../components/deal-pages-layout";
import TabbedLayout from "../../../../components/tabbed-layout";
import DealFeeTableView from "../../../../views/deal/sizing/deal-fee/table";
import {
  addDealFee,
  deleteDealFee,
  getDealFees,
  updateDealFee,
  getDealOnlyCost,
} from "../../../../apis/deal/fee";

export default function DealFeePage(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealFeeTableView
          getDealFees={getDealFees}
          addDealFee={addDealFee}
          updateDealFee={updateDealFee}
          deleteDealFee={deleteDealFee}
          getDealOnlyCost={getDealOnlyCost}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
