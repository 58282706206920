import React from "react";
import Box from "@mui/material/Box";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";
import FormHelperText from "@mui/material/FormHelperText";
import Typography from "@mui/material/Typography";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { format } from "date-fns";

import useStyles from "./styles";
import Button from "../button";
import Tooltip from "../tooltip";
import Modal from "../modal";
import IconButton from "../icon-button";
import SchedulerTextInput from "../scheduler-text-input";
import { useCopyToClipboard } from "../../utils/hooks";
import {
  calculateCurveTotal,
  cn,
  formatNumberForView,
  trimString,
} from "../../utils/helpers";

interface ISchedulerInputProps {
  label: string;
  name: string;
  dateSchedule: string[];
  value: (number | string | null)[];
  error: string;
  disabled: boolean;
  clearErrorOnFocus: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (v: (number | string | null)[]) => void;
  fullWidth?: boolean;
  tooltip?: string;
  formatValue?: boolean;
  startAdornment?: string;
  endAdornment?: string;
  showTotal?: boolean;
  showOnlyYear?: boolean;
}

export default function SchedulerInput({
  label,
  name,
  dateSchedule,
  value,
  error,
  disabled,
  clearErrorOnFocus,
  onChange,
  fullWidth,
  tooltip,
  formatValue,
  startAdornment = "",
  endAdornment = "",
  showTotal = false,
  showOnlyYear = false,
}: ISchedulerInputProps): JSX.Element {
  const styles = useStyles();

  const { copyToClipboard } = useCopyToClipboard();

  const [openCurveModal, setOpenCurveModal] = React.useState<boolean>(false);
  const [curve, setCurve] = React.useState<(number | string | null)[]>([]);

  const handleOpenCurveModal = () => {
    // copy the curve from the main form to the curve's form
    setCurve(value);
    setOpenCurveModal(true);
  };

  const handleCloseCurveModal = () => {
    setOpenCurveModal(false);
  };

  const handleChangeCurve = (value: (number | string | null)[]) => {
    setCurve(value);
  };

  const onConfirm = async () => {
    // on confirm update the entered curve in the main form
    onChange(curve);
    handleCloseCurveModal();
  };

  const formatError = (error: string[] | string) => {
    return Array.isArray(error)
      ? error.find((element) => typeof element === "string")
      : error;
  };

  // convert YYYY-MM-DD to YYYY/MM/DD
  if (dateSchedule?.length > 0 && dateSchedule[0]?.includes("-")) {
    dateSchedule = dateSchedule.map((d) => d.replaceAll("-", "/"));
  }

  dateSchedule = dateSchedule.map((d) =>
    format(new Date(d), showOnlyYear ? "yyyy" : "M/d/yyyy"),
  );

  const handleCopy = (data: (string | number | null)[]) => {
    copyToClipboard(data);
  };

  const getValue = (v: string | number | null) => {
    if (!v) return v;

    if (formatValue) {
      return formatNumberForView(String(v));
    }
    return v;
  };

  const curveTotal = React.useMemo(() => {
    return calculateCurveTotal(value);
  }, [value]);

  const getFormattedValueWithAdornments = (
    startAdornment: string,
    endAdornment: string,
    value: string | number | null,
    format = true,
    trim = true,
  ): JSX.Element => {
    const num = typeof value === "number" ? value : Number(value);
    const textElipsisClass = cn(
      "!whitespace-nowrap !overflow-hidden !text-ellipsis",
    );
    if (num < 0) {
      const absValue = Math.abs(num);
      const formattedValue = format ? getValue(absValue) : absValue;
      return (
        <div className={textElipsisClass}>
          -{startAdornment}
          {trim ? trimString(formattedValue as string, 8) : formattedValue}
          {endAdornment}
        </div>
      );
    }
    const positiveValue = format ? getValue(value) : value;
    return (
      <div className={textElipsisClass}>
        {startAdornment}
        {trim ? trimString(positiveValue as string, 8) : positiveValue}
        {endAdornment}
      </div>
    );
  };

  return (
    <>
      <Box className={cn("flex items-start mb-4")}>
        <Box
          className={cn(`${fullWidth ? "w-full" : "w-[calc(100%_-_40px)]"}`)}
        >
          <Box
            className={`${styles.classes.schdulerInput} ${
              error && styles.classes.schedulerInputError
            }`}
          >
            <Box>
              {!value.length ? (
                <Box>
                  <Typography marginBottom={2}>{label}</Typography>
                  <Typography marginBottom={2} variant="body2">
                    No curves added here
                  </Typography>
                </Box>
              ) : (
                <Box className={cn("flex flex-col gap-2 mb-4")}>
                  <Box className={cn("grid grid-cols-2")}>
                    <p></p>
                    <Typography fontWeight="bold">{label}</Typography>
                  </Box>
                  {dateSchedule?.length > 6 ? (
                    <Box>
                      {/* first 3 row */}
                      <Box className={cn("grid grid-cols-2")}>
                        {dateSchedule?.slice(0, 3).map((d, i) => {
                          return (
                            <React.Fragment key={i}>
                              <div>{d}</div>
                              {getFormattedValueWithAdornments(
                                startAdornment,
                                endAdornment,
                                value[i],
                              )}
                            </React.Fragment>
                          );
                        })}
                      </Box>
                      <Box className={cn("grid grid-cols-2")}>
                        <MoreVertIcon />
                        {/* show the more vert icon when row value > 3 */}
                        {value.length > 3 && <MoreVertIcon />}
                      </Box>
                      <Box>
                        {/* last 3 row */}
                        {dateSchedule?.slice(-3).map((d, i) => {
                          const originalIndex = dateSchedule.length - 3 + i; // Calculate original index
                          return (
                            <div
                              key={originalIndex}
                              className={cn("grid grid-cols-2")}
                            >
                              <div>{d}</div>
                              {getFormattedValueWithAdornments(
                                startAdornment,
                                endAdornment,
                                value[originalIndex],
                              )}
                            </div>
                          );
                        })}
                      </Box>
                    </Box>
                  ) : (
                    <Box className={cn("grid grid-cols-2")}>
                      {dateSchedule?.map((d, i) => {
                        return (
                          <React.Fragment key={i}>
                            <div>{d}</div>
                            {getFormattedValueWithAdornments(
                              startAdornment,
                              endAdornment,
                              value[i],
                            )}
                          </React.Fragment>
                        );
                      })}
                    </Box>
                  )}
                </Box>
              )}
              {showTotal && (
                <Box className={cn("grid grid-cols-2 font-semibold mb-4")}>
                  <div>Total</div>
                  {getFormattedValueWithAdornments(
                    startAdornment,
                    endAdornment,
                    curveTotal,
                    false,
                    false,
                  )}
                </Box>
              )}
              {/* action button to add/modify */}
              <Box className={cn("flex gap-2")}>
                <Button
                  btnType="secondary"
                  label={!value.length ? "Add" : "Modify"}
                  onClick={handleOpenCurveModal}
                  dataPw={`${label}-${!value.length ? "Add" : "Modify"}`}
                />
                <IconButton onClick={() => handleCopy(value)}>
                  <CopyIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
          {/* error text */}
          <FormHelperText
            data-pw={`helper-text-${label}`}
            error={Boolean(error)}
          >
            {formatError(error)}
          </FormHelperText>
        </Box>

        {tooltip && (
          <Tooltip title={tooltip} placement="top-end">
            <InfoIcon className={cn("ml-2 text-light-gray")} />
          </Tooltip>
        )}
      </Box>

      <Modal
        maxWidth="xs"
        open={openCurveModal}
        heading={label}
        onClose={handleCloseCurveModal}
        onConfirm={onConfirm}
        classes={{ paper: cn("!min-w-[500px]") }}
      >
        <SchedulerTextInput
          label={label}
          onChange={handleChangeCurve}
          name={name}
          dateSchedule={dateSchedule}
          value={curve}
          error={error}
          disabled={disabled}
          clearErrorOnFocus={clearErrorOnFocus}
          tooltip={tooltip}
          formatValue={formatValue}
          startAdornment={startAdornment}
          endAdornment={endAdornment}
          showTotal={showTotal}
        />
      </Modal>
    </>
  );
}
