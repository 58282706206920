import { enumToOptions } from "../utils/helpers";

export enum CURVE_TYPE {
  PC = "Price Curve",
  IRC = "Interest Rate Curve",
  IC = "Inflation Curve",
}
export enum PRICE_TERM_TYPE {
  RL = "Real",
  NOM = "Nominal",
}

export const CURVE_TYPE_OPTIONS = enumToOptions(CURVE_TYPE, ["IC"]);
export const PRICE_TERM_TYPE_OPTIONS = enumToOptions(PRICE_TERM_TYPE);
