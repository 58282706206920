import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    container: {
      padding: theme.spacing(2),
      [theme.breakpoints.down(600)]: {
        maxWidth: "100%",
      },
    },
    header: {
      display: "flex",
      justifyContent: "end",
      gap: theme.spacing(1),
      [theme.breakpoints.down(600)]: {
        flexDirection: "column",
      },
    },
    content: {
      marginTop: theme.spacing(4),
      display: "flex",
      justifyContent: "space-between",
      "& > *": {
        width: "32%",
      },
      [theme.breakpoints.down(1050)]: {
        display: "block",
        "& > *": {
          width: "100%",
          marginBottom: theme.spacing(4),
          "&:nth-of-type(1)": {},
        },
      },
    },
    section: {
      display: "flex",
      gap: theme.spacing(2),
      flexDirection: "column",
    },
    showMoreButton: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      width: "100%",
      backgroundColor: selectedTheme.color.surface,
      border: `1px solid ${selectedTheme.color.background}`,
      textTransform: "none",
      borderRadius: "8px",
    },
    formContainer: {
      display: "flex",
      gap: theme.spacing(2),
      [theme.breakpoints.down(1050)]: {
        flexDirection: "column-reverse",
        gap: theme.spacing(4),
      },
    },
    divider: {
      margin: theme.spacing(4, 0),
      border: `1px solid ${selectedTheme.color.aboveSurface}`,
    },
    chartContainer: {
      [theme.breakpoints.up(1200)]: {
        marginLeft: theme.spacing(2),
        width: "61%",
      },
      margin: theme.spacing(0),
      width: "100%",
    },
    innerChartContainer: {
      marginBottom: theme.spacing(2),
    },
    dealSection: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: theme.spacing(4),
      "& > *": {
        width: "39%",
      },
      [theme.breakpoints.down(1200)]: {
        display: "contents",
        "& > *": {
          width: "100%",
        },
        "& > *:first-of-type": {
          marginBottom: theme.spacing(4),
        },
      },
    },
    text404: {
      fontSize: "40px",
      margin: "auto",
      textAlign: "center",
      width: "fit-content",
      [theme.breakpoints.down(500)]: {
        fontSize: "30px",
      },
    },
  };
});
