import Box from "@mui/material/Box";

import useStyles from "./styles";
import ReportTable from "../../components/report-table";
import ChartWrapper from "../../components/chart-wrapper";
import TransferReportSummaryCharts from "../deal/output/transfer/summary/charts";
import { ITEMS_AND_TOTAL_TABLE_COLUMN } from "../../constants";
import {
  ICoZe,
  ITableRow,
  ITransferReportSummaryChart,
} from "../../interfaces";

interface IProps {
  coZe: ICoZe;
  transferReportSummaryChart: ITransferReportSummaryChart | null | undefined;
  transferShortTableRows: ITableRow[] | null;
  transferTableRows: ITableRow[] | null;
  transferIrrRows: ITableRow[] | null;
}

const TransferTab: React.FC<IProps> = ({
  coZe,
  transferReportSummaryChart,
  transferShortTableRows,
  transferTableRows,
  transferIrrRows,
}) => {
  const styles = useStyles();
  return (
    <Box>
      {transferReportSummaryChart &&
      transferShortTableRows &&
      transferTableRows &&
      transferIrrRows ? (
        <Box className={styles.classes.dealSection}>
          <Box>
            <Box className={styles.classes.innerChartContainer}>
              <ReportTable
                columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
                rows={transferIrrRows}
                ignore={coZe.deal_tax_credit_structure == "DP" ? ["ROI"] : []}
              />
            </Box>
          </Box>

          <Box className={styles.classes.chartContainer}>
            <Box className={styles.classes.innerChartContainer}>
              <ChartWrapper
                title={
                  coZe.deal_tax_credit_structure == "DP"
                    ? "Direct Pay Value Components (NPV)"
                    : "Transfer Value Components (NPV)"
                }
              >
                <TransferReportSummaryCharts
                  transferChart={transferReportSummaryChart}
                />
              </ChartWrapper>
            </Box>
          </Box>
        </Box>
      ) : (
        <p className={styles.classes.text404}>
          Transfer Summary Not Available!
        </p>
      )}
    </Box>
  );
};

export default TransferTab;
