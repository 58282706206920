import { darken } from "@mui/system";
import { makeStyles } from "tss-react/mui";

import { ButtonType } from "../../interfaces";
import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{ type: ButtonType }>()((theme, { type }) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    root: {
      backgroundColor:
        type === "primary" ? selectedTheme.color.primary : "transparent",
      color: type === "primary" ? "#000" : selectedTheme.color[type],
      fontWeight: 600,
      textTransform: "none",
      borderRadius: 5,
      padding: theme.spacing(0.5, 0.8),
      border: `3px solid ${selectedTheme.color[type]}`,
      minWidth: 120,
      ":hover": {
        backgroundColor:
          type === "primary"
            ? darken(selectedTheme.color.primary, 0.1)
            : "transparent",
      },
    },
    tooltip: {
      backgroundColor: theme.palette.grey[500],
    },
  };
});
