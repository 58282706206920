import { IDealTermDebtForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_TERM_TERM_DEBT_TYPES {
  BL = "Back-Leverage",
  FL = "Front-Leverage",
}

export const DEAL_TERM_TERM_DEBT_TYPES_OPTIONS = enumToOptions(
  DEAL_TERM_TERM_DEBT_TYPES,
  ["FL"],
);

export enum DEAL_TERM_DEBT_FORM_FIELDS_TO_EXCLUDE {
  FL = "Front-Leverage",
}

export enum DEAL_TERM_DEBT_SOLVE_FORS {
  DA = "Debt Amount",
  CR = "Coverage Ratio",
}

export const DEAL_TERM_DEBT_SOLVE_FORS_OPTIONS = enumToOptions(
  DEAL_TERM_DEBT_SOLVE_FORS,
  ["CR"],
);

export enum DEAL_TERM_DEBT_PERIODICITY {
  MO = "Monthly",
  QU = "Quarterly",
  SA = "Semi-Annual",
  AN = "Annual",
}

export const DEAL_TERM_DEBT_PERIODICITY_OPTIONS = enumToOptions(
  DEAL_TERM_DEBT_PERIODICITY,
);

export enum DEAL_TERM_DEBT_SIZING_TIMING {
  FCOD = "First COD",
  LCOD = "Last COD",
}

export const DEAL_TERM_DEBT_SIZING_TIMING_OPTIONS = enumToOptions(
  DEAL_TERM_DEBT_SIZING_TIMING,
  ["FCOD"],
);

export enum DEAL_TERM_DEBT_BASE_INPUT_TYPES {
  RATE = "Fixed Rate",
  OC = "SOFR (Organization Curve)",
}

export const DEAL_TERM_DEBT_BASE_INPUT_TYPES_OPTIONS = enumToOptions(
  DEAL_TERM_DEBT_BASE_INPUT_TYPES,
);

export const DEAL_TERM_DEBT_FORM_DEFAULT_STATE: IDealTermDebtForm = {
  type: "BL",
  solve_for: "DA",
  sizing_timing: "LCOD",
  funding_date: "",
  debt_term: "",
  base_input_type: "RATE",
  base_rate: "",
  payment_periodicity: "",
  spread: "",
  spread_step_up: "",
  spread_step_up_frequency: "",
  hedged_percentage: 0,
  swap_rate: 0,
  swap_rate_credit_spread: 0,
  upfront_fees_percentage: 0,
  lc_fees_percentage: 0,
  admin_fees: 0,
  contracted_cash_dscr: "",
  uncontracted_cash_dscr: 0,
  special_cash_dscr: 0,
  transfer_cash_dscr: 0,
  base_rate_org_curve: "",
};
