import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputLessorTaxView from "../../../../views/deal/output/sale-leaseback/lessor/tax";
import { getDealReporting } from "../../../../apis/report/deal";

export default function DealOutputLessorTaxPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputLessorTaxView getDealReporting={getDealReporting} />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
