import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    header: {
      display: "flex",
      justifyContent: "end",
      "& > *": {
        display: "flex",
        gap: theme.spacing(2),
      },
    },
    table: {
      "& .MuiTableCell-head": {
        fontWeight: 600,
      },
      "& td": {
        padding: theme.spacing(1),
        paddingInline: theme.spacing(2),
      },
    },
    content: {
      marginTop: theme.spacing(2),
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    chartContainer: {
      marginBlock: theme.spacing(2),
    },
    sectionHeading: {
      margin: theme.spacing(0, 0, 1.2, 0.5),
      fontWeight: 600,
    },
    tableContainer: {
      maxHeight: "calc(100vh - 250px)",
    },
    boldRow: {
      "& td": {
        fontWeight: "bold !important",
      },
    },
    link: {
      color: `${selectedTheme.color.text}`,
      textDecorationColor: `${selectedTheme.color.text}`,
    },
  };
});
