import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputLesseeSummaryView from "../../../../views/deal/output/sale-leaseback/lessee/summary";
import { getOutputLesseeSummary } from "../../../../apis/deal/sale-leaseback";

export default function DealOutputLesseeSummaryPage() {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputLesseeSummaryView
          getLesseeOutputSummary={getOutputLesseeSummary}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
