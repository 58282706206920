import React from "react";

export const useSessionStorage = <V>(
  key: string,
  initialValue: V,
): [V, (newValue: V) => void] => {
  const storedValue = sessionStorage.getItem(key);

  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = React.useState<V>(initial);

  const updateValue = (newValue: V) => {
    setValue(newValue);
    sessionStorage.setItem(key, JSON.stringify(newValue));
  };

  return [value, updateValue];
};
