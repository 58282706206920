import {
  IAddDealCaseComparisonForm,
  IDuplicateDealCaseForm,
  IUpdateDealCaseComparisonForm,
  IUpdateDealCaseForm,
} from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_CASE_TYPE {
  SCEN = "Scenario",
  SENS = "Sensitivity",
}

export const DEAL_CASE_TYPE_OPTIONS = enumToOptions(DEAL_CASE_TYPE);

export enum DEAL_CASE_PROJECT_TAX_CREDIT_TYPE {
  NC = "No Change",
  NONE = "None",
  ITC = "ITC",
  PTC = "PTC",
}

export const DEAL_CASE_PROJECT_TAX_CREDIT_TYPE_OPTIONS = enumToOptions(
  DEAL_CASE_PROJECT_TAX_CREDIT_TYPE,
);

export const ADD_CASE_COMPARISON_FORM_DEFAULT_STATE: IAddDealCaseComparisonForm =
  {
    name: "",
    base_case_deal: "",
    deal_ids: [],
  };

export const UPDATE_CASE_COMPARISON_FORM_DEFAULT_STATE: IUpdateDealCaseComparisonForm =
  {
    base_case_deal: "",
    deal_ids: [],
    name: "",
    id: "",
  };

export const DUPLICATE_CASE_FORM_DEFAULT_STATE: IDuplicateDealCaseForm = {
  id: "",
  name: "",
  description: "",
};

export const UPDATE_CASE_FORM_DEFAULT_STATE: IUpdateDealCaseForm = {
  id: "",
  name: "",
  description: "",
};
