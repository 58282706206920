import React from "react";

import Modal from "../modal";
import SchedulerTextInput from "../scheduler-text-input";
import {
  IProjectEBITDA,
  IProjectEBITDA_Form,
  IProjectEBITDA_FormErrors,
  SetStateAction,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  dateSchedule: string[];
  formErrors?: IProjectEBITDA_FormErrors;
  setFormErrors: SetStateAction<IProjectEBITDA_FormErrors | undefined>;
  form: IProjectEBITDA_Form;
  setForm: SetStateAction<IProjectEBITDA_Form>;
  onClose: () => void;
  onConfirm: (form: IProjectEBITDA_Form) => Promise<IProjectEBITDA | undefined>;
}

export default function ProjectEBITDAFormModal({
  open,
  headerLabel,
  loading,
  dateSchedule,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleScheduleChange = (value: (number | string | null)[]) => {
    setForm((prevState) => ({
      ...prevState,
      ebitda: value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const ebitda = await onConfirm({ ...form });
    ebitda && handleOnClose();
  };

  const handleResetForm = () => {
    setForm({ ebitda: [] });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <SchedulerTextInput
        label="EBITDA"
        name="ebitda"
        value={form?.ebitda || []}
        error={formErrors?.ebitda || ""}
        onChange={handleScheduleChange}
        clearErrorOnFocus={clearErrorOnFocus}
        disabled={loading}
        dateSchedule={dateSchedule}
        formatValue
        startAdornment={"$"}
      />
    </Modal>
  );
}
