import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  header: {
    display: "flex",
    justifyContent: "end",
  },
  content: {
    marginTop: theme.spacing(2),
    display: "flex",
    width: "60%",
    [theme.breakpoints.down(650)]: {
      width: "100%",
    },
  },
}));
