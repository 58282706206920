import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import { TAX_REPORT_TABLE_COLUMNS } from "../../../../../constants";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  getDealReportingUrl,
  generateTaxReportTableRows,
} from "../../../../../utils/helpers";
import {
  IReport,
  ITableRow,
  ReportTerm,
  ReportType,
  ReportPerspective,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    periodicity: OutputReportPeriodicityType,
  ) => Promise<IReport>;
}

export default function DealOutputSponsorEquityTaxView({
  getDealReporting,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { dealId, caseDealId } = useParams();
  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealId}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealId}-output-periodicity`,
      "MO",
    );

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealReportingCallAPI(Number(caseDealId), buyout, periodicityType).then(
        (response) => {
          if (response?.["pship/taxdetail"]) {
            setReport(response);
          }
        },
      );
    }
  }, [caseDealId, buyout, periodicityType]);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
    ) =>
      getDealReporting(
        dealId,
        "sponsorequity",
        "taxdetail",
        term,
        reportPeriodicityType,
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [getReportFailed, loadingReport]);

  const taxTableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateTaxReportTableRows(report) : [];
  }, [report]);

  const handleDownloadReport = async () => {
    if (caseDealId) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(caseDealId),
        "sponsorequity",
        "taxdetail",
        buyout,
        periodicityType,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealReportingUrl(
            Number(caseDealId),
            "sponsorequity",
            "taxdetail",
            buyout,
            periodicityType,
          )
        ]
      }
    >
      <DealOutputFilterFields
        buyout={buyout}
        periodicityType={periodicityType}
        setBuyout={setBuyout}
        setPeriodicityType={setPeriodicityType}
        showBuyout={report?.has_investor_buyout}
        downloadReport={handleDownloadReport}
        disableDownload={isDownloading}
        showPeriodicity
        showSizingRedirectionButton
      />

      <ReportTable
        columns={TAX_REPORT_TABLE_COLUMNS}
        rows={taxTableRows}
        boldTotal
        stickyTotal
      />
    </ViewWrapper>
  );
}
