import React from "react";
import LogsIcon from "@mui/icons-material/Restore";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { Protect } from "@clerk/clerk-react";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import LogsWrapper from "../../../../components/logs-wrapper";
import DetailsCard from "../../../../components/details-card";
import UpdateDealOtherSizingFormModal from "../../../../components/update-deal-other-sizing-form-modal";
import { cn } from "../../../../utils/helpers";
import { DEAL_OTHER_SIZING, USER_PERMISSIONS } from "../../../../constants";
import { useAPI, useLogs, useDrawer } from "../../../../utils/hooks";
import {
  IDealOtherSizing,
  ILogsConfiguration,
  IUpdateDealOtherSizingForm,
  IUpdateDealOtherSizingFormErrors,
} from "../../../../interfaces";

interface IProps {
  getDealOtherSizing: (dealId: number) => Promise<IDealOtherSizing[]>;
  updateDealOtherSizing: (
    dealId: number,
    sizingId: number,
    form: IUpdateDealOtherSizingForm,
  ) => Promise<IDealOtherSizing>;
}

export default function DealSizingOther({
  getDealOtherSizing,
  updateDealOtherSizing,
}: IProps) {
  const styles = useStyles();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const { dealId, caseDealId } = useParams();

  const [otherSizing, setOtherSizing] = React.useState<IDealOtherSizing>();
  const [editSizingForm, setEditSizingForm] =
    React.useState<IUpdateDealOtherSizingForm>({
      id: null,
      expense_allocation_method: "",
    });
  const [openSizingEditModal, setOpenSizingEditModal] =
    React.useState<boolean>(false);

  const {
    callAPI: getDealOtherSizingCallAPI,
    errored: getDealOtherSizingFailed,
    loading: loadingOtherSizing,
  } = useAPI((dealId: number) => getDealOtherSizing(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: updateDealOtherSizingCallAPI,
    errored: updateDealOtherSizingFailed,
    loading: loadingUpdateDealOtherSizing,
    fieldErrors: updateDealOtherSizingFormErrors,
    setFieldErrors: setUpdateDealOtherSizingFormErrors,
  } = useAPI<IDealOtherSizing, IUpdateDealOtherSizingFormErrors>(
    (dealId: number, form: IUpdateDealOtherSizingForm, sizingId: number) =>
      updateDealOtherSizing(dealId, Number(sizingId), form),
  );

  React.useEffect(() => {
    getDealOtherSizingCallAPI(Number(caseDealId)).then((res) => {
      res && setOtherSizing(res[0]);
    });
  }, [caseDealId]);

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const onUpdateDealSizing = async (form: IUpdateDealOtherSizingForm) => {
    const res = await updateDealOtherSizingCallAPI(caseDealId, form, form.id);
    res && setOtherSizing(res);
    return res;
  };

  const handleOpenEditSizigModal = () => {
    setEditSizingForm({
      expense_allocation_method:
        otherSizing?.expense_allocation_method as keyof typeof DEAL_OTHER_SIZING,
      id: Number(otherSizing?.id),
    });
    setOpenSizingEditModal(true);
  };

  const handleCloseEditSizigModal = () => {
    setOpenSizingEditModal(false);
  };

  const dealSizingOtherLogConfiguration: ILogsConfiguration = {
    id: Number(otherSizing?.id),
    type: "dealothersizing",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      dealSizingOtherLogConfiguration.type,
      dealSizingOtherLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingOtherSizing}
        error={getDealOtherSizingFailed}
      >
        <Box>
          <Box className={styles.classes.header}>
            <Box className={cn("flex gap-4 items-center")}>
              <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                <IconButton onClick={handleOnOpenLogs}>
                  <LogsIcon />
                </IconButton>
                {!isOnCase && (
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleOpenEditSizigModal}
                  />
                )}
              </Protect>
            </Box>
          </Box>
          <Box className={styles.classes.content}>
            <DetailsCard
              heading="Other Sizing"
              sections={[
                {
                  fields: [
                    {
                      label: "Expense Allocation Method",
                      value: {
                        text: DEAL_OTHER_SIZING[
                          otherSizing?.expense_allocation_method as keyof typeof DEAL_OTHER_SIZING
                        ],
                      },
                    },
                  ],
                },
              ]}
            />
          </Box>
        </Box>
      </ViewWrapper>

      <UpdateDealOtherSizingFormModal
        open={openSizingEditModal}
        loading={loadingUpdateDealOtherSizing}
        form={editSizingForm}
        formErrors={updateDealOtherSizingFormErrors}
        setFormErrors={setUpdateDealOtherSizingFormErrors}
        setForm={setEditSizingForm}
        onClose={handleCloseEditSizigModal}
        onUpdate={onUpdateDealSizing}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={dealSizingOtherLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={dealSizingOtherLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
