import Layout from "../../components/layout";
import WorkflowModelView from "../../views/workflow/model";

export default function WorkflowModelPage(): JSX.Element {
  return (
    <Layout title="Workflow">
      <WorkflowModelView />
    </Layout>
  );
}
