import Box from "@mui/material/Box";
import MuiButton from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import useStyles from "./styles";
import { useNavigate } from "react-router-dom";

export default function ProductPlanLimitHitView(): JSX.Element {
  const styles = useStyles();

  const navigate = useNavigate();

  const goToContactUsPage = () => {
    window.open("https://capezero.com/contact", "_blank");
  };

  const gotToBillingAndUsagePage = () => {
    navigate("/configuration/organization/billing-and-usage");
  };

  return (
    <Box className={styles.classes.container}>
      <Typography fontSize={20}>
        You have hit the limits of your plan, please contact us to subscribe to
        upgrade.
      </Typography>

      <Box>
        <MuiButton
          classes={{ root: styles.classes.contactUsBtn }}
          onClick={goToContactUsPage}
        >
          CONTACT US
        </MuiButton>
        <MuiButton
          classes={{ root: styles.classes.billingBtn }}
          onClick={gotToBillingAndUsagePage}
        >
          BILLING AND USAGE
        </MuiButton>
      </Box>
    </Box>
  );
}
