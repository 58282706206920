import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  modal: {
    minWidth: "80%",
    minHeight: "625px",
  },
  sectionHeading: {
    margin: theme.spacing(0, 0, 1.2, 0.5),
    fontWeight: 600,
  },
  tableRow: {
    "& td": {
      padding: theme.spacing(0.5, 0.5),
    },
    "& td:nth-of-type(2n)": {
      padding: 0,
    },
  },
  textField: {
    width: 180,
    borderRadius: 0,
    height: "100%",
    "& input": {
      fontSize: 14,
      padding: theme.spacing(0.5, 1),
    },
  },

  pFactorTableTextField: {
    width: "100%",
    "& input": {
      fontSize: 14,
      padding: theme.spacing(0.5, 0.5),
    },
  },
}));
