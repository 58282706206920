import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { DataGridPro, DataGridProProps } from "@mui/x-data-grid-pro";

import { IReportTable } from "../../interfaces";
import { cn, generateReportTableV2Data } from "../../utils/helpers";

interface IProps {
  data: IReportTable;
  groupExpandedDepth?: number;
  dataPw?: string;
}

export default function ReportTableV2({
  data,
  groupExpandedDepth = 0,
  dataPw,
}: IProps): JSX.Element {
  const { rows, columns } = generateReportTableV2Data(data);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => {
    return row.hierarchy;
  };

  return (
    <Box>
      {rows.length ? (
        <DataGridPro
          columns={columns}
          rows={rows}
          density="compact"
          getTreeDataPath={getTreeDataPath}
          pinnedColumns={{ left: ["__tree_data_group__", "Total"] }}
          classes={{ columnHeaderTitle: cn("!font-bold text-[15px]") }}
          columnHeaderHeight={70}
          groupingColDef={{
            headerName: "Name",
            minWidth: 300,
            hideDescendantCount: true,
          }}
          getCellClassName={(params) => {
            return params.colDef.field === "Total" ? cn("font-bold") : "";
          }}
          isGroupExpandedByDefault={(group) => {
            return group.depth === groupExpandedDepth;
          }}
          treeData
          hideFooter
          disableColumnMenu
          disableAutosize
          disableColumnResize
        />
      ) : (
        <Box className={cn("h-96 content-center text-center")}>
          <Typography
            variant="body1"
            className={cn("mt-[20px] p-4 text-[#666] ")}
            data-pw={dataPw}
          >
            There is no data in this report.
          </Typography>
        </Box>
      )}
    </Box>
  );
}
