import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{
  borderBottom?: boolean;
  hasStickyTabs?: boolean;
}>()((theme, { borderBottom, hasStickyTabs }) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    tabRoot: {
      width: "100%",
      minHeight: 42,
      marginTop: -1,
      borderBottom: borderBottom
        ? `2px solid ${selectedTheme.color.background}`
        : "none",
      // sticky styles
      position: hasStickyTabs ? "sticky" : undefined,
      top: hasStickyTabs ? 42 : undefined,
      zIndex: hasStickyTabs ? 24 : undefined,
      background: hasStickyTabs
        ? `${selectedTheme.color.surface} !important`
        : undefined,
    },
    tab: {
      fontSize: 14,
      minHeight: 42,
      textTransform: "none",
    },
    selectedTab: {
      color: `${selectedTheme.color.secondary} !important`,
    },
    selectedTabIndicator: {
      backgroundColor: `${selectedTheme.color.secondary} !important`,
    },
  };
});
