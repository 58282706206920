import React from "react";
import { useDispatch } from "react-redux";

import ProductPlansModal from "../product-plans-modal";
import ConfirmDeleteModal from "../confirm-delete-modal";
import { useAppSelector } from "../../utils/hooks";
import { updateContentLoadedAction } from "../../utils/redux/slices";

interface IProps {
  children: JSX.Element;
}

export default function AppWrapper({ children }: IProps): JSX.Element {
  const dispatch = useDispatch();
  const { deleteModalProps } = useAppSelector((s) => s.common);

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        updateContentLoadedAction(
          Boolean(document.getElementById("content-loaded")),
        ),
      );
    }, 300);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {children}
      <ConfirmDeleteModal {...deleteModalProps} />
      <ProductPlansModal />
    </>
  );
}
