import {
  ITab,
  IDealOutputPageTabs,
  DealOutputPageTypes,
} from "../../interfaces";

export const DEAL_OUTPUT_COMMON_TABS: ITab[] = [
  {
    label: "Summary",
    value: "summary",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/summary",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Cash",
    value: "cash",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/cash",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Equity",
    value: "equity",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/equity",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Construction Loan",
    value: "construction-loan",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/construction-loan",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Investor Bridge Loan",
    value: "investor-bridge-loan",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/investor-bridge-loan",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  // {
  //   label: "Transfer Bridge Loan",
  //   value: "transfer-bridge-loan",
  //   path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/transfer-bridge-loan",
  //   pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  // },
  {
    label: "Tax",
    value: "tax",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/tax",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Capital Accounts",
    value: "capital-accounts",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/capital-accounts",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Outside Basis",
    value: "outside-basis",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/outside-basis",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Accounting",
    value: "accounting",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/accounting",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Service Coverage",
    value: "service-coverage",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/service-coverage",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Splits",
    value: "splits",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/splits",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Coverage",
    value: "coverage",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/coverage",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Facility",
    value: "facility",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/facility",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
  {
    label: "Charts",
    value: "charts",
    path: "/deal/:dealId/case-deal/:caseDealId/output/:activePage/charts",
    pathToMatch: "/deal/:dealId/case-deal/:caseDealId/output/:activePage",
  },
];

export const DEAL_OUTPUT_PAGE_TABS: IDealOutputPageTabs = {
  partnership: DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      tab.label === "Summary" ||
      tab.label === "Cash" ||
      tab.label === "Tax" ||
      tab.label === "Splits",
  ),
  "tax-equity": DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      tab.label === "Summary" ||
      tab.label === "Cash" ||
      tab.label === "Tax" ||
      tab.label === "Capital Accounts" ||
      tab.label === "Outside Basis" ||
      tab.label === "Accounting" ||
      tab.label === "Charts",
  ),
  "sponsor-equity": DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      tab.label === "Summary" ||
      tab.label === "Cash" ||
      tab.label === "Tax" ||
      tab.label === "Capital Accounts" ||
      tab.label === "Outside Basis" ||
      tab.label === "Accounting" ||
      tab.label === "Charts",
  ),
  "sponsor-equity-bol": DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      tab.label === "Summary" || tab.label === "Cash" || tab.label === "Tax",
  ),
  developer: DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      // tab.label === "Summary" ||
      tab.label === "Cash" || tab.label === "Tax",
  ),
  "construction-debt": DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      tab.label === "Summary" ||
      tab.label === "Cash" ||
      tab.label === "Equity" ||
      tab.label === "Construction Loan" ||
      tab.label === "Investor Bridge Loan" ||
      tab.label === "Transfer Bridge Loan",
    // tab.label === "Charts",
  ),
  "term-debt": DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      tab.label === "Summary" ||
      tab.label === "Coverage" ||
      tab.label === "Facility" ||
      tab.label === "Charts",
    // tab.label === "Service Coverage",
  ),
  transfer: DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) => tab.label === "Summary" || tab.label === "Cash",
  ),
  lease: DEAL_OUTPUT_COMMON_TABS.filter((tab) => tab.label === "Summary"),
  lessor: DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      tab.label === "Summary" || tab.label === "Cash" || tab.label === "Tax",
  ),
  lessee: DEAL_OUTPUT_COMMON_TABS.filter(
    (tab) =>
      tab.label === "Summary" || tab.label === "Cash" || tab.label === "Tax",
  ),
};

export const DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS: {
  [k in DealOutputPageTypes | "dealId"]: string;
} = {
  "term-debt": "summary",
  partnership: "summary",
  "sponsor-equity": "summary",
  "tax-equity": "summary",
  transfer: "summary",
  dealId: "",
  lessor: "summary",
  lease: "summary",
  lessee: "summary",
  "sponsor-equity-bol": "summary",
  developer: "cash",
  "construction-debt": "summary",
};
