import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    table: {
      "& .MuiTableCell-head": {
        fontWeight: 600,
      },
      "& td": {
        padding: 0,
        paddingInline: theme.spacing(2),
      },
    },
    footer: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: `${selectedTheme.color.aboveSurface} !important`,
    },
    searchbar: {
      minWidth: "250px !important",
      backgroundColor: `${selectedTheme.color.aboveSurface} !important`,
    },
  };
});
