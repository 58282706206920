import React from "react";
import { Chart as ChartJS, BarElement, BarController } from "chart.js";

import { useScreenWidth } from "../../../../../utils/hooks";
import { ITaxEquityReportSummaryChart } from "../../../../../interfaces";
import { chartTooltipArrayOfNumbersToNumber } from "../../../../../utils/helpers";

ChartJS.register(BarElement, BarController);

interface IProps {
  taxEquityChart?: ITaxEquityReportSummaryChart;
}

export default function Chart({ taxEquityChart }: IProps): JSX.Element {
  const taxEquityChartRef = React.useRef<HTMLCanvasElement | null>(null);
  const width = useScreenWidth();

  React.useEffect(() => {
    const taxEquityChart2DRef = taxEquityChartRef?.current?.getContext("2d");
    const chartData = [
      {
        label: "Cash Distributions",
        backgroundColor: "#00B969",
        value: taxEquityChart?.cash_distributions.data,
        percent: taxEquityChart?.cash_distributions.percent,
      },
      (taxEquityChart?.transfer_proceeds?.data !== undefined &&
        taxEquityChart?.transfer_proceeds?.data !== null && {
          label: "Transfer Cash",
          backgroundColor: "#4F5EA1",
          value: taxEquityChart?.transfer_proceeds?.data,
          percent: taxEquityChart?.transfer_proceeds?.percent,
        }) ||
        {},
      {
        label: "Tax Credits",
        backgroundColor: "#5335FA",
        value: taxEquityChart?.tax_credit.data,
        percent: taxEquityChart?.tax_credit.percent,
      },
      {
        label: "Depreciation Benefits",
        backgroundColor: "#7E68FB",
        value: taxEquityChart?.depreciation.data,
        percent: taxEquityChart?.depreciation.percent,
      },
      {
        label: "Tax Detriments",
        backgroundColor: "#9481FC",
        value: taxEquityChart?.taxable_income.data,
        percent: taxEquityChart?.taxable_income.percent,
      },
      {
        label: "Total Value",
        backgroundColor: "#9F9F9F",
        value: taxEquityChart?.tax_efficient_value.data,
        percent: taxEquityChart?.tax_efficient_value.percent,
      },
    ].filter((cD) => cD.label !== undefined);
    const taxEquityChartObj =
      taxEquityChart2DRef &&
      new ChartJS(taxEquityChart2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: chartData.map((cD) => cD.label),
          datasets: [
            {
              label: "",
              data: chartData.map((cD) => cD.value),
              backgroundColor: chartData.map((cD) => cD.backgroundColor),
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  return (
                    chartTooltipArrayOfNumbersToNumber(ctx) +
                    " / " +
                    chartData[ctx.dataIndex].percent +
                    "%"
                  );
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y: {
              title: {
                display: true,
                text: "Amount ($)",
              },
            },
          },
        },
      });

    return () => {
      taxEquityChartObj?.destroy();
    };
  }, [width]);

  return <canvas id="taxEquityChart" ref={taxEquityChartRef} />;
}
