import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{ sidebarWidth: number }>()((
  theme,
  { sidebarWidth },
) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    drawerPaper: {
      borderRight: "none",
      background: `${selectedTheme.color.surface} !important`,
      boxShadow: "0px 0px 3px 1px #0000001F",
      boxSizing: "border-box",
      width: sidebarWidth,
      zIndex: 7,
    },
    linkList: {
      padding: theme.spacing(1),
    },
    listItemRoot: {
      borderRadius: 5,
      textDecoration: "none",
      display: "flex",
      padding: theme.spacing(1.8),
      width: "100%",
      alignItems: "center",
      height: 50,
      ".MuiListItemText-root": {
        margin: 0,
        "& span": {
          fontSize: 16,
        },
      },
      "& svg:first-of-type": {
        marginRight: theme.spacing(1),
      },
    },
    selectedOption: {
      backgroundColor: `${selectedTheme.color.secondary} !important`,
      ".MuiListItemText-root": {
        "& span": {
          color: "#fff !important",
        },
      },
      ":hover": {
        backgroundColor: `${selectedTheme.color.secondary} !important`,
      },
    },
    subLink: {
      display: "flex",
      padding: theme.spacing(1),
      borderRadius: 5,
      textDecoration: "none",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
      height: 36,
      paddingLeft: theme.spacing(4.7),
      ".MuiListItemText-root": {
        margin: 0,
        "& span": {
          lineHeight: "14px",
          fontSize: 15,
        },
      },
    },
    activesubLink: {
      borderLeft: `6px solid ${selectedTheme.color.secondary}`,
      ".MuiListItemText-root": {
        "& span": {
          marginLeft: theme.spacing(-0.7),
        },
      },
    },
    floatingLink: {
      textDecoration: "none",
      fontSize: 15,
      padding: theme.spacing(0.8, 2),
      minWidth: 150,
    },
  };
});
