import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import { UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE } from "../../constants";
import {
  SetStateAction,
  IDealTaxCreditTransferPortfolioForm,
  IDealTaxCreditTransferPortfolioFormErrors,
  IDealTaxCreditTransferPortfolio,
} from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IDealTaxCreditTransferPortfolioForm;
  setForm: SetStateAction<IDealTaxCreditTransferPortfolioForm>;
  formErrors?: IDealTaxCreditTransferPortfolioFormErrors;
  setFormErrors: SetStateAction<
    IDealTaxCreditTransferPortfolioFormErrors | undefined
  >;
  onClose: () => void;
  onConfirm: (
    form: IDealTaxCreditTransferPortfolioForm,
  ) => Promise<IDealTaxCreditTransferPortfolio | undefined>;
}

export default function EditDealTaxCreditTransfer({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleOnConfirm = async () => {
    const res = await onConfirm(form);
    res && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleResetform = () => {
    setForm(UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetform}
    >
      <Box>
        <Divider classes={{ root: styles.classes.divider }} textAlign="left">
          Additional Parameters
        </Divider>
        <TextInput
          isNumeric
          label="Discount Rate"
          name="transfer_discount_rate"
          value={form.transfer_discount_rate}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.transfer_discount_rate)}
          helperText={formErrors?.transfer_discount_rate}
          endAdornment={<>%</>}
          disabled={loading}
          tooltip="Discount rate for measuring the value of transferred tax credit"
          fullWidth={false}
        />
      </Box>
    </Modal>
  );
}
