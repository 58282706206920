import React from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import { useDispatch } from "react-redux";

import { cn } from "../../utils/helpers";
import { useAppSelector } from "../../utils/hooks";
import { updateShowUsageLimitHitModalAction } from "../../utils/redux/slices";

interface IProps {
  canOpenUpgrade?: boolean;
  actionType: "add" | "edit" | "delete" | "duplicate";
}

export default function ActionButton({
  canOpenUpgrade = false,
  actionType,
  onClick,
  ...props
}: ButtonProps & IProps): JSX.Element {
  const dispatch = useDispatch();
  const { productUsageDetails } = useAppSelector((s) => s.org);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (canOpenUpgrade) {
      if (productUsageDetails?.strawman_plan === "TRIAL") {
        dispatch(updateShowUsageLimitHitModalAction(true));
        return;
      }
    }
    onClick && onClick(e);
  };

  const buttonStyles: string = React.useMemo(() => {
    let twClassName = "!font-semibold ";

    if (["add", "edit"].includes(actionType)) {
      twClassName += "!text-white !bg-secondary";
    }
    if (actionType === "delete") {
      twClassName += "!text-danger !border-danger";
    }
    if (actionType === "duplicate") {
      twClassName += "!text-gray !border-gray";
    }

    return twClassName;
  }, [actionType]);

  const buttonVariant = React.useMemo(() => {
    return ["add", "edit"].includes(actionType) ? "contained" : "outlined";
  }, [actionType]);

  return (
    <Button
      className={cn(buttonStyles)}
      variant={buttonVariant}
      onClick={handleOnClick}
      {...props}
    />
  );
}
