import React from "react";
import MenuItem from "@mui/material/MenuItem";
import MuiMenu, { MenuProps } from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Button from "../button";
import { IMenuItem } from "../../interfaces";

interface IProps {
  placeholder: string;
  selectedItem: string;
  menuProps?: MenuProps;
  menuItems: IMenuItem[];
}

export default function Menu({
  placeholder,
  menuProps,
  menuItems,
  selectedItem,
}: IProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (onClick: () => void) => {
    onClick();
    handleClose();
  };

  return (
    <>
      <Button
        btnType="secondary"
        label={selectedItem || placeholder}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      />
      <MuiMenu
        open={Boolean(anchorEl)}
        elevation={0}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        {...menuProps}
      >
        {menuItems.map((item, idx) => {
          return (
            <MenuItem
              key={idx}
              onClick={() => handleItemClick(item.onClick)}
              {...item.menuItemProps}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </MuiMenu>
    </>
  );
}
