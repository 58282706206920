import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import TextInput from "../text-input";
import AutocompleteField from "../autocomplete-field";
import MultiAutocompleteField from "../multi-autocomplete-field";
import { UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE } from "../../constants";
import {
  SetStateAction,
  ISelectOption,
  IDealComparison,
  IUpdateDealComparisonForm,
  IUpdateDealComparisonFormErrors,
} from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IUpdateDealComparisonForm;
  setForm: SetStateAction<IUpdateDealComparisonForm>;
  formErrors?: IUpdateDealComparisonFormErrors;
  setFormErrors: SetStateAction<IUpdateDealComparisonFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (
    form: IUpdateDealComparisonForm,
  ) => Promise<IDealComparison | undefined>;
  existingDeals: ISelectOption[];
}

export default function UpdateDealComparisonFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
  existingDeals,
}: IProps): JSX.Element {
  const handleChangeDealComparisonInfo = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | ISelectOption[] | null,
    name: string,
  ) => {
    setFormErrors((prevErrors) => {
      return {
        ...prevErrors,
        [name]: "",
      };
    });
    if (Array.isArray(value)) {
      const v = value?.map((o: ISelectOption) => o?.value);
      setForm((prev) => {
        return {
          ...prev,
          [name]: v as Array<string>,
        };
      });
    }
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnUpdateDealComparison = async () => {
    const deal = await onConfirm(form);
    deal && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleReset = () => {
    setForm(UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="sm"
      fullWidth
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnUpdateDealComparison}
      resetForm={handleReset}
    >
      <Box>
        <TextInput
          required
          label="Deal Comparison Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleChangeDealComparisonInfo}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
        />

        <AutocompleteField
          label="Select Benchmark Deal"
          name="benchmark_deal"
          onChange={handleSingleAutoCompleteChange}
          options={existingDeals}
          value={String(form.benchmark_deal)}
          helperText={formErrors?.benchmark_deal}
          onFocus={() => clearSelectErrorOnFocus("benchmark_deal")}
          disabled={loading}
        />

        <MultiAutocompleteField
          label="Select Deals"
          name="deal_ids"
          onChange={handleAutoCompleteChange}
          options={existingDeals}
          value={form.deal_ids}
          helperText={formErrors?.deal_ids}
          onFocus={() => clearSelectErrorOnFocus("deal_ids")}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
