import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputsponsorEquityBOL_View from "../../../../views/deal/output/sponsor-equity-BOL";

export default function DealOutputSponsorEquityBOL_Page(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputsponsorEquityBOL_View />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
