import ProjectPagesLayout from "../../../../components/project-pages-layout";
import {
  getProjectCreditSupport,
  addProjectCreditSupport,
  editProjectCreditSupport,
  deleteProjectCreditSupport,
  getProjectCostsSchedule,
} from "../../../../apis/project/cost";
import TabbedLayout from "../../../../components/tabbed-layout";
import CreditSupportTableView from "../../../../views/project/cost/credit-support/list";

export default function ProjectCostCreditSupportPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Cost">
        <CreditSupportTableView
          getProjectCreditSupport={getProjectCreditSupport}
          addProjectCreditSupport={addProjectCreditSupport}
          editProjectCreditSupport={editProjectCreditSupport}
          deleteProjectCreditSupport={deleteProjectCreditSupport}
          getProjectCostsSchedule={getProjectCostsSchedule}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
