import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  projectChartsContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    margin: theme.spacing(4, 0),
    "& > *": {
      width: "49%",
    },
  },
}));
