import { Theme, createTheme } from "@mui/material/styles";

import { externalLightTheme } from "./external-light";

export const lightTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#7f7f7f",
    },
    mode: "light",
  },
  typography: {
    allVariants: {
      letterSpacing: 0.5,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& th": {
            backgroundColor: `${externalLightTheme.color.surface} !important`,
          },
        },
      },
    },
  },
});
