import DealPagesLayout from "../../components/deal-pages-layout";
import DealAccessView from "../../views/deal/access";

export default function DealAccessPage() {
  return (
    <DealPagesLayout>
      <DealAccessView />
    </DealPagesLayout>
  );
}
