import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  card: {
    margin: theme.spacing(3, 2),
    padding: theme.spacing(2, 2),
    minWidth: 275,
  },
  infoCardContent: {
    width: "40%",
    float: "left",
  },
  scheduleCardContent: {
    width: "55%",
    float: "right",
  },
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  message: {
    padding: theme.spacing(10, 5),
  },
}));
