import React from "react";
import Box from "@mui/material/Box";

import useStyles from "./styles";
import ReportTable from "../../components/report-table";
import ChartWrapper from "../../components/chart-wrapper";
import TaxEquitySummaryCharts from "../deal/output/tax-equity/summary/charts";
import {
  ICoZe,
  ITableRow,
  ITaxEquityReportSummaryChart,
} from "../../interfaces";
import {
  CO_ZE_DEAL_STRUCTURE_TYPE,
  CO_ZE_TAX_EQUITY_IRR_IGNORE_FIELDS,
  ITEMS_AND_TOTAL_TABLE_COLUMN,
} from "../../constants";

interface IProps {
  coZe: ICoZe;
  taxEquityTableIrrRows: ITableRow[];
  taxEquityShortTableRows: ITableRow[];
  taxEquityReportSummaryChart: ITaxEquityReportSummaryChart | undefined;
}

const TaxEquityTab: React.FC<IProps> = ({
  coZe,
  taxEquityTableIrrRows,
  taxEquityShortTableRows,
  taxEquityReportSummaryChart,
}) => {
  const styles = useStyles();

  return (
    <Box className={styles.classes.dealSection}>
      <Box>
        <Box className={styles.classes.innerChartContainer}>
          <ReportTable
            columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
            rows={taxEquityTableIrrRows}
            fullWidth
            ignore={CO_ZE_TAX_EQUITY_IRR_IGNORE_FIELDS}
          />
        </Box>

        <ReportTable
          columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
          rows={taxEquityShortTableRows}
          fullWidth
        />
      </Box>
      <Box className={styles.classes.chartContainer}>
        <Box className={styles.classes.innerChartContainer}>
          <ChartWrapper
            title={
              CO_ZE_DEAL_STRUCTURE_TYPE[
                coZe?.deal_structure as keyof typeof CO_ZE_DEAL_STRUCTURE_TYPE
              ] === CO_ZE_DEAL_STRUCTURE_TYPE.TEP
                ? "Tax Equity Value Components (NPV)"
                : "Cash Equity Value Components (NPV)"
            }
          >
            <TaxEquitySummaryCharts
              taxEquityChart={taxEquityReportSummaryChart}
            />
          </ChartWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default TaxEquityTab;
