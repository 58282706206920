import {
  IProjectMerchantRevenueForm,
  IProjectRevenueForm,
} from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES {
  BDP = "Basis Differential",
  BDC = "Basis Differential Curve",
}

export const PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES_OPTIONS = enumToOptions(
  PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES,
);

export enum PROJECT_REVENUE_CONTRACT_TYPE {
  PPA = "PPA",
  VPPA = "VPPA",
  HEDGE = "Hedge",
  OTHER = "Other",
  REC = "REC",
  CAPACITY = "Capacity",
}

export const PROJECT_REVENUE_CONTRACT_TYPE_OPTIONS = enumToOptions(
  PROJECT_REVENUE_CONTRACT_TYPE,
);

export enum PROJECT_MERCHANT_REVENUE_TYPE {
  ENERGY = "Energy",
  REC = "REC",
  CAPACITY = "Capacity",
  OTHER = "Other",
}

export const PROJECT_MERCHANT_REVENUE_TYPE_OPTIONS = enumToOptions(
  PROJECT_MERCHANT_REVENUE_TYPE,
);

export enum TERM_END_POINT_TYPES {
  PEOL = "Project End of Life",
}

export const TERM_END_POINT_TYPES_OPTIONS = enumToOptions(TERM_END_POINT_TYPES);

export enum TERM_START_POINT_TYPES {
  COD = "Commercial Operation Date",
}

export const TERM_START_POINT_TYPES_OPTIONS = enumToOptions(
  TERM_START_POINT_TYPES,
);

export enum TERM_INPUT_METHOD_TYPE {
  YR = "Years",
  EP = "End Point",
}

export const TERM_INPUT_METHOD_TYPE_OPTIONS = enumToOptions(
  TERM_INPUT_METHOD_TYPE,
);

export enum START_INPUT_METHOD_TYPE {
  YR = "Fixed Date",
  SP = "Start Point",
}

export enum ESCALATION_METHOD_TYPE {
  CY = "Calendar Year",
  OY = "Operating Year",
}

export const ESCALATION_METHOD_TYPE_OPTIONS = enumToOptions(
  ESCALATION_METHOD_TYPE,
);

export const START_INPUT_METHOD_TYPE_OPTIONS = enumToOptions(
  START_INPUT_METHOD_TYPE,
);

export enum PROJECT_MERCHANT_PRICE_INPUT_TYPES {
  FIXED = "Price",
  CURVE = "Price Curve",
  OC = "Organization Curve",
}

export const PROJECT_MERCHANT_PRICE_INPUT_TYPES_OPTIONS = enumToOptions(
  PROJECT_MERCHANT_PRICE_INPUT_TYPES,
);

export enum PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES {
  FIXED = "Percentage",
  CURVE = "Percentage Curve",
}

export const PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES_OPTIONS =
  enumToOptions(PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES);

export const PROJECT_REVENUE_FORM_DEFAULT_STATE: IProjectRevenueForm = {
  differential_method: "BDP",
  cash_basis_lag: "",
  base_year: "",
  escalator: "",
  includes_capacity: false,
  includes_recs: false,
  price_input_type: "",
  price: "",
  price_curve: [],
  basis_differential: 0,
  basis_curve: null,
  start_input_method: "",
  term_start_point: "",
  start_date: "",
  term_input_method: "",
  term_end_point: "",
  term_years: "",
  type: "",
  name: "",
  volume_percentage: "",
  revenue_curve: null,
  org_curve: null,
};

export const PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE: IProjectMerchantRevenueForm =
  {
    base_year: "",
    escalator: "",
    type: "",
    name: "",
    price: null,
    price_curve: [],
    price_input_type: "",
    revenue_curve: null,
    price_haircut: 0.0,
    eligible_capacity_input_type: "",
    cash_basis_lag: 0,
    eligible_capacity_percentage: null,
    eligible_capacity_percentage_curve: null,
    monthly_allocation_type: "TH",
    org_curve: null,
    basis_curve: [],
    basis_differential: 0,
    differential_method: "BDP",
  };
