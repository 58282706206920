import React from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import SchedulerInput from "../scheduler-input";
import {
  PROJECT_OTHER_STREAM_TYPE_OPTIONS,
  PROJECT_OTHER_STREAM_INPUT_TYPE_OPTIONS,
  PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE,
} from "../../constants";
import {
  IProjectOtherFlow,
  IProjectOtherFlowForm,
  IProjectOtherFlowFormErrors,
  SetStateAction,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  form: IProjectOtherFlowForm;
  loading: boolean;
  formErrors?: IProjectOtherFlowFormErrors;
  setFormErrors: SetStateAction<IProjectOtherFlowFormErrors | undefined>;
  setForm: SetStateAction<IProjectOtherFlowForm>;
  onConfirm: (
    form: IProjectOtherFlowForm,
  ) => Promise<IProjectOtherFlow | undefined>;
  onClose: () => void;
  dateSchedule: string[];
}

export default function ProjectOtherFlowFormModal({
  open,
  headerLabel,
  form,
  loading,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
  dateSchedule,
}: IProps): JSX.Element {
  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleCurveChange = (value: (number | string | null)[]) => {
    setForm((prevState) => ({
      ...prevState,
      stream_strip: value,
    }));
  };

  const clearErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const res = await onConfirm(form);
    res && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        <TextInput
          required
          label="Name"
          name="name"
          value={form.name}
          onChange={handleTextChange}
          onFocus={(e) => clearErrorOnFocus(e.target.name)}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
        />
        <SelectInput
          required
          label="Type"
          selected={form.type}
          items={PROJECT_OTHER_STREAM_TYPE_OPTIONS}
          onFocus={() => clearErrorOnFocus("type")}
          onChange={(e) => handleSelectInputChange(e, "type")}
          error={Boolean(formErrors?.type)}
          helperText={formErrors?.type}
          disabled={loading}
        />
        <SchedulerInput
          label="Other Flow"
          name="stream_strip"
          dateSchedule={dateSchedule || []}
          value={form?.stream_strip || []}
          error={formErrors?.stream_strip || ""}
          clearErrorOnFocus={() => clearErrorOnFocus("stream_strip")}
          onChange={handleCurveChange}
          disabled={loading}
          fullWidth
          formatValue
          startAdornment={"$"}
        />
        <SelectInput
          required
          label="Input Type"
          selected={form.input_type}
          items={PROJECT_OTHER_STREAM_INPUT_TYPE_OPTIONS}
          onFocus={() => clearErrorOnFocus("input_type")}
          onChange={(e) => handleSelectInputChange(e, "input_type")}
          error={Boolean(formErrors?.input_type)}
          helperText={formErrors?.input_type}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
