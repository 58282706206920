import React from "react";
import Box from "@mui/material/Box";

import Modal from "../modal";
import TextInput from "../text-input";
import {
  INote,
  INoteForm,
  INoteFormErrors,
  SetStateAction,
} from "../../interfaces";
import { cn } from "../../utils/helpers";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: INoteForm;
  setForm: SetStateAction<INoteForm>;
  formErrors?: INoteFormErrors;
  setFormErrors: SetStateAction<INoteFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (form: INoteForm) => Promise<INote | undefined>;
}

export default function DealCaseDuplicateFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleOnAdd = async () => {
    const dealCase = await onConfirm(form);
    dealCase && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm({ note: "", object_id: null, object_type: null });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnAdd}
      resetForm={handleResetForm}
    >
      <Box>
        <TextInput
          multiline
          required
          rows={4}
          label="Note"
          name="note"
          value={form.note}
          onFocus={clearErrorOnFocus}
          onChange={handleChange}
          error={Boolean(formErrors?.note)}
          helperText={formErrors?.note}
          disabled={loading}
          classes={{ root: "[&_textarea]:!p-0" }}
        />
      </Box>
    </Modal>
  );
}
