import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES, PROJECT_COST_REPORT_TYPE } from "../../constants";
import {
  IAddProjectCreditSupportForm,
  ICostItemForm,
  ICostItemResponse,
  IProjectCost,
  IProjectCostDataResponse,
  IProjectCostItemDataResponse,
  IProjectCostSchduleResponse,
  IProjectCostSummaryResponse,
  IProjectCreditSupport,
  IUpdateProjectCostForm,
  IUpdateProjectCreditSupportForm,
  ServerPaginatedResponse,
} from "../../interfaces";

export const getProjectCostsSummary = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.summary.get, { projectId });
  return await AxiosHelper.get<IProjectCostSummaryResponse>(url);
};

export const updateProjectCosts = async (
  form: { profit_percent: number },
  projectId: number,
  costId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costUpdate, {
    projectId,
    costId,
  });
  return await AxiosHelper.put(url, form);
};

export const getProjectCostList = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costList, {
    projectId,
  });
  return await AxiosHelper.get<IProjectCostDataResponse>(url);
};

export const getProjectCostItem = async (
  projectId: number,
  costId: number,
  itemId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.get, {
    projectId,
    costId,
    itemId,
  });
  return await AxiosHelper.get<IProjectCostItemDataResponse>(url);
};

export const createProjectCostItem = async (
  form: ICostItemForm,
  projectId: number,
  costId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.create, {
    projectId,
    costId,
  });
  return await AxiosHelper.post<ICostItemForm, ICostItemResponse>(url, form);
};

export const editProjectCostItem = async (
  form: ICostItemForm,
  projectId: number,
  costId: number,
  itemId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.update, {
    projectId,
    costId,
    itemId,
  });
  return await AxiosHelper.put<ICostItemForm, ICostItemResponse>(url, form);
};

export const deleteProjectCostItem = async (
  projectId: number,
  costId: number,
  itemId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.costItem.delete, {
    projectId,
    costId,
    itemId,
  });
  return await AxiosHelper.delete(url);
};

export const updateProjectCost = async (
  projectId: number,
  costId: number,
  form: IUpdateProjectCostForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.update, {
    projectId,
    costId,
  });
  return await AxiosHelper.patch<IUpdateProjectCostForm, IProjectCost>(
    url,
    form,
  );
};

export const getProjectCreditSupport = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.get, {
    projectId,
  });
  return await AxiosHelper.get<
    ServerPaginatedResponse<IProjectCreditSupport[]>
  >(url);
};

export const getDetailsCreditSupport = async (
  projectId: number,
  creditSupportId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.getDetails, {
    projectId,
    creditSupportId,
  });
  return await AxiosHelper.get<IProjectCreditSupport>(url);
};

export const addProjectCreditSupport = async (
  projectId: number,
  form: IAddProjectCreditSupportForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.create, {
    projectId,
  });
  return await AxiosHelper.post<
    IAddProjectCreditSupportForm,
    IProjectCreditSupport
  >(url, form);
};

export const editProjectCreditSupport = async (
  projectId: number,
  creditSupportId: number,
  form: IUpdateProjectCreditSupportForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.update, {
    projectId,
    creditSupportId,
  });
  return await AxiosHelper.put<
    IUpdateProjectCreditSupportForm,
    IProjectCreditSupport
  >(url, form);
};

export const deleteProjectCreditSupport = async (
  projectId: number,
  creditSupportId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.creditSupport.delete, {
    projectId,
    creditSupportId,
  });
  return await AxiosHelper.delete(url);
};

export const getProjectCostsSchedule = async (
  projectId: number,
  type: keyof typeof PROJECT_COST_REPORT_TYPE,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.summary.getCostSchedule, {
    projectId,
    type,
  });
  return await AxiosHelper.get<IProjectCostSchduleResponse>(url);
};
