import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import useStyles from "./styles";
import Button from "../../../components/button";
import Searchbar from "../../../components/search-bar";
import ViewWrapper from "../../../components/view-wrapper";
import PopoverMenu from "../../../components/popover-menu";
import AddDealCaseComparisonFormModal from "../../../components/add-deal-case-comparison-form-modal";
import UpdateDealCaseComparisonFormModal from "../../../components/update-deal-case-comparison-form-modal";
import StickyTableCell from "../../../components/sticky-table-cell";
import { sortArrayOfObjects, trimString } from "../../../utils/helpers";
import { setDeleteModalPropsAction } from "../../../utils/redux/slices";
import { useAPI, useAppSelector } from "../../../utils/hooks";
import {
  USER_PERMISSIONS,
  ADD_CASE_COMPARISON_FORM_DEFAULT_STATE,
  UPDATE_CASE_COMPARISON_FORM_DEFAULT_STATE,
} from "../../../constants";
import {
  ITableSort,
  ISelectOption,
  IDealComparison,
  ServerPaginatedResponse,
  IAddDealCaseComparisonForm,
  IUpdateDealCaseComparisonForm,
  IAddDealCaseComparisonFormErrors,
  IUpdateDealCaseComparisonFormErrors,
  IDealCaseLite,
} from "../../../interfaces";

const columns = [
  { id: "name", label: "Case Comparison Name", minWidth: 170, align: "left" },
  {
    id: "benchmark_deal.name",
    label: "Benchmark Case Name",
    minWidth: 150,
    align: "left",
  },
  {
    id: "deal_count",
    label: "Number of Cases",
    minWidth: 100,
    align: "left",
  },
  { id: "modified", label: "Last Modified", minWidth: 50, align: "left" },
  { id: "action", label: "Action", minWidth: 50, align: "right" },
];

interface IProps {
  getDealComparisons: (
    base_case_deal: number,
  ) => Promise<ServerPaginatedResponse<IDealComparison[]>>;
  addDealComparison: (
    form: IAddDealCaseComparisonForm,
  ) => Promise<IDealComparison>;
  deleteDealComparison: (id: number) => Promise<boolean>;
  getDealComparison: (id: number) => Promise<IDealComparison>;
  updateDealCaseComparison: (
    id: number,
    form: IUpdateDealCaseComparisonForm,
  ) => Promise<IDealComparison>;
  getDealCasesLite: (dealId: number) => Promise<IDealCaseLite[]>;
}

export default function DealComparisonListView({
  addDealComparison,
  getDealComparisons,
  getDealCasesLite,
  deleteDealComparison,
  getDealComparison,
  updateDealCaseComparison,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealId } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);

  const [searchString, setSearchString] = React.useState("");
  const [addDealComparisonForm, setAddDealComparisonForm] =
    React.useState<IAddDealCaseComparisonForm>(
      ADD_CASE_COMPARISON_FORM_DEFAULT_STATE,
    );
  const [dealComparisons, setDealComparisons] = React.useState<
    IDealComparison[]
  >([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [addDealComparisonModalOpen, setAddDealComparisonModalOpen] =
    React.useState<boolean>(false);
  const [updateDealCaseForm, setUpdateDealCaseForm] =
    React.useState<IUpdateDealCaseComparisonForm>(
      UPDATE_CASE_COMPARISON_FORM_DEFAULT_STATE,
    );
  const [openEditFormModal, setOpenEditFormModal] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (dealId) {
      getDealComparisonsAPI(dealId).then((res) => {
        res && setDealComparisons(res?.results);
      });
    }
  }, [dealId]);

  const {
    callAPI: getDealComparisonsAPI,
    errored: getDealComparisonsFailed,
    loading: loadingGetDealComparisons,
  } = useAPI((base_case_deal) => getDealComparisons(base_case_deal), {
    initialLoading: true,
  });

  const { callAPI: getDealCasesLiteCallAPI } = useAPI((dealId) =>
    getDealCasesLite(Number(dealId)),
  );

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const filteredRows = React.useMemo(() => {
    return dealComparisons.filter((d) =>
      d.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [dealComparisons, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToDealComparison = (id: number) => {
    if (ctrlPressed) {
      window.open(`/deal/${dealId}/analysis/${id}`);
    } else {
      navigate(`/deal/${dealId}/analysis/${id}`);
    }
  };

  const handleOpenAddDealComparisonModal = async () => {
    await handleGetDealCases();
    setAddDealComparisonModalOpen(true);
  };

  const handleCloseAddDealComparisonModal = () => {
    setAddDealComparisonModalOpen(false);
  };

  const handleGetDealCases = async () => {
    const cases = await getDealCasesLiteCallAPI(Number(dealId));
    cases && setDealCasesLite(cases);
  };

  const {
    callAPI: addDealComparisonCallAPI,
    fieldErrors: addDealComparisonFormErrors,
    setFieldErrors: setAddDealCaseComparisonFormErrors,
    loading: addDealComparisonLoading,
  } = useAPI<IDealComparison, IAddDealCaseComparisonFormErrors>(
    (form: IAddDealCaseComparisonForm) => {
      const updatedForm: IAddDealCaseComparisonForm = {
        name: form.name,
        deal_ids: [form.base_case_deal, ...form.deal_ids],
        base_case_deal: form.base_case_deal,
      };
      return addDealComparison(updatedForm);
    },
  );

  const { callAPI: deleteDealComparisonCallAPI } = useAPI(
    (id: number) => deleteDealComparison(id),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: updateDealCaseComparisonCallAPI,
    fieldErrors: updateDealCaseFormErrors,
    setFieldErrors: setUpdateDealCaseFormErrors,
    loading: loadingUpdateDealCaseComparison,
  } = useAPI<IDealComparison, IUpdateDealCaseComparisonFormErrors>(
    (dealId, form: IUpdateDealCaseComparisonForm) => {
      const updatedForm = {
        base_case_deal: form.base_case_deal,
        deal_ids: [form.base_case_deal, ...form.deal_ids],
        name: form.name,
        id: form.id,
      };
      return updateDealCaseComparison(Number(dealId), updatedForm);
    },
  );

  const { callAPI: getDealComparisonCallAPI } = useAPI(
    (id: number) => getDealComparison(id),
    { initialLoading: false },
  );

  const handleUpdateDealCase = async (form: IUpdateDealCaseComparisonForm) => {
    const deal = await updateDealCaseComparisonCallAPI(Number(form.id), form);
    if (deal) {
      setDealComparisons((prevState) => {
        return prevState?.map((c) => {
          if (c.id === Number(deal.id)) {
            return deal;
          }
          return c;
        });
      });
    }
    return deal;
  };

  const handleEditDealComparison = async (id: number) => {
    // call the comparison details api to get the `deals_to_compare`/
    const caseComp = await getDealComparisonCallAPI(id);
    if (caseComp) {
      const { name, benchmark_deal, deals_to_compare } = caseComp;
      await handleGetDealCases();
      setUpdateDealCaseForm({
        deal_ids: deals_to_compare
          .map((d) => String(d?.id))
          .filter((d) => d !== String(benchmark_deal?.id)),
        base_case_deal: String(benchmark_deal?.id),
        name: name,
        id: Number(id),
      });
      setOpenEditFormModal(true);
    }
  };

  const handleAddDealComparison = async (form: IAddDealCaseComparisonForm) => {
    const deal = await addDealComparisonCallAPI(form);
    deal && goToDealComparison(deal?.id);
    return deal;
  };

  const [dealCasesLite, setDealCasesLite] = React.useState<IDealCaseLite[]>([]);

  const dealCasesOptions: ISelectOption[] = dealCasesLite.map((d) => {
    return {
      label: d.name,
      value: String(d.child_deal_id),
    };
  });

  const deleteDealCaseComparison = async (id: number) => {
    const deleted = await deleteDealComparisonCallAPI(id);

    if (deleted) {
      // remove the comparison from table
      setDealComparisons((c) => c.filter((c) => c.id !== id));
    }
  };

  const handleOnDelete = (id: number | undefined) => {
    if (id === undefined) {
      return;
    }
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Case Comparison",
        description: "Are you sure you want to delete Case Comparison?",
        onConfirm: () => deleteDealCaseComparison(id),
      }),
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingGetDealComparisons}
        error={getDealComparisonsFailed}
      >
        {dealComparisons?.length > 0 ? (
          <>
            <Box className={styles.classes.topSection}>
              <Box className={styles.classes.searchAndFilterContainer}>
                <Searchbar
                  searchString={searchString}
                  onTextChange={onSearchStringChange}
                />
              </Box>
              <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Add Case Comparison"
                  onClick={handleOpenAddDealComparisonModal}
                />
              </Protect>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer classes={{ root: styles.classes.tableContainer }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      {columns.map((column, idx) => {
                        if (column.id === "action") {
                          return (
                            <StickyTableCell
                              key={idx}
                              direction="right"
                              fixedColumnWidth={50}
                              align="center"
                              highZIndex
                            >
                              {column.label}
                            </StickyTableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={idx}
                            align={column.align as "left" | "right"}
                            style={{ maxWidth: column.minWidth }}
                          >
                            <TableSortLabel
                              active={sortTable.orderBy === column.id}
                              direction={
                                sortTable.orderBy === column.id
                                  ? sortTable.order
                                  : "asc"
                              }
                              onClick={() => sortRows(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows?.length === 0 && (
                      <TableRow>
                        <TableCell align="center" colSpan={6}>
                          No deal case comparison found.
                        </TableCell>
                      </TableRow>
                    )}
                    {visibleRows.map((dealComparison, idx) => {
                      return (
                        <TableRow
                          key={idx}
                          hover
                          tabIndex={-1}
                          className={styles.classes.dataRow}
                          onClick={() => goToDealComparison(dealComparison?.id)}
                        >
                          <TableCell align="left" title={dealComparison.name}>
                            {trimString(dealComparison?.name, 40)}
                          </TableCell>
                          <TableCell align="left" title="">
                            {dealComparison?.benchmark_deal !== null
                              ? trimString(
                                  dealComparison?.benchmark_deal.name,
                                  80,
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="left">
                            {dealComparison.deal_count}
                          </TableCell>
                          <TableCell align="left">
                            {format(
                              new Date(dealComparison?.modified),
                              "M/d/yyyy",
                            )}
                          </TableCell>
                          <StickyTableCell
                            direction="right"
                            fixedColumnWidth={50}
                            align="center"
                          >
                            <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                              <PopoverMenu
                                uniqueId={idx}
                                canOpenUpgrade
                                items={[
                                  {
                                    label: "Edit",
                                    onClick: () =>
                                      handleEditDealComparison(
                                        dealComparison?.id,
                                      ),
                                  },
                                  {
                                    label: "Delete",
                                    onClick: () =>
                                      handleOnDelete(dealComparison?.id),
                                  },
                                ]}
                              />
                            </Protect>
                          </StickyTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={dealComparisons?.length}
                className={styles.classes.paginationRow}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </>
        ) : (
          <Box className={styles.classes.emptyContainer}>
            <Box>
              <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Add Comparison"
                  onClick={handleOpenAddDealComparisonModal}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </Protect>

              <Typography variant="body1" className={styles.classes.createInfo}>
                Case comparison allows you to analyze differences between the
                inputs and outputs of cases. It looks like you do not have any
                comparisons yet.
              </Typography>
            </Box>
          </Box>
        )}
      </ViewWrapper>

      <AddDealCaseComparisonFormModal
        headerLabel="Add Case Comparison"
        open={addDealComparisonModalOpen}
        loading={addDealComparisonLoading}
        form={addDealComparisonForm}
        formErrors={addDealComparisonFormErrors}
        setFormErrors={setAddDealCaseComparisonFormErrors}
        setForm={setAddDealComparisonForm}
        onClose={handleCloseAddDealComparisonModal}
        onConfirm={handleAddDealComparison}
        existingCases={dealCasesOptions}
        baseCase={{
          label: "Base Case",
          value: String(dealId),
        }}
      />

      <UpdateDealCaseComparisonFormModal
        headerLabel="Edit Deal Case Comparison"
        open={openEditFormModal}
        loading={loadingUpdateDealCaseComparison}
        form={updateDealCaseForm}
        formErrors={updateDealCaseFormErrors}
        setFormErrors={setUpdateDealCaseFormErrors}
        setForm={setUpdateDealCaseForm}
        onClose={() => setOpenEditFormModal(false)}
        onConfirm={handleUpdateDealCase}
        existingCases={dealCasesOptions}
        baseCase={{
          label: "Base Case",
          value: String(dealId),
        }}
      />
    </>
  );
}
