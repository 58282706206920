import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    log: {
      display: "flex",
      fontSize: "0.9rem",
      marginBlock: theme.spacing(2),
    },
    iconContainer: {
      marginRight: theme.spacing(1),
    },
    logDetail: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-start",
      "& > *:first-of-type": {
        width: "calc(360px - 128px) !important",
      },
      "& > *:nth-of-type(2)": {
        width: "3rem",
        marginLeft: "1rem",
      },
    },
    text: {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    highlight: {
      color: selectedTheme.color.secondary,
    },
  };
});
