import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  emptyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 276px)",
    textAlign: "center",
  },
  info: {
    display: "block",
    marginTop: 20,
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(1, 2),
    borderRadius: 20,
    color: "#666",
  },
  editContainer: {
    display: "flex",
    justifyContent: "end",
    marginBlock: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(2, 0),
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  sectionHeading: {
    margin: theme.spacing(0, 0, 1.2, 0.5),
    fontWeight: 600,
  },
}));
