import React from "react";
import { Chart as ChartJS, BarElement, BarController } from "chart.js";

import { useScreenWidth } from "../../../../../utils/hooks";
import { IDebtReportSummaryChart } from "../../../../../interfaces";
import { chartTooltipArrayOfNumbersToNumber } from "../../../../../utils/helpers";

ChartJS.register(BarElement, BarController);

interface IProps {
  debtChart?: IDebtReportSummaryChart;
}

export default function Chart({ debtChart }: IProps): JSX.Element {
  const debtChartRef = React.useRef<HTMLCanvasElement | null>(null);
  const width = useScreenWidth();

  React.useEffect(() => {
    const debtChart2DRef = debtChartRef?.current?.getContext("2d");

    const chartData = [
      {
        label: "Cash Distributions",
        backgroundColor: "#00B969",
        value: debtChart?.cash_distributions.data,
        percent: debtChart?.cash_distributions.percent,
      },
      {
        label: "Tax Credits",
        backgroundColor: "#5335FA",
        value: debtChart?.tax_credit.data,
        percent: debtChart?.tax_credit.percent,
      },
      {
        label: "Depreciation Benefits",
        backgroundColor: "#7E68FB",
        value: debtChart?.depreciation.data,
        percent: debtChart?.depreciation.percent,
      },
      {
        label: "Tax Detriments",
        backgroundColor: "#9481FC",
        value: debtChart?.taxable_income.data,
        percent: debtChart?.taxable_income.percent,
      },
      {
        label: "Total Value",
        backgroundColor: "#9F9F9F",
        value: debtChart?.tax_efficient_value.data,
        percent: debtChart?.tax_efficient_value.percent,
      },
    ];

    const debtChartObj =
      debtChart2DRef &&
      new ChartJS(debtChart2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: chartData.map((cD) => cD.label),
          datasets: [
            {
              data: chartData.map((cD) => cD.value),
              backgroundColor: chartData.map((cD) => cD.backgroundColor),
            },
          ],
        },
        options: {
          scales: {
            x: {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y: {
              title: {
                display: true,
                text: "Amount ($)",
              },
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  return (
                    chartTooltipArrayOfNumbersToNumber(ctx) +
                    " / " +
                    chartData[ctx.dataIndex].percent +
                    "%"
                  );
                },
              },
            },
          },
        },
      });

    return () => {
      debtChartObj?.destroy();
    };
  }, [width]);

  return <canvas id="debtChart" ref={debtChartRef} />;
}
