import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputSponsorEquityBOL_CashView from "../../../../views/deal/output/sponsor-equity-BOL/cash";
import { getDealReporting } from "../../../../apis/report/deal";

export default function DealOutputSponsorEquityBOL_CashPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputSponsorEquityBOL_CashView
          getDealReporting={getDealReporting}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
