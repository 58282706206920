import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  usersRoot: {
    marginTop: theme.spacing(2),
    "& > .cl-rootBox": {
      width: "100%",
    },
    "& .cl-card": {
      width: "100%",
      boxShadow: "none",
    },
  },
}));
