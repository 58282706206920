import React from "react";
import MuiIconButton, { IconButtonProps } from "@mui/material/IconButton";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../utils/hooks";
import { updateShowUsageLimitHitModalAction } from "../../utils/redux/slices";

interface IProps {
  canOpenUpgrade?: boolean;
}

export default function IconButton({
  canOpenUpgrade = false,
  onClick,
  ...props
}: IconButtonProps & IProps) {
  const dispatch = useDispatch();
  const { productUsageDetails } = useAppSelector((s) => s.org);

  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (canOpenUpgrade) {
      if (productUsageDetails?.strawman_plan === "TRIAL") {
        dispatch(updateShowUsageLimitHitModalAction(true));
        return;
      }
    }
    onClick && onClick(e);
  };

  return <MuiIconButton onClick={handleOnClick} {...props} />;
}
