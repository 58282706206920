import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    flexDirection: "column",
    marginTop: theme.spacing(8),
    "& h1": {
      marginBottom: 0,
      textAlign: "center",
    },
    "& p": {
      fontWeight: 600,
    },
  },
}));
