import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import { format } from "date-fns";
import { useTheme } from "@mui/material/styles";

import IconButton from "../icon-button";
import EditIcon from "../logos-and-icons/edit-icon";
import { cn } from "../../utils/helpers";
import { INote } from "../../interfaces";

export default function UserNote({
  note,
  onEdit,
  onDelete,
}: {
  note: INote;
  onEdit: (note: INote) => void;
  onDelete: (id: number) => void;
}): JSX.Element {
  const theme = useTheme();

  return (
    <Paper classes={{ root: cn("my-4") }} elevation={1}>
      <Card>
        <CardContent classes={{ root: cn("!p-3") }}>
          <div className="flex justify-between items-start mb-2">
            <Typography
              fontSize={12}
              variant="subtitle2"
              classes={{ root: cn("!font-semibold") }}
            >
              {note.author.first_name} {note.author.last_name}
            </Typography>
            <div className="flex space-x-2">
              <IconButton
                size="small"
                color="primary"
                onClick={() => onEdit(note)}
              >
                <EditIcon fillColor="#5F51F4" />
              </IconButton>
              <IconButton
                size="small"
                color="primary"
                onClick={() => onDelete(note.id)}
              >
                <DeleteIcon htmlColor="#5F51F4" />
              </IconButton>
            </div>
          </div>
          <div className="mt-2">
            <Typography
              fontSize={14}
              variant="body1"
              classes={{ root: cn("mb-2") }}
            >
              {note.note}
            </Typography>
          </div>

          <div
            className={cn("col-span-2 my-2 border-b")}
            style={{ borderColor: theme.palette.divider }}
          ></div>
          <div className={cn("text-right")}>
            <Typography
              fontSize={9}
              variant="caption"
              sx={{
                color: theme.palette.text.secondary,
              }}
            >
              Last modified by {note.last_modified_by.first_name}{" "}
              {note.last_modified_by.last_name} on{" "}
              {format(new Date(note.modified), "MMM d, yyyy HH:mm")}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
}
