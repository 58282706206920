import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputTaxEquityOutsideBasisView from "../../../../views/deal/output/tax-equity/outside-basis";
import { getDealReporting } from "../../../../apis/report/deal";

export default function DealOutputTaxEquityOutsideBasis(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputTaxEquityOutsideBasisView
          getDealReporting={getDealReporting}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
