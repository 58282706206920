import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import { IDealTaxEquity, IDealTaxEquityForm } from "../../interfaces";

export const getDealTaxEquity = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.taxEquity.get, { dealId });
  return await AxiosHelper.get<IDealTaxEquity[]>(url);
};

export const addDealTaxEquity = async (
  dealId: number,
  form: IDealTaxEquityForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.taxEquity.create, { dealId });
  return await AxiosHelper.post<IDealTaxEquityForm, IDealTaxEquity>(url, form);
};

export const updateDealTaxEquity = async (
  dealId: number,
  taxEquityId: number,
  form: IDealTaxEquityForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.taxEquity.update, {
    dealId,
    taxEquityId,
  });
  return await AxiosHelper.patch<IDealTaxEquityForm, IDealTaxEquity>(url, form);
};
