import DealPagesLayout from "../../../components/deal-pages-layout";
import DealComparisonListView from "../../../views/deal/analysis";
import { getDealCasesLite } from "../../../apis/deal/case";
import {
  deleteDealComparison,
  getDealComparison,
} from "../../../apis/deal-comparison/base";
import {
  getDealCaseComparisons,
  addDealCaseComparison,
  updateDealCaseComparison,
} from "../../../apis/deal/analysis";

export default function Analysis(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealComparisonListView
        addDealComparison={addDealCaseComparison}
        getDealComparisons={getDealCaseComparisons}
        deleteDealComparison={deleteDealComparison}
        getDealComparison={getDealComparison}
        updateDealCaseComparison={updateDealCaseComparison}
        getDealCasesLite={getDealCasesLite}
      />
    </DealPagesLayout>
  );
}
