import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IProjectDetails } from "../../../interfaces";

export interface IProjectSliceState {
  currentProject: IProjectDetails | undefined;
  currentProjectStatus: { loading: boolean; errored: boolean };
  valuationExist: boolean;
}

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    currentProject: undefined,
    currentProjectStatus: { loading: false, errored: false },
    status: "ACT",
    valuationExist: false,
  },
  reducers: {
    setCurrentProject(
      state: IProjectSliceState,
      action: PayloadAction<IProjectSliceState["currentProject"]>,
    ) {
      state.currentProject = action.payload;
    },
    setCurrentProjectStatus(
      state: IProjectSliceState,
      action: PayloadAction<IProjectSliceState["currentProjectStatus"]>,
    ) {
      state.currentProjectStatus = action.payload;
    },
    setValuationExist(
      state: IProjectSliceState,
      action: PayloadAction<IProjectSliceState["valuationExist"]>,
    ) {
      state.valuationExist = action.payload;
    },
  },
});
