import ProjectPagesLayout from "../../../../components/project-pages-layout";
import TabbedLayout from "../../../../components/tabbed-layout";
import ProjectCostView from "../../../../views/project/cost/dashboard";
import {
  getProjectCostsSchedule,
  getProjectCostsSummary,
  updateProjectCosts,
} from "../../../../apis/project/cost";

export default function Cost(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Cost">
        <ProjectCostView
          getProjectCostSummary={getProjectCostsSummary}
          updateProjectCosts={updateProjectCosts}
          getProjectCostsSchedule={getProjectCostsSchedule}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
