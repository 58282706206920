import { IProjectOtherFlowForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum PROJECT_OTHER_STREAM_TYPE {
  CN = "Contracted",
  UNC = "Uncontracted",
  SPC = "Special",
}

export const PROJECT_OTHER_STREAM_TYPE_OPTIONS = enumToOptions(
  PROJECT_OTHER_STREAM_TYPE,
);

export enum PROJECT_OTHER_STREAM_INPUT_TYPE {
  CASH = "Cash",
  TAX = "Tax",
  CA_TX = "Cash & Tax",
}

export const PROJECT_OTHER_STREAM_INPUT_TYPE_OPTIONS = enumToOptions(
  PROJECT_OTHER_STREAM_INPUT_TYPE,
);

export const PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE: IProjectOtherFlowForm = {
  stream_strip: [],
  name: "",
  type: "",
  input_type: "",
};
