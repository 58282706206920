import DealPagesLayout from "../../../components/deal-pages-layout";
import TabbedLayout from "../../../components/tabbed-layout";
import SaleLeasebackView from "../../../views/deal/sizing/sale-leaseback";
import {
  editDealSaleLeaseback,
  getDealSaleLeaseback,
  getDealSaleLeasebackDetails,
} from "../../../apis/deal/sale-leaseback";

export default function SaleLeasebackPage() {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <SaleLeasebackView
          getDealSaleLeaseback={getDealSaleLeaseback}
          getDealSaleLeasebackDetails={getDealSaleLeasebackDetails}
          editDealSaleLeaseback={editDealSaleLeaseback}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
