import Layout from "../../components/layout";
import WorkflowNDAView from "../../views/workflow/n-d-a";

export default function WorkflowNDAPage(): JSX.Element {
  return (
    <Layout title="Workflow">
      <WorkflowNDAView />
    </Layout>
  );
}
