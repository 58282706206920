import DealPagesLayout from "../../../components/deal-pages-layout";
import DealCostView from "../../../views/deal/components/cost";
import TabbedLayout from "../../../components/tabbed-layout";
import {
  getCreditSupportSummary,
  getDealCost,
  getDealCostsSchedule,
} from "../../../apis/deal/cost";

export default function DealCost(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Components">
        <DealCostView
          getDealCost={getDealCost}
          getCreditSupportSummary={getCreditSupportSummary}
          getDealCostsSchedule={getDealCostsSchedule}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
