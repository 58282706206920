import React from "react";
import Paper from "@mui/material/Paper";

import { cn } from "../../utils/helpers";

const DashboardCard = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & { dataPw?: string }
>(({ className, color, dataPw, ...props }, ref) => {
  const cardShadowColor = React.useMemo(() => {
    let shadowColor = "";
    switch (color) {
      case "green":
        shadowColor = "hover:shadow-[#33CC8C]";
        break;
      case "purple":
        shadowColor = "hover:shadow-[#7E66FF]";
        break;
      case "blue":
        shadowColor = "hover:shadow-[#3333FF]";
        break;
      case "orange":
        shadowColor = "hover:shadow-[#FFBB55]";
        break;
      default:
        shadowColor = "hover:shadow-[#aaaaaa]";
    }

    return shadowColor;
  }, [color]);

  return (
    <Paper
      ref={ref}
      className={cn(
        `rounded-xl cursor-pointer hover:shadow ${cardShadowColor} transition-all`,
        className,
      )}
      data-pw={dataPw}
      {...props}
    />
  );
});
DashboardCard.displayName = "DashboardCard";

const DashboardCardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-sm p-2 flex justify-between items-center", className)}
    {...props}
  />
));
DashboardCardHeader.displayName = "DashboardCardHeader";

const DashboardCardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p
    ref={ref}
    className={cn("text-sm font-semibold pl-2 pb-2", className)}
    {...props}
  />
));
DashboardCardDescription.displayName = "DashboardCardDescription";

export { DashboardCard, DashboardCardHeader, DashboardCardDescription };
