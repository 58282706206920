import Layout from "../../components/layout";
import WorkflowMarketingMaterialView from "../../views/workflow/marketing-material";

export default function WorkflowMarketingMaterialPage(): JSX.Element {
  return (
    <Layout title="Workflow">
      <WorkflowMarketingMaterialView />
    </Layout>
  );
}
