import Layout from "../../components/layout";
import DealComparisonListView from "../../views/analysis/deal-comparison-list";
import { getDeals } from "../../apis/deal/base";
import {
  addDealComparison,
  deleteDealComparison,
  getDealComparison,
  getDealComparisons,
  updateDealComparison,
} from "../../apis/deal-comparison/base";

export default function DealComparisonList(): JSX.Element {
  return (
    <Layout title="Deal Comparison">
      <DealComparisonListView
        addDealComparison={addDealComparison}
        getDeals={getDeals}
        getDealComparisons={getDealComparisons}
        updateDealComparison={updateDealComparison}
        deleteDealComparison={deleteDealComparison}
        getDealComparison={getDealComparison}
      />
    </Layout>
  );
}
