import DealPagesLayout from "../../../components/deal-pages-layout";
import DealCaseView from "../../../views/deal/cases";
import {
  addDealCase,
  getDealCases,
  duplicateDealCase,
  deleteDealCase,
  updateDealCase,
} from "../../../apis/deal/case";

export default function Cases(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealCaseView
        getDealCases={getDealCases}
        addCase={addDealCase}
        duplicateDealCase={duplicateDealCase}
        deleteDealCase={deleteDealCase}
        updateDealCase={updateDealCase}
      />
    </DealPagesLayout>
  );
}
