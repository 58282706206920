import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  container: {
    width: "50%",
    minWidth: 450,
    [theme.breakpoints.down(600)]: {
      minWidth: "100%",
    },
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    "& > button:nth-of-type(2)": {
      marginLeft: theme.spacing(2),
    },
  },
  flexDisplay: {
    display: "flex",
    alignItems: "center",
    "& > div:first-of-type": {
      minWidth: "calc(100% - 3rem)",
      marginRight: theme.spacing(1),
    },
    "& > button:nth-of-type(1)": {
      marginBottom: theme.spacing(2),
    },
  },
  shortWidthField: {
    width: "70%",
  },
  regularWidthField: {
    width: "100%",
  },
}));
