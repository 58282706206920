import { IIconProps } from "../../interfaces";

export default function EditIcon({ fillColor }: IIconProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7188 0.6875L15.3125 1.28125C16 1.96875 16 3.0625 15.3125 3.75L14.375 4.6875L11.3125 1.625L12.25 0.6875C12.9375 0 14.0312 0 14.7188 0.6875ZM5.375 7.5625L10.5938 2.34375L13.6562 5.40625L8.4375 10.625C8.25 10.8125 8 10.9688 7.75 11.0625L4.96875 11.9688C4.71875 12.0625 4.40625 12 4.21875 11.7812C4 11.5938 3.9375 11.2812 4.03125 11.0312L4.9375 8.25C5.03125 8 5.1875 7.75 5.375 7.5625ZM3 2H6C6.53125 2 7 2.46875 7 3C7 3.5625 6.53125 4 6 4H3C2.4375 4 2 4.46875 2 5V13C2 13.5625 2.4375 14 3 14H11C11.5312 14 12 13.5625 12 13V10C12 9.46875 12.4375 9 13 9C13.5312 9 14 9.46875 14 10V13C14 14.6562 12.6562 16 11 16H3C1.34375 16 0 14.6562 0 13V5C0 3.34375 1.34375 2 3 2Z"
        fill={fillColor ?? "black"}
      />
    </svg>
  );
}
