import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputTaxEquityView from "../../../../views/deal/output/tax-equity";

export default function DealOutputTaxEquityPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputTaxEquityView />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
