import React from "react";
import { dark } from "@clerk/themes";
import { ClerkProvider } from "@clerk/clerk-react";

import ThemeContext from "../../utils/context/theme-context";
import { externalDarkTheme } from "../../utils/theme";

interface IProps {
  children: React.ReactNode;
}

export default function MyClerkProvider({ children }: IProps) {
  const { darkMode } = React.useContext(ThemeContext);

  return (
    <ClerkProvider
      appearance={{
        baseTheme: darkMode ? dark : undefined,
        variables: {
          colorAlphaShade: darkMode ? externalDarkTheme.color.text : undefined,
          colorBackground: darkMode
            ? externalDarkTheme.color.aboveSurface
            : undefined,
        },
        elements: { footerAction: { display: "none" } },
      }}
      publishableKey={String(process.env.REACT_APP_CLERK_PUBLISHABLE_KEY)}
    >
      {children}
    </ClerkProvider>
  );
}
