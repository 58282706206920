import { makeStyles } from "tss-react/mui";

export default makeStyles<{ columnToHide: number }>()(
  (theme, { columnToHide }) => ({
    tableWithHideableColumn: {
      '&[data-hide-total="true"]': {
        [`& tbody td:nth-of-type(${columnToHide})`]: {
          display: "none",
        },
        [`& thead th:nth-of-type(${columnToHide})`]: {
          display: "none",
        },
      },
    },
  }),
);
