import React from "react";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import useStyles from "./styles";

interface IProps {
  searchString: string;
  onTextChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function Searchbar({
  searchString,
  onTextChange,
  ...inputBaseProps
}: IProps & InputBaseProps): JSX.Element {
  const styles = useStyles();
  return (
    <InputBase
      {...inputBaseProps}
      value={searchString}
      onChange={onTextChange}
      placeholder="Search…"
      className={styles.classes.input}
      startAdornment={<SearchIcon />}
    />
  );
}
