import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  header: {
    top: 105,
    display: "flex",
    justifyContent: "end",
    "& > *": {
      display: "flex",
      gap: theme.spacing(2),
    },
  },
  content: {
    display: "flex",
    gap: theme.spacing(2),
    width: "100%",
    "& > :nth-of-type(2)": {
      width: "50%",
    },
    [theme.breakpoints.down(650)]: {
      flexDirection: "column",
      "& > :nth-of-type(2)": {
        width: "100%",
      },
    },
  },
  chartContainer: {
    margin: theme.spacing(2, 0),
  },
  showMoreDatesBtn: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    borderRadius: 8,
  },
  tables: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    width: "50%",
    [theme.breakpoints.down(650)]: {
      width: "100%",
    },
  },
}));
