import { IUpdateDealDepreciationElectionForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_DEPRECIATION_ELECTION {
  REG = "Regular",
  BON = "Bonus",
  ADS = "12yr SL ADS",
}

export const DEAL_DEPRECIATION_ELECTION_OPTIONS = enumToOptions(
  DEAL_DEPRECIATION_ELECTION,
);

export const UPDATE_DEAL_DEPRECIATION_ELECTION_FORM_DEFAULT_STATE: IUpdateDealDepreciationElectionForm =
  {
    year: "",
    macrs_election: "",
  };
