import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    section: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1, 2),
    },
    planItems: {
      marginTop: theme.spacing(0.5),
      display: "flex",
      gap: theme.spacing(2),
      "& svg": {
        color: selectedTheme.color.secondary,
        marginRight: theme.spacing(1),
      },
      "& p": {
        fontSize: 14,
        display: "flex",
        color: "#A6A6A6",
      },
    },
    bllingDetails: {
      margin: theme.spacing(3, 0),
      display: "flex",
      justifyContent: "space-between",
      "& > div": {
        width: "30%",
      },
    },
    usageDetails: {
      margin: theme.spacing(2, 0),
      display: "flex",
      justifyContent: "space-between",
      "& > div": {
        width: "48%",
      },
    },
    plans: {
      marginTop: theme.spacing(2),
    },
  };
});
