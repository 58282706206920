import React from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import { PROJECT_DEPRECIATION_STREAM_TYPE_OPTIONS } from "../../constants";
import {
  IAddProjectDepreciationStreamForm,
  IAddProjectDepreciationStreamFormErrors,
  IProjectDepreciationStream,
  IUpdateProjectDepreciationStreamForm,
  IUpdateProjectDepreciationStreamFormErrors,
  SetStateAction,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?:
    | IAddProjectDepreciationStreamFormErrors
    | IUpdateProjectDepreciationStreamFormErrors;
  setFormErrors: SetStateAction<
    | IAddProjectDepreciationStreamFormErrors
    | IUpdateProjectDepreciationStreamFormErrors
    | undefined
  >;
  form:
    | IAddProjectDepreciationStreamForm
    | IUpdateProjectDepreciationStreamForm;
  setForm: SetStateAction<
    IAddProjectDepreciationStreamForm | IUpdateProjectDepreciationStreamForm
  >;
  onClose: () => void;
  onConfirm: (
    form:
      | IAddProjectDepreciationStreamForm
      | IUpdateProjectDepreciationStreamForm,
  ) => Promise<IProjectDepreciationStream | undefined>;
}

export default function ProjectDepreciationStreamFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const ITC_ELIGIBLE_STREAMS = ["5YMACRS", "15YMACRS", "12YSL", "20YSL"];

  const handleOnConfirm = async () => {
    const updatedForm = { ...form };
    const depreciationStream = await onConfirm(updatedForm);
    depreciationStream && handleOnClose();
  };

  const handleResetForm = () => {
    setForm({
      type: "",
      percentage: "",
      itc_eligibility: "",
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        <SelectInput
          required
          label="Type"
          selected={form.type}
          items={PROJECT_DEPRECIATION_STREAM_TYPE_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("type")}
          onChange={(e) => handleSelectInputChange(e, "type")}
          error={Boolean(formErrors?.type)}
          helperText={formErrors?.type}
          disabled={loading}
          fullWidth={false}
        />
        <TextInput
          required
          isNumeric
          label="Percentage of FMV"
          name="percentage"
          endAdornment={<>%</>}
          value={form.percentage}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.percentage)}
          helperText={formErrors?.percentage}
          disabled={loading}
          fullWidth={false}
        />
        {ITC_ELIGIBLE_STREAMS.includes(form.type) ? (
          <TextInput
            required
            isNumeric
            label="ITC Eligibility (when applicable)"
            name="itc_eligibility"
            endAdornment={<>%</>}
            value={form.itc_eligibility}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.itc_eligibility)}
            helperText={formErrors?.itc_eligibility}
            disabled={loading}
            tooltip="Percentage of depreciation that is ITC eligible (when applicable)"
            fullWidth={false}
          />
        ) : null}
      </Box>
    </Modal>
  );
}
