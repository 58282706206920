import { enumToOptions } from "../../utils/helpers";
import { IProjectValuationForm } from "../../interfaces";

export enum PROJECT_VALUATION_FMV_METHODS {
  AVG = "Average of Cost and Income",
  HGR = "Higher of Cost and Income",
  LGR = "Lower of Cost and Income",
}

export enum PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS {
  COST = "ITC Eligible Basis (Cost)",
  COSTPROF = "ITC Eligible Basis (Cost + Profit)",
  DCF = "ITC Eligible Basis (DCF)",
}

export const PROJECT_VALUATION_FORM_DEFAULT_STATE: IProjectValuationForm = {
  contracted_discount_rate: null,
  developer_profit: null,
  fmv_method: "",
  itc_adder_domestic_content: false,
  itc_adder_energy_community: false,
  itc_adder_environmental_justice: false,
  itc_adder_special_qualified_ej: false,
  itc_eligible_basis_method: "",
  merchant_discount_rate: null,
};

export const PROJECT_VALUATION_FMV_METHODS_OPTIONS = enumToOptions(
  PROJECT_VALUATION_FMV_METHODS,
);

export const PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS_OPTIONS = enumToOptions(
  PROJECT_VALUATION_ELIGIBLE_BASIS_METHODS,
);

export const PROJECT_VALUATION_DISCLAIMER_MESSAGE =
  "This valuation is for informational purposes only and is indicative in nature. Please consult a certified appraiser for support of appraised value and ITC eligible basis.";
