import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import SelectInput from "../select-input";
import CheckboxInput from "../checkbox-input";
import {
  SetStateAction,
  IAddDealCaseForm,
  IAddDealCaseFormErrors,
  IDealCase,
} from "../../interfaces";
import {
  DEAL_CASE_PROJECT_TAX_CREDIT_TYPE_OPTIONS,
  DEAL_CASE_TYPE_OPTIONS,
  DEAL_STRUCTURE_TYPE_OPTIONS,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS,
} from "../../constants";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IAddDealCaseForm;
  setForm: SetStateAction<IAddDealCaseForm>;
  formErrors?: IAddDealCaseFormErrors;
  setFormErrors: SetStateAction<IAddDealCaseFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (form: IAddDealCaseForm) => Promise<IDealCase | undefined>;
}

export default function AddCaseFormModal({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnAdd = async () => {
    const dealCase = await onConfirm(form);
    dealCase && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleResetForm = () => {
    setForm({
      name: "",
      type: "",
      description: "",
      is_synced_with_base_case: false,
      deal_structure: "",
      deal_tax_credit_structure: "",
      project_tax_credit_type: "",
    });
    setFormErrors({});
  };
  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnAdd}
      resetForm={handleResetForm}
    >
      <Box>
        <TextInput
          required
          label="Case Name"
          name="name"
          value={form.name}
          onFocus={clearErrorOnFocus}
          onChange={handleChange}
          error={Boolean(formErrors?.name)}
          helperText={formErrors?.name}
          disabled={loading}
        />
        <SelectInput
          required
          label="Type"
          selected={form.type}
          items={DEAL_CASE_TYPE_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("type")}
          onChange={(e) => handleSelectInputChange(e, "type")}
          error={Boolean(formErrors?.type)}
          helperText={formErrors?.type}
          disabled={loading}
        />
        <TextInput
          classes={{ root: styles.classes.input }}
          multiline
          rows={4}
          label="Description"
          name="description"
          value={form.description}
          onFocus={clearErrorOnFocus}
          onChange={handleChange}
          error={Boolean(formErrors?.description)}
          helperText={formErrors?.description}
          disabled={loading}
        />
        <CheckboxInput
          label="Keep any unaltered case data in sync with the deal and its associated projects."
          name="is_synced_with_base_case"
          checked={form.is_synced_with_base_case}
          onChange={handleCheckboxChange}
          disabled={loading}
        />
        <Divider classes={{ root: styles.classes.divider }} textAlign="left">
          Case Structure
        </Divider>
        <SelectInput
          label="Deal Structure"
          selected={form.deal_structure}
          items={DEAL_STRUCTURE_TYPE_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("structure")}
          onChange={(e) => handleSelectInputChange(e, "deal_structure")}
          error={Boolean(formErrors?.deal_structure)}
          helperText={formErrors?.deal_structure}
          disabled={loading}
        />
        <SelectInput
          label="Deal Tax Credit Structure"
          selected={form.deal_tax_credit_structure}
          items={DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("tax_credit_structure")}
          onChange={(e) =>
            handleSelectInputChange(e, "deal_tax_credit_structure")
          }
          error={Boolean(formErrors?.deal_tax_credit_structure)}
          helperText={formErrors?.deal_tax_credit_structure}
          disabled={loading}
        />
        <SelectInput
          label="Tax Credit Type for Project"
          selected={form.project_tax_credit_type}
          items={DEAL_CASE_PROJECT_TAX_CREDIT_TYPE_OPTIONS}
          onFocus={() => clearNonTextFieldErrorOnFocus("credit_type")}
          onChange={(e) =>
            handleSelectInputChange(e, "project_tax_credit_type")
          }
          error={Boolean(formErrors?.project_tax_credit_type)}
          helperText={formErrors?.project_tax_credit_type}
          disabled={loading}
        />
      </Box>
    </Modal>
  );
}
