import { IIconProps } from "../../interfaces";

export default function BulletIcon({ fillColor }: IIconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M24 30c-3.312 0-6-2.688-6-6s2.688-6 6-6 6 2.688 6 6-2.688 6-6 6z"
        fill={fillColor ?? "black"}
      ></path>
    </svg>
  );
}
