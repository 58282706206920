import Box from "@mui/material/Box";
import { OrganizationProfile } from "@clerk/clerk-react";

import useStyles from "./styles";
import ViewWrapper from "../../../../components/view-wrapper";

export default function ConfigurationOrganizationUsersView(): JSX.Element {
  const styles = useStyles();

  return (
    <ViewWrapper error={false} loading={false}>
      <Box className={styles.classes.usersRoot}>
        <OrganizationProfile />
      </Box>
    </ViewWrapper>
  );
}
