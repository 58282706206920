import { AxiosHelper } from "../../utils/helpers";
import { REPORT_API_ROUTES } from "../../constants";
import { IPipelineReportByYear, ProjectStageType } from "../../interfaces";

export const getPipelineReportByYear = async (
  projectStage: ProjectStageType | "",
) => {
  return await AxiosHelper.get<IPipelineReportByYear>(
    REPORT_API_ROUTES.pipeline.getByYear,
    {
      params: projectStage
        ? {
            project_stage: projectStage,
          }
        : undefined,
    },
  );
};
