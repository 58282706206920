import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputPartnershipCashView from "../../../../views/deal/output/partnership/cash";
import { getDealReporting } from "../../../../apis/report/deal";

export default function DealOutputPartnershipCash(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputPartnershipCashView getDealReporting={getDealReporting} />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
