import React from "react";
import { Chart as ChartJS, BarElement, BarController } from "chart.js";

import { useScreenWidth } from "../../../../../utils/hooks";
import { ITaxEquityCashChart } from "../../../../../interfaces";

ChartJS.register(BarElement, BarController);

interface IProps {
  cashReportChart: ITaxEquityCashChart;
  stack: boolean;
  isPercentage: boolean;
  label: string;
}

export default function CashChart({
  cashReportChart,
  stack,
  isPercentage,
  label,
}: IProps): JSX.Element {
  const width = useScreenWidth();
  const cashReportChartRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    const cashReportChart2DRef = cashReportChartRef?.current?.getContext("2d");

    const cashReportChartObj =
      cashReportChart2DRef &&
      new ChartJS(cashReportChart2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: Object.keys(cashReportChart.cash),
          datasets: [
            {
              label: `Cash Distributions`,
              data: Object.values(cashReportChart.cash),
              backgroundColor: "#00B969",
            },
            {
              label: "Transfer Cash",
              data: Object.values(cashReportChart.transfer_cash),
              backgroundColor: "#4F5EA1",
            },
            {
              label: `Cash Taxes (without Credits)`,
              data: Object.values(cashReportChart.taxes),
              backgroundColor: "#9481FC",
            },
            {
              label: `Tax Credits`,
              data: Object.values(cashReportChart.tax_credits),
              backgroundColor: "#5335FA",
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
              position: "bottom",
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  let label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    if (isPercentage) {
                      // div by 100 since backend already sends in percentage, this assumes fraction
                      label += new Intl.NumberFormat("en-US", {
                        style: "percent",
                        minimumFractionDigits: 2,
                      }).format(context.parsed.y / 100);
                    } else {
                      label += new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                      }).format(context.parsed.y);
                    }
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              stacked: stack,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y: {
              stacked: stack,
              title: {
                display: true,
                text: isPercentage ? "Share (%)" : "Amount ($)",
              },
            },
          },
        },
      });

    return () => {
      cashReportChartObj?.destroy();
    };
  }, [width]);

  return <canvas id="cashReportChart" ref={cashReportChartRef} />;
}
