import Layout from "../../components/layout";
import WorkflowTimelineView from "../../views/workflow/timeline";

export default function WorkflowTimelinePage(): JSX.Element {
  return (
    <Layout title="Workflow">
      <WorkflowTimelineView />
    </Layout>
  );
}
