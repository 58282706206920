import {
  PROJECT_API_ROUTES,
  PROJECT_COST_REPORT_TYPE,
  REPORT_API_ROUTES,
} from "../../constants";
import { IProjectsStageReport } from "../../interfaces";
import { AxiosHelper, replaceParams } from "../../utils/helpers";

export const getProjectsStageReport = async () => {
  return await AxiosHelper.get<IProjectsStageReport>(
    REPORT_API_ROUTES.project.getByStage,
  );
};

export const downloadProjectCostsReport = async (
  projectId: number,
  type: keyof typeof PROJECT_COST_REPORT_TYPE,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.cost.summary.download, {
    projectId,
    type,
  });
  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    const contentBlob = new Blob([response], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.setAttribute("href", window.URL.createObjectURL(contentBlob));
    link.setAttribute(
      "download",
      `project-cost-report-download-${projectId}.xlsx`,
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });
};
