import React from "react";
import { differenceInYears, format } from "date-fns";
import { Chart as ChartJS, TimeScale } from "chart.js";
import "chartjs-adapter-date-fns";

ChartJS.register(TimeScale);

export interface ITimingChartProps {
  startDate: Date;
  endDate: Date;
  data: Array<{
    date: Date;
    color: string;
    symbol: string;
    tooltip: string;
    rotation?: number;
    id: string;
  }>;
}

export default function TimingChart({
  startDate,
  endDate,
  data,
}: ITimingChartProps): JSX.Element {
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);

  const verticalLineImage = () => {
    const img = new Image();
    img.src = "/vertical line.png";
    img.height = 155;
    return img;
  };

  const minDate = React.useMemo(() => {
    const allDates = data.map((d) => new Date(d.date).getTime());
    allDates.push(new Date(startDate).getTime());
    allDates.push(new Date().getTime());
    return new Date(Math.min(...allDates));
  }, [data, startDate]);

  const maxDate = React.useMemo(() => {
    const allDates = data.map((d) => new Date(d.date).getTime());
    allDates.push(new Date().getTime());
    return new Date(Math.max(...allDates));
  }, [data]);

  const graphUnit = React.useMemo(() => {
    return differenceInYears(maxDate, minDate) > 2 ? "year" : "month";
  }, [minDate, maxDate]);

  React.useEffect(() => {
    const chart =
      chartRef &&
      verticalLineImage &&
      new ChartJS(chartRef.current as HTMLCanvasElement, {
        type: "line",
        data: {
          datasets: [
            {
              label: "Today",
              data: [{ x: format(new Date(), "M/d/yyyy"), y: 0 }],
              pointBackgroundColor: "#4739F3",
              pointBorderColor: "#4739F3",
              pointStyle: verticalLineImage(),
            },
            ...data.map((d) => ({
              label: d.tooltip,
              data: [{ x: format(d.date, "M/d/yyyy"), y: 0 }],
              pointRadius: 8,
              pointBackgroundColor: d.color,
              pointBorderColor: d.color,
              pointStyle: d.symbol,
              pointRotation: d.rotation || 0,
            })),
            {
              label: "Timeline",
              data: [
                { x: format(minDate, "M/d/yyyy"), y: 0 },
                { x: format(maxDate, "M/d/yyyy"), y: 0 },
              ],
              borderColor: "#D9D9D9",
              borderWidth: 10,
              pointRadius: 0,
              pointHitRadius: 0,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.2,
          plugins: {
            tooltip: {
              callbacks: {
                title: () => "",
                label: (ctx) => {
                  return `${ctx.dataset.label}: ${format(
                    new Date(ctx.parsed.x),
                    "M/d/yyyy",
                  )}`;
                },
              },
            },
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true,
                boxWidth: 8,
                boxHeight: 8,
                filter: (i) => {
                  return i.text !== "Timeline";
                },
              },
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              type: "time",
              time: {
                unit: graphUnit,
                parser: "M/d/yyyy",
                displayFormats: {
                  year: "yyyy",
                },
              },
            },
          },
        },
      });

    return () => {
      chart.destroy();
    };
  }, [data, startDate, verticalLineImage, minDate, maxDate]);

  return (
    <div>
      <canvas id="timing-chart" ref={chartRef} />
    </div>
  );
}
