import React from "react";
import TableCell from "@mui/material/TableCell";
import TableRow, { TableRowProps } from "@mui/material/TableRow";

import useStyles from "./styles";
import ThemeContext from "../../utils/context/theme-context";
import { cn, numberWithCommas } from "../../utils/helpers";
import { PROFORMA_TABLE_CONFIG } from "../../constants";
import { externalDarkTheme, externalLightTheme } from "../../utils/theme";
import {
  ITableColumn,
  IProjectProformaRow,
  ProformaRowNumberRecord,
} from "../../interfaces";

interface IProps extends TableRowProps {
  boldRow?: boolean;
  data: IProjectProformaRow;
  requiredColumns: string[];
  headermode?: boolean;
  sticky?: boolean;
  headerHeight?: number;
}

const getProformaTableDetailCells = (
  value_dict: Record<string, ProformaRowNumberRecord>,
  column_config: ITableColumn,
  requiredColumns: string[],
  headermode?: boolean,
): JSX.Element | null => {
  const keys = Object.keys(value_dict);
  keys.sort();
  const sorted_value_dict: Record<string, ProformaRowNumberRecord> = {};
  keys.forEach((key) => {
    sorted_value_dict[key] = value_dict[key];
  });
  return (
    <>
      {Object.entries(sorted_value_dict).map(
        ([_, record]: [string, ProformaRowNumberRecord]) => {
          return getProformaTableCell(
            record.value,
            column_config,
            requiredColumns,
            headermode,
            record.suffix,
            record.display,
          );
        },
      )}
    </>
  );
};

const getProformaTableCell = (
  value: number | string,
  column_config: ITableColumn,
  requiredColumns: string[],
  headermode?: boolean,
  suffix?: string,
  display = true,
  sticky?: boolean,
  darkMode?: boolean,
): JSX.Element | null => {
  if (requiredColumns.includes(column_config.id) && display) {
    const bgColor = darkMode
      ? externalDarkTheme.color.surface
      : externalLightTheme.color.surface;

    const stickyStyle = cn(`!sticky left-0 ${headermode && "!z-10"}`);

    return (
      <TableCell
        style={{ backgroundColor: sticky ? bgColor : "" }}
        classes={{ root: sticky ? stickyStyle : "" }}
        align={column_config.align}
      >
        {headermode
          ? [column_config.label, suffix ? ` (${suffix})` : ""]
          : typeof value === "number"
            ? numberWithCommas.format(value)
            : value}
      </TableCell>
    );
  }
  return null;
};

const ProformaTableRow = React.forwardRef<
  HTMLTableRowElement,
  React.HTMLAttributes<HTMLTableRowElement> & IProps
>(
  (
    {
      boldRow,
      data,
      requiredColumns,
      headermode,
      sticky,
      headerHeight,
      ...tableRowProps
    },
    ref,
  ) => {
    const styles = useStyles({ headerHeight });
    const { darkMode } = React.useContext(ThemeContext);
    let classNames = boldRow ? styles.classes.boldRow : "";

    classNames = sticky
      ? [classNames, styles.classes.stickyRow].join(" ")
      : classNames;

    return (
      <TableRow
        {...tableRowProps}
        ref={ref}
        tabIndex={-1}
        className={classNames}
      >
        {getProformaTableCell(
          data.date_formatted,
          PROFORMA_TABLE_CONFIG.date_formatted,
          requiredColumns,
          headermode,
          undefined,
          true,
          true,
          darkMode,
        )}
        {getProformaTableCell(
          data.gross_production,
          PROFORMA_TABLE_CONFIG.gross_production,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.production_losses,
          PROFORMA_TABLE_CONFIG.production_losses,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.net_production,
          PROFORMA_TABLE_CONFIG.net_production,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.gross_capacity,
          PROFORMA_TABLE_CONFIG.gross_capacity,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.net_capacity,
          PROFORMA_TABLE_CONFIG.net_capacity,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.contracted_revenue_type_ppa,
          PROFORMA_TABLE_CONFIG.contracted_revenue_type_ppa,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.contracted_revenue_type_vppa,
          PROFORMA_TABLE_CONFIG.contracted_revenue_type_vppa,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.contracted_revenue_type_hedge,
          PROFORMA_TABLE_CONFIG.contracted_revenue_type_hedge,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.contracted_revenue_type_rec,
          PROFORMA_TABLE_CONFIG.contracted_revenue_type_rec,
          requiredColumns,
          headermode,
        )}

        {getProformaTableDetailCells(
          data.contracted_revenue_type_capacity,
          PROFORMA_TABLE_CONFIG.contracted_revenue_type_capacity,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.contracted_revenue_type_other,
          PROFORMA_TABLE_CONFIG.contracted_revenue_type_other,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.contracted_revenue_total,
          PROFORMA_TABLE_CONFIG.contracted_revenue_total,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.merchant_revenue_type_energy,
          PROFORMA_TABLE_CONFIG.merchant_revenue_type_energy,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.merchant_revenue_type_rec,
          PROFORMA_TABLE_CONFIG.merchant_revenue_type_rec,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.merchant_revenue_type_capacity,
          PROFORMA_TABLE_CONFIG.merchant_revenue_type_capacity,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.merchant_revenue_type_other,
          PROFORMA_TABLE_CONFIG.merchant_revenue_type_other,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.merchant_revenue_total,
          PROFORMA_TABLE_CONFIG.merchant_revenue_total,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.revenue_total,
          PROFORMA_TABLE_CONFIG.revenue_total,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_oandm,
          PROFORMA_TABLE_CONFIG.expense_type_oandm,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_oandm_un,
          PROFORMA_TABLE_CONFIG.expense_type_oandm_un,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_ins,
          PROFORMA_TABLE_CONFIG.expense_type_ins,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_ins_un,
          PROFORMA_TABLE_CONFIG.expense_type_ins_un,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_assmgmt,
          PROFORMA_TABLE_CONFIG.expense_type_assmgmt,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_admin,
          PROFORMA_TABLE_CONFIG.expense_type_admin,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_land,
          PROFORMA_TABLE_CONFIG.expense_type_land,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_proptx,
          PROFORMA_TABLE_CONFIG.expense_type_proptx,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_majmain,
          PROFORMA_TABLE_CONFIG.expense_type_majmain,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_loc,
          PROFORMA_TABLE_CONFIG.expense_type_loc,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_taxes,
          PROFORMA_TABLE_CONFIG.expense_type_taxes,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_cont,
          PROFORMA_TABLE_CONFIG.expense_type_cont,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_other,
          PROFORMA_TABLE_CONFIG.expense_type_other,
          requiredColumns,
          headermode,
        )}
        {getProformaTableDetailCells(
          data.expense_type_other_no,
          PROFORMA_TABLE_CONFIG.expense_type_other_no,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.expenses,
          PROFORMA_TABLE_CONFIG.expenses,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.ebitda,
          PROFORMA_TABLE_CONFIG.ebitda,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.other_flow_cash_total,
          PROFORMA_TABLE_CONFIG.other_flow_cash_total,
          requiredColumns,
          headermode,
        )}
        {getProformaTableCell(
          data.other_flow_tax_total,
          PROFORMA_TABLE_CONFIG.other_flow_tax_total,
          requiredColumns,
          headermode,
        )}
      </TableRow>
    );
  },
);

export default ProformaTableRow;
