import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { FormHelperTextProps } from "@mui/material/FormHelperText";

import useStyles from "./styles";
import Tooltip from "../tooltip";
import { ISelectOption } from "../../interfaces";

interface IProps {
  label: string;
  name: string;
  options: { [key: string]: ISelectOption[] } | ISelectOption[];
  value: string[];
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption[],
    name: string,
  ) => void;
  firstItem?: { label: string; value: string; onClick: () => void };
  onFocus?: () => void;
  helperText?: string;
  disabled?: boolean;
  dataPw?: string;
  tooltip?: string;
  className?: string;
  fullWidth?: boolean;
  limitTags?: number;
  size?: "small" | "medium";
}

interface IFormHelperTextProps extends Partial<FormHelperTextProps> {
  "data-pw"?: string;
}

export default function MultiAutocompleteField({
  label,
  name,
  options,
  value,
  onChange,
  firstItem,
  onFocus,
  helperText,
  disabled,
  dataPw,
  tooltip,
  className,
  fullWidth = true,
  limitTags,
  size,
}: IProps): JSX.Element {
  const styles = useStyles({ fullWidth: fullWidth });

  const handleChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption[],
  ) => {
    if (value?.find((v) => v?.label === firstItem?.label)) {
      firstItem?.onClick();
    } else {
      onChange(event, value, name);
    }
  };
  const flattenOptions = Array.isArray(options)
    ? firstItem
      ? [firstItem as ISelectOption, ...options]
      : options
    : Object.values(options)?.reduce((acc, group) => [...acc, ...group], []);

  const trimmedOptions = flattenOptions.slice().map((o) => {
    if (o.label.length > 45) {
      o.label = o.label.substring(0, 45) + "...";
    }
    return o;
  });

  return (
    <Box className={styles.classes.container}>
      <Autocomplete
        multiple
        size={size}
        options={trimmedOptions}
        onChange={handleChange}
        getOptionLabel={(option) => option?.label}
        data-pw={dataPw || label}
        className={className}
        PopperComponent={(props) => (
          <Popper {...props} placement={value.length > 10 ? "top" : "bottom"} />
        )}
        value={value.map(
          (val) =>
            flattenOptions.find(
              (option) => option?.value === val,
            ) as ISelectOption,
        )}
        disabled={disabled}
        classes={{
          option: styles.classes.option,
          root: styles.classes.autoCompleteRoot,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            onFocus={onFocus}
            helperText={helperText}
            error={Boolean(helperText)}
            FormHelperTextProps={
              {
                "data-pw": `${dataPw || label}-helper-text`,
              } as IFormHelperTextProps
            }
            inputProps={{
              ...params.inputProps,
              onKeyDown: (e) => {
                e.stopPropagation();
              },
            }}
          />
        )}
        groupBy={(option) => {
          if (Array.isArray(options)) {
            return "";
          }
          for (const group in options) {
            if (options[group]?.some((item) => item?.value === option?.value)) {
              return group;
            }
          }
          return "";
        }}
        getOptionDisabled={(option) => Boolean(option?.disabled)}
        limitTags={limitTags}
      />

      {tooltip && (
        <Tooltip title={tooltip} placement="top-end">
          <InfoIcon classes={{ root: styles.classes.infoIcon }} />
        </Tooltip>
      )}
    </Box>
  );
}
