import React from "react";
import Box from "@mui/material/Box";
import { useSearchParams } from "react-router-dom";

import MultiAutocompleteField from "../multi-autocomplete-field";
import { cn } from "../../utils/helpers";
import { IDealFilters, ISelectOption } from "../../interfaces";
import {
  DEAL_STAGES,
  DEAL_STAGES_OPTIONS,
  DEAL_STRUCTURE_TYPE,
  DEAL_STRUCTURE_TYPE_OPTIONS,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS,
} from "../../constants";

export default function DealFilters(): JSX.Element {
  const [filterParams, setFilterParams] = useSearchParams();
  const [filters, setFilters] = React.useState<IDealFilters>({
    stage: [],
    structure: [],
    tax_credit_structure: [],
  });

  React.useEffect(() => {
    const stage = filterParams.get("stage");
    const structure = filterParams.get("structure");
    const tax_credit_structure = filterParams.get("tax_credit_structure");

    if (stage || structure || tax_credit_structure) {
      setFilters((prev) => {
        const newFilters = { ...prev };
        if (stage) {
          newFilters.stage = stage.split(",") as Array<
            keyof typeof DEAL_STAGES
          >;
        }
        if (structure) {
          newFilters.structure = structure.split(",") as Array<
            keyof typeof DEAL_STRUCTURE_TYPE
          >;
        }
        if (tax_credit_structure) {
          newFilters.tax_credit_structure = tax_credit_structure.split(
            ",",
          ) as Array<keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE>;
        }
        return newFilters;
      });
    } else {
      setFilters({ stage: [], structure: [], tax_credit_structure: [] });
    }
  }, [filterParams]);

  const handleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | ISelectOption[] | null,
    name: string,
  ) => {
    if (Array.isArray(value)) {
      const v = value?.map((o: ISelectOption) => o.value);
      setFilters((prev) => {
        return {
          ...prev,
          [name]: v as Array<string>,
        };
      });
      const params = new URLSearchParams(filterParams);
      params.set(name, v.toString());
      setFilterParams(params);
    }
  };

  return (
    <Box
      className={cn(
        "flex flex-col gap-4 md:flex-row md:flex-wrap md:items-start",
      )}
    >
      <MultiAutocompleteField
        label="Deal Stage"
        name="stage"
        options={DEAL_STAGES_OPTIONS}
        value={filters.stage}
        onChange={handleAutoCompleteChange}
        limitTags={2}
        size="small"
        className={cn("min-w-[250px]")}
      />
      <MultiAutocompleteField
        label="Deal Structure"
        name="structure"
        options={DEAL_STRUCTURE_TYPE_OPTIONS}
        value={filters.structure}
        onChange={handleAutoCompleteChange}
        limitTags={2}
        size="small"
        className={cn("min-w-[250px]")}
      />
      <MultiAutocompleteField
        label="Tax Credit Structure"
        name="tax_credit_structure"
        options={DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS}
        value={filters.tax_credit_structure}
        onChange={handleAutoCompleteChange}
        limitTags={2}
        size="small"
        className={cn("min-w-[250px]")}
      />
    </Box>
  );
}
