import { IUpdateProjectTaxCreditForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum PROJECT_TAX_CREDIT_TYPE {
  NONE = "None",
  ITC = "ITC",
  PTC = "PTC",
}

export const PROJECT_TAX_CREDIT_TYPE_OPTIONS = enumToOptions(
  PROJECT_TAX_CREDIT_TYPE,
);

export enum PROJECT_TAX_CREDIT_ELECTION_TYPE {
  REG = "Regular",
  TR = "Transfer",
  DP = "Direct Pay",
}

export const PROJECT_TAX_CREDIT_ELECTION_TYPE_OPTIONS = enumToOptions(
  PROJECT_TAX_CREDIT_ELECTION_TYPE,
  ["DP"],
);

export const UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE: IUpdateProjectTaxCreditForm =
  {
    credit_type: "",
    placed_in_service_date: "",
    pwa_requirements_met: true,
    ptc_inflation: null,
    is_ira_domestic_content_adder: false,
    is_ira_energy_community_adder: false,
    is_ira_environmental_justice_adder: false,
    is_ira_special_qualfied_ej_adder: false,
  };
