import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputSponsorEquitySummaryView from "../../../../views/deal/output/sponsor-equity/summary";
import { getSponsorEquityReportSummary } from "../../../../apis/report/deal";

export default function DealOutputSponsorEquitySummary(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputSponsorEquitySummaryView
          getSponsorEquityReportSummary={getSponsorEquityReportSummary}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
