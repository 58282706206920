import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import CopyIcon from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import LogsIcon from "@mui/icons-material/Restore";
import IconButton from "@mui/material/IconButton";
import { useParams } from "react-router-dom";
import { Protect } from "@clerk/clerk-react";
import { useDispatch } from "react-redux";

import useStyles from "./styles";
import Logs from "../../../../components/logs";
import DateSchedule from "../../../../components/date-schedule";
import Button from "../../../../components/button";
import DetailsCard from "../../../../components/details-card";
import ViewWrapper from "../../../../components/view-wrapper";
import ProjectProductionFormModal from "../../../../components/project-production-form-modal";
import ReportTableV2 from "../../../../components/report-table-v2";
import LogsWrapper from "../../../../components/logs-wrapper";
import CurveFieldLabel from "../../../../components/curve-field-label";
import { setCurrentProjectAction } from "../../../../utils/redux/slices";
import {
  formatNumberForView,
  numberWithCommas,
  getProjectSeasonalityAdjustmentFactors,
  cn,
} from "../../../../utils/helpers";
import {
  useAPI,
  useAppSelector,
  useCopyToClipboard,
  useDrawer,
  useLogs,
} from "../../../../utils/hooks";
import {
  IProjectProduction,
  IUpdateProjectProductionForm,
  IUpdateProjectProductionFormErrors,
  IProjectTiming,
  ILogsConfiguration,
  IReportTable,
} from "../../../../interfaces";
import {
  PROJECT_GROSS_PRODUCTION_METHOD,
  PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES,
  PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES,
  PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE,
  PROJECT_PRODUCTION_SEASONALITY_ADJUSTMENT_TYPE,
  PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES,
  PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES,
  USER_PERMISSIONS,
  PROJECT_PRODUCTION_INPUT_TYPE,
  UPDATE_PROJECT_PRODUCTION_FORM_DEFAULT_STATE,
  PROJECT_PRODUCTION_DEGRADATION_METHODS,
} from "../../../../constants";

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  getProjectProduction: (id: number) => Promise<IProjectProduction[]>;
  updateProjectProduction: (
    id: number,
    productionId: number,
    form: IUpdateProjectProductionForm,
  ) => Promise<IProjectProduction>;
  getProjectProductionComponents: (projectId: number) => Promise<IReportTable>;
}
export default function ProjectProductionView({
  getProjectTiming,
  getProjectProduction,
  updateProjectProduction,
  getProjectProductionComponents,
}: IProps): JSX.Element {
  const styles = useStyles();
  const { projectId } = useParams();

  const dispatch = useDispatch();
  const { currentProject } = useAppSelector((s) => s.project);

  const { copyToClipboard } = useCopyToClipboard();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [productionComponents, setProductionComponents] =
    React.useState<IReportTable>();
  const [degradationCurveToggle, setDegradationCurveToggle] =
    React.useState<boolean>(false);
  const [availabilityCurveToggle, setAvailabilityCurveToggle] =
    React.useState<boolean>(false);
  const [curtailmentCurveToggle, setCurtailmentCurveToggle] =
    React.useState<boolean>(false);
  const [lossCurveToggle, setLossCurveToggle] = React.useState<boolean>(false);
  const [dateSchedule, setDateSchedule] = React.useState<string[]>([]);
  const [updateProductionModalOpen, setUpdateProductionModalOpen] =
    React.useState<boolean>(false);
  const [projectProduction, setProjectProduction] =
    React.useState<IProjectProduction>();
  const [seasonalityAdjustmentFactors, setSeasonalityAdjustmentFactors] =
    React.useState<Array<{ name: string; value: string }>>([]);
  const [productionInputValues, setProductionInputValues] = React.useState({});
  const [form, setForm] = React.useState<IUpdateProjectProductionForm>(
    UPDATE_PROJECT_PRODUCTION_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    handleGetProductionComponents(Number(projectId));
    getProjectProductionCallAPI().then((response) => {
      response && setProjectProduction(response[0]);
    });
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setDateSchedule(response[0]?.date_schedule || []);
    });
  }, [projectId]);

  const {
    callAPI: getProductionComponentsCallAPI,
    loading: loadingProductionComponents,
    errored: getProductionComponentsFailed,
  } = useAPI((projectId) => getProjectProductionComponents(projectId));

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: getProjectProductionCallAPI,
    errored: getProductionDetailsFailed,
    loading: loadingProductionDetails,
  } = useAPI(() => getProjectProduction(Number(projectId)), {
    initialLoading: true,
  });

  const {
    callAPI: updateProjectProductionCallAPI,
    fieldErrors: updateProjectProductionFormErrors,
    setFieldErrors: setUpdateProjectProductionFormErrors,
    loading: updatingProduction,
  } = useAPI<IProjectProduction, IUpdateProjectProductionFormErrors>(
    (
      projectId: number,
      productionId: number,
      form: IUpdateProjectProductionForm,
    ) => updateProjectProduction(projectId, productionId, form),
  );

  const handleUpdateProduction = async (form: IUpdateProjectProductionForm) => {
    const production = await updateProjectProductionCallAPI(
      Number(projectId),
      Number(projectProduction!.id),
      form,
    );

    if (production && currentProject) {
      setProjectProduction(production);
      dispatch(
        setCurrentProjectAction({
          ...currentProject,
          capacity_ac: production?.capacity_ac,
          capacity_dc: production?.capacity_dc,
        }),
      );
      await handleGetProductionComponents(Number(projectId));
    }

    return production;
  };

  const handleGetProductionComponents = async (projectId: number) => {
    const components = await getProductionComponentsCallAPI(projectId);

    if (components) {
      setProductionComponents(components);
    }
  };

  const seasonalityAdjustmentFactorView = React.useMemo(() => {
    if (projectProduction) {
      return getProjectSeasonalityAdjustmentFactors(projectProduction!);
    } else {
      return [];
    }
  }, [projectProduction]);

  const handleOpenUpdateProductionModal = () => {
    const {
      id,
      created,
      project,
      modified,
      created_by,
      ncf_percentage,
      gross_production_mwh,
      gross_yield,
      probability_adjustment_factor_percentage,
      conversion_rate,
      production_input,
      ...formDetails
    } = projectProduction!;
    const factors = getProjectSeasonalityAdjustmentFactors(projectProduction!);
    setSeasonalityAdjustmentFactors(factors);
    setForm(JSON.parse(JSON.stringify(formDetails)));
    // Initialize productionInputValues with form.production_input
    if (formDetails.production_input_type === "TI") {
      // Table Input
      const parsedProductionInputs = JSON.parse(production_input);
      const productionInputs = Object.keys(parsedProductionInputs).reduce(
        (acc, currentKey) => {
          return {
            ...acc,
            [currentKey]: formatNumberForView(
              parsedProductionInputs[currentKey],
            ), // formatting numbers
          };
        },
        {} as Record<string, string>,
      );
      setProductionInputValues(productionInputs);
    }
    setUpdateProductionModalOpen(true);
  };

  const handleCloseUpdateProductionModal = () => {
    setUpdateProductionModalOpen(false);
  };

  const projectProductionLogConfiguration: ILogsConfiguration = {
    id: Number(projectProduction?.id),
    type:
      currentProject?.type === "BAST"
        ? "storageprojectproduction"
        : "projectproduction",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectProductionLogConfiguration.type,
      projectProductionLogConfiguration.id,
    );
  };

  const handleCopySeasonalityFactor = () => {
    if (projectProduction?.seasonality_adjustment_factors?.length) {
      copyToClipboard(
        projectProduction?.seasonality_adjustment_factors?.map(String),
      );
    }
  };

  const copySeasonalityFactorButton = (
    <IconButton onClick={handleCopySeasonalityFactor}>
      <CopyIcon />
    </IconButton>
  );

  return (
    <>
      <ViewWrapper
        loading={loadingProductionDetails}
        error={getProductionDetailsFailed}
      >
        <Box className={styles.classes.container}>
          {projectProduction && (
            <Box>
              <Box className={styles.classes.header}>
                <Box className={styles.classes.logsButtonContainer}>
                  <IconButton onClick={handleOnOpenLogs}>
                    <LogsIcon />
                  </IconButton>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
                        <Button
                          canOpenUpgrade
                          startIcon={<EditIcon />}
                          label="Edit"
                          btnType="primary"
                          onClick={handleOpenUpdateProductionModal}
                        />
                      </Protect>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box className={styles.classes.content}>
                <DetailsCard
                  heading="Production"
                  autoHeight
                  sections={[
                    {
                      fields: [
                        {
                          label:
                            currentProject?.type !== "BAST"
                              ? "Project Capacity (AC)"
                              : "Storage Power Capacity",
                          value: {
                            text: projectProduction.capacity_ac + " MW",
                          },
                        },
                        ...(currentProject?.type !== "BAST"
                          ? [
                              {
                                label: "Project Capacity (DC)",
                                value: {
                                  text: projectProduction.capacity_dc + " MW",
                                },
                              },
                              {
                                label: "Conversion Rate",
                                value: {
                                  text: projectProduction.conversion_rate + "x",
                                },
                              },
                              {
                                label: "Production Method",
                                value: {
                                  text: PROJECT_GROSS_PRODUCTION_METHOD[
                                    projectProduction.gross_production_method
                                  ],
                                },
                              },
                              {
                                label: "Production Input Type",
                                value: {
                                  text: PROJECT_PRODUCTION_INPUT_TYPE[
                                    projectProduction.production_input_type
                                  ],
                                },
                              },
                            ]
                          : []),
                        ...(currentProject?.type === "BAST"
                          ? [
                              {
                                label: "Duration",
                                value: {
                                  text:
                                    String(projectProduction.duration_hrs) +
                                    " Hrs",
                                },
                              },
                              {
                                label: "Storage Energy Capacity",
                                value: {
                                  text:
                                    projectProduction.capacity_energy_mwh +
                                    " MWh",
                                },
                              },
                            ]
                          : []),
                        ...(currentProject?.type !== "BAST"
                          ? [
                              {
                                label: "Gross NCF",
                                value: {
                                  text: projectProduction.ncf_percentage + "%",
                                },
                              },
                              {
                                label: "Gross Yield",
                                value: {
                                  text:
                                    numberWithCommas.format(
                                      projectProduction.gross_yield,
                                    ) + " kWh/kWp",
                                },
                              },
                              {
                                label: "Gross Production",
                                value: {
                                  text:
                                    numberWithCommas.format(
                                      projectProduction.gross_production_mwh,
                                    ) + " MWh",
                                },
                              },
                            ]
                          : []),

                        ...(currentProject?.type !== "BAST" &&
                        projectProduction.production_input_type === "SI"
                          ? [
                              {
                                label: "Benchmark Probability Factor",
                                value: {
                                  text: PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE[
                                    projectProduction.probability_factor_type as keyof typeof PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE
                                  ],
                                },
                              },
                              {
                                label: "Standard Deviation",
                                value: {
                                  text:
                                    projectProduction.standard_deviation !==
                                    null
                                      ? `${projectProduction.standard_deviation}%`
                                      : "N/A",
                                },
                              },
                            ]
                          : []),
                        ...(currentProject?.type !== "BAST"
                          ? [
                              {
                                label: "Case Probability Factor",
                                value: {
                                  text: PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE[
                                    projectProduction.case_probability_factor_type as keyof typeof PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE
                                  ],
                                },
                              },
                              {
                                label: "Adjust for Leap Years",
                                value: {
                                  text: projectProduction.adjust_for_leap_years
                                    ? "Yes"
                                    : "No",
                                },
                              },
                            ]
                          : []),
                      ],
                    },
                  ]}
                />

                <DetailsCard
                  heading="Loss Factors"
                  autoHeight
                  sections={[
                    {
                      fields: [
                        {
                          label: "Degradation Method",
                          value: {
                            text: `${
                              PROJECT_PRODUCTION_DEGRADATION_METHODS[
                                projectProduction.degradation_method
                              ]
                            }`,
                          },
                        },
                        ...(PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES[
                          projectProduction.degradation_input_type
                        ] === PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES.DRP
                          ? [
                              {
                                label: "Degradation Rate",
                                value: {
                                  text: `${projectProduction.degradation_rate_percentage}%`,
                                },
                              },
                            ]
                          : []),
                        ...(PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES[
                          projectProduction.degradation_input_type
                        ] === PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES.DRPC
                          ? [
                              {
                                label: (
                                  <CurveFieldLabel
                                    label="Cumulative Degradation Rate Percentage Curve"
                                    primaryAction={setDegradationCurveToggle}
                                    value={
                                      projectProduction.degradation_rate_percentage_curve
                                    }
                                    toggled={degradationCurveToggle}
                                  />
                                ),
                                value: {
                                  text: (
                                    <DateSchedule
                                      curve={
                                        projectProduction.degradation_rate_percentage_curve ||
                                        []
                                      }
                                      dateSchedule={dateSchedule}
                                      toggle={degradationCurveToggle}
                                      handleToggle={setDegradationCurveToggle}
                                    />
                                  ),
                                },
                              },
                            ]
                          : []),
                        ...(currentProject?.type !== "BAST"
                          ? [
                              ...(PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES[
                                projectProduction.availability_factor_input_type
                              ] ===
                              PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES.AFP
                                ? [
                                    {
                                      label: "Availability Factor",
                                      value: {
                                        text: `${projectProduction.availability_factor_percentage}%`,
                                      },
                                    },
                                  ]
                                : []),

                              ...(PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES[
                                projectProduction.availability_factor_input_type
                              ] ===
                              PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES.AFPC
                                ? [
                                    {
                                      label: (
                                        <CurveFieldLabel
                                          label="Availability Factor Percentage Curve"
                                          primaryAction={
                                            setAvailabilityCurveToggle
                                          }
                                          value={
                                            projectProduction.availability_factor_percentage_curve
                                          }
                                          toggled={availabilityCurveToggle}
                                        />
                                      ),
                                      value: {
                                        text: (
                                          <DateSchedule
                                            curve={
                                              projectProduction.availability_factor_percentage_curve ||
                                              []
                                            }
                                            dateSchedule={dateSchedule}
                                            toggle={availabilityCurveToggle}
                                            handleToggle={
                                              setAvailabilityCurveToggle
                                            }
                                          />
                                        ),
                                      },
                                    },
                                  ]
                                : []),
                              ...(PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES[
                                projectProduction.curtailment_input_type
                              ] ===
                              PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES.CP
                                ? [
                                    {
                                      label: "Curtailment Loss Percentage",
                                      value: {
                                        text: `${projectProduction.curtailment_percentage}%`,
                                      },
                                    },
                                  ]
                                : []),
                              ...(PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES[
                                projectProduction.curtailment_input_type
                              ] ===
                              PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES.CPC
                                ? [
                                    {
                                      label: (
                                        <CurveFieldLabel
                                          label="Curtailment Loss Percentage Curve"
                                          primaryAction={
                                            setCurtailmentCurveToggle
                                          }
                                          value={
                                            projectProduction.availability_factor_percentage_curve
                                          }
                                          toggled={curtailmentCurveToggle}
                                        />
                                      ),
                                      value: {
                                        text: (
                                          <DateSchedule
                                            curve={
                                              projectProduction.curtailment_percentage_curve ||
                                              []
                                            }
                                            dateSchedule={dateSchedule}
                                            toggle={curtailmentCurveToggle}
                                            handleToggle={
                                              setCurtailmentCurveToggle
                                            }
                                          />
                                        ),
                                      },
                                    },
                                  ]
                                : []),

                              ...(PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES[
                                projectProduction.other_losses_input_type
                              ] ===
                              PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES.OLP
                                ? [
                                    {
                                      label: "Other Losses Percentage",
                                      value: {
                                        text: `${projectProduction.other_losses_percentage}%`,
                                      },
                                    },
                                  ]
                                : []),

                              ...(PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES[
                                projectProduction.other_losses_input_type
                              ] ===
                              PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES.OLPC
                                ? [
                                    {
                                      label: (
                                        <CurveFieldLabel
                                          label="Other Losses Percentage Curve"
                                          primaryAction={setLossCurveToggle}
                                          value={
                                            projectProduction.other_losses_percentage_curve
                                          }
                                          toggled={lossCurveToggle}
                                        />
                                      ),
                                      value: {
                                        text: (
                                          <DateSchedule
                                            curve={
                                              projectProduction.other_losses_percentage_curve ||
                                              []
                                            }
                                            dateSchedule={dateSchedule}
                                            toggle={lossCurveToggle}
                                            handleToggle={setLossCurveToggle}
                                          />
                                        ),
                                      },
                                    },
                                  ]
                                : []),
                              {
                                label: "Periodicity (Seasonality Factor)",
                                value: {
                                  text: PROJECT_PRODUCTION_SEASONALITY_ADJUSTMENT_TYPE[
                                    projectProduction
                                      .seasonality_adjustment_type
                                  ],
                                },
                              },
                            ]
                          : []),
                      ],
                    },
                  ]}
                />

                {currentProject?.type !== "BAST" &&
                projectProduction.seasonality_adjustment_type !== "AN" ? (
                  <Box>
                    <DetailsCard
                      heading="Seasonality Factors"
                      secondaryButton={copySeasonalityFactorButton}
                      autoHeight
                      sections={[
                        {
                          fields: [
                            ...seasonalityAdjustmentFactorView.map((v) => ({
                              label: v.name,
                              value: { text: `${v.value}%` },
                            })),
                            {
                              label: "Total",
                              value: {
                                text: `${projectProduction.seasonality_adjustment_factors_total}%`,
                              },
                              textWeight: 600,
                              textColor:
                                projectProduction.seasonality_adjustment_factors_total <
                                99.99
                                  ? "red"
                                  : "",
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                ) : null}
              </Box>
            </Box>
          )}

          <ViewWrapper
            loading={loadingProductionComponents}
            error={getProductionComponentsFailed}
          >
            {productionComponents ? (
              <Box className={cn("mt-4")}>
                <ReportTableV2 data={productionComponents} />
              </Box>
            ) : null}
          </ViewWrapper>
        </Box>
      </ViewWrapper>
      <ProjectProductionFormModal
        open={updateProductionModalOpen}
        headerLabel="Edit Production Details"
        loading={updatingProduction}
        dateSchedule={dateSchedule}
        formErrors={updateProjectProductionFormErrors}
        setFormErrors={setUpdateProjectProductionFormErrors}
        seasonalityAdjustmentFactors={seasonalityAdjustmentFactors}
        setSeasonalityAdjustmentFactors={setSeasonalityAdjustmentFactors}
        productionInputValues={productionInputValues}
        setProductionInputValues={setProductionInputValues}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateProductionModal}
        onConfirm={handleUpdateProduction}
        projectType={currentProject?.type}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectProductionLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectProductionLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
