import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { Protect } from "@clerk/clerk-react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import Button from "../../../../../components/button";
import DetailsCard from "../../../../../components/details-card";
import ViewWrapper from "../../../../../components/view-wrapper";
import ProjectDepreciationStreamFormModal from "../../../../../components/project-depreciation-stream-form-modal";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import { numberToUSD } from "../../../../../utils/helpers";
import { useAPI } from "../../../../../utils/hooks";
import {
  PROJECT_DEPRECIATION_STREAM_TYPE,
  USER_PERMISSIONS,
} from "../../../../../constants";
import {
  IProjectDepreciationStream,
  IUpdateProjectDepreciationStreamForm,
  IUpdateProjectDepreciationStreamFormErrors,
} from "../../../../../interfaces";

interface IProps {
  getProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    streamId: number,
  ) => Promise<IProjectDepreciationStream>;
  updateProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    streamId: number,
    form: IUpdateProjectDepreciationStreamForm,
  ) => Promise<IProjectDepreciationStream>;
  deleteProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    streamId: number,
  ) => Promise<boolean>;
}

export default function ProjectDepreciationStreamDetails({
  getProjectDepreciationStream,
  updateProjectDepreciationStream,
  deleteProjectDepreciationStream,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { projectId, depreciationId, streamId } = useParams();

  const [
    updateDepreciationStreamModalOpen,
    setUpdateDepreciationStreamModalOpen,
  ] = React.useState<boolean>(false);
  const [stream, setStream] = React.useState<IProjectDepreciationStream | null>(
    null,
  );
  const [form, setForm] = React.useState<IUpdateProjectDepreciationStreamForm>({
    type: "",
    percentage: "",
    itc_eligibility: "",
  });

  React.useEffect(() => {
    getProjectDepreciationStreamCallAPI(
      Number(projectId),
      Number(depreciationId),
      Number(streamId),
    ).then((response) => {
      response && setStream(response);
    });
  }, [projectId, depreciationId, streamId]);

  const {
    callAPI: getProjectDepreciationStreamCallAPI,
    errored: getDepreciationFailed,
    loading: loadingDepreciation,
  } = useAPI(
    (projectId: number, depreciationId: number, streamId: number) =>
      getProjectDepreciationStream(projectId, depreciationId, streamId),
    { initialLoading: true },
  );

  const {
    callAPI: updateProjectDepreciationStreamCallAPI,
    fieldErrors: updateProjectDepreciationStreamFormErrors,
    setFieldErrors: setUpdateProjectDepreciationStreamFormErrors,
    loading: updateProjectDepreciationStreamLoading,
  } = useAPI<
    IProjectDepreciationStream,
    IUpdateProjectDepreciationStreamFormErrors
  >(
    (
      projectId: number,
      depreciationId: number,
      streamId: number,
      form: IUpdateProjectDepreciationStreamForm,
    ) =>
      updateProjectDepreciationStream(
        projectId,
        depreciationId,
        streamId,
        form,
      ),
  );

  const { callAPI: deleteProjectDepreciationStreamCallAPI } = useAPI(
    (projectId: number, depreciationId: number, streamId: number) =>
      deleteProjectDepreciationStream(projectId, depreciationId, streamId),
    { setConfirmModalLoading: true },
  );

  const handleEditClick = async () => {
    const { type, percentage, itc_eligibility } = stream!;
    setForm({ type, percentage, itc_eligibility });
    setUpdateDepreciationStreamModalOpen(true);
  };

  const handleCloseEditDepreciationStreamModal = () => {
    setUpdateDepreciationStreamModalOpen(false);
  };

  const handleOnDeleteClick = async () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Depreciation Stream",
        description:
          "Are you sure you want to delete this depreciation stream?",
        onConfirm: handleDeleteClick,
      }),
    );
  };

  const handleDeleteClick = async () => {
    const deleted = await deleteProjectDepreciationStreamCallAPI(
      Number(projectId),
      Number(depreciationId),
      Number(streamId),
    );

    if (deleted) {
      navigate(`/project/${projectId}/tax/depreciation/`);
    }
  };

  const handleEditDepreciationStream = async (
    form: IUpdateProjectDepreciationStreamForm,
  ) => {
    const depreciationStream = await updateProjectDepreciationStreamCallAPI(
      Number(projectId),
      Number(depreciationId),
      Number(streamId),
      form,
    );
    depreciationStream && setStream(depreciationStream);

    return depreciationStream;
  };

  return (
    <>
      <ViewWrapper loading={loadingDepreciation} error={getDepreciationFailed}>
        <Box>
          <Box className={styles.classes.header}>
            <IconButton
              classes={{ root: styles.classes.iconButton }}
              onClick={() => window.history.back()}
            >
              <ArrowBackIcon />
            </IconButton>
            <Box>
              <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Button
                      canOpenUpgrade
                      startIcon={<EditIcon />}
                      label="Edit"
                      btnType="primary"
                      onClick={handleEditClick}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      canOpenUpgrade
                      label="Delete"
                      btnType="danger"
                      onClick={handleOnDeleteClick}
                    />
                  </Grid>
                </Grid>
              </Protect>
            </Box>
          </Box>
          <Box className={styles.classes.content}>
            <DetailsCard
              heading=""
              sections={[
                {
                  fields: [
                    {
                      label: "Type",
                      value: {
                        text: PROJECT_DEPRECIATION_STREAM_TYPE[
                          stream?.type as keyof typeof PROJECT_DEPRECIATION_STREAM_TYPE
                        ],
                      },
                    },
                    {
                      label: "Amount",
                      value: {
                        text: numberToUSD.format(stream?.amount || 0),
                      },
                    },
                    {
                      label: "Percentage of FMV",
                      value: {
                        text: stream?.percentage + "%",
                      },
                    },
                    ...(stream?.is_itc_eligible
                      ? [
                          {
                            label: "ITC Eligibility (when applicable)",
                            value: {
                              text: (stream?.itc_eligibility ?? 0) + "%",
                            },
                          },
                        ]
                      : []),
                  ],
                },
              ]}
            />
          </Box>
        </Box>
      </ViewWrapper>

      <ProjectDepreciationStreamFormModal
        headerLabel="Edit Depreciation"
        open={updateDepreciationStreamModalOpen}
        loading={updateProjectDepreciationStreamLoading}
        formErrors={updateProjectDepreciationStreamFormErrors}
        setFormErrors={setUpdateProjectDepreciationStreamFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseEditDepreciationStreamModal}
        onConfirm={handleEditDepreciationStream}
      />
    </>
  );
}
