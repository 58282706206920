import React from "react";
import Box from "@mui/material/Box";

import useStyles from "./styles";
import ChartWrapper from "../../components/chart-wrapper";
import ReportTable from "../../components/report-table";
import DebtReportSummaryCharts from "../deal/output/debt/summary/charts";
import { ITEMS_AND_TOTAL_TABLE_COLUMN } from "../../constants";
import { IDebtReportSummaryChart, ITableRow } from "../../interfaces";

interface IProps {
  debtReportSummaryChart: IDebtReportSummaryChart | null | undefined;
  debtShortTableRows: ITableRow[] | null;
  debtTableRows: ITableRow[] | null;
  debtIrrRows: ITableRow[] | null;
}

const TermDebtTab: React.FC<IProps> = ({
  debtReportSummaryChart,
  debtShortTableRows,
  debtTableRows,
  debtIrrRows,
}) => {
  const styles = useStyles();
  return (
    <Box>
      {debtReportSummaryChart &&
      debtShortTableRows &&
      debtTableRows &&
      debtIrrRows ? (
        <Box className={styles.classes.dealSection}>
          <Box>
            <Box className={styles.classes.innerChartContainer}>
              <ReportTable
                columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
                rows={debtIrrRows}
              />
            </Box>
            <ReportTable
              columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
              rows={debtShortTableRows}
            />
          </Box>

          <Box className={styles.classes.chartContainer}>
            <Box className={styles.classes.innerChartContainer}>
              <ChartWrapper title="Debt Value Components (NPV)">
                <DebtReportSummaryCharts debtChart={debtReportSummaryChart} />
              </ChartWrapper>
            </Box>
          </Box>
        </Box>
      ) : (
        <p className={styles.classes.text404}>Debt Summary Not Available!</p>
      )}
    </Box>
  );
};

export default TermDebtTab;
