import { DASHBOARD_ROUTES } from "../../constants";
import { AxiosHelper } from "../../utils/helpers";
import { IDashboardChartData, IDashboardMetrics } from "../../interfaces";

export const getDashboardMetrics = async () => {
  return await AxiosHelper.get<IDashboardMetrics>(DASHBOARD_ROUTES.metrics.get);
};

export const getDashboardCharts = async () => {
  return await AxiosHelper.get<IDashboardChartData>(
    DASHBOARD_ROUTES.charts.get,
  );
};
