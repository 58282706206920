import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CONFIRM_DELETE_MODAL_DEFAULT_PROPS } from "../../../constants";
import { IConfirmDeleteModalProps } from "../../../components/confirm-delete-modal";

export interface ICommonSliceState {
  deleteModalProps: IConfirmDeleteModalProps;
  ctrlPressed: boolean;
  shiftPressed: boolean;
  contentLoaded: boolean;
}

export const commonSlice = createSlice({
  name: "common",
  initialState: {
    deleteModalProps: CONFIRM_DELETE_MODAL_DEFAULT_PROPS,
    ctrlPressed: false,
    shiftPressed: false,
    contentLoaded: false,
  },
  reducers: {
    setDeleteModalProps(
      state: ICommonSliceState,
      action: PayloadAction<ICommonSliceState["deleteModalProps"]>,
    ) {
      state.deleteModalProps = action.payload;
    },
    resetDeleteModalProps(state: ICommonSliceState) {
      state.deleteModalProps = CONFIRM_DELETE_MODAL_DEFAULT_PROPS;
    },
    toggleDeleteModalLoading(
      state: ICommonSliceState,
      action: PayloadAction<boolean>,
    ) {
      state.deleteModalProps.loading = action.payload;
    },
    updateCtrlPressed(
      state: ICommonSliceState,
      action: PayloadAction<ICommonSliceState["ctrlPressed"]>,
    ) {
      state.ctrlPressed = action.payload;
    },
    updateShiftPressed(
      state: ICommonSliceState,
      action: PayloadAction<ICommonSliceState["shiftPressed"]>,
    ) {
      state.shiftPressed = action.payload;
    },
    updateContentLoaded(
      state: ICommonSliceState,
      action: PayloadAction<ICommonSliceState["contentLoaded"]>,
    ) {
      state.contentLoaded = action.payload;
    },
  },
});
