import { IIconProps } from "../../interfaces";

export default function ProjectsIcon({ fillColor }: IIconProps): JSX.Element {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1198_2427)">
        <path
          d="M4.50797 3.22621L3.30797 2.03288L2.36797 2.97288L3.5613 4.16621L4.50797 3.22621ZM2.66797 6.99954H0.667969V8.33288H2.66797V6.99954ZM8.66797 0.366211H7.33464V2.33288H8.66797V0.366211ZM13.6346 2.97288L12.6946 2.03288L11.5013 3.22621L12.4413 4.16621L13.6346 2.97288ZM11.4946 12.1062L12.688 13.3062L13.628 12.3662L12.428 11.1729L11.4946 12.1062ZM13.3346 6.99954V8.33288H15.3346V6.99954H13.3346ZM8.0013 3.66621C5.79464 3.66621 4.0013 5.45954 4.0013 7.66621C4.0013 9.87288 5.79464 11.6662 8.0013 11.6662C10.208 11.6662 12.0013 9.87288 12.0013 7.66621C12.0013 5.45954 10.208 3.66621 8.0013 3.66621ZM7.33464 14.9662H8.66797V12.9995H7.33464V14.9662ZM2.36797 12.3595L3.30797 13.2995L4.5013 12.0995L3.5613 11.1595L2.36797 12.3595Z"
          fill={fillColor ?? "black"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1198_2427">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
