import Box from "@mui/material/Box";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { Property as CssProperty } from "csstype";

import useStyles from "./styles";

interface IProps {
  loaderPlacement?: CssProperty.AlignItems;
  loadingPercentage?: number;
}

export default function LoaderScreen({
  loaderPlacement = "center",
  loadingPercentage,
}: IProps): JSX.Element {
  const styles = useStyles({ loaderPlacement });

  return (
    <Box className={styles.classes.container}>
      <Box className={styles.classes.circularProgressContainer}>
        <CircularProgress
          classes={{ colorPrimary: styles.classes.circleColor }}
        />
        {_.isNumber(loadingPercentage) &&
          _.inRange(loadingPercentage, 0, 100) && (
            <Box className={styles.classes.circleLabelContainer}>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
              >{`${loadingPercentage}%`}</Typography>
            </Box>
          )}
      </Box>
    </Box>
  );
}
