import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  grid: {
    display: "grid",
    marginTop: theme.spacing(2),
    gridTemplateColumns: "1fr 1fr",
    gap: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
}));
