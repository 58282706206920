export const externalLightTheme = {
  color: {
    primary: "#16F197",
    secondary: "#5F51F4",
    danger: "#F44336",
    background: "#EAEAEA",
    surface: "#FBFBFB",
    aboveSurface: "#EEEEEE",
    highlightColor: "#5F51F4",
    text: "#000000",
    gray: "#333",
    disabledBackground: "#D3D3D3",
    disabledText: "#FFFFFF",
    aboveAll: "#FFFFFF",
    secondaryHighlight: "#FFFEC8",
    secondaryBackground: "#FBFBFB",
  },
};
