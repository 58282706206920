import { makeStyles } from "tss-react/mui";
import { darken } from "@mui/material";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      flexDirection: "column",
      minHeight: 400,
      "& p": {
        marginBottom: theme.spacing(2),
      },
    },
    contactUsBtn: {
      backgroundColor: selectedTheme.color.secondary,
      color: "#fff",
      marginRight: theme.spacing(2),
      padding: theme.spacing(0.8, 2),
      "&:hover": {
        backgroundColor: darken(selectedTheme.color.secondary, 0.2),
      },
    },
    billingBtn: {
      color: selectedTheme.color.text,
      border: `1px solid ${selectedTheme.color.text}`,
      padding: theme.spacing(0.8, 2),
    },
  };
});
