import React from "react";
import { ToastContainer } from "react-toastify";

import ThemeContext from "../../utils/context/theme-context";

export default function MyToastProvider() {
  const { darkMode } = React.useContext(ThemeContext);

  return (
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      pauseOnHover
      theme={darkMode ? "dark" : "light"}
      toastStyle={{
        backgroundColor: darkMode ? "#444444" : undefined, // #444444 so that it appears clearly even in light grays
      }}
    />
  );
}
