import Layout from "../../components/layout";
import WorkflowDocumentsView from "../../views/workflow/documents";

export default function WorkflowDocumentsPage(): JSX.Element {
  return (
    <Layout title="Workflow">
      <WorkflowDocumentsView />
    </Layout>
  );
}
