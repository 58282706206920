import {
  useUser,
  OrganizationSwitcher as ClerkOrganizationSwitcher,
} from "@clerk/clerk-react";

export default function OrganizationSwitcher(): JSX.Element | null {
  const { user } = useUser();
  if (
    user?.organizationMemberships &&
    user?.organizationMemberships.length > 0
  ) {
    return (
      <ClerkOrganizationSwitcher
        createOrganizationUrl="/deals" // To prevent new org creation
        afterSwitchOrganizationUrl="/"
        hidePersonal={true}
      />
    );
  } else {
    return null;
  }
}
