import React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LinearProgress from "@mui/material/LinearProgress";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

import useStyles from "./styles";
import Button from "../button";
import { cn } from "../../utils/helpers";
import { ButtonType } from "../../interfaces";

interface IProps extends DialogProps {
  onClose: () => void;
  onConfirm?: () => Promise<unknown>;
  loading?: boolean;
  heading?: string;
  children?: JSX.Element;
  textContent?: string;
  cancelBtnLabel?: string;
  confirmBtnLabel?: string;
  confirmBtnType?: ButtonType;
  form?: object;
  hideActionButtons?: boolean;
  noContentPadding?: boolean;
  resetForm?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Modal({
  onClose,
  onConfirm,
  loading,
  heading,
  children,
  textContent,
  cancelBtnLabel,
  confirmBtnLabel,
  confirmBtnType,
  form,
  hideActionButtons,
  noContentPadding,
  resetForm,
  ...dialogProps
}: IProps): JSX.Element {
  const styles = useStyles();

  const defaultFormState = React.useMemo(() => {
    return dialogProps.open ? JSON.stringify(form) : "";
  }, [dialogProps.open]);

  const handleClose = () => {
    if (JSON.stringify(form) !== defaultFormState) {
      const close = window.confirm(
        "Are you sure you want to close this modal ? \nAny unsaved changes will be lost.",
      );
      close && onClose();
      return;
    }
    onClose();
  };

  return (
    <Dialog
      classes={{ paper: cn("min-w-[444px]") }}
      onClose={handleClose}
      {...dialogProps}
      onTransitionExited={resetForm}
      TransitionComponent={Transition}
    >
      {loading && (
        <LinearProgress
          classes={{
            bar1Indeterminate: styles.classes.progressBar,
            bar2Indeterminate: styles.classes.progressBar,
            colorPrimary: styles.classes.progressBarBuffer,
            root: styles.classes.root,
          }}
        />
      )}
      {heading && (
        <DialogTitle classes={{ root: cn("!font-semibold") }}>
          {heading}
        </DialogTitle>
      )}
      <DialogContent
        classes={{ root: `${noContentPadding ? "!p-0" : "!py-2 !px-6"}` }}
      >
        {textContent ? (
          <DialogContentText>{textContent}</DialogContentText>
        ) : null}
        {children}
      </DialogContent>
      {!hideActionButtons && (
        <DialogActions classes={{ root: cn("!p-6") }}>
          <Button
            btnType="secondary"
            label={cancelBtnLabel || "Cancel"}
            dataPw={`${cancelBtnLabel || "Cancel"}-${heading}`}
            onClick={onClose}
          />
          <Button
            btnType={confirmBtnType || "primary"}
            label={confirmBtnLabel || "Confirm"}
            dataPw={`${confirmBtnLabel || "Confirm"}-${heading}`}
            disabled={loading}
            onClick={onConfirm}
          />
        </DialogActions>
      )}
    </Dialog>
  );
}
