import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    schdulerInput: {
      padding: theme.spacing(2),
      background: `${selectedTheme.color.surface} !important`,
      border: `1.5px solid ${selectedTheme.color.aboveSurface}`,
      borderRadius: theme.spacing(0.25),
    },
    schedulerInputError: {
      border: `1.5px solid ${selectedTheme.color.danger}`,
    },
  };
});
