import React from "react";
import { cloneDeep } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import { useAPI } from "../../../utils/hooks";
import { getDealDetails } from "../../../apis/deal/base";
import { filterSidebarLinks } from "../../../utils/helpers";
import { SIDEBAR_NAVIGATION } from "../../../constants";

export default function DealSizing() {
  const navigate = useNavigate();
  const { dealId, caseDealId } = useParams();

  const { callAPI: getDealDetailsCallAPI } = useAPI(
    (dealId) => getDealDetails(dealId),
    { initialLoading: true },
  );

  React.useEffect(() => {
    getDealDetailsCallAPI(Number(caseDealId)).then((deal) => {
      const links = SIDEBAR_NAVIGATION.find(
        (l) => l.label === "Deals",
      )?.subLinks;

      if (links && deal) {
        const clonedSidebar = cloneDeep(links);
        const li = filterSidebarLinks(clonedSidebar, {
          structure: deal?.structure,
          tax_credit_structure: deal?.tax_credit_structure,
        });

        const sizingLinks = li?.find((l) => l?.label === "Sizing");
        const naviagteToUrl =
          sizingLinks?.subLinks?.length &&
          sizingLinks?.subLinks[0]?.path
            ?.replace(":dealId", String(dealId))
            .replace(":caseDealId", String(caseDealId));

        naviagteToUrl && navigate(naviagteToUrl);
      }
    });
  }, []);

  return null;
}
