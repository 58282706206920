import Layout from "../components/layout";
import CozeView from "../views/co-ze";
import {
  getCoZes,
  createCoZe,
  updateCoZe,
  deleteCoZe,
  updateCozeConvertToDeal,
} from "../apis/co-ze/base";
import {
  getOutputPartnershipSummary,
  getTaxEquityReportSummary,
  getSponsorEquityReportSummary,
  getDebtReportSummary,
  getTransferReportSummary,
  getCapitalChartData,
  getOutputPartnershipSplit,
} from "../apis/report/deal";

export default function CoZe(): JSX.Element {
  return (
    <Layout title="CoZe">
      <CozeView
        getCoZes={getCoZes}
        deleteCoZe={deleteCoZe}
        createCoZe={createCoZe}
        updateCoZe={updateCoZe}
        updateCozeConvertToDeal={updateCozeConvertToDeal}
        getTaxEquityReportSummary={getTaxEquityReportSummary}
        getPartnershipReportSummary={getOutputPartnershipSummary}
        getSponsorEquityReportSummary={getSponsorEquityReportSummary}
        getDebtReportSummary={getDebtReportSummary}
        getTransferReportSummary={getTransferReportSummary}
        getCapitalChartData={getCapitalChartData}
        getOutputPartnershipSplit={getOutputPartnershipSplit}
      />
    </Layout>
  );
}
