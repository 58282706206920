import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import LogsIcon from "@mui/icons-material/Restore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate, useParams } from "react-router-dom";
import { Protect } from "@clerk/clerk-react";
import { useDispatch } from "react-redux";

import useStyles from "../../details-page-styles";
import Logs from "../../../../../../components/logs";
import Button from "../../../../../../components/button";
import LogsWrapper from "../../../../../../components/logs-wrapper";
import ViewWrapper from "../../../../../../components/view-wrapper";
import DetailsCard from "../../../../../../components/details-card";
import DateSchedule from "../../../../../../components/date-schedule";
import CurveFieldLabel from "../../../../../../components/curve-field-label";
import ProjectMerchantRevenueFormModal from "../../../../../../components/project-merchant-revenue-form-modal";
import { numberToUSD } from "../../../../../../utils/helpers";
import {
  setCurrentOrgCurvesAction,
  setDeleteModalPropsAction,
} from "../../../../../../utils/redux/slices";
import {
  useAPI,
  useAppSelector,
  useDrawer,
  useLogs,
} from "../../../../../../utils/hooks";
import {
  PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES,
  PROJECT_MERCHANT_PRICE_INPUT_TYPES,
  PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE,
  PROJECT_MERCHANT_REVENUE_TYPE,
  PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES,
  USER_PERMISSIONS,
} from "../../../../../../constants";
import {
  IProjectTiming,
  IProjectMerchantRevenue,
  IProjectMerchantRevenueForm,
  IProjectMerchantRevenueFormErrors,
  ISelectOption,
  IGetCurvesParams,
  ServerPaginatedResponse,
  IOrganizationCurve,
  ILogsConfiguration,
} from "../../../../../../interfaces";

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  getProjectMerchantRevenueDetails: (
    projectId: number,
    revenueId: number,
  ) => Promise<IProjectMerchantRevenue>;
  updateProjectMerchantRevenue: (
    projectId: number,
    revenueId: number,
    form: IProjectMerchantRevenueForm,
  ) => Promise<IProjectMerchantRevenue>;
  deleteProjectMerchantRevenueContract: (
    projectId: number,
    revenueContractId: number,
  ) => Promise<boolean>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
}

export default function ProjectMerchantRevenueDetails({
  getProjectTiming,
  updateProjectMerchantRevenue,
  getProjectMerchantRevenueDetails,
  deleteProjectMerchantRevenueContract,
  getCurves,
}: IProps): JSX.Element {
  const styles = useStyles();

  const navigate = useNavigate();
  const { projectId, revenueId } = useParams();

  const dispatch = useDispatch();
  const { currentProject } = useAppSelector((s) => s.project);
  const { currentOrgCurves } = useAppSelector((s) => s.org);

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [basisCurveToggle, setBasisCurveToggle] =
    React.useState<boolean>(false);
  const [eligibieCurveToggle, setEligibieCurveToggle] =
    React.useState<boolean>(false);
  const [revenueCurveToggle, setRevenueCurveToggle] =
    React.useState<boolean>(false);
  const [priceCurveToggle, setPriceCurveToggle] =
    React.useState<boolean>(false);
  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();
  const [revenue, setRevenue] = React.useState<IProjectMerchantRevenue | null>(
    null,
  );

  const [editRevenueModalOpen, setEditRevenueModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IProjectMerchantRevenueForm>(
    PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE,
  );

  React.useEffect(() => {
    getProjectMerchantRevenueDetailsCallAPI(
      Number(projectId),
      Number(revenueId),
    ).then((response) => {
      response && setRevenue(response);
    });
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setProjectTiming(response[0]);
    });
  }, [projectId, revenueId]);

  const {
    callAPI: getProjectMerchantRevenueDetailsCallAPI,
    errored: getRevenueFailed,
    loading: loadingRevenue,
  } = useAPI(
    (projectId: number, revenueId: number) =>
      getProjectMerchantRevenueDetails(Number(projectId), Number(revenueId)),
    { initialLoading: true },
  );

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: updateProjectMerchantRevenueCallAPI,
    fieldErrors: updateProjectMerchantRevenueFormErrors,
    setFieldErrors: setUpdateProjectMerchantRevenueFormErrors,
    loading: updateProjectMerchantRevenueLoading,
  } = useAPI<IProjectMerchantRevenue, IProjectMerchantRevenueFormErrors>(
    (projectId: number, revenueId: number, form: IProjectMerchantRevenueForm) =>
      updateProjectMerchantRevenue(projectId, revenueId, form),
  );

  const { callAPI: deleteProjectMerchantRevenueContractCallAPI } = useAPI(
    (projectId: number, revenueId: number) =>
      deleteProjectMerchantRevenueContract(projectId, revenueId),
    { setConfirmModalLoading: true },
  );

  const handleDelete = async () => {
    const deleted = await deleteProjectMerchantRevenueContractCallAPI(
      Number(projectId),
      Number(revenueId),
    );

    if (deleted) {
      navigate(`/project/${projectId}/pro-forma/revenue/merchant`);
    }
  };

  const handleEditRevenueContract = async (
    form: IProjectMerchantRevenueForm,
  ) => {
    const merchantRevenue = await updateProjectMerchantRevenueCallAPI(
      Number(projectId),
      Number(revenueId),
      form,
    );
    merchantRevenue && setRevenue(merchantRevenue);

    return merchantRevenue;
  };

  const handleGetCurves = async () => {
    const curves = await getCurves({ curve_type: ["PC"] }).catch(() => null);
    curves && dispatch(setCurrentOrgCurvesAction(curves.results));
  };

  const getDefaultBaseYear = (cod?: string) => {
    return !isNaN(Date.parse(cod || ""))
      ? new Date(cod || "").getFullYear()
      : "";
  };

  const handleEditClick = async () => {
    await handleGetCurves();
    const { created, created_by, id, modified, project, ...formData } =
      revenue!;
    setForm({
      ...formData,
      base_year: formData.base_year || getDefaultBaseYear(),
    });
    setEditRevenueModalOpen(true);
  };

  const handleCloseEditRevenueModal = () => {
    setEditRevenueModalOpen(false);
  };

  const handleOnDeleteClick = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Revenue",
        description: "Are you sure you want to delete this revenue?",
        onConfirm: handleDelete,
      }),
    );
  };

  const organizationCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves.map((c) => ({
      label: c.name,
      value: String(c.id),
    }));
  }, [currentOrgCurves]);

  const gotoCurveDetailPage = (url: string) => {
    window.open(url);
  };

  const projectRevenueLogConfiguration: ILogsConfiguration = {
    id: Number(revenue?.id),
    type: "projectmerchantrevenue",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectRevenueLogConfiguration.type,
      projectRevenueLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper loading={loadingRevenue} error={getRevenueFailed}>
        {revenue ? (
          <Box>
            <Box className={styles.classes.header}>
              <IconButton
                data-pw="go-back-btn"
                classes={{ root: styles.classes.iconButton }}
                onClick={() => window.history.back()}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box className={styles.classes.buttonsContainer}>
                <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
                  <IconButton onClick={handleOnOpenLogs}>
                    <LogsIcon />
                  </IconButton>
                  <Button
                    canOpenUpgrade
                    startIcon={<EditIcon />}
                    label="Edit"
                    btnType="primary"
                    onClick={handleEditClick}
                  />
                  <Button
                    canOpenUpgrade
                    label="Delete"
                    btnType="danger"
                    onClick={handleOnDeleteClick}
                  />
                </Protect>
              </Box>
            </Box>
            <Box className={styles.classes.content}>
              <DetailsCard
                heading=""
                autoHeight
                sections={[
                  {
                    fields: [
                      {
                        label: "Type",
                        value: {
                          text:
                            revenue.type === "CAPACITY" &&
                            currentProject?.type === "BAST"
                              ? "Merchant Capacity"
                              : PROJECT_MERCHANT_REVENUE_TYPE[revenue.type],
                        },
                      },
                      {
                        label: "Name",
                        value: { text: revenue.name || "" },
                      },
                      ...(revenue.type !== "OTHER"
                        ? [
                            ...(revenue.type === "CAPACITY"
                              ? [
                                  {
                                    label: "Eligible Capacity Input Type",
                                    value: {
                                      text: PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                        revenue.eligible_capacity_input_type
                                      ],
                                    },
                                  },
                                  ...(PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                    revenue.eligible_capacity_input_type
                                  ] ===
                                  PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES.FIXED
                                    ? [
                                        {
                                          label: "Eligible Capacity Percentage",
                                          value: {
                                            text:
                                              revenue.eligible_capacity_percentage +
                                              "%",
                                          },
                                        },
                                      ]
                                    : [
                                        {
                                          label: (
                                            <CurveFieldLabel
                                              label="Eligible Capacity Percentage Curve"
                                              primaryAction={
                                                setEligibieCurveToggle
                                              }
                                              value={
                                                revenue.eligible_capacity_percentage_curve
                                              }
                                              toggled={eligibieCurveToggle}
                                            />
                                          ),
                                          value: {
                                            text: (
                                              <DateSchedule
                                                curve={
                                                  revenue.eligible_capacity_percentage_curve ||
                                                  []
                                                }
                                                dateSchedule={
                                                  projectTiming?.date_schedule ||
                                                  []
                                                }
                                                toggle={eligibieCurveToggle}
                                                handleToggle={
                                                  setEligibieCurveToggle
                                                }
                                              />
                                            ),
                                          },
                                        },
                                      ]),
                                ]
                              : []),

                            {
                              label: "Price Input Type",
                              value: {
                                text: PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                  revenue.price_input_type
                                ],
                              },
                            },
                            {
                              label: "Escalator (Calendar Year)",
                              value: { text: revenue.escalator + "%" },
                            },
                            {
                              label: "Escalator Base Year",
                              value: { text: String(revenue.base_year) },
                            },
                            {
                              label: "Price Haircut",
                              value: { text: revenue.price_haircut + "%" },
                            },
                            {
                              label: "Cash Lag (Months)",
                              value: {
                                text:
                                  revenue.cash_basis_lag !== null
                                    ? String(revenue.cash_basis_lag)
                                    : "N/A",
                              },
                            },
                            ...(revenue.price !== null &&
                            PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                              revenue.price_input_type
                            ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.FIXED
                              ? [
                                  {
                                    label:
                                      PROJECT_MERCHANT_PRICE_INPUT_TYPES.FIXED,
                                    value: {
                                      text:
                                        numberToUSD.format(revenue.price) +
                                        (revenue.type === "CAPACITY"
                                          ? " / kW-Month"
                                          : " / MWh"),
                                    },
                                  },
                                ]
                              : []),
                            ...(PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                              revenue.price_input_type
                            ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.CURVE
                              ? [
                                  {
                                    label: (
                                      <CurveFieldLabel
                                        label="Price Curve"
                                        primaryAction={setPriceCurveToggle}
                                        value={revenue.price_curve}
                                        toggled={priceCurveToggle}
                                      />
                                    ),
                                    value: {
                                      text: (
                                        <DateSchedule
                                          curve={revenue.price_curve || []}
                                          dateSchedule={
                                            projectTiming?.date_schedule || []
                                          }
                                          inUSD
                                          toggle={priceCurveToggle}
                                          handleToggle={setPriceCurveToggle}
                                          endAdornment={`${
                                            revenue.type === "CAPACITY"
                                              ? " /kW-Month"
                                              : " /MWh"
                                          }`}
                                        />
                                      ),
                                    },
                                  },
                                ]
                              : []),
                            ...(PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                              revenue.price_input_type
                            ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.OC
                              ? [
                                  {
                                    label: "Organization Curve Name",
                                    value: {
                                      text: (
                                        <Link
                                          component="button"
                                          variant="body2"
                                          onClick={() =>
                                            gotoCurveDetailPage(
                                              `/configuration/data/curves/${revenue.org_curve_detail?.curve_group}/${revenue.org_curve_detail?.id}`,
                                            )
                                          }
                                        >
                                          {revenue?.org_curve_detail?.name}
                                          <OpenInNewIcon fontSize="small" />
                                        </Link>
                                      ),
                                    },
                                  },
                                  {
                                    label: "Curve Start Date",
                                    value: {
                                      text:
                                        revenue?.org_curve_detail?.start_date ??
                                        "N/A",
                                    },
                                  },
                                  {
                                    label: "Curve Term Years",
                                    value: {
                                      text:
                                        revenue?.org_curve_detail?.term_years.toString() +
                                        " Yrs",
                                    },
                                  },
                                ]
                              : []),
                          ]
                        : [
                            ...(revenue.type !== "OTHER"
                              ? [
                                  ...(revenue.type === "CAPACITY"
                                    ? [
                                        {
                                          label: "Eligible Capacity Input Type",
                                          value: {
                                            text: PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                              revenue
                                                .eligible_capacity_input_type
                                            ],
                                          },
                                        },
                                        ...(PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES[
                                          revenue.eligible_capacity_input_type
                                        ] ===
                                        PROJECT_MERCHANT_ELIGIBLE_CAPACITY_INPUT_TYPES.FIXED
                                          ? [
                                              {
                                                label:
                                                  "Eligible Capacity Percentage",
                                                value: {
                                                  text:
                                                    revenue.eligible_capacity_percentage +
                                                    "%",
                                                },
                                              },
                                            ]
                                          : [
                                              {
                                                label: (
                                                  <CurveFieldLabel
                                                    label="Eligible Capacity Percentage Curve"
                                                    primaryAction={
                                                      setEligibieCurveToggle
                                                    }
                                                    value={
                                                      revenue.eligible_capacity_percentage_curve
                                                    }
                                                    toggled={
                                                      eligibieCurveToggle
                                                    }
                                                  />
                                                ),
                                                value: {
                                                  text: (
                                                    <DateSchedule
                                                      curve={
                                                        revenue.eligible_capacity_percentage_curve ||
                                                        []
                                                      }
                                                      dateSchedule={
                                                        projectTiming?.date_schedule ||
                                                        []
                                                      }
                                                      toggle={
                                                        eligibieCurveToggle
                                                      }
                                                      handleToggle={
                                                        setEligibieCurveToggle
                                                      }
                                                    />
                                                  ),
                                                },
                                              },
                                            ]),
                                      ]
                                    : []),

                                  {
                                    label: "Price Input Type",
                                    value: {
                                      text: PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                        revenue.price_input_type
                                      ],
                                    },
                                  },
                                  {
                                    label: "Escalator (Calendar Year)",
                                    value: { text: revenue.escalator + "%" },
                                  },
                                  {
                                    label: "Escalator Base Year",
                                    value: { text: String(revenue.base_year) },
                                  },
                                  {
                                    label: "Price Haircut",
                                    value: {
                                      text: revenue.price_haircut + "%",
                                    },
                                  },
                                  {
                                    label: "Cash Lag (Months)",
                                    value: {
                                      text:
                                        revenue.cash_basis_lag !== null
                                          ? String(revenue.cash_basis_lag)
                                          : "N/A",
                                    },
                                  },
                                  ...(revenue.price !== null &&
                                  PROJECT_MERCHANT_PRICE_INPUT_TYPES[
                                    revenue.price_input_type
                                  ] === PROJECT_MERCHANT_PRICE_INPUT_TYPES.FIXED
                                    ? [
                                        {
                                          label: "Price",
                                          value: {
                                            text:
                                              numberToUSD.format(
                                                revenue.price,
                                              ) +
                                              (revenue.type === "CAPACITY"
                                                ? " / kW-Month"
                                                : " / MWh"),
                                          },
                                        },
                                      ]
                                    : [
                                        {
                                          label: (
                                            <CurveFieldLabel
                                              label="Price Curve"
                                              primaryAction={
                                                setPriceCurveToggle
                                              }
                                              value={revenue.price_curve}
                                              toggled={priceCurveToggle}
                                            />
                                          ),
                                          value: {
                                            text: (
                                              <DateSchedule
                                                curve={
                                                  revenue.price_curve || []
                                                }
                                                dateSchedule={
                                                  projectTiming?.date_schedule ||
                                                  []
                                                }
                                                inUSD
                                                toggle={priceCurveToggle}
                                                handleToggle={
                                                  setPriceCurveToggle
                                                }
                                                endAdornment={`${
                                                  revenue.type === "CAPACITY"
                                                    ? " /kW-Month"
                                                    : " /MWh"
                                                }`}
                                              />
                                            ),
                                          },
                                        },
                                      ]),
                                ]
                              : [
                                  {
                                    label: "Price Haircut",
                                    value: {
                                      text: revenue.price_haircut + "%",
                                    },
                                  },
                                  {
                                    label: "Cash Lag (Months)",
                                    value: {
                                      text:
                                        revenue.cash_basis_lag !== null
                                          ? String(revenue.cash_basis_lag)
                                          : "N/A",
                                    },
                                  },
                                  {
                                    label: (
                                      <CurveFieldLabel
                                        label="Revenue Schedule"
                                        primaryAction={setRevenueCurveToggle}
                                        value={revenue.revenue_curve}
                                        toggled={revenueCurveToggle}
                                      />
                                    ),
                                    value: {
                                      text: (
                                        <DateSchedule
                                          inUSD
                                          showTotal={{
                                            total: revenue.schedule_total,
                                          }}
                                          curve={revenue.revenue_curve || []}
                                          dateSchedule={
                                            projectTiming?.date_schedule || []
                                          }
                                          toggle={revenueCurveToggle}
                                          handleToggle={setRevenueCurveToggle}
                                        />
                                      ),
                                    },
                                  },
                                ]),
                          ]),
                      ...(revenue.type === "ENERGY"
                        ? [
                            {
                              label: "Basis Differential Method",
                              value: {
                                text:
                                  revenue.differential_method &&
                                  PROJECT_REVENUE_DIFFERENTIAL_METHOD_TYPES[
                                    revenue.differential_method
                                  ],
                              },
                            },
                            ...(revenue.differential_method === "BDP"
                              ? [
                                  {
                                    label: "Basis Differential",
                                    value: {
                                      text:
                                        "$" +
                                        revenue.basis_differential +
                                        " / MWh",
                                    },
                                  },
                                ]
                              : [
                                  {
                                    label: (
                                      <CurveFieldLabel
                                        label="Basis Curve"
                                        primaryAction={setBasisCurveToggle}
                                        value={revenue.basis_curve}
                                        toggled={basisCurveToggle}
                                      />
                                    ),
                                    value: {
                                      text: (
                                        <DateSchedule
                                          curve={revenue.basis_curve || []}
                                          dateSchedule={
                                            projectTiming?.date_schedule || []
                                          }
                                          inUSD
                                          toggle={basisCurveToggle}
                                          handleToggle={setBasisCurveToggle}
                                        />
                                      ),
                                    },
                                  },
                                ]),
                          ]
                        : []),
                    ],
                  },
                ]}
              />
            </Box>
          </Box>
        ) : null}
      </ViewWrapper>

      <ProjectMerchantRevenueFormModal
        headerLabel="Edit Merchant Revenue"
        open={editRevenueModalOpen}
        loading={updateProjectMerchantRevenueLoading}
        formErrors={updateProjectMerchantRevenueFormErrors}
        setFormErrors={setUpdateProjectMerchantRevenueFormErrors}
        form={form}
        dateSchedule={projectTiming?.date_schedule || []}
        setForm={setForm}
        onClose={handleCloseEditRevenueModal}
        onConfirm={handleEditRevenueContract}
        org_curves={organizationCurvesOptions}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectRevenueLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectRevenueLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
