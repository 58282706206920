import darkScrollbar from "@mui/material/darkScrollbar";
import { Theme, createTheme } from "@mui/material/styles";

import { externalDarkTheme } from "./external-dark";

export const darkTheme: Theme = createTheme({
  palette: {
    primary: {
      main: "#FAFAFA",
    },
    background: {
      default: "#000000",
    },
    mode: "dark",
  },
  typography: {
    allVariants: {
      letterSpacing: 0.5,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: `${externalDarkTheme.color.background} !important`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#000000 !important",
        },
      },
      defaultProps: {
        elevation: 5,
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        groupLabel: {
          backgroundColor: `${externalDarkTheme.color.aboveSurface} !important`,
        },
        groupUl: {
          backgroundColor: `${externalDarkTheme.color.aboveSurface} !important`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          "& th": {
            backgroundColor: `${externalDarkTheme.color.surface} !important`,
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: {
          backgroundColor: `${externalDarkTheme.color.surface} !important`,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: `${externalDarkTheme.color.surface} !important`,
          text: `${externalDarkTheme.color.highlightColor} !important`,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: `${externalDarkTheme.color.aboveSurface} !important`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: `${externalDarkTheme.color.aboveAll} !important`,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          ...darkScrollbar(),
        },
      },
    },
  },
});
