import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  header: {
    display: "flex",
    justifyContent: "end",
  },
  content: {
    margin: theme.spacing(2, 0),
    display: "flex",
    gap: theme.spacing(2),
    "& > *": {
      width: "100%",
    },
    [theme.breakpoints.down(650)]: {
      width: "100%",
    },
  },
  chartContainer: {
    margin: theme.spacing(2, 0),
  },
  tableHeader: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  boaldRow: {
    "& td": {
      fontWeight: 600,
    },
  },
}));
