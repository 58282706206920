import Alert, { AlertProps } from "@mui/material/Alert";

import { PROJECT_VALUATION_DISCLAIMER_MESSAGE } from "../../../../constants";

export default function ValuationDisclaimer(props: AlertProps) {
  return (
    <Alert severity="warning" {...props}>
      {PROJECT_VALUATION_DISCLAIMER_MESSAGE}
    </Alert>
  );
}
