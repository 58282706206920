import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputTaxEquityAccountingView from "../../../../views/deal/output/tax-equity/accounting";
import { getDealReporting } from "../../../../apis/report/deal";

export default function DealOutputTaxEquityAccounting(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputTaxEquityAccountingView
          getDealReporting={getDealReporting}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
