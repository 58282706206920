import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    appbarRoot: {
      height: 68,
      borderBottom: `1px solid ${selectedTheme.color.surface} !important`,
      boxShadow: "0px 1px 3px 0px #0000001F",
    },
    toolbarRoot: {
      display: "flex",
      justifyContent: "space-between",
      minHeight: "68px !important",
    },
    controls: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      gap: theme.spacing(2),
      ".cl-organizationSwitcher-root": {
        display: "flex",
      },
    },
  };
});
