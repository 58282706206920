import DealPagesLayout from "../../../components/deal-pages-layout";
import DealTaxCreditsView from "../../../views/deal/components/tax-credits";
import TabbedLayout from "../../../components/tabbed-layout";
import {
  getDealTaxCreditPTC_Data,
  getTaxCredit,
} from "../../../apis/deal/tax-credit";

export default function DealTaxCreditPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Components">
        <DealTaxCreditsView
          getTaxCredit={getTaxCredit}
          getTaxCreditPTC_Data={getDealTaxCreditPTC_Data}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
