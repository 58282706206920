import React from "react";
import axios, { AxiosResponse } from "axios";
import { useSession } from "@clerk/clerk-react";

import { useLocalStorage } from "../../utils/hooks";
import { API_POLLING_INTERVAL } from "../../constants";

interface IProps {
  children: React.ReactNode;
}

interface IUrlsPercentageLoaded {
  [k: string]: number;
}

const AxiosContext = React.createContext<{
  urlLoadingPercentages: IUrlsPercentageLoaded;
}>({
  urlLoadingPercentages: {},
});

export default function AxiosProvider({ children }: IProps): JSX.Element {
  const { session } = useSession();

  const [buildHashes, setBuildHashes] = useLocalStorage<string[]>(
    "cz-build-hashes",
    [],
  );

  const [urlLoadingPercentages, setUrlLoadingPercentages] =
    React.useState<IUrlsPercentageLoaded>({});

  const activeRequests: Map<
    string,
    { pageHref: string; url: string; req: NodeJS.Timeout }
  > = new Map();

  axios.interceptors.request.use(
    async (config) => {
      config.baseURL = process.env.REACT_APP_SERVER_URL;

      try {
        const token = await session?.getToken({ template: "cz" });

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } catch (error) {
        console.log(error);
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    async (response: AxiosResponse) => {
      const fullPageURL = window.location.href;
      const buildHash = response.headers["x-cz-build"];

      // If build hash is present
      if (buildHash) {
        // If the new build hash is different and not already in the last two hashes
        if (!buildHashes.includes(buildHash)) {
          // Add the new hash to the end of the array, and keep the last two hashes
          const newBuildHashes = [...buildHashes, buildHash].slice(-2);
          setBuildHashes(newBuildHashes);
          // If there are two hashes, reload the page
          if (newBuildHashes.length === 2) {
            window.location.reload();
          }
        }
      }

      const requestUrl: string = response.config.url as string;

      // clear polling requests if the page url is changed
      activeRequests.forEach((req) => {
        if (req.pageHref !== fullPageURL) {
          clearInterval(req.req);
          activeRequests.delete(req.url);
        }
      });

      setUrlLoadingPercentages((prevState) => ({
        ...prevState,
        [requestUrl]: response.data.pct_complete,
      }));

      if (response.data.status === "processing") {
        // Cancel existing polling for this specific request
        if (activeRequests.has(requestUrl)) {
          clearInterval(activeRequests.get(requestUrl)?.req);
          activeRequests.delete(requestUrl);
        }

        // Wrap the polling logic in a Promise
        const pollingPromise: Promise<AxiosResponse> = new Promise(
          async (resolve, reject) => {
            const interval: NodeJS.Timeout = setInterval(async () => {
              try {
                const res: AxiosResponse = await axios.get(requestUrl);
                if (res.data.status !== "processing") {
                  clearInterval(interval);
                  activeRequests.delete(requestUrl); // Remove the request from active requests
                  resolve(res); // Resolve the promise with the final response
                }
              } catch (error) {
                clearInterval(interval);
                activeRequests.delete(requestUrl); // Remove the request from active requests
                reject(error); // Reject the promise if there's an error during polling
              }
            }, API_POLLING_INTERVAL);
            activeRequests.set(requestUrl, {
              pageHref: fullPageURL,
              url: requestUrl,
              req: interval,
            }); // Store the interval in the active requests map
          },
        );

        // Return the polling promise
        return pollingPromise;
      } else {
        // Cancel existing polling when the response is not processing
        if (activeRequests.has(requestUrl)) {
          clearInterval(activeRequests.get(requestUrl)?.req);
          activeRequests.delete(requestUrl);
        }

        // Return the original response for non-polling URLs
        return response;
      }
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  return (
    <AxiosContext.Provider value={{ urlLoadingPercentages }}>
      {children}
    </AxiosContext.Provider>
  );
}

// to access axios context
export const useAxios = () => {
  return React.useContext(AxiosContext);
};
