import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputSponsorEquityBOL_SummaryView from "../../../../views/deal/output/sponsor-equity-BOL/summary";
import { getSponsorEquityBOL_ReportSummary } from "../../../../apis/report/deal";

export default function DealOutputSponsorEquityBOL_SummaryPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputSponsorEquityBOL_SummaryView
          getSponsorEquityBOL_ReportSummary={getSponsorEquityBOL_ReportSummary}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
