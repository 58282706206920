import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { LOG_API_ROTES, OBJECT_TYPE } from "../../constants";
import {
  ILog,
  INote,
  INoteForm,
  ServerPaginatedResponse,
} from "../../interfaces";

export const getLogs = async (
  object_type: OBJECT_TYPE,
  object_id: number | string,
  limit: number,
  offset: number,
) => {
  return await AxiosHelper.get<ServerPaginatedResponse<ILog[]>>(
    LOG_API_ROTES.logs.get,
    {
      params: {
        object_type,
        object_id,
        limit,
        offset,
      },
    },
  );
};

export const getNotes = async (
  object_type: OBJECT_TYPE,
  object_id: number | string,
) => {
  return await AxiosHelper.get<ServerPaginatedResponse<INote[]>>(
    LOG_API_ROTES.notes.get,
    {
      params: {
        object_type,
        object_id,
      },
    },
  );
};

export const createNote = async (note: INoteForm) => {
  return await AxiosHelper.post<INoteForm, INote>(
    LOG_API_ROTES.notes.create,
    note,
  );
};

export const updateNote = async (id: number, note: INoteForm) => {
  const url = replaceParams(LOG_API_ROTES.notes.update, { id });
  return await AxiosHelper.put<INoteForm, INote>(url, note);
};

export const deleteNote = async (id: number) => {
  const url = replaceParams(LOG_API_ROTES.notes.delete, { id });
  return await AxiosHelper.delete(url);
};
