import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { DEAL_API_ROUTES } from "../../constants";
import {
  IConstructionLoanForm,
  IConstructionLoanResponse,
  IGeneralAssumptionResponse,
  IGeneralAssumptionsForm,
  IInvestorBridgeLoanForm,
  IInvestorBridgeLoanResponse,
  ITransferBridgeLoanForm,
  ITransferBridgeLoanResponse,
} from "../../interfaces/deal/construction-debt";

// general assumption
export const getGeneralAssumption = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.debtConstruction.get, { dealId });
  return await AxiosHelper.get<IGeneralAssumptionResponse[]>(url);
};

export const addGeneralAssumption = async (
  dealId: number,
  form: IGeneralAssumptionsForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.debtConstruction.create, {
    dealId,
  });
  return await AxiosHelper.post<
    IGeneralAssumptionsForm,
    IGeneralAssumptionResponse
  >(url, form);
};

export const updateGeneralAssumption = async (
  dealId: number,
  debtId: number,
  form: IGeneralAssumptionsForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.debtConstruction.update, {
    dealId,
    debtId,
  });
  return await AxiosHelper.patch<
    IGeneralAssumptionsForm,
    IGeneralAssumptionResponse
  >(url, form);
};

// construction
export const getConstructionLoan = async (dealId: number) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.constructionLoan.get,
    { dealId },
  );
  return await AxiosHelper.get<IConstructionLoanResponse[]>(url);
};

export const addConstructionLoan = async (
  dealId: number,
  form: IConstructionLoanForm,
) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.constructionLoan.create,
    {
      dealId,
    },
  );
  return await AxiosHelper.post<
    IConstructionLoanForm,
    IConstructionLoanResponse
  >(url, form);
};

export const updateConstructionLoan = async (
  dealId: number,
  debtId: number,
  form: IConstructionLoanForm,
) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.constructionLoan.update,
    {
      dealId,
      debtId,
    },
  );
  return await AxiosHelper.patch<
    IConstructionLoanForm,
    IConstructionLoanResponse
  >(url, form);
};

export const deleteConstructionLoan = async (
  dealId: number,
  debtId: number,
) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.constructionLoan.delete,
    {
      dealId,
      debtId,
    },
  );
  return await AxiosHelper.delete(url);
};

// investor
export const getInvestorLoan = async (dealId: number) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.investorBridgeLoan.get,
    { dealId },
  );
  return await AxiosHelper.get<IInvestorBridgeLoanResponse[]>(url);
};

export const addInvestorLoan = async (
  dealId: number,
  form: IInvestorBridgeLoanForm,
) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.investorBridgeLoan.create,
    {
      dealId,
    },
  );
  return await AxiosHelper.post<
    IInvestorBridgeLoanForm,
    IInvestorBridgeLoanResponse
  >(url, form);
};

export const updateInvestorLoan = async (
  dealId: number,
  debtId: number,
  form: IInvestorBridgeLoanForm,
) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.investorBridgeLoan.update,
    {
      dealId,
      debtId,
    },
  );
  return await AxiosHelper.patch<
    IInvestorBridgeLoanForm,
    IInvestorBridgeLoanResponse
  >(url, form);
};

export const deleteInvestorLoan = async (dealId: number, debtId: number) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.investorBridgeLoan.delete,
    {
      dealId,
      debtId,
    },
  );
  return await AxiosHelper.delete(url);
};

// transfer
export const getTransferLoan = async (dealId: number) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.transferBridgeLoan.get,
    { dealId },
  );
  return await AxiosHelper.get<ITransferBridgeLoanResponse[]>(url);
};

export const addTransferLoan = async (
  dealId: number,
  form: ITransferBridgeLoanForm,
) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.transferBridgeLoan.create,
    {
      dealId,
    },
  );
  return await AxiosHelper.post<
    ITransferBridgeLoanForm,
    ITransferBridgeLoanResponse
  >(url, form);
};

export const updateTransferLoan = async (
  dealId: number,
  debtId: number,
  form: ITransferBridgeLoanForm,
) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.transferBridgeLoan.update,
    {
      dealId,
      debtId,
    },
  );
  return await AxiosHelper.patch<
    ITransferBridgeLoanForm,
    ITransferBridgeLoanResponse
  >(url, form);
};

export const deleteTransferLoan = async (dealId: number, debtId: number) => {
  const url = replaceParams(
    DEAL_API_ROUTES.debtConstruction.transferBridgeLoan.delete,
    {
      dealId,
      debtId,
    },
  );
  return await AxiosHelper.delete(url);
};
