import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 250px)",
  },
  dataRow: {
    cursor: "pointer",
  },
}));
