import { makeStyles } from "tss-react/mui";

const styles = makeStyles()((theme) => ({
  modal: {
    minWidth: "444px",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2.5),
    fontWeight: 600,
    "&::before, &::after": {
      borderTopWidth: "2px",
      borderColor: theme.palette.primary.main,
    },
  },
  selectInput: {
    marginBottom: "0.5rem",
  },
}));

export default styles;
