import { makeStyles } from "tss-react/mui";
import { darken, lighten } from "@mui/material/styles";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    downloadBtn: {
      backgroundColor: selectedTheme.color.secondary,
      padding: theme.spacing(1, 2.5),
      textTransform: "none",
      alignSelf: "start",
      color: "#fff",
      "& hr": {
        marginLeft: theme.spacing(1.5),
        backgroundColor: "#fff",
      },
      ":hover": {
        backgroundColor: darken(selectedTheme.color.secondary, 0.2),
      },
    },
    disabledDownloadBtn: {
      color: `${theme.palette.grey[500]} !important`,
      backgroundColor: `${lighten(
        selectedTheme.color.secondary,
        0.1,
      )} !important`,
    },
    downloadMenu: {
      width: 205,
      "& li": {
        padding: theme.spacing(1, 2.5),
      },
    },
  };
});
