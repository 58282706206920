import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Tabs from "../tabs";
import Layout from "../layout";
import TabbedLayout from "../tabbed-layout";
import LoaderScreen from "../loader-screen";
import { cn } from "../../utils/helpers";
import { getOrganization } from "../../apis/configuration/base";
import { ITab } from "../../interfaces";
import { useAppSelector } from "../../utils/hooks";
import { setCurrentOrgAction } from "../../utils/redux/slices";

const CONFIGURATION_TABS: ITab[] = [
  {
    label: "Curves",
    path: "/configuration/data/curves",
    pathToMatch: "",
    value: "curves",
  },
  {
    label: "Profile",
    path: "/configuration/organization/profile",
    pathToMatch: "",
    value: "profile",
  },
  {
    label: "Billing and Usage",
    path: "/configuration/organization/billing-and-usage",
    pathToMatch: "",
    value: "billing-and-usage",
  },
  {
    label: "Users",
    path: "/configuration/organization/users",
    pathToMatch: "",
    value: "users",
  },
];

interface IProps {
  children: JSX.Element;
  showOrgTabs?: boolean;
}

export default function ConfigurationPageLayout({
  children,
  showOrgTabs,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { currentOrg } = useAppSelector((s) => s.org);

  React.useEffect(() => {
    handleGetCurrentOrganizationData();
  }, []);

  const selectedOrgTab = React.useMemo(() => {
    if (pathname.includes("configuration/data/curves")) {
      return "curves";
    }
    const splitPath = pathname.split("/");
    return splitPath[splitPath.length - 1];
  }, [pathname]);

  const handleGetCurrentOrganizationData = async () => {
    const organization = await getOrganization().catch(() => null);
    if (organization) {
      dispatch(setCurrentOrgAction(organization));
    }
  };

  const goToPage = (tab: ITab) => {
    if (tab.value !== selectedOrgTab) {
      navigate(tab.path || "");
    }
  };

  // compute tabs based on the current pathname
  const tabs = React.useMemo(() => {
    if (pathname.includes("/configuration/data")) {
      return CONFIGURATION_TABS.filter((t) =>
        t.path?.includes("/configuration/data"),
      );
    }
    if (pathname.includes("/configuration/organization")) {
      return CONFIGURATION_TABS.filter((t) =>
        t.path?.includes("/configuration/organization"),
      );
    }
    return [];
  }, [pathname]);

  return (
    <Layout title="Configuration">
      {currentOrg ? (
        <>
          <Box className={cn("mx-4 mt-4")}>
            <Typography variant="h5" component="h5">
              {currentOrg?.name}
            </Typography>
            <Divider className={cn("my-4")} />
          </Box>

          <TabbedLayout module="Configuration" withPadding>
            <>
              {showOrgTabs && (
                <Tabs
                  selectedTab={selectedOrgTab}
                  tabs={tabs}
                  onClick={goToPage}
                />
              )}
              {children}
            </>
          </TabbedLayout>
        </>
      ) : (
        <LoaderScreen />
      )}
    </Layout>
  );
}
