import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material/Select";
import { format, isValid } from "date-fns";

import Modal from "../modal";
import useStyles from "./styles";
import TextInput from "../text-input";
import DatePicker from "../date-picker";
import SelectInput from "../select-input";
import {
  DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  DEAL_TAX_CREDIT_TRANSFER_PERIODICITY_OPTIONS,
} from "../../constants";
import {
  SetStateAction,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferResults,
  IDealTaxCreditTransferFormErrors,
} from "../../interfaces";

interface IProps {
  headerLabel: string;
  open: boolean;
  loading: boolean;
  form: IDealTaxCreditTransferForm;
  setForm: SetStateAction<IDealTaxCreditTransferForm>;
  formErrors?: IDealTaxCreditTransferFormErrors;
  setFormErrors: SetStateAction<IDealTaxCreditTransferFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (
    form: IDealTaxCreditTransferForm,
  ) => Promise<IDealTaxCreditTransferResults | undefined>;
}

export default function EditTaxCreditTransfer({
  headerLabel,
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleOnAddDealComparison = async () => {
    const deal = await onConfirm(form);
    deal && handleOnClose();
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "M/d/yyyy"),
      }));
    }
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (e: SelectChangeEvent<unknown>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleResetForm = () => {
    setForm(DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnAddDealComparison}
      resetForm={handleResetForm}
    >
      <Box>
        <Divider classes={{ root: styles.classes.divider }} textAlign="left">
          Timing
        </Divider>
        <Typography>
          Placed in Service Date: {form.placed_in_service_date}
        </Typography>
        <br />
        {/* <Typography>Payment Date Num 1: {form.payment_date_num_1}</Typography>
        <br /> */}
        {/* <Typography>{form.isITC ? "ITC" : "PTC"} Start Date: {form.creditStartDate}</Typography>
        <br />
        <Typography>{form.isITC ? "ITC" : "PTC"} End Date: {form.creditEndDate}</Typography>
        <br /> */}
        <DatePicker
          label="Pre Filing Date"
          value={form.pre_filing_date ? new Date(form.pre_filing_date) : null}
          onChange={(v) => handleDateChange(v, "pre_filing_date")}
          onOpen={() => clearNonTextFieldErrorOnFocus("pre_filing_date")}
          error={Boolean(formErrors?.pre_filing_date)}
          helperText={formErrors?.pre_filing_date}
          disabled={loading}
          fullWidth={false}
        />
        <Divider classes={{ root: styles.classes.divider }} textAlign="left">
          Key Parameters
        </Divider>
        <TextInput
          required
          isNumeric
          label="Transfer Portion"
          name="transfer_portion"
          value={form.transfer_portion}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.transfer_portion)}
          helperText={formErrors?.transfer_portion}
          endAdornment={<>%</>}
          disabled={loading}
          tooltip="Percentage of tax credit transferred to buyer"
          fullWidth={false}
        />
        <TextInput
          required
          isNumeric
          label="Payment Rate"
          name="payment_rate"
          value={form.payment_rate}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.payment_rate)}
          helperText={formErrors?.payment_rate}
          endAdornment={<>$/Credit</>}
          disabled={loading}
          tooltip="The price paid per dollar of transferred tax credit"
          fullWidth={false}
        />
        {form.isPTC && (
          <SelectInput
            required
            className={styles.classes.selectInput}
            label="Payment Periodicity"
            name="payment_periodicity"
            selected={form.payment_periodicity}
            items={DEAL_TAX_CREDIT_TRANSFER_PERIODICITY_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("payment_periodicity")}
            onChange={handleSelectInputChange}
            error={Boolean(formErrors?.payment_periodicity)}
            helperText={formErrors?.payment_periodicity}
            disabled={loading}
            fullWidth={false}
          />
        )}
        <TextInput
          required
          isNumeric
          label="Payment Dates Lag"
          name="payment_dates_lag"
          value={form.payment_dates_lag}
          onFocus={clearErrorOnFocus}
          onChange={handleTextChange}
          error={Boolean(formErrors?.payment_dates_lag)}
          helperText={formErrors?.payment_dates_lag}
          endAdornment={<>Months</>}
          disabled={loading}
          tooltip="The number of months after placed in service that cash is received for the tax credit"
          fullWidth={false}
        />
      </Box>
    </Modal>
  );
}
