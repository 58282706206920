import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 250px)",
  },
  tableContainerAction: {
    display: "flex",
    justifyContent: "end",
  },
  totalRow: {
    "& td": {
      fontWeight: 600,
    },
  },
  boldRow: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
}));
