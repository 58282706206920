import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { SelectChangeEvent } from "@mui/material/Select";
import { format, isValid } from "date-fns";

import useStyles from "./styles";
import Modal from "../modal";
import TextInput from "../text-input";
import AutocompleteField from "../autocomplete-field";
import DatePicker from "../date-picker";
import SelectInput from "../select-input";
import {
  CARRYFORWARD_CREDITS_OPTIONS,
  DEAL_SPONSOR_EQUITY_FORM_DEFAULT_STATE,
  DISTRIBUTION_PERIODICITY_OPTIONS,
} from "../../constants";
import {
  ISelectOption,
  SetStateAction,
  IDealSponsorEquityForm,
  IDealSponsorEquityFormErrors,
  IDealSponsorEquity,
} from "../../interfaces";

interface IProps {
  open: boolean;
  loading: boolean;
  form: IDealSponsorEquityForm;
  setForm: SetStateAction<IDealSponsorEquityForm>;
  formErrors?: IDealSponsorEquityFormErrors;
  setFormErrors: SetStateAction<IDealSponsorEquityFormErrors | undefined>;
  onClose: () => void;
  onConfirm: (
    form: IDealSponsorEquityForm,
  ) => Promise<IDealSponsorEquity | undefined>;
  headerLabel: string;
}

export default function DealSponsorEquityFormModal({
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
  headerLabel,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSingleAutoCompleteChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: ISelectOption | null,
    name: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [name]: value?.value,
      };
    });
  };

  const handleDateChange = (v: Date | null, field: string) => {
    if (!v || !isValid(v)) {
      setForm((prev) => ({ ...prev, [field]: null }));
      return;
    }
    if (v) {
      setForm((prev) => ({
        ...prev,
        [field]: format(v, "yyyy"),
      }));
    }
  };

  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnAddDeal = async () => {
    const deal = await onConfirm(form);
    deal && handleOnClose();
  };

  const onSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [field]: e.target.value,
      };
    });
  };

  React.useEffect(() => {
    if (form.change_in_federal_tax_rate === "N") {
      setForm((form) => {
        return {
          ...form,
          new_federal_tax_rate: null,
          federal_tax_rate_change_year: null,
        };
      });
    }
  }, [form.change_in_federal_tax_rate]);

  const handleResetForm = () => {
    setForm(DEAL_SPONSOR_EQUITY_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      heading={headerLabel}
      open={open}
      form={form}
      loading={loading}
      onClose={handleOnClose}
      onConfirm={handleOnAddDeal}
      resetForm={handleResetForm}
    >
      <Box>
        <Divider classes={{ root: styles.classes.divider }} textAlign="left">
          Key Parameters
        </Divider>
        <TextInput
          required
          isNumeric
          label="Pre-Tax Discount Rate"
          name="pre_tax_discount_rate"
          value={form.pre_tax_discount_rate}
          endAdornment={<>%</>}
          onFocus={clearErrorOnFocus}
          onChange={onTextChange}
          error={Boolean(formErrors?.pre_tax_discount_rate)}
          helperText={formErrors?.pre_tax_discount_rate}
          disabled={loading}
        />
        <TextInput
          required
          isNumeric
          label="After-Tax Discount Rate"
          name="after_tax_discount_rate"
          value={form.after_tax_discount_rate}
          endAdornment={<>%</>}
          onFocus={clearErrorOnFocus}
          onChange={onTextChange}
          error={Boolean(formErrors?.after_tax_discount_rate)}
          helperText={formErrors?.after_tax_discount_rate}
          disabled={loading}
        />
        <AutocompleteField
          label="Carryforward Credits and Losses"
          name="carryforward_credits_and_losses"
          onChange={handleSingleAutoCompleteChange}
          options={CARRYFORWARD_CREDITS_OPTIONS}
          value={String(form.carryforward_credits_and_losses)}
          helperText={formErrors?.carryforward_credits_and_losses}
          onFocus={() =>
            clearSelectErrorOnFocus("carryforward_credits_and_losses")
          }
          disabled={loading}
        />
        <TextInput
          required
          isNumeric
          label="Federal Tax Rate"
          name="federal_tax_rate"
          value={form.federal_tax_rate}
          endAdornment={<>%</>}
          onFocus={clearErrorOnFocus}
          onChange={onTextChange}
          error={Boolean(formErrors?.federal_tax_rate)}
          helperText={formErrors?.federal_tax_rate}
          disabled={loading}
        />
        <AutocompleteField
          label="Change in Federal Tax Rate"
          name="change_in_federal_tax_rate"
          onChange={handleSingleAutoCompleteChange}
          options={[
            { label: "Yes", value: "Y" },
            { label: "No", value: "N" },
          ]}
          value={String(form.change_in_federal_tax_rate)}
          helperText={formErrors?.change_in_federal_tax_rate}
          onFocus={() => clearSelectErrorOnFocus("change_in_federal_tax_rate")}
          disabled={loading}
        />
        {form.change_in_federal_tax_rate === "Y" && (
          <>
            <TextInput
              required
              isNumeric
              label="New Federal Tax Rate"
              name="new_federal_tax_rate"
              value={form.new_federal_tax_rate}
              endAdornment={<>%</>}
              onFocus={clearErrorOnFocus}
              onChange={onTextChange}
              error={Boolean(formErrors?.new_federal_tax_rate)}
              helperText={formErrors?.new_federal_tax_rate}
              disabled={loading}
            />
            <DatePicker
              label="Tax Rate Change Year"
              value={
                form.federal_tax_rate_change_year
                  ? new Date(form.federal_tax_rate_change_year, 0, 1)
                  : null
              }
              onChange={(v) =>
                handleDateChange(v, "federal_tax_rate_change_year")
              }
              onOpen={() =>
                clearNonTextFieldErrorOnFocus("federal_tax_rate_change_year")
              }
              error={Boolean(formErrors?.federal_tax_rate_change_year)}
              helperText={formErrors?.federal_tax_rate_change_year}
              views={["year"]}
              format="yyyy"
              disabled={loading}
            />
          </>
        )}
        <TextInput
          required
          isNumeric
          label="State Tax Rate"
          name="state_tax_rate"
          value={form.state_tax_rate}
          endAdornment={<>%</>}
          onFocus={clearErrorOnFocus}
          onChange={onTextChange}
          error={Boolean(formErrors?.state_tax_rate)}
          helperText={formErrors?.state_tax_rate}
          disabled={loading}
        />
        {/* hide if deal is a partnership (TE/CE) */}
        {!form.is_partnership_deal && (
          <SelectInput
            required
            label="Distribution Periodicity"
            selected={form.distribution_periodicity}
            items={DISTRIBUTION_PERIODICITY_OPTIONS}
            onFocus={() =>
              clearNonTextFieldErrorOnFocus("distribution_periodicity")
            }
            onChange={(e) => onSelectInputChange(e, "distribution_periodicity")}
            error={Boolean(formErrors?.distribution_periodicity)}
            helperText={formErrors?.distribution_periodicity}
            disabled={loading}
          />
        )}
      </Box>
    </Modal>
  );
}
