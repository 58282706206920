import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IDealComparison,
  IAddDealCaseComparisonForm,
  ServerPaginatedResponse,
  IUpdateDealCaseComparisonForm,
} from "../../interfaces";

export const getDealCaseComparisons = async (base_case_deal: number) => {
  return await AxiosHelper.get<ServerPaginatedResponse<IDealComparison[]>>(
    DEAL_API_ROUTES.analysis.get,
    {
      params: {
        base_case_deal,
      },
    },
  );
};

export const addDealCaseComparison = async (
  form: IAddDealCaseComparisonForm,
) => {
  return await AxiosHelper.post<IAddDealCaseComparisonForm, IDealComparison>(
    DEAL_API_ROUTES.analysis.create,
    form,
  );
};

export const updateDealCaseComparison = async (
  id: number,
  form: IUpdateDealCaseComparisonForm,
) => {
  const url = replaceParams(DEAL_API_ROUTES.analysis.update, {
    id,
  });

  return await AxiosHelper.patch<
    IUpdateDealCaseComparisonForm,
    IDealComparison
  >(url, form);
};
