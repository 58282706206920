import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{ contentLoaded: boolean; hasStickyTabs: boolean }>()(
  (theme, { contentLoaded, hasStickyTabs }) => {
    const selectedTheme = getSelectedTheme(theme.palette.mode);

    return {
      container: {
        border: `1px solid ${selectedTheme.color.background}`,
        borderRadius: 4,
        height: contentLoaded ? "auto" : "100%",
        // for sticky
        maxHeight: hasStickyTabs ? "calc(90vh - 1rem)" : undefined,
        overflow: contentLoaded && hasStickyTabs ? "auto" : undefined,
      },
      tabs: {
        backgroundColor: `${selectedTheme.color.background}`,
        border: `3px solid ${selectedTheme.color.background}`,
        borderBottom: 0,
        minHeight: 40,
        // sticky styles
        position: hasStickyTabs ? "sticky" : undefined,
        top: hasStickyTabs ? 0 : undefined,
        zIndex: hasStickyTabs ? 24 : undefined,
      },
      selectedTabIndicator: {
        display: "none",
      },
      selectedTab: {
        color: `${selectedTheme.color.highlightColor} !important`,
        backgroundColor: selectedTheme.color.surface,
        minHeight: 40,
        borderTopRightRadius: 4,
        borderTopLeftRadius: 4,
      },
      tab: {
        padding: theme.spacing(0, 2),
        minHeight: 40,
        textTransform: "none",
        fontSize: 15.5,
      },
      content: {
        padding: theme.spacing(1),
        height: contentLoaded ? "auto" : "calc(100% - 43px)",
        background: selectedTheme.color.surface,
      },
    };
  },
);
