import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import Menu from "../../../../../components/menu";
import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAPI, useAppSelector } from "../../../../../utils/hooks";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  getDealReportingUrl,
  generateTransferCashReportTableRows,
  cn,
} from "../../../../../utils/helpers";
import {
  ITableRow,
  IReport,
  ReportTerm,
  ReportType,
  ReportPerspective,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";
import {
  OUTPUT_REPORT_PERIODICITY,
  OUTPUT_REPORT_PERIODICITY_OPTIONS,
  TRANSFER_CASH_REPORT_TABLE_COLUMNS,
} from "../../../../../constants";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IReport>;
}

export default function DealOutputTransferCashView({
  getDealReporting,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { caseDealId } = useParams();

  const { urlLoadingPercentages } = useAxios();

  const { currentDeal } = useAppSelector((s) => s.deal);

  const [report, setReport] = React.useState<IReport>();
  const [term, setTerm] = React.useState<ReportTerm>("default");
  const [reportPeriodicityType, setReportPeriodicityType] =
    React.useState<OutputReportPeriodicityType>("MO");
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (Number(caseDealId)) {
      getDealReportingCallAPI(
        Number(caseDealId),
        term,
        reportPeriodicityType,
      ).then((response) => {
        if (response?.["transfer"]) {
          setReport(response);
        }
      });
    }
  }, [caseDealId, term, reportPeriodicityType]);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
    ) =>
      getDealReporting(
        dealId,
        "transfer",
        "transfer",
        term,
        reportPeriodicityType,
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [getReportFailed, loadingReport]);

  const cashTableRows: ITableRow[] = React.useMemo(() => {
    if (report) {
      return generateTransferCashReportTableRows(report);
    }
    return [];
  }, [report]);

  const handleReportPeriodicityTypeChange = React.useCallback(
    (reportPeriodicityType: OutputReportPeriodicityType) => {
      getDealReportingCallAPI(
        Number(caseDealId),
        term,
        reportPeriodicityType,
      ).then((response) => {
        if (response?.["loan/combined(all)"]) {
          setReport(response);
        }
      });
    },
    [caseDealId, term, reportPeriodicityType],
  );

  const handleDownloadReport = async () => {
    if (caseDealId) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(caseDealId),
        "transfer",
        "transfer",
        term,
        reportPeriodicityType,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const hideCols: (keyof IReport["transfer"])[] = React.useMemo(() => {
    if (!report) return [];

    const keysToCheck: (keyof IReport["transfer"])[] = [
      "cash_paid_to_investor",
      "cash_paid_to_sponsor",
    ];

    const hiddenCols = keysToCheck.filter((col) => !(col in report.transfer));

    return hiddenCols;
  }, [report]);

  const transferCashReportTableColumns = TRANSFER_CASH_REPORT_TABLE_COLUMNS.map(
    (col) => {
      if (col.id === "cash_paid_to_investor") {
        return {
          ...col,
          label: `Payments to ${
            currentDeal?.structure === "CEP" ? "Cash Equity" : "Tax Equity"
          }`,
        };
      }
      return col;
    },
  );

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealReportingUrl(
            Number(caseDealId),
            "transfer",
            "transfer",
            term,
            reportPeriodicityType,
          )
        ]
      }
    >
      <Box className={cn("flex justify-between items-center my-4")}>
        <Menu
          selectedItem={OUTPUT_REPORT_PERIODICITY[reportPeriodicityType]}
          placeholder="Periodicity"
          menuItems={OUTPUT_REPORT_PERIODICITY_OPTIONS.map((o) => ({
            label: o.label,
            onClick: () => {
              handleReportPeriodicityTypeChange(
                o.value as OutputReportPeriodicityType,
              );
              setReportPeriodicityType(o.value as OutputReportPeriodicityType);
            },
          }))}
        />
        <Box className={cn("flex gap-2")}>
          <ToggleSizingOutputButton />
          <IconButton
            title="Download Report"
            onClick={handleDownloadReport}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        </Box>
      </Box>

      <ReportTable
        columns={transferCashReportTableColumns}
        hideCols={hideCols}
        rows={cashTableRows}
        boldTotal
        stickyTotal
      />
    </ViewWrapper>
  );
}
