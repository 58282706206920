import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    date: {
      display: "inline-block",
      width: 80,
      textAlign: "right",
      marginRight: theme.spacing(2),
    },
    showMoreCurveBtn: {
      float: "right",
      textTransform: "none",
      color: selectedTheme.color.secondary,
      paddingRight: 0,
    },
  };
});
