import { enumToOptions } from "../../utils/helpers";
import {
  IConstructionLoanForm,
  IGeneralAssumptionsForm,
  IInvestorBridgeLoanForm,
  ITransferBridgeLoanForm,
} from "../../interfaces/deal/construction-debt";

export enum FUNDING_METHODOLOGY_CHOICES {
  EF = "Equity First",
  DF = "Debt First",
}

export const FUNDING_METHODOLOGY_CHOICES_OPTIONS = enumToOptions(
  FUNDING_METHODOLOGY_CHOICES,
  ["DF"],
);

export enum DATE_SELECTION_TYPE_CHOICES {
  MD = "Milestone Date",
  SPD = "Specified Date",
}

export const DATE_SELECTION_TYPE_CHOICES_OPTION = enumToOptions(
  DATE_SELECTION_TYPE_CHOICES,
);

export enum CLOSING_DATE_POINT_CHOICES {
  FNTP = "First NTP",
  LNTP = "Last NTP",
}

export const CLOSING_DATE_POINT_CHOICES_OPTIONS = enumToOptions(
  CLOSING_DATE_POINT_CHOICES,
);

export const GENERAL_ASSUMPTIONS_FORM_DEFAULT_STATE: IGeneralAssumptionsForm = {
  debt_closing_date_type: "MD",
  debt_closing_date_point: "FNTP",
  debt_closing_date: null,
  equity_letter_of_credit_fee: null,
  funding_methodology: "",
  loc_for_construction_credit_support: null,
  min_equity_during_construction: null,
  loc_fee: null,
  sofr_org_curve: null,
  admin_agent_fees: null,
};

export enum LOC_FOR_CREDIT_SUPPORT {
  YES = "Yes",
  NO = "No",
}

export const LOC_FOR_CREDIT_SUPPORT_OPTIONS = enumToOptions(
  LOC_FOR_CREDIT_SUPPORT,
);

export enum CONSTRUCTION_DEBT_BASE_RATE_TYPE {
  FR = "Fixed Rate",
  SOFR = "SOFR Curve",
}

export const CONSTRUCTION_DEBT_BASE_RATE_TYPE_OPTIONS = enumToOptions(
  CONSTRUCTION_DEBT_BASE_RATE_TYPE,
);

export const CONSTRUCTION_LOAN_FORM_DEFAULT_STATE: IConstructionLoanForm = {
  base_rate_type: "",
  base_rate: null,
  spread: null,
  hedge: null,
  swap_rate: null,
  swap_credit_spread: null,
  commitment_fee: null,
  yearfrac_convention: "",
  sizing_type: "",
  upfront_fee: null,
  percent_of_project_costs: null,
};

export const INVESTOR_BRIDGE_LOAN_FORM_DEFAULT_STATE: IInvestorBridgeLoanForm =
  {
    base_rate_type: "",
    base_rate: null,
    spread: null,
    hedge: null,
    swap_rate: null,
    swap_credit_spread: null,
    commitment_fee: null,
    upfront_fee: null,
    yearfrac_convention: "",
    advance_rate: null,
    repay_with_investor_investment: false,
  };

export enum CONSTRUCTION_LOAN_SIZING_TYPE {
  TDA = "Term Debt Amount",
  PPC = "Percentage of Project Costs",
}

export const CONSTRUCTION_LOAN_SIZING_TYPE_OPTIONS = enumToOptions(
  CONSTRUCTION_LOAN_SIZING_TYPE,
);

export const YEAR_FRAC_CONVENTION_TYPE = {
  "30360": "30/360",
  ACTACT: "Actual/Actual",
};

export const YEAR_FRAC_CONVENTION_TYPE_OPTIONS = enumToOptions(
  YEAR_FRAC_CONVENTION_TYPE,
);

export const TRANSFER_BRIDGE_LOAN_FORM_DEFAULT_STATE: ITransferBridgeLoanForm =
  {
    base_rate_type: "",
    base_rate: null,
    spread: null,
    hedge: null,
    swap_rate: null,
    swap_credit_spread: null,
    commitment_fee: null,
    upfront_fee: null,
    yearfrac_convention: "",
    sizing_type: "",
    advance_rate: null,
  };
