import TabbedLayout from "../../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../../components/project-pages-layout";
import ProjectOtherPageLayout from "../../../../components/project-other-page-layout";
import ProjectOtherFlowDetailView from "../../../../views/project/pro-forma/other-flows/flow-detail";
import { getProjectTiming } from "../../../../apis/project/timing";
import {
  deleteProjectOtherFlow,
  editProjectOtherFlow,
  getProjectOtherFlowDetails,
} from "../../../../apis/project/other-flows";

export default function ProjectOtherFlowDetailPage() {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectOtherPageLayout>
          <ProjectOtherFlowDetailView
            getProjectOtherFlowDetails={getProjectOtherFlowDetails}
            getProjectTiming={getProjectTiming}
            editProjectOtherFlow={editProjectOtherFlow}
            deleteProjectOtherFlow={deleteProjectOtherFlow}
          />
        </ProjectOtherPageLayout>
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
