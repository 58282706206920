import React from "react";
import { Box, SelectChangeEvent } from "@mui/material";

import Modal from "../modal";
import SelectInput from "../select-input";
import TextInput from "../text-input";
// import CheckboxInput from "../checkbox-input";
import { cn } from "../../utils/helpers";
import { SetStateAction } from "../../interfaces";
import {
  CONSTRUCTION_DEBT_BASE_RATE_TYPE_OPTIONS,
  INVESTOR_BRIDGE_LOAN_FORM_DEFAULT_STATE,
  YEAR_FRAC_CONVENTION_TYPE_OPTIONS,
} from "../../constants/deal/construction-debt";
import {
  IInvestorBridgeLoanForm,
  IInvestorBridgeLoanFormErrors,
  IInvestorBridgeLoanResponse,
} from "../../interfaces/deal/construction-debt";

interface IProps {
  open: boolean;
  form: IInvestorBridgeLoanForm;
  headerLabel: string;
  loading: boolean;
  formErrors?: IInvestorBridgeLoanFormErrors;
  setFormErrors: SetStateAction<IInvestorBridgeLoanFormErrors | undefined>;
  setForm: SetStateAction<IInvestorBridgeLoanForm>;
  onClose: () => void;
  onConfirm: (
    form: IInvestorBridgeLoanForm,
  ) => Promise<IInvestorBridgeLoanResponse | undefined>;
}

export default function InvestorBridgeFormModal({
  open,
  form,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const updatedForm: IInvestorBridgeLoanForm = {
      ...form,
      base_rate: form.base_rate_type === "SOFR" ? null : form.base_rate,
    };
    const loan = await onConfirm(updatedForm);
    loan && handleOnClose();
  };

  const handleResetForm = () => {
    setForm(INVESTOR_BRIDGE_LOAN_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
      classes={{ paper: cn("!min-w-[50%]") }}
    >
      <Box className={cn("grid grid-cols-2 gap-4")}>
        <Box>
          <TextInput
            required
            label="Advance Rate"
            name="advance_rate"
            type="number"
            value={form.advance_rate}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.advance_rate)}
            helperText={formErrors?.advance_rate}
            endAdornment={<>%</>}
            disabled={loading}
          />
          <SelectInput
            required
            label="Base Rate Type"
            selected={form.base_rate_type}
            items={CONSTRUCTION_DEBT_BASE_RATE_TYPE_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("base_rate_type")}
            onChange={(e) => handleSelectInputChange(e, "base_rate_type")}
            error={Boolean(formErrors?.base_rate_type)}
            helperText={formErrors?.base_rate_type}
            disabled={loading}
          />
          {form.base_rate_type === "FR" && (
            <TextInput
              required
              label="Base Rate"
              name="base_rate"
              value={form.base_rate}
              onFocus={clearErrorOnFocus}
              onChange={handleTextChange}
              error={Boolean(formErrors?.base_rate)}
              helperText={formErrors?.base_rate}
              endAdornment={<>%</>}
              disabled={loading}
            />
          )}
          <TextInput
            required
            label="Spread"
            name="spread"
            type="number"
            value={form.spread}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.spread)}
            helperText={formErrors?.spread}
            endAdornment={<>%</>}
            disabled={loading}
          />
          <TextInput
            required
            label="Hedge %"
            name="hedge"
            value={form.hedge}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.hedge)}
            helperText={formErrors?.hedge}
            endAdornment={<>%</>}
            disabled={loading}
          />
          <TextInput
            required
            label="Swap Rate"
            name="swap_rate"
            type="number"
            value={form.swap_rate}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.swap_rate)}
            helperText={formErrors?.swap_rate}
            endAdornment={<>%</>}
            disabled={loading}
          />
          <TextInput
            required
            label="Swap Credit Spread"
            name="swap_credit_spread"
            type="number"
            value={form.swap_credit_spread}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.swap_credit_spread)}
            helperText={formErrors?.swap_credit_spread}
            endAdornment={<>%</>}
            disabled={loading}
          />
        </Box>
        <Box>
          <TextInput
            required
            label="Upfront Fee"
            name="upfront_fee"
            type="number"
            value={form.upfront_fee}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.upfront_fee)}
            helperText={formErrors?.upfront_fee}
            endAdornment={<>%</>}
            disabled={loading}
          />
          <TextInput
            required
            label="Commitment Fee"
            name="commitment_fee"
            type="number"
            value={form.commitment_fee}
            onFocus={clearErrorOnFocus}
            onChange={handleTextChange}
            error={Boolean(formErrors?.commitment_fee)}
            helperText={formErrors?.commitment_fee}
            endAdornment={<>%</>}
            disabled={loading}
          />
          <SelectInput
            required
            label="Yearfrac Convention"
            selected={form.yearfrac_convention}
            items={YEAR_FRAC_CONVENTION_TYPE_OPTIONS}
            onFocus={() => clearSelectErrorOnFocus("yearfrac_convention")}
            onChange={(e) => handleSelectInputChange(e, "yearfrac_convention")}
            error={Boolean(formErrors?.yearfrac_convention)}
            helperText={formErrors?.yearfrac_convention}
            disabled={loading}
          />
          {/* Note - we will later add this back in some way when we have two stage funding for the investor bridge loan */}
          {/* <CheckboxInput
            label="Repay with Investor Investment"
            name="repay_with_investor_investment"
            checked={form.repay_with_investor_investment}
            onChange={handleCheckboxChange}
            disabled={loading}
          /> */}
        </Box>
      </Box>
    </Modal>
  );
}
