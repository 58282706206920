import React from "react";
import { toast } from "react-toastify";

export const useCopyToClipboard = () => {
  const [copied, setCopied] = React.useState(false);

  const copyToClipboard = React.useCallback(
    (data: string | (string | number | null)[]) => {
      let text;
      // if it is an array convert it into a string separated by \n
      if (Array.isArray(data)) {
        text = data.toString().split(",").join("\n");
      } else {
        text = data;
      }

      navigator.clipboard
        .writeText(text)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // set copied to false after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy to clipboard:", err);
        });
    },
    [],
  );

  React.useEffect(() => {
    if (copied) {
      toast.success("Copied to clipboard!", {
        autoClose: 3000,
        toastId: "copy-toast",
      });
    }
  }, [copied]);

  return { copyToClipboard };
};
