import React from "react";
import { Chart as ChartJS, BarElement, BarController } from "chart.js";

import { useScreenWidth } from "../../../../../utils/hooks";
import { ICapitalChart } from "../../../../../interfaces";
import { chartTooltipArrayOfNumbersToNumber } from "../../../../../utils/helpers";

ChartJS.register(BarElement, BarController);

interface IProps {
  data?: ICapitalChart;
  dealStructureType: "tax-equity" | "cash-equity";
}

export default function CapitalSourcesChart({
  data,
  dealStructureType,
}: IProps): JSX.Element {
  const chartRef = React.useRef<HTMLCanvasElement | null>(null);
  const width = useScreenWidth();

  React.useEffect(() => {
    const chart2DRef = chartRef?.current?.getContext("2d");

    const chartData = [
      {
        label:
          dealStructureType === "cash-equity" ? "Cash Equity" : "Tax Equity",
        backgroundColor: "#7E68FB",
        value: data?.tax_equity.amount,
        percent: data?.tax_equity.percent,
      },
      {
        label: "Sponsor Equity",
        backgroundColor: "#00B969",
        value: data?.sponsor_equity.amount,
        percent: data?.sponsor_equity.percent,
      },
      {
        label: "FMV",
        backgroundColor: "#9F9F9F",
        value: data?.fmv.amount,
        percent: data?.fmv.percent,
      },
    ];

    const capitalChartObj =
      chart2DRef &&
      new ChartJS(chart2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: chartData
            .filter((cD) => cD.percent !== 0)
            .map((cD) => cD.label),
          datasets: [
            {
              label: "",
              data: chartData
                .filter((cD) => cD.percent !== 0)
                .map((cD) => cD.value),
              backgroundColor: chartData
                .filter((cD) => cD.percent !== 0)
                .map((cD) => cD.backgroundColor),
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  return (
                    chartTooltipArrayOfNumbersToNumber(ctx) +
                    " / " +
                    chartData[ctx.dataIndex].percent?.toFixed(2) +
                    "%"
                  );
                },
              },
            },
          },
          scales: {
            x: {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y: {
              title: {
                display: true,
                text: "Amount ($)",
              },
            },
          },
        },
      });

    return () => {
      capitalChartObj?.destroy();
    };
  }, [data, width]);

  return <canvas id="capitalChart" ref={chartRef} />;
}
