import Layout from "../components/layout";
import ProjectListView from "../views/project-list";
import { addProject, getProjects } from "../apis/project/base";

export default function ProjectList(): JSX.Element {
  return (
    <Layout title="Projects">
      <ProjectListView getProjects={getProjects} addProject={addProject} />
    </Layout>
  );
}
