import { enumToOptions } from "../../utils/helpers";

export const DEAL_COST_HEADERS = [{ label: "Type" }, { label: "Total" }];

export enum DEAL_COST_UNIT {
  DL = "$",
  DLPWD = "$/W-dc",
}

export const DEAL_COST_UNIT_OPTIONS = enumToOptions(DEAL_COST_UNIT);

export enum DEAL_CREDIT_SUPPORT_UNIT {
  DL = "$",
  DLPAD = "$/kW-ac",
}

export const DEAL_CREDIT_SUPPORT_UNIT_OPTIONS = enumToOptions(
  DEAL_CREDIT_SUPPORT_UNIT,
);
