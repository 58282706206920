import { makeStyles } from "tss-react/mui";
import { darken } from "@mui/material";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    container: {
      display: "flex",
      gap: theme.spacing(2),
    },
    card: {
      width: "-webkit-fill-available",
      padding: theme.spacing(2),
      position: "relative",
      paddingBottom: theme.spacing(9),
    },
    cardBody: {
      marginTop: theme.spacing(2),
    },
    cardFeature: {
      fontSize: 14,
      display: "flex",
      color: "#A6A6A6",
      margin: theme.spacing(0.5, 0),
      "& svg": {
        color: selectedTheme.color.secondary,
        marginRight: theme.spacing(1),
      },
    },
    button: {
      backgroundColor: selectedTheme.color.secondary,
      color: "#fff",
      padding: theme.spacing(0.8, 2.5),
      marginTop: theme.spacing(2),
      position: "absolute",
      bottom: theme.spacing(2),
      boxShadow: "0px 2px 2px 0px #00000024",
      ":hover": {
        backgroundColor: darken(selectedTheme.color.secondary, 0.2),
      },
    },
    disabledButton: {
      backgroundColor: selectedTheme.color.disabledBackground,
      color: selectedTheme.color.disabledText,
      padding: theme.spacing(0.8, 2.5),
      marginTop: theme.spacing(2),
      position: "absolute",
      bottom: theme.spacing(2),
    },
    centeredContent: {
      marginTop: theme.spacing(1),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  };
});
