import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import Tabs from "../tabs";
import { ITab } from "../../interfaces";

interface IProps {
  children: JSX.Element;
}

export default function ProjectOtherPageLayout({ children }: IProps) {
  const { projectId } = useParams();
  const { pathname } = useLocation();

  const navigate = useNavigate();

  const handleChangeTab = (tab: ITab) => {
    if (tab.path && projectId) {
      const updatedPath = tab.path.replace(":projectId", projectId);
      navigate(updatedPath);
    }
  };

  const selectedTab = React.useMemo(() => {
    if (pathname.includes("other-flows")) {
      return "other_flows";
    }
    return "";
  }, []);

  return (
    <>
      <Tabs
        tabs={[
          {
            label: "Other Flows",
            value: "other_flows",
            path: "/project/:projectId/pro-forma/other-flows",
          },
        ]}
        onClick={handleChangeTab}
        selectedTab={selectedTab}
      />
      {children}
    </>
  );
}
