import TabbedLayout from "../../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../../components/project-pages-layout";
import ProjectExpenseTableView from "../../../../views/project/pro-forma/expenses/table";
import { getProjectTiming } from "../../../../apis/project/timing";
import {
  getProjectExpenses,
  addProjectExpenses,
  updateProjectExpense,
  deleteProjectExpense,
} from "../../../../apis/project/expenses";

export default function ExpenseTable(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectExpenseTableView
          getProjectTiming={getProjectTiming}
          getProjectExpenses={getProjectExpenses}
          addProjectExpense={addProjectExpenses}
          updateProjectExpense={updateProjectExpense}
          deleteExpense={deleteProjectExpense}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
