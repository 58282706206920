import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";

import ContentWrappedTabs from "../content-wrapped-tabs";
import { ITab } from "../../interfaces";

interface IProps {
  children: JSX.Element;
}

const tabs: ITab[] = [
  {
    label: "Dashboard",
    value: "/",
    path: "/",
    pathToMatch: "/",
  },
  {
    label: "Recent",
    value: "/recent",
    path: "/recent",
    pathToMatch: "/recent",
  },
];

export default function HomePagesLayout({ children }: IProps): JSX.Element {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const goToPage = (tab: ITab) => {
    navigate(tab.path || "");
  };

  return (
    <Box style={{ padding: 16, height: "100%" }}>
      <ContentWrappedTabs onClick={goToPage} tabs={tabs} selectedTab={pathname}>
        {children}
      </ContentWrappedTabs>
    </Box>
  );
}
