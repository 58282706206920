import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import {
  IProjectTaxCredit,
  IReportTable,
  IUpdateProjectTaxCreditForm,
} from "../../interfaces";

export const getProjectTaxCredit = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.get, { projectId });
  return await AxiosHelper.get<IProjectTaxCredit[]>(url);
};

export const getProjectTaxCreditPTC_Report = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.getPTC_Report, {
    projectId,
  });
  return await AxiosHelper.get<IReportTable>(url);
};

export const updateProjectTaxCredit = async (
  projectId: number,
  taxCreditId: number,
  form: IUpdateProjectTaxCreditForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.taxCredit.update, {
    projectId,
    taxCreditId,
  });
  return await AxiosHelper.patch<
    IUpdateProjectTaxCreditForm,
    IProjectTaxCredit
  >(url, form);
};
