import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    input: {
      backgroundColor: selectedTheme.color.aboveSurface,
      padding: theme.spacing(1),
      "& svg": {
        marginRight: theme.spacing(1),
      },
      borderRadius: 4,
    },
  };
});
