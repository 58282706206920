import Layout from "../../components/layout";
import RecentView from "../../views/home/recent";
import HomePagesLayout from "../../components/home-pages-layout";
import { getDeals, addDeal } from "../../apis/deal/base";
import { getProjects, addProject } from "../../apis/project/base";
import {
  addDealComparison,
  getDealComparisons,
} from "../../apis/deal-comparison/base";

export default function RecentPage(): JSX.Element {
  return (
    <Layout title="Recent">
      <HomePagesLayout>
        <RecentView
          getDeals={getDeals}
          getProjects={getProjects}
          addDeal={addDeal}
          addProject={addProject}
          getDealComparisons={getDealComparisons}
          addDealComparison={addDealComparison}
        />
      </HomePagesLayout>
    </Layout>
  );
}
