import React from "react";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import MuiButton from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import TextInput from "../text-input";
import ThemeContext from "../../utils/context/theme-context";
import { cn } from "../../utils/helpers";
import {
  IArrayDataModifyErrors,
  IArrayDataModifyPayload,
} from "../../interfaces";

type OperatorType = IArrayDataModifyPayload["operator"];

interface IProps {
  value: (number | string | null)[];
  onChange: (v: (number | string | null)[]) => void;
  loadingModify: boolean;
  showUndo: boolean;
  setShowUndo: React.Dispatch<React.SetStateAction<boolean>>;
  clearUndoTimer: () => void;
  onUpdateCurve: (payload: IArrayDataModifyPayload) => void;
  modifyErrors?: IArrayDataModifyErrors;
  setModifyErrors: React.Dispatch<
    React.SetStateAction<IArrayDataModifyErrors | undefined>
  >;
}

export default function ModifyDataAccordion({
  value,
  onChange,
  loadingModify,
  showUndo,
  setShowUndo,
  clearUndoTimer,
  onUpdateCurve,
  modifyErrors,
  setModifyErrors,
}: IProps) {
  const { darkMode } = React.useContext(ThemeContext);

  const [operator, setOperator] = React.useState<OperatorType>("MULTIPLY");
  const [modifyNumber, setModifyNumber] = React.useState<string | null>("1");
  const [previousValue, setPreviousValue] = React.useState<
    (number | string | null)[]
  >([]);

  const handleChangeModifyNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    setModifyNumber(e.target.value);
  };

  const handleChangeOperation = (operator: OperatorType) => () => {
    setOperator(operator);
  };

  const getOperatorButtonClass = React.useCallback(
    (operatorType: OperatorType) => {
      const isSelected = operator === operatorType;
      const baseStyles = "!h-[48px] !text-lg";
      const unselectedStyles = darkMode
        ? "!text-secondary !bg-[#222] !border-table-gray"
        : "!text-secondary";
      const selectedStyles = darkMode
        ? "!bg-[#111] !text-white !border-table-gray"
        : "!bg-gray-300 !text-[#000]";
      return cn(baseStyles, isSelected ? selectedStyles : unselectedStyles);
    },
    [operator, darkMode],
  );

  const handleEditCurve = async () => {
    const parsedNumber = modifyNumber ? parseFloat(modifyNumber) : null;
    // Save previous value to be able to undo
    setPreviousValue(value);
    // update the curve
    onUpdateCurve({
      input_array: value,
      number: parsedNumber,
      operator,
    });
  };

  const styles = React.useMemo(() => {
    const disabledStyles = cn("!opacity-50");
    const outlinedButtonStyles = cn(
      "!border-secondary !text-secondary !h-[48px]",
    );
    const containedButtonStyles = cn("!bg-secondary !text-white !h-[48px]");
    return { disabledStyles, outlinedButtonStyles, containedButtonStyles };
  }, [darkMode]);

  const handleUndo = () => {
    onChange(previousValue);
    setShowUndo(false);
    clearUndoTimer();
  };

  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setModifyErrors({});
  };

  const areFieldsDisabled = showUndo || loadingModify;

  return (
    <Accordion className={cn("!mb-4")}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Modify Data
      </AccordionSummary>
      <AccordionDetails>
        <Box className={cn("flex justify-between items-start gap-4 mb-8")}>
          <ButtonGroup variant="outlined" aria-label="Loading button group">
            <MuiButton
              onClick={handleChangeOperation("MULTIPLY")}
              variant="outlined"
              disabled={areFieldsDisabled || operator === "MULTIPLY"}
              classes={{
                root: getOperatorButtonClass("MULTIPLY"),
                disabled: styles.disabledStyles,
              }}
            >
              ×
            </MuiButton>
            <MuiButton
              onClick={handleChangeOperation("DIVIDE")}
              variant="outlined"
              disabled={areFieldsDisabled || operator === "DIVIDE"}
              classes={{
                root: getOperatorButtonClass("DIVIDE"),
                disabled: styles.disabledStyles,
              }}
            >
              ÷
            </MuiButton>
          </ButtonGroup>
          <Box className={cn("!max-h-4")}>
            <TextInput
              required
              isNumeric
              name="number"
              value={modifyNumber ?? ""}
              onFocus={clearErrorOnFocus}
              onChange={handleChangeModifyNumber}
              error={Boolean(modifyErrors?.number)}
              helperText={modifyErrors?.number}
              disabled={areFieldsDisabled}
            />
          </Box>
          {showUndo ? (
            <MuiButton
              onClick={handleUndo}
              variant="outlined"
              classes={{
                root: styles.outlinedButtonStyles,
              }}
            >
              Undo
            </MuiButton>
          ) : (
            <MuiButton
              variant="contained"
              disabled={loadingModify}
              onClick={handleEditCurve}
              classes={{
                root: styles.containedButtonStyles,
                disabled: styles.disabledStyles,
              }}
            >
              Apply
            </MuiButton>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
