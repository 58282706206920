import Tooltip from "@mui/material/Tooltip";
import FormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";

import useStyles from "./styles";

interface IProps extends CheckboxProps {
  label: string;
  tooltip?: string;
  formControlLabelClasses?: FormControlLabelProps["classes"];
}

export default function CheckboxInput({
  label,
  tooltip,
  formControlLabelClasses,
  ...checkboxProps
}: IProps): JSX.Element {
  const styles = useStyles();

  return (
    <Tooltip
      title={tooltip}
      placement="top-end"
      classes={{ tooltip: styles.classes.tooltip }}
    >
      <FormControlLabel
        control={<Checkbox {...checkboxProps} />}
        label={label}
        classes={formControlLabelClasses}
      />
    </Tooltip>
  );
}
