import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputTaxChartsView from "../../../../views/deal/output/tax-equity/charts";
import {
  getDealTaxEquityChart,
  getDealTaxEquityCashChart,
} from "../../../../apis/report/deal";

export default function DealOutputTaxCharts(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputTaxChartsView
          getDealTaxEquityChart={getDealTaxEquityChart}
          getDealTaxEquityCashChart={getDealTaxEquityCashChart}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
