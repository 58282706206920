import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  input: {
    "& textarea": {
      padding: 0,
      paddingBottom: theme.spacing(1),
    },
  },
}));
