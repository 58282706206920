import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectValuationSummaryView from "../../../views/project/valuation/summary";
import TabbedLayout from "../../../components/tabbed-layout";
import {
  addProjectValuation,
  editProjectValuation,
  getProjectValuation,
} from "../../../apis/project/valuation";

export default function ValuationSummaryPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Valuation">
        <ProjectValuationSummaryView
          getProjectValuation={getProjectValuation}
          addProjectValuation={addProjectValuation}
          editProjectValuation={editProjectValuation}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
