import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputDebtChartsView from "../../../../views/deal/output/debt/charts";
import {
  getDealDebtServiceChart,
  getDealDebtBalanceChart,
} from "../../../../apis/report/deal";

export default function DealOutputDebtCharts(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputDebtChartsView
          getDealDebtServiceChart={getDealDebtServiceChart}
          getDealDebtBalanceChart={getDealDebtBalanceChart}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
