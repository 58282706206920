import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  projectChartsContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    "& > *": {
      width: "49%",
    },
    "& > *:nth-of-type(1)": {
      width: "40%",
    },
    "& > *:nth-of-type(2)": {
      width: "59%",
    },
    [theme.breakpoints.down(850)]: {
      display: "block",
      "& > *": {
        width: "100% !important",
        "&:nth-of-type(1)": {
          marginBottom: theme.spacing(4),
        },
      },
    },
  },
  chartContainerWithTabs: {
    "& > *:nth-of-type(1)": {
      marginBottom: theme.spacing(2),
    },
  },
}));
