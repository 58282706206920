import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  chartContainer: {
    margin: theme.spacing(0, 2),
    width: "61%",

    [theme.breakpoints.down(900)]: {
      display: "contents",
      "& > *": {
        width: "100%",
      },
      "& > *:first-of-type": {
        marginBottom: theme.spacing(4),
      },
    },
  },

  innerChartContainer: {
    marginBottom: theme.spacing(2),
  },
  text404: {
    fontSize: "40px",
    margin: "auto",
    textAlign: "center",
    width: "fit-content",
    [theme.breakpoints.down(500)]: {
      fontSize: "30px",
    },
  },
  dealSection: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(2, 0),
    "& > *": {
      flex: "1",
    },
    [theme.breakpoints.down(900)]: {
      display: "contents",
      "& > *": {
        width: "100%",
      },
      "& > *:first-of-type": {
        marginBottom: theme.spacing(4),
      },
    },
  },
}));
