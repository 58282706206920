import React from "react";
import Box from "@mui/material/Box";
import LigtModeIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeIcon from "@mui/icons-material/DarkModeOutlined";
import IconButton from "@mui/material/IconButton";
import { Link, useNavigate } from "react-router-dom";
import {
  useAuth,
  UserButton,
  useOrganization,
  useOrganizationList,
} from "@clerk/clerk-react";

import Button from "../../components/button";
import CapeZeroLogoLight from "../../components/logos-and-icons/capezero-logo-light";
import CapeZeroLogoDark from "../../components/logos-and-icons/capezero-logo-dark";
import OrganizationSwitcher from "../../components/organization-switcher";
import ThemeContext from "../../utils/context/theme-context";
import { cn } from "../../utils/helpers";

export default function NotFound404(): JSX.Element {
  const { darkMode, setDarkMode } = React.useContext(ThemeContext);

  const { signOut } = useAuth();
  const { organization } = useOrganization();
  const { setActive: setActiveOrg, userMemberships } = useOrganizationList({
    userMemberships: true,
  });

  const navigate = useNavigate();

  const goToDashboard = () => {
    navigate("/");
  };

  React.useEffect(() => {
    if (!organization?.name && !userMemberships.isLoading) {
      if (userMemberships.data?.length && setActiveOrg) {
        setActiveOrg({
          organization: userMemberships.data[0].organization,
        });
        navigate("/");
      } else {
        signOut();
      }
    }
  }, [organization, userMemberships]);

  const CapezeroLogo = React.useMemo(() => {
    return darkMode ? <CapeZeroLogoDark /> : <CapeZeroLogoLight />;
  }, [darkMode]);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  return (
    <Box>
      <Box className={cn("flex justify-between items-center py-4 px-8")}>
        <Link to="/">{CapezeroLogo}</Link>
        <Box className={cn("flex items-center gap-4")}>
          <IconButton onClick={toggleTheme}>
            {darkMode ? <LigtModeIcon /> : <DarkModeIcon />}
          </IconButton>
          {/* Clerk action buttons */}
          <OrganizationSwitcher />
          <UserButton
            userProfileMode="modal"
            afterSignOutUrl="/sign-in"
            signInUrl="/sign-in"
            userProfileProps={{
              appearance: {
                elements: {
                  profileSectionPrimaryButton__emailAddresses: {
                    display: "none",
                  },
                  profileSectionPrimaryButton__connectedAccounts: {
                    display: "none",
                  },
                },
              },
            }}
          />
        </Box>
      </Box>
      {/* 404 message */}
      <Box className={cn("h-[90vh] flex flex-col justify-center items-center")}>
        <Box>
          <p className={cn("text-[8rem] m-auto w-fit md:text-[12rem]")}>404</p>
          <div className={cn("px-8 md:w-4/5 m-auto mb-4")}>
            <p>
              We're sorry, but the page you're looking for cannot be found. It
              seems like the content you're seeking may have been moved,
              deleted, or never existed in the first place. <br /> <br />
              <b>Here are a few things you can try:</b> <br />
              <b>Check the URL: </b> Make sure you've entered the correct web
              address. It's easy to mistype or misspell a URL. <br />
              <b>Navigate from the homepage: </b>You can go back to our homepage
              and explore from there. There might be links or menus that can
              help you find what you're looking for. <br />
              <b>Contact Us: </b> If you believe there's a problem with our
              website, or if you can't find the information you need, please
              don't hesitate to contact us at support@capezero.com. We're here
              to assist you.
            </p>
          </div>

          <Box className={cn("flex justify-center")}>
            <Button
              btnType="secondary"
              label="Go to Dashboard"
              onClick={goToDashboard}
              disableElevation
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
