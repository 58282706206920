import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";

import ReportTable from "../../../../../components/report-table";
import ViewWrapper from "../../../../../components/view-wrapper";
import { useAPI } from "../../../../../utils/hooks";
import { IOutputLessorLesseeSummary } from "../../../../../interfaces";
import { ITEMS_AND_TOTAL_TABLE_COLUMN } from "../../../../../constants";
import { useAxios } from "../../../../../components/axios-provider";
import {
  generateLessorLesseeSummaryReport,
  getOutputLesseeSummaryUrl,
} from "../../../../../utils/helpers";

interface IProps {
  getLesseeOutputSummary: (
    dealId: number,
  ) => Promise<IOutputLessorLesseeSummary>;
}

export default function DealOutputLesseeSummaryView({
  getLesseeOutputSummary,
}: IProps) {
  const { caseDealId } = useParams();
  const { urlLoadingPercentages } = useAxios();

  const [lesseeSummary, setOutputLesseeSummary] =
    React.useState<IOutputLessorLesseeSummary>();

  const {
    callAPI: getLesseeOutputSummaryCallAPI,
    loading: getLesseeOutputSummaryLoading,
    errored: getLesseeOutputSummaryFailed,
  } = useAPI((dealId: number) => getLesseeOutputSummary(Number(dealId)), {
    initialLoading: true,
  });

  React.useEffect(() => {
    getLesseeOutputSummaryCallAPI(caseDealId).then((res) => {
      res && setOutputLesseeSummary(res);
    });
  }, [caseDealId]);

  const rows = React.useMemo(
    () =>
      lesseeSummary ? generateLessorLesseeSummaryReport(lesseeSummary) : [],
    [lesseeSummary],
  );

  return (
    <ViewWrapper
      loading={getLesseeOutputSummaryLoading}
      error={getLesseeOutputSummaryFailed}
      loadingPercentage={
        urlLoadingPercentages[getOutputLesseeSummaryUrl(Number(caseDealId))]
      }
    >
      <Box>
        <Grid container>
          <Grid item xs={12} md={6} marginTop={2}>
            <Box>
              <ReportTable columns={ITEMS_AND_TOTAL_TABLE_COLUMN} rows={rows} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ViewWrapper>
  );
}
