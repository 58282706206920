import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  emptyContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "calc(100vh - 276px)",
    textAlign: "center",
  },
  createBtn: {
    height: 60,
    width: 280,
    fontSize: 16,
  },
  createInfo: {
    display: "block",
    marginTop: 20,
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(1, 2),
    borderRadius: 20,
    color: "#666",
  },
  content: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2),
    width: "100%",
    flex: 1,
    [theme.breakpoints.down(650)]: {
      flexDirection: "column",
    },
    "& > *": {
      width: "100%",
    },
  },
}));
