import { AxiosHelper, replaceParams } from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import {
  IProject,
  IProjectDetails,
  IProjectDetailsFat,
  IGetProjectsParams,
  ServerPaginatedResponse,
  IProjectForm,
  IAddUpdateProjectResponse,
  IDuplicateProjectForm,
  IDuplicateProjectResponse,
} from "../../interfaces";

export const addProject = async (form: IProjectForm) => {
  return await AxiosHelper.post<IProjectForm, IAddUpdateProjectResponse>(
    PROJECT_API_ROUTES.base.create,
    {
      ...form,
    },
  );
};

export const getProjectsOfDeal = async (dealId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.getProjectOfDeal, {
    dealId,
  });
  return await AxiosHelper.get<IProject[]>(url);
};

export const getProjects = async (params: IGetProjectsParams) => {
  const {
    limit,
    state,
    sub_stage,
    type,
    cod,
    tax_credit__credit_type,
    power_market,
  } = params;

  const queryParams = {
    limit,
    state__in: state,
    sub_stage__in: sub_stage,
    type__in: type,
    cod__in: cod,
    tax_credit__credit_type__in: tax_credit__credit_type,
    power_market__in: power_market,
  };

  return await AxiosHelper.get<ServerPaginatedResponse<IProject[]>>(
    PROJECT_API_ROUTES.base.get,
    {
      params: queryParams,
    },
  );
};

export const getProjectDetails = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.getProjectDetails, {
    projectId,
  });
  return await AxiosHelper.get<IProjectDetails>(url);
};

export const updateProject = async (projectId: number, form: IProjectForm) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.update, { projectId });
  return await AxiosHelper.patch<IProjectForm, IAddUpdateProjectResponse>(
    url,
    form,
  );
};

export const duplicateProject = async (
  projectId: number,
  form: IDuplicateProjectForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.duplicate, { projectId });
  return await AxiosHelper.post<
    IDuplicateProjectForm,
    IDuplicateProjectResponse
  >(url, form);
};

export const getProjectDetailsFat = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.base.getDetailsFat, {
    projectId,
  });
  return await AxiosHelper.get<IProjectDetailsFat>(url);
};
