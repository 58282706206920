import Box from "@mui/material/Box";
import Modal from "../modal";
import useStyles from "./styles";
import Select from "../select-input";
import { SelectChangeEvent } from "@mui/material";

import { DEAL_OTHER_SIZING_OPTIONS } from "../../constants";
import {
  SetStateAction,
  IUpdateDealOtherSizingForm,
  IUpdateDealOtherSizingFormErrors,
  IDealOtherSizing,
} from "../../interfaces";

interface IProps {
  open: boolean;
  loading: boolean;
  form: IUpdateDealOtherSizingForm;
  formErrors?: IUpdateDealOtherSizingFormErrors;
  hide?: string[];
  setForm: SetStateAction<IUpdateDealOtherSizingForm>;
  setFormErrors: SetStateAction<IUpdateDealOtherSizingFormErrors | undefined>;
  onClose: () => void;
  onUpdate: (
    form: IUpdateDealOtherSizingForm,
  ) => Promise<IDealOtherSizing | undefined>;
}

export default function UpdateDealOtherSizingFormModal({
  open,
  loading,
  form,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onUpdate,
}: IProps): JSX.Element {
  const styles = useStyles();

  const clearSelectErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleChange = (e: SelectChangeEvent<unknown>, field: string) => {
    setForm((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleUpdateDealSizing = async () => {
    const deal = await onUpdate(form);
    deal && handleOnClose();
  };

  const handleResetForm = () => {
    setForm({
      expense_allocation_method: "",
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading="Update Sizing"
      onClose={handleOnClose}
      onConfirm={handleUpdateDealSizing}
      classes={{ paper: styles.classes.modal }}
      resetForm={handleResetForm}
    >
      <Box>
        <Select
          required
          label="Expense Allocation Method"
          name="expense_allocation_method"
          selected={form.expense_allocation_method ?? ""}
          items={DEAL_OTHER_SIZING_OPTIONS}
          onFocus={() => clearSelectErrorOnFocus("expense_allocation_method")}
          onChange={(e) => handleChange(e, "expense_allocation_method")}
          error={Boolean(formErrors?.expense_allocation_method)}
          helperText={formErrors?.expense_allocation_method}
          disabled={loading}
          tooltip="<b>Contracted</b> = Treat all expenses as contracted. \n<b>Weighted by Revenue</b> = Spread expenses prorata by their cash allocation category (i.e. Contracted, Uncontracted, Special).\n<b>Excess as Uncontracted</b> = Treat expenses as contracted to offset contracted revenue. Treat any excess expenses as uncontracted."
          fullWidth={false}
        />
      </Box>
    </Modal>
  );
}
