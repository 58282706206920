import { IAddDealForm, IUpdateDealForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_STAGES {
  PL = "Pre-Launch",
  AB = "Accepting Bids",
  EB = "Evaluating Bids",
  TS = "Term Sheet",
  DOC = "Documentation",
  CL = "Closing",
  PCL = "Post-Closing",
}

export const DEAL_STAGES_OPTIONS = enumToOptions(DEAL_STAGES);

export enum DEAL_STRUCTURE_TYPE {
  TEP = "Tax Equity Partnership",
  CEP = "Cash Equity Partnership",
  EO = "Equity Only",
  SL = "Sale-Leaseback (Beta)",
}

export const disabledDealStructureTypes =
  process.env.REACT_APP_NODE_ENV === "dev" ||
  process.env.REACT_APP_NODE_ENV === "staging" ||
  process.env.REACT_APP_NODE_ENV === "test"
    ? []
    : []; // : ["SL"]; // Sale-Leaseback enabled as of 2024-07-10

export const DEAL_STRUCTURE_TYPE_OPTIONS = enumToOptions(
  DEAL_STRUCTURE_TYPE,
  disabledDealStructureTypes,
);

export enum DEAL_TAX_CREDIT_TYPE {
  NONE = "None",
  PTC = "PTC",
  ITC = "ITC",
  MIX = "PTC/ITC",
}

export enum DEAL_TAX_CREDIT_STRUCTURE_TYPE {
  REG = "Regular",
  TR = "Transfer",
  DP = "Direct Pay",
}

export const DEAL_TAX_CREDIT_STRUCTURE_TYPE_OPTIONS = enumToOptions(
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
);

export enum DEAL_DEVELOPER_HORIZON_TYPE {
  DSE = "Develop & Sell-Exit",
  DSD = "Develop & Sell-Down",
  DO = "Develop & Operate",
}

export const DEAL_DEVELOPER_HORIZON_TYPE_OPTIONS = enumToOptions(
  DEAL_DEVELOPER_HORIZON_TYPE,
  ["DSE", "DSD"],
);

export const DEAL_FORM_DEFAULT_STATE: IAddDealForm = {
  name: "",
  stage: "",
  developer_horizon: "",
  structure: "",
  tax_credit_structure: "",
  project_ids: [],
  status: "ACT",
};

export const ADD_DEAL_FORM_DEFAULT_VAULES: IAddDealForm = {
  name: "",
  stage: "AB",
  developer_horizon: "DO",
  structure: "TEP",
  tax_credit_structure: "REG",
  project_ids: [],
  status: "ACT",
};

export const UPDATE_DEAL_FORM_DEFAULT_STATE: IUpdateDealForm = {
  name: "",
  stage: "",
  tax_credit_structure: "",
  project_ids: [],
  status: "ACT",
};

export enum DEAL_STATUS {
  ACT = "Active",
  DRFT = "Draft",
}

export const DEAL_STATUS_OPTIONS = enumToOptions(DEAL_STATUS);
