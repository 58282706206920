import DealPagesLayout from "../../../components/deal-pages-layout";
import TabbedLayout from "../../../components/tabbed-layout";
import DealSizingSponsorEquityView from "../../../views/deal/sizing/sponsor-equity";
import {
  getDealSponsorEquity,
  updateDealSponsorEquity,
} from "../../../apis/deal/sponsor-equity";

export default function DealSizingSponsorEquityPage() {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealSizingSponsorEquityView
          getDealSponsorEquity={getDealSponsorEquity}
          updateDealSponsorEquity={updateDealSponsorEquity}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
