import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    headerSection: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1.5),
    },
    dealDetailsWrapper: {
      width: "100%",
      marginTop: theme.spacing(-2),
    },
    dealDetailsContainer: {
      minHeight: 600,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      "& > div": {
        width: "100%",
      },
    },
    projectDetailsContainer: {
      minHeight: 900,
      justifyContent: "center",
    },
    container: {
      border: `1px solid ${selectedTheme.color.aboveSurface}`,
      borderRadius: theme.spacing(0.6),
      margin: theme.spacing(2, 0),
      padding: theme.spacing(1.5),
    },
    dealHeadingContainer: {
      display: "flex",
      alignItems: "center",
      color: selectedTheme.color.secondary,
      "& p": {
        marginLeft: theme.spacing(1),
      },
    },
    blocksContainer: {
      display: "grid",
      gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
      gap: theme.spacing(2),
    },
    verticalAlignedBlocks: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: 12,
      height: "auto",
    },
    projectTableDetailsContainer: {
      display: "flex",
      gap: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
    },
    tableBlockContainer: {
      padding: theme.spacing(1),
      width: "100%",
    },
    tableRow: {
      "& th, & td": {
        padding: theme.spacing(1),
      },
      "& th": {
        fontWeight: 600,
      },
    },
    muiButton: {
      fontSize: 14,
      color: selectedTheme.color.secondary,
    },
    seeMoreButton: {
      display: "flex",
      margin: "auto",
      fontSize: 14,
      color: selectedTheme.color.secondary,
      textTransform: "none",
    },
    actionBtnOnCard: {
      width: "100%",
      display: "flex",
      borderRadius: 8,
      textTransform: "none",
      marginTop: theme.spacing(2),
    },
    addBtnOnCard: {
      border: `1px solid ${selectedTheme.color.secondary}`,
      color: selectedTheme.color.secondary,
    },
    deleteBtnOnCard: {
      border: `1px solid ${selectedTheme.color.danger}`,
      color: selectedTheme.color.danger,
    },
    projectGrid: {
      display: "grid",
      gridTemplateColumns: "5fr 7fr",
      gap: theme.spacing(2),
    },
  };
});
