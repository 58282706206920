import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputDebtView from "../../../../views/deal/output/debt";

export default function DealOutputDebtPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputDebtView />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
