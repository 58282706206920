import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 250px)",
  },
  totalRow: {
    "& td": {
      fontWeight: 600,
    },
  },
  boldRow: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  iconButton: {
    padding: 0,
    marginLeft: theme.spacing(1),
    maxHeight: 14,
  },
}));
