import { IDealSaleLeasebackSizingForm } from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_SLB_LEASE_TYPES {
  RCRS = "Financing (Recourse)",
  NRCRS = "Cash Flow (Non-Recourse)",
}

export const DEAL_SLB_LEASE_TYPES_OPTIONS = enumToOptions(
  DEAL_SLB_LEASE_TYPES,
  ["RCRS"],
);

export enum DEAL_SLB_SOLVE_FORS {
  MINPPY = "Minimum Prepayment",
  MAXYLD = "Maximum Yield",
  MAXRTCV = "Maximum Rent Coverage",
  MINRESD = "Minimum Residual Value",
}

export const DEAL_SLB_SOLVE_FORS_OPTIONS = enumToOptions(DEAL_SLB_SOLVE_FORS, [
  "MAXYLD",
  "MAXRTCV",
  "MINRESD",
]);

export enum DEAL_SLB_RESERVE_MODE_TYPES {
  PCTPUR = "% of Purchase Price",
  SPEC = "Specified",
  AVGRT = "Average Rent",
  HGHRT = "Maximum Rent",
}

export const DEAL_SLB_RESERVE_MODE_TYPES_OPTIONS = enumToOptions(
  DEAL_SLB_RESERVE_MODE_TYPES,
);

export enum DEAL_SLB_RESIDUAL_SIZING_MODE_TYPES {
  SPECD = "Specified",
  SPECP = "% of Purchase Price",
  PVALL = "All Cash",
  PVCONT = "Contracted Cash",
}

export const DEAL_SLB_RESIDUAL_SIZING_MODE_TYPES_OPTIONS = enumToOptions(
  DEAL_SLB_RESIDUAL_SIZING_MODE_TYPES,
);

export enum DEAL_SLB_PREPAY_INPUT_TYPES {
  DOLLAR = "Dollar Amount",
  PCT = "Percentage of Purchase Price",
}

export const DEAL_SLB_PREPAY_INPUT_TYPES_OPTIONS = enumToOptions(
  DEAL_SLB_PREPAY_INPUT_TYPES,
);

export const DEAL_SALES_LEASEBACK_FORM_DEFAULT_STATE: IDealSaleLeasebackSizingForm =
  {
    consecutive_periods_of_highest_rent: null,
    coverage_ratio_cnt: null,
    coverage_ratio_unc: null,
    coverage_ratio_spc: null,
    ebo_term: null,
    ebo_yield_premium: null,
    lease_start_date: null,
    lease_term: null,
    loan_rate: null,
    months_of_reserve: null,
    prepayment_limit: null,
    purchase_amount: null,
    reserve_amount: null,
    reserve_mode: null,
    residual_pv_rate: null,
    residual_sizing_mode: null,
    residual_value: null,
    lease_type: null,
    solve_for: null,
    target_yield: null,
    prepayment_input_mode: null,
  };
