import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import Modal from "../modal";
import ProductPlanCards from "../product-plan-cards";
import { useAppSelector } from "../../utils/hooks";
import { updateShowUsageLimitHitModalAction } from "../../utils/redux/slices";

export default function ProductPlansModal(): JSX.Element {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { showUsageLimitHitModal } = useAppSelector((s) => s.org);

  const handleOnClose = () => {
    dispatch(updateShowUsageLimitHitModalAction(false));
  };

  const goToBillingPage = async () => {
    navigate("/configuration/organization/billing-and-usage");
    dispatch(updateShowUsageLimitHitModalAction(false));
  };

  return (
    <Modal
      open={showUsageLimitHitModal}
      onClose={handleOnClose}
      heading="Subscribe"
      onConfirm={goToBillingPage}
      textContent="You have hit the limits of your plan, please contact us to subscribe to upgrade."
      cancelBtnLabel="Close"
      confirmBtnLabel="Billing and Usage"
      maxWidth="lg"
    >
      <Box style={{ marginTop: 8 }}>
        <ProductPlanCards />
      </Box>
    </Modal>
  );
}
