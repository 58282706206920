import TabbedLayout from "../../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../../components/project-pages-layout";
import ProjectRevenueLayout from "../../../../components/project-revenue-layout";
import ProjectEBITDAView from "../../../../views/project/pro-forma/revenue/ebitda";
import { getProjectTiming } from "../../../../apis/project/timing";
import {
  getProjectScheduleOverrides,
  updateProjectScheduleOverride,
} from "../../../../apis/project/schedule-overrides";

export default function ProjectEbitdaPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectRevenueLayout>
          <ProjectEBITDAView
            getProjectTiming={getProjectTiming}
            getProjectEBITDA={getProjectScheduleOverrides}
            updateProjectEBITDA={updateProjectScheduleOverride}
          />
        </ProjectRevenueLayout>
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
