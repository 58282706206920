import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import Typography from "@mui/material/Typography";
import { SelectChangeEvent } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";

import useStyles from "./styles";
import useCommonStyles from "../common-styles";
import ViewWrapper from "../../../../components/view-wrapper";
import ReportTable from "../../../../components/report-table";
import SelectInput from "../../../../components/select-input";
import { downloadDealCostsReport } from "../../../../apis/report/deal";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import {
  DEAL_COST_UNIT,
  DEAL_COST_UNIT_OPTIONS,
  DEAL_CREDIT_SUPPORT_UNIT,
  DEAL_CREDIT_SUPPORT_UNIT_OPTIONS,
  PROJECT_COST_REPORT_TYPE,
} from "../../../../constants";
import {
  IDealCreditSupportSummary,
  IDealProjectCostComponent,
  IProjectCostSchduleResponse,
} from "../../../../interfaces";
import {
  generateDealCostsRows,
  generateDealCostsHeaders,
  cn,
  generateProjectCostScheduleRows,
  generateDealCreditSupportHeaders,
  generateDealCreditSupportRows,
  generateProjectCostScheduleColumns,
} from "../../../../utils/helpers";

interface IProps {
  getDealCost: (
    dealId: number,
    cost_input_type: string,
  ) => Promise<IDealProjectCostComponent>;
  getCreditSupportSummary: (
    dealId: number,
    cost_input_type: string,
  ) => Promise<IDealCreditSupportSummary>;
  getDealCostsSchedule: (
    projectId: number,
    type: keyof typeof PROJECT_COST_REPORT_TYPE,
  ) => Promise<IProjectCostSchduleResponse>;
}
export default function DealCostView({
  getDealCost,
  getCreditSupportSummary,
  getDealCostsSchedule,
}: IProps): JSX.Element {
  const styles = useStyles();
  const commonStyles = useCommonStyles({ columnToHide: 2 }); // hide the second column

  const navigate = useNavigate();

  const { caseDealId, dealId } = useParams();

  const { currentDeal } = useAppSelector((s) => s.deal);

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [projectCostSchedule, setProjectCostSchedule] =
    React.useState<IProjectCostSchduleResponse>();

  const [dealCostUnit, setDealCostUnit] =
    React.useState<keyof typeof DEAL_COST_UNIT>("DL");
  const [dealCreditUnit, setDealCreditUnit] =
    React.useState<keyof typeof DEAL_CREDIT_SUPPORT_UNIT>("DL");
  const onShownAsChange = (event: SelectChangeEvent<unknown>) => {
    getDealCostCallAPI(Number(caseDealId), event.target.value);
    setDealCostUnit(event.target.value as keyof typeof DEAL_COST_UNIT);
  };

  const onShownAsCreditSupportChange = (event: SelectChangeEvent<unknown>) => {
    getCreditSupportSummaryCallAPI(Number(caseDealId), event.target.value);
    setDealCreditUnit(
      event.target.value as keyof typeof DEAL_CREDIT_SUPPORT_UNIT,
    );
  };

  React.useEffect(() => {
    getDealCostCallAPI(Number(caseDealId), dealCostUnit);
    getCreditSupportSummaryCallAPI(Number(caseDealId), dealCostUnit);
    getProjectCostScheduleCallAPI(caseDealId, "summary").then((res) => {
      res && setProjectCostSchedule(res);
    });
  }, [caseDealId]);

  const {
    callAPI: getProjectCostScheduleCallAPI,
    errored: getCostScheduleFailed,
    loading: loadingCostSchedule,
  } = useAPI(
    (dealId: number, type: keyof typeof PROJECT_COST_REPORT_TYPE) =>
      getDealCostsSchedule(dealId, type),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: getCreditSupportSummaryCallAPI,
    loading: getCreditSupportSummaryLoading,
    errored: getCreditSupportSummaryFailed,
    response: creditSupportSummary,
  } = useAPI(
    (id, credit_support_input_type) =>
      getCreditSupportSummary(id, credit_support_input_type),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: getDealCostCallAPI,
    loading: getDealCostLoading,
    errored: getDealCostFailed,
    response: deal_cost,
  } = useAPI((id, cost_input_type) => getDealCost(id, cost_input_type), {
    initialLoading: true,
  });
  const headers = React.useMemo(() => {
    if (deal_cost) {
      return generateDealCostsHeaders(deal_cost);
    }
    return [];
  }, [deal_cost]);

  const creditSupportHeaders = React.useMemo(() => {
    if (creditSupportSummary) {
      return generateDealCreditSupportHeaders(creditSupportSummary);
    }
    return [];
  }, [creditSupportSummary]);

  const rows = React.useMemo(() => {
    if (deal_cost) {
      return generateDealCostsRows(
        deal_cost,
        dealCostUnit as keyof typeof DEAL_COST_UNIT,
      );
    }
    return [];
  }, [deal_cost, dealCostUnit]);

  const creditSupportRows = React.useMemo(() => {
    if (creditSupportSummary) {
      return generateDealCreditSupportRows(
        creditSupportSummary,
        dealCreditUnit as keyof typeof DEAL_CREDIT_SUPPORT_UNIT,
      );
    }
    return [];
  }, [creditSupportSummary, dealCreditUnit]);

  const scheduleRows = React.useMemo(() => {
    if (projectCostSchedule) {
      return generateProjectCostScheduleRows(projectCostSchedule?.data);
    }
    return [];
  }, [projectCostSchedule]);

  const downloadDealCostReportHandle = async () => {
    setIsDownloading(true);
    await downloadDealCostsReport(Number(caseDealId)).catch(() => null);
    setIsDownloading(false);
  };

  return (
    <ViewWrapper loading={getDealCostLoading} error={getDealCostFailed}>
      <Paper classes={{ root: cn("p-4 flex flex-col gap-4") }}>
        <Typography variant="h6" fontWeight={"bold"}>
          Project Cost Summary
        </Typography>
        <SelectInput
          label="Shown as"
          items={DEAL_COST_UNIT_OPTIONS}
          selected={dealCostUnit}
          onChange={onShownAsChange}
          className={cn("w-64 h-9")}
        />
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer classes={{ root: styles.classes.tableContainer }}>
            <Table
              stickyHeader
              classes={{ root: commonStyles.classes.tableWithHideableColumn }}
              data-hide-total={
                (currentDeal?.projects?.length ?? 0) <= 1 // set this attribute true to hide the total column when only one project is present
              }
            >
              <TableHead className={styles.classes.header}>
                <TableRow>
                  {headers.map((header, idx) => {
                    if (idx > 1) {
                      return (
                        <TableCell key={idx} align="right">
                          <Link
                            to={`/project/${header.id}/cost`}
                            target="_blank"
                          >
                            {header.label}
                          </Link>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={idx}
                          align={
                            header.align
                              ? (header.align as
                                  | "center"
                                  | "left"
                                  | "right"
                                  | "inherit"
                                  | "justify")
                              : "right"
                          }
                        >
                          {header.label}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    className={
                      row.some((cell) => cell?.is_bold)
                        ? styles.classes.boldRow
                        : ""
                    }
                  >
                    {row.map((cell, idx) => (
                      <TableCell
                        key={idx}
                        align={
                          cell?.align
                            ? (cell.align as
                                | "center"
                                | "left"
                                | "right"
                                | "inherit"
                                | "justify")
                            : "right"
                        }
                      >
                        {cell?.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Paper>
      <Paper classes={{ root: cn("p-4 mt-4") }}>
        <SelectInput
          label="Shown as"
          items={DEAL_CREDIT_SUPPORT_UNIT_OPTIONS}
          selected={dealCreditUnit}
          onChange={onShownAsCreditSupportChange}
          className={cn("w-64 h-9")}
        />
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <Box className={cn("p-2")}>
            <Typography variant="h6" id="deal-cost" fontWeight="bold">
              Credit Support Summary
            </Typography>
          </Box>

          <TableContainer classes={{ root: styles.classes.tableContainer }}>
            <Table
              stickyHeader
              classes={{ root: commonStyles.classes.tableWithHideableColumn }}
              data-hide-total={
                (currentDeal?.projects?.length ?? 0) <= 1 // set this attribute true to hide the total column when only one project is present
              }
            >
              <TableHead className={styles.classes.header}>
                <TableRow>
                  {creditSupportHeaders.map((header, idx) => {
                    if (idx > 1) {
                      return (
                        <TableCell key={idx} align="right">
                          <Link
                            to={`/project/${header.id}/credit-support`}
                            target="_blank"
                          >
                            {header.label}
                          </Link>
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          key={idx}
                          align={
                            header.align
                              ? (header.align as
                                  | "center"
                                  | "left"
                                  | "right"
                                  | "inherit"
                                  | "justify")
                              : "right"
                          }
                        >
                          {header.label}
                        </TableCell>
                      );
                    }
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {creditSupportRows.map((row, index) => (
                  <TableRow
                    key={index}
                    className={
                      row.some((cell) => cell?.is_bold)
                        ? styles.classes.boldRow
                        : ""
                    }
                  >
                    {row.map((cell, idx) => (
                      <TableCell
                        key={idx}
                        align={
                          cell?.align
                            ? (cell.align as
                                | "center"
                                | "left"
                                | "right"
                                | "inherit"
                                | "justify")
                            : "right"
                        }
                      >
                        {cell?.value}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Paper>

      <ViewWrapper loading={loadingCostSchedule} error={getCostScheduleFailed}>
        <Paper classes={{ root: cn("mt-4 p-2") }}>
          <Box className={cn("flex justify-between items-center mb-2")}>
            <Typography variant="h6" fontWeight="bold">
              Cost Schedule
            </Typography>
            <IconButton
              disabled={isDownloading}
              onClick={downloadDealCostReportHandle}
            >
              <DownloadIcon />
            </IconButton>
          </Box>
          <ReportTable
            columns={generateProjectCostScheduleColumns(
              projectCostSchedule?.data,
            )}
            rows={scheduleRows}
            fullWidth
            stickyTotal
            boldTotal
          />
        </Paper>
      </ViewWrapper>
    </ViewWrapper>
  );
}
