import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputPartnershipSplitsView from "../../../../views/deal/output/partnership/splits";
import {
  getDealOtherIncomeChart,
  getDealPartnershipDepreciationChart,
  getDealNetOperatingIncomeChart,
  getNonTransferCashChart,
  getTransferCashChart,
} from "../../../../apis/report/deal";

export default function DealOutputPartnershipSplits(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputPartnershipSplitsView
          getDealPartnershipDepreciationChart={
            getDealPartnershipDepreciationChart
          }
          getDealNetOperatingIncomeChart={getDealNetOperatingIncomeChart}
          getDealOtherIncomeChart={getDealOtherIncomeChart}
          getNonTransferCashReportChart={getNonTransferCashChart}
          getTransferCashReportChart={getTransferCashChart}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
