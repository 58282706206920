import React from "react";
import { Chart as ChartJS, BarElement, BarController } from "chart.js";

import { useScreenWidth } from "../../../../../utils/hooks";
import { ITransferReportSummaryChart } from "../../../../../interfaces";
import { chartTooltipArrayOfNumbersToNumber } from "../../../../../utils/helpers";

ChartJS.register(BarElement, BarController);

interface IProps {
  transferChart?: ITransferReportSummaryChart;
}

export default function Chart({ transferChart }: IProps): JSX.Element {
  const transferChartRef = React.useRef<HTMLCanvasElement | null>(null);
  const width = useScreenWidth();

  React.useEffect(() => {
    const transferChart2DRef = transferChartRef?.current?.getContext("2d");

    const chartData = [
      {
        label: "Cash Distributions",
        backgroundColor: "#00B969",
        value: 0,
        percent: 0,
      },
      {
        label: "Tax Credits",
        backgroundColor: "#5335FA",
        value: transferChart?.tax_credits.data,
        percent: transferChart?.tax_credits.percent,
      },
      {
        label: "Depreciation Benefits",
        backgroundColor: "#7E68FB",
        value: 0,
        percent: 0,
      },
      {
        label: "Tax Detriments",
        backgroundColor: "#9481FC",
        value: 0,
        percent: 0,
      },
      {
        label: "Total Value",
        backgroundColor: "#9F9F9F",
        value: transferChart?.tax_efficient_value.data,
        percent: transferChart?.tax_efficient_value.percent,
      },
    ];

    const transferChartObj =
      transferChart2DRef &&
      new ChartJS(transferChart2DRef as CanvasRenderingContext2D, {
        type: "bar",
        data: {
          labels: chartData.map((cD) => cD.label),
          datasets: [
            {
              data: chartData.map((cD) => cD.value),
              backgroundColor: chartData.map((cD) => cD.backgroundColor),
            },
          ],
        },
        options: {
          scales: {
            x: {
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y: {
              title: {
                display: true,
                text: "Amount ($)",
              },
            },
          },
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  return (
                    chartTooltipArrayOfNumbersToNumber(ctx) +
                    " / " +
                    chartData[ctx.dataIndex].percent +
                    "%"
                  );
                },
              },
            },
          },
        },
      });

    return () => {
      transferChartObj?.destroy();
    };
  }, [width]);

  return <canvas id="transferChart" ref={transferChartRef} />;
}
