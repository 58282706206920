import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

interface IProps {
  path: string;
  text: string;
}

export default function TextAsLink({ path, text }: IProps): JSX.Element {
  const navigate = useNavigate();

  return (
    <Typography
      onClick={() => navigate(path)}
      component="span"
      variant="body2"
      style={{ cursor: "pointer" }}
    >
      {text}
    </Typography>
  );
}
