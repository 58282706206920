import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    header: {
      display: "flex",
      justifyContent: "space-between",
    },
    content: {
      marginTop: theme.spacing(2),
      display: "flex",
      width: "60%",
      flex: 1,
      [theme.breakpoints.down(650)]: {
        width: "100%",
      },
    },
    date: {
      display: "inline-block",
      width: 80,
      textAlign: "right",
      marginRight: theme.spacing(2),
    },
    iconButton: {
      backgroundColor: selectedTheme.color.aboveSurface,
      ":hover": {
        backgroundColor: selectedTheme.color.surface,
      },
    },
    buttonsContainer: {
      display: "flex",
      gap: theme.spacing(2),
    },
    showMoreCurveBtn: {
      float: "right",
      textTransform: "none",
      color: selectedTheme.color.secondary,
      paddingRight: 0,
    },
  };
});
