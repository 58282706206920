import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputSponsorEquityAccountingView from "../../../../views/deal/output/sponsor-equity/accounting";
import { getDealReporting } from "../../../../apis/report/deal";

export default function DealOutputSponsorEquityAccounting(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputSponsorEquityAccountingView
          getDealReporting={getDealReporting}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
