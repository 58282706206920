import { makeStyles } from "tss-react/mui";

export default makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down(750)]: {
      display: "block",
      "& > *": {
        width: "100%",
      },
    },
  },
  header: {
    ".MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  tableContainer: {
    maxHeight: "calc(100vh - 220px)",
  },
  dataRow: {
    cursor: "pointer",
  },
  paginationRow: {
    backgroundColor: "#F6F6F6",
  },
  searchAndFilterContainer: {
    display: "flex",
    "& > *:first-of-type": {
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.down(750)]: {
      display: "block",
      marginBottom: theme.spacing(0),
      "& > *:first-of-type": {
        marginBottom: theme.spacing(2),
      },
      "& > *:last-of-type": {
        width: "100%",
      },
    },
  },
}));
