import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputPartnershipTaxView from "../../../../views/deal/output/partnership/tax";
import { getDealReporting } from "../../../../apis/report/deal";

export default function DealOutputPartnershipTax(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputPartnershipTaxView getDealReporting={getDealReporting} />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
