import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Protect } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import useStyles from "./styles";
import ViewWrapper from "../../../components/view-wrapper";
import Searchbar from "../../../components/search-bar";
import Button from "../../../components/button";
import PopoverMenu from "../../../components/popover-menu";
import AddDealComparisonFormModal from "../../../components/add-deal-comparison-form-modal";
import UpdateDealComparisonFormModal from "../../../components/update-deal-comparison-form-modal";
import StickyTableCell from "../../../components/sticky-table-cell";
import { useAPI, useAppSelector } from "../../../utils/hooks";
import { setDeleteModalPropsAction } from "../../../utils/redux/slices";
import { sortArrayOfObjects, trimString } from "../../../utils/helpers";
import {
  USER_PERMISSIONS,
  ADD_DEAL_COMPARISON_FORM_DEFAULT_STATE,
  UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE,
} from "../../../constants";
import {
  IDeal,
  IGetDealsParams,
  ISelectOption,
  ITableSort,
  ServerPaginatedResponse,
  IAddDealComparisonForm,
  IAddDealComparisonFormErrors,
  IDealComparison,
  IUpdateDealComparisonForm,
} from "../../../interfaces";

const columns = [
  { id: "name", label: "Deal Comparison Name", minWidth: 170, align: "left" },
  {
    id: "benchmark_deal.name",
    label: "Benchmark Deal Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "deal_count",
    label: "Number of Deals",
    minWidth: 50,
    align: "left",
  },
  { id: "modified", label: "Last Modified", minWidth: 50, align: "left" },
  { id: "action", label: "Action", minWidth: 50, align: "right" },
];

interface IProps {
  getDealComparisons: () => Promise<ServerPaginatedResponse<IDealComparison[]>>;
  addDealComparison: (form: IAddDealComparisonForm) => Promise<IDealComparison>;
  getDeals: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IDeal[]>>;
  updateDealComparison: (
    id: number,
    form: IUpdateDealComparisonForm,
  ) => Promise<IDealComparison>;
  deleteDealComparison: (id: number) => Promise<boolean>;
  getDealComparison: (id: number) => Promise<IDealComparison>;
}

export default function DealComparisonListView({
  addDealComparison,
  getDeals,
  getDealComparisons,
  updateDealComparison,
  deleteDealComparison,
  getDealComparison,
}: IProps): JSX.Element {
  const styles = useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);

  const [searchString, setSearchString] = React.useState("");
  const [addDealComparisonForm, setAddDealComparisonForm] =
    React.useState<IAddDealComparisonForm>(
      ADD_DEAL_COMPARISON_FORM_DEFAULT_STATE,
    );
  const [dealOptions, setDealOptions] = React.useState<ISelectOption[]>([]);
  const [dealComparisons, setDealComparisons] = React.useState<
    IDealComparison[]
  >([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [addDealComparisonModalOpen, setAddDealComparisonModalOpen] =
    React.useState<boolean>(false);
  const [openEditFormModal, setOpenEditFormModal] =
    React.useState<boolean>(false);
  const [updateDealComparisonForm, setUpdateDealComparisonForm] =
    React.useState<IUpdateDealComparisonForm>(
      UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE,
    );

  React.useEffect(() => {
    getDealComparisonsAPI().then((res) => {
      res && setDealComparisons(res?.results);
    });
  }, []);

  const {
    callAPI: getDealComparisonsAPI,
    errored: getDealComparisonsFailed,
    loading: loadingGetDealComparisons,
  } = useAPI(() => getDealComparisons(), { initialLoading: true });

  const { callAPI: deleteDealComparisonCallAPI } = useAPI(
    (id: number) => deleteDealComparison(id),
    { setConfirmModalLoading: true },
  );
  const { callAPI: getDealsCallAPI } = useAPI(() => getDeals({}));

  const {
    callAPI: updateDealComparisonCallAPI,
    loading: loadingUpdateDealComparison,
    fieldErrors: formErrors,
    setFieldErrors: setFormErrors,
  } = useAPI<IDealComparison, IAddDealComparisonFormErrors>(
    (id: number, form: IUpdateDealComparisonForm) => {
      const updatedForm = {
        name: form.name,
        deal_ids: [form.benchmark_deal, ...form.deal_ids],
      };
      return updateDealComparison(id, updatedForm as IUpdateDealComparisonForm);
    },
    { initialLoading: false },
  );

  const { callAPI: getDealComparisonCallAPI } = useAPI(
    (id: number) => getDealComparison(id),
    { initialLoading: true },
  );

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const filteredRows = React.useMemo(() => {
    return dealComparisons.filter((d) =>
      d.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [dealComparisons, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToDealComparison = (id: number) => {
    if (ctrlPressed) {
      window.open(`/analysis/deal-comparison/${id}`);
    } else {
      navigate(`/analysis/deal-comparison/${id}`);
    }
  };

  const handleOpenAddDealComparisonModal = async () => {
    await handleGetDeals();
    setAddDealComparisonModalOpen(true);
  };

  const handleGetDeals = async () => {
    const deals = await getDeals({}).catch(() => null);
    if (deals) {
      setDealOptions(
        deals?.results?.map((d: IDeal) => ({
          label: d?.name,
          value: String(d?.id),
        })),
      );
    }
  };

  const handleCloseAddDealComparisonModal = () => {
    setAddDealComparisonModalOpen(false);
  };

  const {
    callAPI: addDealComparisonCallAPI,
    fieldErrors: addDealComparisonFormErrors,
    setFieldErrors: setAddDealComparisonFormErrors,
    loading: addDealComparisonLoading,
  } = useAPI<IDealComparison, IAddDealComparisonFormErrors>(
    (form: IAddDealComparisonForm) => {
      const updatedForm = {
        name: form.name,
        deal_ids: [form.benchmark_deal, ...form.deal_ids],
      };
      return addDealComparison(updatedForm as IAddDealComparisonForm);
    },
  );

  const handleAddDealComparison = async (form: IAddDealComparisonForm) => {
    const deal = await addDealComparisonCallAPI(form);
    deal && goToDealComparison(deal?.id);
    return deal;
  };

  const handleDeleteDealComparison = async (id: number) => {
    const deleted = await deleteDealComparisonCallAPI(id);

    // remove the comparison from the table
    if (deleted) {
      setDealComparisons((prevState) => {
        return prevState?.filter((c) => c.id !== id);
      });
    }
  };

  const handleOnDelete = (id: number | undefined) => {
    if (id === undefined) {
      return;
    }
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Deal Comparison",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteDealComparison(id),
      }),
    );
  };

  const handleEditDealComparison = async (id: number) => {
    await handleGetDeals();
    const dealComparison = await getDealComparisonCallAPI(id);
    if (dealComparison) {
      const { name, benchmark_deal, deals_to_compare } = dealComparison;
      setUpdateDealComparisonForm({
        name,
        benchmark_deal: String(benchmark_deal?.id),
        deal_ids: deals_to_compare
          .map((d) => String(d?.id))
          .filter((d) => d !== String(benchmark_deal?.id)),
        id: id,
      });
      setOpenEditFormModal(true);
    }
  };

  const handleUpdate = async (form: IUpdateDealComparisonForm) => {
    const deal = await updateDealComparisonCallAPI(Number(form.id), form);

    if (deal) {
      setDealComparisons((prevState) => {
        return prevState?.map((c) => {
          if (c.id === deal.id) {
            return deal;
          }
          return c;
        });
      });
    }

    return deal;
  };

  return (
    <>
      <ViewWrapper
        loading={loadingGetDealComparisons}
        error={getDealComparisonsFailed}
      >
        <Box className={styles.classes.container}>
          <Box className={styles.classes.topSection}>
            <Box className={styles.classes.searchAndFilterContainer}>
              <Searchbar
                searchString={searchString}
                onTextChange={onSearchStringChange}
              />
            </Box>
            <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
              <Button
                canOpenUpgrade
                startIcon={<AddIcon />}
                btnType="primary"
                label="Add Deal Comparison"
                onClick={handleOpenAddDealComparisonModal}
              />
            </Protect>
          </Box>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer classes={{ root: styles.classes.tableContainer }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {columns.map((column, idx) => {
                      if (column.id === "action") {
                        return (
                          <StickyTableCell
                            key={idx}
                            direction="right"
                            fixedColumnWidth={50}
                            align="center"
                            highZIndex
                          >
                            {column.label}
                          </StickyTableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left" | "right"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={sortTable.orderBy === column.id}
                            direction={
                              sortTable.orderBy === column.id
                                ? sortTable.order
                                : "asc"
                            }
                            onClick={() => sortRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {visibleRows?.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        No deal comparison found.
                      </TableCell>
                    </TableRow>
                  )}
                  {visibleRows.map((dealComparison, idx) => {
                    return (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        className={styles.classes.dataRow}
                        onClick={() => goToDealComparison(dealComparison?.id)}
                        data-pw={`deal-comparison-row-${idx + 1}`}
                      >
                        <TableCell align="left" title={dealComparison.name}>
                          {trimString(dealComparison?.name, 40)}
                        </TableCell>
                        <TableCell align="left" title="">
                          {dealComparison?.benchmark_deal !== null
                            ? trimString(
                                dealComparison?.benchmark_deal.name,
                                80,
                              )
                            : ""}
                        </TableCell>
                        <TableCell align="left">
                          {dealComparison.deal_count}
                        </TableCell>
                        <TableCell align="left">
                          {format(
                            new Date(dealComparison?.modified),
                            "M/d/yyyy",
                          )}
                        </TableCell>
                        <StickyTableCell
                          direction="right"
                          fixedColumnWidth={50}
                          align="center"
                        >
                          <Protect permission={USER_PERMISSIONS.DEALS_CRUD}>
                            <PopoverMenu
                              uniqueId={idx}
                              canOpenUpgrade
                              items={[
                                {
                                  label: "Edit",
                                  onClick: () =>
                                    handleEditDealComparison(
                                      dealComparison?.id,
                                    ),
                                },
                                {
                                  label: "Delete",
                                  onClick: () =>
                                    handleOnDelete(dealComparison?.id),
                                },
                              ]}
                            />
                          </Protect>
                        </StickyTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={dealComparisons?.length}
              className={styles.classes.paginationRow}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </ViewWrapper>
      <AddDealComparisonFormModal
        headerLabel="Add a new Deal Comparison"
        open={addDealComparisonModalOpen}
        loading={addDealComparisonLoading}
        form={addDealComparisonForm}
        formErrors={addDealComparisonFormErrors}
        setFormErrors={setAddDealComparisonFormErrors}
        setForm={setAddDealComparisonForm}
        onClose={handleCloseAddDealComparisonModal}
        onConfirm={handleAddDealComparison}
        existingDeals={dealOptions}
      />

      <UpdateDealComparisonFormModal
        headerLabel="Edit Deal Comparison"
        open={openEditFormModal}
        loading={loadingUpdateDealComparison}
        form={updateDealComparisonForm}
        setForm={setUpdateDealComparisonForm}
        onClose={() => setOpenEditFormModal(false)}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        existingDeals={dealOptions}
        onConfirm={handleUpdate}
      />
    </>
  );
}
