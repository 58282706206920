import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    container: {
      height: "100%",
    },
    header: {
      display: "flex",
      justifyContent: "end",
    },
    content: {
      marginTop: theme.spacing(2),
      display: "flex",
      width: "100%",
      gap: theme.spacing(2),
      "& > *": {
        flex: 0.5,
      },
    },
    date: {
      display: "inline-block",
      width: 80,
      textAlign: "right",
      marginRight: theme.spacing(2),
    },
    logsButtonContainer: {
      marginRight: theme.spacing(2),
    },
    showMoreCurveBtn: {
      float: "right",
      textTransform: "none",
      color: selectedTheme.color.secondary,
      paddingRight: 0,
    },
  };
});
