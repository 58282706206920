import React from "react";
import { useDispatch } from "react-redux";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import Tabs from "../tabs";
import LoaderScreen from "../loader-screen";
import TabbedLayout from "../tabbed-layout";
import { DEAL_OUTPUT_PAGE_TABS } from "../../constants";
import { ITab, IDealOutputPageTabs, ReportTerm } from "../../interfaces";
import { useAppSelector, useSessionStorage } from "../../utils/hooks";
import {
  updateDealOutputLoadedAction,
  updateSelectedDealOutputPageTabAction,
} from "../../utils/redux/slices";

interface IProps {
  children: JSX.Element;
}

export default function DealOutputPagesLayout({
  children,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { dealId, caseDealId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const { currentDeal, selectedDealOutputPageTab, casesOfDeal } =
    useAppSelector((s) => s.deal);

  const isTermTaxFlip = searchParams.get("term") === "taxflip";
  const isOutputTaxEquityCash = pathname.includes("/output/tax-equity/cash");

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealId}-output-buyout`,
    "default",
  );

  React.useEffect(() => {
    if (searchParams.has("term")) {
      const buyout = searchParams.get("term") as ReportTerm;
      if (isTermTaxFlip && !isOutputTaxEquityCash) {
        setBuyout("default");
        searchParams.set("term", "default");
        setSearchParams(searchParams);
      } else {
        setBuyout(buyout);
      }
    } else {
      searchParams.append("term", buyout);
      setSearchParams(searchParams);
    }
  }, [isTermTaxFlip, isOutputTaxEquityCash]);

  React.useEffect(() => {
    // reset deal output loaded whenever the case deal is changed.
    dispatch(updateDealOutputLoadedAction(false));
  }, [caseDealId]);

  const selectedSidebarItem = React.useMemo(
    () => pathname.split("/")[6] || "",
    [pathname],
  );

  const tabsOfPage = React.useMemo(() => {
    let tabs =
      DEAL_OUTPUT_PAGE_TABS[selectedSidebarItem as keyof IDealOutputPageTabs];

    const dealTaxCreditStructure = currentDeal?.tax_credit_structure;
    const dealStructureToUse = currentDeal?.structure;
    const hasConstructionLoan = currentDeal?.has_construction_loan;
    const hasInvestorBridgeLoan = currentDeal?.has_investor_bridge_loan;

    if (
      dealStructureToUse === "EO" &&
      selectedSidebarItem === "sponsor-equity"
    ) {
      tabs = tabs.filter(
        (tab) =>
          tab.value !== "accounting" &&
          tab.value !== "outside-basis" &&
          tab.value !== "capital-accounts",
      );
    }

    if (
      selectedSidebarItem === "construction-debt" &&
      dealTaxCreditStructure !== "TR"
    ) {
      tabs = tabs.filter((tab) => tab.value !== "transfer-bridge-loan");
    }

    if (selectedSidebarItem === "construction-debt" && !hasConstructionLoan) {
      tabs = tabs.filter((tab) => tab.value !== "construction-loan");
    }

    if (selectedSidebarItem === "construction-debt" && !hasInvestorBridgeLoan) {
      tabs = tabs.filter((tab) => tab.value !== "investor-bridge-loan");
    }

    if (dealStructureToUse === "EO") {
      tabs = tabs.filter((tab) => tab.value !== "splits");
    }

    return tabs;
  }, [currentDeal, selectedSidebarItem, casesOfDeal, caseDealId]);

  const selectedTab = React.useMemo(() => {
    const tab = tabsOfPage.find((tab) => {
      const path = tab.path;

      return pathname.includes(
        path
          ?.replace(":dealId", String(dealId))
          .replace(":caseDealId", String(caseDealId))
          .replace(":activePage", selectedSidebarItem) || "",
      );
    });

    return tab?.value;
  }, [pathname, tabsOfPage]);

  const goToPage = (tab: ITab) => {
    if (tab.value !== selectedTab) {
      dispatch(
        updateSelectedDealOutputPageTabAction({
          ...selectedDealOutputPageTab,
          [selectedSidebarItem]: tab.value,
        }),
      );

      let path = tab.path;
      path =
        path
          ?.replace(":dealId", String(dealId))
          .replace(":caseDealId", String(caseDealId))
          .replace(":activePage", selectedSidebarItem) || "";

      navigate(path);
    }
  };

  const hasStickyTabs: boolean = React.useMemo(() => {
    const pagesWithStickyTabs = ["summary", "charts", "splits"];
    const containsTabs = pagesWithStickyTabs.some((page) =>
      pathname.includes(page),
    );
    return containsTabs;
  }, [pathname]);

  return !currentDeal || currentDeal.id !== Number(caseDealId) ? (
    <LoaderScreen />
  ) : (
    <TabbedLayout module="Deals" pages="Output" hasStickyTabs={hasStickyTabs}>
      <>
        <Tabs
          onClick={goToPage}
          tabs={tabsOfPage}
          selectedTab={selectedTab}
          hasStickyTabs={hasStickyTabs}
        />
        {children}
      </>
    </TabbedLayout>
  );
}
