import ConfigurationPageLayout from "../../../components/configuration-page-layout";
import ConfigurationOrganizationProfileView from "../../../views/configuration/organization/profile";

export default function ConfigurationOrganizationProfilePage() {
  return (
    <ConfigurationPageLayout showOrgTabs>
      <ConfigurationOrganizationProfileView />
    </ConfigurationPageLayout>
  );
}
