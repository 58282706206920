import { enumToOptions } from "../../utils/helpers";

export enum PROJECT_CREDIT_SUPPORT_COST_METHOD {
  CC = "Cash Collateral",
  LC = "Letter of Credit",
  PG = "Parent Guarantee",
}

export const PROJECT_CREDIT_SUPPORT_COST_METHOD_OPTIONS = enumToOptions(
  PROJECT_CREDIT_SUPPORT_COST_METHOD,
);

export enum PROJECT_CREDIT_SUPPORT_TYPE {
  DL = "$",
  DLPAD = "$/kWac",
}

export enum PROJECT_OBLICATION_TYPE {
  OF = "Offtake",
  IC = "Interconnection",
  LE = "Lease",
  OT = "Other",
}

export const PROJECT_OBLICATION_TYPE_OPTIONS = enumToOptions(
  PROJECT_OBLICATION_TYPE,
);

export const PROJECT_CREDIT_SUPPORT_TYPE_OPTIONS = enumToOptions(
  PROJECT_CREDIT_SUPPORT_TYPE,
);

export enum DATE_SELECTION_TYPES {
  MD = "Milestone Date",
  SPD = "Specified Date",
}

export const DATE_SELECTION_TYPE_OPTIONS = enumToOptions(DATE_SELECTION_TYPES);

export enum MILESTONE_DATE_TYPES {
  BOL = "BOL",
  NTP = "NTP",
  COD = "COD",
}

export const MILESTONE_DATE_TYPE_OPTIONS = enumToOptions(MILESTONE_DATE_TYPES);

export const YEAR_FRAC_CONVENTION_TYPE = {
  "30360": "30/360",
  ACTACT: "Actual/Actual",
};

export const YEAR_FRAC_CONVENTION_TYPE_OPTIONS = enumToOptions(
  YEAR_FRAC_CONVENTION_TYPE,
);
