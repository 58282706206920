import React from "react";

import Modal from "../modal";
import TextInput from "../text-input";
import {
  IDuplicateDealForm,
  IDuplicateDealFormErrors,
  IDuplicateDealResponse,
  SetStateAction,
} from "../../interfaces";

interface IProps {
  open: boolean;
  form: IDuplicateDealForm;
  headerLabel: string;
  loading: boolean;
  formErrors?: IDuplicateDealFormErrors;
  setFormErrors: SetStateAction<IDuplicateDealFormErrors | undefined>;
  setForm: SetStateAction<IDuplicateDealForm>;
  onClose: () => void;
  onConfirm: (
    form: IDuplicateDealForm,
  ) => Promise<IDuplicateDealResponse | undefined>;
}

export default function DealDuplicateModal({
  open,
  form,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  setForm,
  onClose,
  onConfirm,
}: IProps): JSX.Element {
  const clearErrorOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [e.target.name]: "",
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const deal = await onConfirm(form);
    deal && handleOnClose();
  };

  const handleResetForm = () => {
    setForm({
      name: "",
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <TextInput
        required
        label="Name"
        name="name"
        onFocus={clearErrorOnFocus}
        onChange={handleTextChange}
        error={Boolean(formErrors?.name)}
        helperText={formErrors?.name}
        disabled={loading}
      />
    </Modal>
  );
}
