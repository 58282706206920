import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputLessorSummaryView from "../../../../views/deal/output/sale-leaseback/lessor/summary";
import { getOutputLessorSummary } from "../../../../apis/deal/sale-leaseback";

export default function DealOutputLessorSummary() {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputLessorSummaryView
          getLessorOutputSummary={getOutputLessorSummary}
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}
