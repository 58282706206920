import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  ICurveGroup,
  IOrganization,
  IOrganizationCurve,
  IProductUsageDetails,
} from "../../../interfaces";

export interface IOrgSliceState {
  productUsageDetails: IProductUsageDetails | null;
  showUsageLimitHitModal: boolean;
  currentOrg: IOrganization | undefined;
  currentOrgCurveGroups: ICurveGroup[];
  currentOrgCurves: IOrganizationCurve[];
}

export const orgSlice = createSlice({
  name: "org",
  initialState: {
    productUsageDetails: null,
    showUsageLimitHitModal: false,
    currentOrg: undefined,
    currentOrgCurveGroups: [],
    currentOrgCurves: [],
  },
  reducers: {
    setProductUsageDetails(
      state: IOrgSliceState,
      action: PayloadAction<IOrgSliceState["productUsageDetails"]>,
    ) {
      state.productUsageDetails = action.payload;
    },
    updateShowUsageLimitHitModal(
      state: IOrgSliceState,
      action: PayloadAction<IOrgSliceState["showUsageLimitHitModal"]>,
    ) {
      state.showUsageLimitHitModal = action.payload;
    },
    setCurrentOrg(
      state: IOrgSliceState,
      action: PayloadAction<IOrgSliceState["currentOrg"]>,
    ) {
      state.currentOrg = action.payload;
    },
    setCurrentOrgCurveGroups(
      state: IOrgSliceState,
      action: PayloadAction<IOrgSliceState["currentOrgCurveGroups"]>,
    ) {
      state.currentOrgCurveGroups = action.payload;
    },
    setCurrentOrgCurves(
      state: IOrgSliceState,
      action: PayloadAction<IOrgSliceState["currentOrgCurves"]>,
    ) {
      state.currentOrgCurves = action.payload;
    },
  },
});
