import ConfigurationPageLayout from "../../../components/configuration-page-layout";
import ConfigurationOrganizationUsersView from "../../../views/configuration/organization/users";

export default function ConfigurationOrganizationUsersPage() {
  return (
    <ConfigurationPageLayout showOrgTabs>
      <ConfigurationOrganizationUsersView />
    </ConfigurationPageLayout>
  );
}
