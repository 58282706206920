import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";

import Modal from "../modal";
import SelectInput from "../select-input";
import {
  IDealTiming,
  SetStateAction,
  IUpdateDealTimingForm,
  IUpdateDealTimingFormErrors,
} from "../../interfaces";
import {
  MONTH_OPTIONS,
  UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE,
  DEAL_TIMING_DEVELOPER_HORIZON_OPTIONS,
} from "../../constants";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: IUpdateDealTimingFormErrors;
  setFormErrors: SetStateAction<IUpdateDealTimingFormErrors | undefined>;
  form: IUpdateDealTimingForm;
  setForm: SetStateAction<IUpdateDealTimingForm>;
  onClose: () => void;
  onConfirm: (form: IUpdateDealTimingForm) => Promise<IDealTiming | undefined>;
  isTransfer: boolean;
  hide?: (keyof IUpdateDealTimingForm)[];
}

export default function DealTimingFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  isTransfer,
  hide = [],
}: IProps): JSX.Element {
  const clearNonTextFieldErrorOnFocus = (name: string) => {
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleOnClose = () => {
    onClose();
  };

  const handleOnConfirm = async () => {
    const timing = await onConfirm(form);
    timing && handleOnClose();
  };

  const handleSelectInputChange = (
    e: SelectChangeEvent<unknown>,
    field: string,
  ) => {
    setForm((prev) => {
      return {
        ...prev,
        [field]: e.target.value,
      };
    });
  };

  const handleResetForm = () => {
    setForm(UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE);
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={handleOnClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
    >
      <Box>
        {!hide?.includes("developer_horizon") && (
          <SelectInput
            required
            label="Developer Horizon"
            selected={form.developer_horizon}
            items={DEAL_TIMING_DEVELOPER_HORIZON_OPTIONS}
            onFocus={() => clearNonTextFieldErrorOnFocus("developer_horizon")}
            onChange={(e) => handleSelectInputChange(e, "developer_horizon")}
            error={Boolean(formErrors?.developer_horizon)}
            helperText={formErrors?.developer_horizon}
            disabled={loading}
          />
        )}

        {isTransfer && (
          <>
            {!hide.includes("tct_seller_tax_year_end_month") && (
              <SelectInput
                required
                label="Seller's Tax Year End Month"
                selected={form.tct_seller_tax_year_end_month || ""}
                items={MONTH_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("tct_seller_tax_year_end_month")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "tct_seller_tax_year_end_month")
                }
                error={Boolean(formErrors?.tct_seller_tax_year_end_month)}
                helperText={formErrors?.tct_seller_tax_year_end_month}
                disabled={loading}
              />
            )}
            {!hide.includes("tct_buyer_tax_year_end_month") && (
              <SelectInput
                required
                label="Buyer's Tax Year End Month"
                selected={form.tct_buyer_tax_year_end_month || ""}
                items={MONTH_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("tct_buyer_tax_year_end_month")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "tct_buyer_tax_year_end_month")
                }
                error={Boolean(formErrors?.tct_buyer_tax_year_end_month)}
                helperText={formErrors?.tct_buyer_tax_year_end_month}
                disabled={loading}
              />
            )}
            {!hide.includes("tct_seller_tax_filing_month") && (
              <SelectInput
                required
                label="Seller's Tax Filing Month"
                selected={form.tct_seller_tax_filing_month || ""}
                items={MONTH_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("tct_seller_tax_filing_month")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "tct_seller_tax_filing_month")
                }
                error={Boolean(formErrors?.tct_seller_tax_filing_month)}
                helperText={formErrors?.tct_seller_tax_filing_month}
                disabled={loading}
              />
            )}
            {!hide.includes("tct_buyer_tax_filing_month") && (
              <SelectInput
                required
                label="Buyer's Tax Filing Month"
                selected={form.tct_buyer_tax_filing_month || ""}
                items={MONTH_OPTIONS}
                onFocus={() =>
                  clearNonTextFieldErrorOnFocus("tct_buyer_tax_filing_month")
                }
                onChange={(e) =>
                  handleSelectInputChange(e, "tct_buyer_tax_filing_month")
                }
                error={Boolean(formErrors?.tct_buyer_tax_filing_month)}
                helperText={formErrors?.tct_buyer_tax_filing_month}
                disabled={loading}
              />
            )}
          </>
        )}
      </Box>
    </Modal>
  );
}
