/* eslint-disable  @typescript-eslint/no-explicit-any */

import { toast } from "react-toastify";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const showToastOnFail = (
  e: AxiosError<{ detail?: string; non_field_errors?: string[] }>,
  toastId: string,
) => {
  const isToastActive = toast.isActive(toastId);
  if (e.response) {
    let errorMessage =
      e.response.data?.detail || e.response.data.non_field_errors;

    // Convert "•" into bullet points
    if (Array.isArray(errorMessage)) {
      errorMessage = errorMessage.map((error) => `• ${error}`).join("\n");
    }

    if (errorMessage && isToastActive) {
      toast.update(toastId, {
        render: errorMessage,
      });
    } else if (errorMessage && !isToastActive) {
      toast.error(errorMessage, {
        toastId,
        style: { whiteSpace: "pre-line" },
      });
    }

    if (e.response.status !== 400 && isToastActive) {
      toast.update(toastId, {
        render: "Something went wrong. Please try again later.",
      });
    } else if (e.response.status !== 400 && !isToastActive) {
      toast.error("Something went wrong. Please try again later.", {
        toastId,
      });
    }
  } else {
    toast.error("Something went wrong. Please try again later.", { toastId });
  }
};

const formatFieldErrors = (
  e: AxiosError<{
    [k: string]: string[] | { [k: string | number]: string[] };
  }>,
) => {
  if (e?.response?.data) {
    const formatedErrors: { [k: string]: string | string[] } = {};
    const errorObj = e.response.data;

    Object.keys(errorObj).forEach((key) => {
      if (typeof errorObj[key] === "object" && !Array.isArray(errorObj[key])) {
        const arrayObj = errorObj[key];
        const formatedArrayErrors: string[] = [];
        Object.keys(arrayObj).forEach((k) => {
          if (!isNaN(parseInt(k)) && Array.isArray(arrayObj[Number(k)])) {
            formatedArrayErrors[Number(k)] = (arrayObj[Number(k)] as []).join(
              " ",
            );
          }
        });

        formatedErrors[key] = formatedArrayErrors;
      } else {
        formatedErrors[key] = errorObj[key][0];
      }
    });
    return formatedErrors;
  }
  return undefined;
};

export const replaceParams = (url: string, params: Record<string, any>) => {
  let newUrl = url;
  Object.keys(params).forEach((param) => {
    newUrl = newUrl.replace(`:${param}`, params[param]);
  });
  return newUrl;
};

export const addParamsToUrl = (
  url: string,
  params: Record<string, any>,
  addParams: Record<string, any>,
): string => {
  url = replaceParams(url, params);
  const searchParams = new URLSearchParams();

  Object.keys(addParams).forEach((param) => {
    searchParams.append(param, addParams[param]);
  });
  return `${url}?${searchParams.toString()}`;
};

export class AxiosHelper {
  private static getToastId = (method: string, url: string): string => {
    return method === "" ? url : `${method}-${url}`;
  };

  static get = async <Response>(url: string, config?: AxiosRequestConfig) => {
    try {
      const response: AxiosResponse<Response> = await axios.get(url, config);
      return response.data;
    } catch (e: any) {
      if (e.response?.status !== 429) {
        showToastOnFail(e, this.getToastId(config?.method || "", url));
      }
      if (e.response?.status === 404) {
        window.location.replace("/404");
      }
      throw e;
    }
  };

  static post = async <Data, Success>(
    url: string,
    data?: Data,
    config?: AxiosRequestConfig,
  ) => {
    try {
      const response: AxiosResponse<Success> = await axios.post(
        url,
        data,
        config,
      );

      return response.data;
    } catch (e: any) {
      showToastOnFail(e, this.getToastId(config?.method || "", url));
      e.fieldErrors = formatFieldErrors(e);
      throw e;
    }
  };

  static put = async <Data, Success>(
    url: string,
    data?: Data,
    config?: AxiosRequestConfig,
  ) => {
    try {
      const response: AxiosResponse<Success> = await axios.put(
        url,
        data,
        config,
      );

      return response.data;
    } catch (e: any) {
      showToastOnFail(e, this.getToastId(config?.method || "", url));
      e.fieldErrors = formatFieldErrors(e);
      throw e;
    }
  };

  static patch = async <Data, Success>(
    url: string,
    data?: Data,
    config?: AxiosRequestConfig,
  ) => {
    try {
      const response: AxiosResponse<Success> = await axios.patch(
        url,
        data,
        config,
      );

      return response.data;
    } catch (e: any) {
      showToastOnFail(e, this.getToastId(config?.method || "", url));
      e.fieldErrors = formatFieldErrors(e);
      throw e;
    }
  };

  static delete = async (url: string, config?: AxiosRequestConfig) => {
    try {
      await axios.delete(url, config);
      return true;
    } catch (e: any) {
      showToastOnFail(e, this.getToastId(config?.method || "", url));
      throw e;
    }
  };
}
