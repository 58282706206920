import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import InfoIcon from "@mui/icons-material/Info";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import ListSubheader from "@mui/material/ListSubheader";
import FormHelperText from "@mui/material/FormHelperText";
import Select, { SelectChangeEvent, SelectProps } from "@mui/material/Select";

import useStyles from "./styles";
import Tooltip from "../tooltip";
import { ISelectOption } from "../../interfaces";

interface IProps extends SelectProps {
  label: string;
  selected: string;
  items: ISelectOption[];
  helperText?: string;
  dataPw?: string;
  groupedItems?: { [k: string]: ISelectOption[] };
  tooltip?: string;
  fullWidth?: boolean;
  firstItem?: { label: string; value: string; onClick?: () => void }; // New prop
}

export default function SelectInput({
  label,
  selected,
  items,
  helperText,
  groupedItems,
  dataPw,
  tooltip,
  fullWidth = true,
  firstItem,
  ...selectProps
}: IProps): JSX.Element {
  const styles = useStyles({ fullWidth });

  const renderGroupItems = (items: ISelectOption[]) => {
    return items.map((item, idx) => (
      <MenuItem
        key={idx}
        value={item.value}
        className={styles.classes.groupedItem}
        disabled={item.disabled}
      >
        {item.label}
      </MenuItem>
    ));
  };

  const handleChange = (
    event: SelectChangeEvent<unknown>,
    child: React.ReactNode,
  ) => {
    const selectedValue = event.target.value as string;
    if (firstItem && selectedValue === firstItem.value && firstItem.onClick) {
      firstItem.onClick();
    } else {
      selectProps.onChange && selectProps.onChange(event, child);
    }
  };

  return (
    <Box className={styles.classes.container}>
      <FormControl classes={{ root: styles.classes.formControl }} fullWidth>
        <InputLabel id="select-label" error={selectProps.error}>
          {label}
        </InputLabel>

        <Select
          {...selectProps}
          size="small"
          labelId="select-label"
          value={selected}
          label={label}
          onChange={handleChange}
          classes={{ select: styles.classes.select }}
          componentsProps={{ root: { className: styles.classes.selectRoot } }}
          MenuProps={{ classes: { paper: styles.classes.menuPaper } }}
          SelectDisplayProps={
            {
              "data-pw": dataPw || label,
            } as SelectProps["SelectDisplayProps"]
          }
        >
          {firstItem && (
            <MenuItem value={firstItem.value} key="first-item">
              {firstItem.label}
            </MenuItem>
          )}
          {groupedItems
            ? Object.keys(groupedItems).map((key) => {
                const label = <ListSubheader>{key}</ListSubheader>;
                const items = renderGroupItems(groupedItems[key]);

                return [label, items];
              })
            : null}
          {items.map((item, idx) => {
            return (
              <MenuItem key={idx} value={item.value} disabled={item.disabled}>
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
        {selectProps.error && (
          <FormHelperText
            data-pw={`helper-text-${dataPw || label}`}
            error={selectProps.error}
          >
            {helperText}
          </FormHelperText>
        )}
      </FormControl>

      {tooltip && (
        <Tooltip title={tooltip} placement="top-end">
          <InfoIcon classes={{ root: styles.classes.infoIcon }} />
        </Tooltip>
      )}
    </Box>
  );
}
