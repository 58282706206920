import ProjectDepreciationStreamDetailsView from "../../../../views/project/tax/depreciation/details";
import ProjectPagesLayout from "../../../../components/project-pages-layout";
import TabbedLayout from "../../../../components/tabbed-layout";
import {
  getProjectDepreciationStream,
  updateProjectDepreciationStream,
  deleteProjectDepreciationStream,
} from "../../../../apis/project/depreciation";

export default function RevenueList(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Tax">
        <ProjectDepreciationStreamDetailsView
          getProjectDepreciationStream={getProjectDepreciationStream}
          updateProjectDepreciationStream={updateProjectDepreciationStream}
          deleteProjectDepreciationStream={deleteProjectDepreciationStream}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}
