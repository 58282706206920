import DealPagesLayout from "../../../components/deal-pages-layout";
import DealSizingOther from "../../../views/deal/sizing/other";
import TabbedLayout from "../../../components/tabbed-layout";
import {
  getDealOtherSizing,
  updateDealOtherSizing,
} from "../../../apis/deal/other";

export default function SizingOther() {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Sizing">
        <DealSizingOther
          getDealOtherSizing={getDealOtherSizing}
          updateDealOtherSizing={updateDealOtherSizing}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}
