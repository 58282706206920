import Layout from "../../components/layout";
import WorkflowThirdPartyReportsView from "../../views/workflow/third-party-reports";

export default function WorkflowThirdPartyReportsPage(): JSX.Element {
  return (
    <Layout title="Workflow">
      <WorkflowThirdPartyReportsView />
    </Layout>
  );
}
