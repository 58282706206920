import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import useStyles from "./styles";
import Menu from "../../../../components/menu";
import Tabs from "../../../../components/tabs";
import ViewWrapper from "../../../../components/view-wrapper";
import Chart from "../../../project/pro-forma/dashboard/chart";
import ProformaTableRow from "../../../../components/proforma-table-row";
import { useAPI, useSessionStorage } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";
import {
  PROJECT_TIMING_PERIODICITY,
  PROFORMA_TABLE_CONFIG,
  PROFORMA_DETAIL_COLUMNS,
  PROFORMA_SUMARY_COLUMNS,
  PROJECT_TIMING_PERIODICITY_OPTIONS,
  PROFORMA_DEFAULT_STATE,
  PROFORMA_BASIS,
  PROFORMA_BASIS_OPTIONS,
} from "../../../../constants";
import {
  ITab,
  IProjectProforma,
  ProformaReportType,
  IProjectProformaResponse,
} from "../../../../interfaces";

interface IProps {
  getDealProformaDetails: (
    id: number,
    periodicity: ProformaReportType,
    basis: "cash" | "accrual",
  ) => Promise<IProjectProformaResponse | null>;
  downloadDealProforma: (
    id: number,
    periodicity: ProformaReportType,
    basis: "cash" | "accrual",
  ) => Promise<void>;
}

export default function DealProformaView({
  getDealProformaDetails,
  downloadDealProforma,
}: IProps): JSX.Element {
  const styles = useStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { dealId, caseDealId } = useParams();

  const [headerRowHeight, setHeaderRowHeight] = React.useState<number>(0);
  const [selectedPeriodicity, setSelectedPeriodicity] = useSessionStorage<
    ProformaReportType | ""
  >(`deal-periodicity-${dealId}`, "");
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [selectedTab, setSelectedTab] = React.useState<
    "proforma_summary" | "proforma_detail"
  >("proforma_summary");

  const [selectedBasis, setSelectedBasis] = useSessionStorage<
    "cash" | "accrual"
  >(`deal-selected-basis-${caseDealId}`, "cash");

  const [dealProforma, setDealProforma] = React.useState<IProjectProforma>(
    PROFORMA_DEFAULT_STATE,
  );

  React.useEffect(() => {
    if (searchParams.has("periodicity")) {
      const periodicity = searchParams.get("periodicity") as ProformaReportType;
      setSelectedPeriodicity(periodicity);
    } else {
      setSelectedPeriodicity(selectedPeriodicity || "AN");
      navigate(
        `${location.pathname}?periodicity=${selectedPeriodicity || "AN"}`,
        {
          replace: true,
        },
      );
    }
  }, []);

  React.useEffect(() => {
    if (Number(caseDealId)) {
      // Get the current value from sessionStorage
      const currentValue = sessionStorage.getItem(
        `deal-selected-basis-${caseDealId}`,
      );
      // If there's a value in sessionStorage for the new caseDealId, update activeShowUnlevered
      if (currentValue !== null) {
        setSelectedBasis(JSON.parse(currentValue));
      }
    }
  }, [caseDealId]);

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const {
    callAPI: getDealProformaDetailsCallAPI,
    errored: getDealProformaFailed,
    loading: loadingDealProforma,
  } = useAPI(
    (dealId: number, reportType: ProformaReportType, basis) =>
      getDealProformaDetails(dealId, reportType, basis),
    { initialLoading: true },
  );

  React.useEffect(() => {
    getDealProformaDetailsCallAPI(
      caseDealId,
      selectedPeriodicity,
      selectedBasis,
    ).then((response) => {
      response && setDealProforma(response.data);
    });
  }, [caseDealId, selectedPeriodicity, selectedBasis]);

  const handleSwitchTab = (tab: ITab) => {
    setSelectedTab(tab.value as "proforma_summary" | "proforma_detail");
  };

  const handleReportTypeChange = (v: ProformaReportType) => {
    setSelectedPeriodicity(v);
    navigate(`${location.pathname}?periodicity=${v}`, {
      replace: true,
    });
  };

  const handleDownloadDealProforma = async () => {
    if (selectedPeriodicity && caseDealId) {
      setIsDownloading(true);
      await downloadDealProforma(
        Number(caseDealId),
        selectedPeriodicity,
        selectedBasis,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const handleBasisChange = (basis: "cash" | "accrual") => {
    setSelectedBasis(basis);
  };

  const handleSetHeaderRwHeight = (ref: HTMLTableRowElement) => {
    ref && setHeaderRowHeight(ref.clientHeight);
  };

  function getDealProformaColumns(selected_tab: string): string[] {
    // hide capacity columns in deal proforma for now
    // ToDo: check if we need to show/hide these columns for based on projects present in the deal

    if (selected_tab === "proforma_summary") {
      const hideCols = ["net_capacity"];
      const otherFlowColumnsToHide =
        selectedBasis === "cash"
          ? ["other_flow_tax_total"]
          : ["other_flow_cash_total"];
      hideCols.push(...otherFlowColumnsToHide);

      return PROFORMA_SUMARY_COLUMNS.filter(
        (column) => !hideCols.includes(column),
      );
    } else if (selected_tab === "proforma_detail") {
      const hideCols = ["gross_capacity", "net_capacity"];

      const otherFlowColumnsToHide =
        selectedBasis === "cash"
          ? ["other_flow_tax_total"]
          : ["other_flow_cash_total"];

      hideCols.push(...otherFlowColumnsToHide);

      return PROFORMA_DETAIL_COLUMNS.filter(
        (column) => !hideCols.includes(column),
      );
    }
    return [];
  }

  const requiredColumns = getDealProformaColumns(selectedTab);

  return (
    <>
      <Tabs
        onClick={handleSwitchTab}
        selectedTab={selectedTab}
        tabs={[
          {
            label: "Summary",
            value: "proforma_summary",
          },
          {
            label: "Detail",
            value: "proforma_detail",
          },
        ]}
      />
      <Box className={cn("flex justify-between items-center mt-4")}>
        <Box className={cn("flex gap-4")}>
          <Menu
            selectedItem={
              selectedPeriodicity
                ? PROJECT_TIMING_PERIODICITY[selectedPeriodicity]
                : selectedPeriodicity
            }
            placeholder="Periodicity"
            menuItems={PROJECT_TIMING_PERIODICITY_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleReportTypeChange(o.value as ProformaReportType),
            }))}
          />
          <Menu
            selectedItem={
              selectedBasis ? PROFORMA_BASIS[selectedBasis] : selectedBasis
            }
            placeholder="Basis"
            menuItems={PROFORMA_BASIS_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleBasisChange(o.value as keyof typeof PROFORMA_BASIS),
            }))}
          />
        </Box>

        {!loadingDealProforma && !getDealProformaFailed && (
          <IconButton
            title="Download Deal Pro Forma"
            onClick={handleDownloadDealProforma}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        )}
      </Box>

      <ViewWrapper loading={loadingDealProforma} error={getDealProformaFailed}>
        {dealProforma.proforma_data.length > 0 ? (
          <Box>
            <Box>
              <Chart
                chartData={dealProforma.proforma_data}
                chartFormat={
                  selectedTab === "proforma_summary" ? "summary" : "detail"
                }
                chartElementConfig={PROFORMA_TABLE_CONFIG}
                selectedBasis={selectedBasis}
              />
              <Paper>
                <TableContainer
                  classes={{ root: styles.classes.proformaTableContainer }}
                >
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead className={styles.classes.header}>
                      <ProformaTableRow
                        key={-2}
                        boldRow
                        headermode
                        ref={handleSetHeaderRwHeight}
                        data={dealProforma.totals}
                        requiredColumns={requiredColumns}
                      />
                    </TableHead>
                    <TableBody>
                      <ProformaTableRow
                        key={-1}
                        boldRow
                        sticky
                        headerHeight={headerRowHeight}
                        data={dealProforma.totals}
                        requiredColumns={requiredColumns}
                      />
                      {dealProforma.proforma_data.map((revenue, idx) => {
                        return (
                          <ProformaTableRow
                            key={idx}
                            data={revenue}
                            requiredColumns={requiredColumns}
                          />
                        );
                      })}
                      <ProformaTableRow
                        key={dealProforma.proforma_data.length + 1}
                        boldRow
                        data={dealProforma.totals}
                        requiredColumns={requiredColumns}
                      />
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </Box>
        ) : null}
      </ViewWrapper>
    </>
  );
}
